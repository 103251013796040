import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import { handleNavigation } from "../../../components/src/CommonFunctions";
import { getStorageData } from "../../../framework/src/Utilities";

export interface HelpForm {
    nameType: string
    fullName: string;
    email: string;
    countryCode: string;
    phoneNumber: string;
    message: string;
    inquiryType: string;
    contactType: string;
    file: File | null;
    otherValue: string;
    lastName: string


}
export interface NameType {
    id: number;
    type: string
}
export interface CountryCode {
    label: string,
    phone: string


}
export interface InquiryType {
    id: number,
    inquiryName: string
    inquiryValue: string
}
interface LinkObject {
    url: string;
    title: string;
  }
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    contactUSForm: HelpForm;
    NameType: NameType[];
    namePopupStatus: boolean;
    custoryCodeStatus: boolean;
    contactUsErrorForm: HelpForm;
    sentRequest: any;
    openToasterStatus: boolean
    activeTab: number,
    inquiryType: InquiryType[],
    inquiryBoxStatus: boolean,
    pdfError: string,
    documentUploadStatus: boolean;
    pageLoader: boolean;
    socialLinksData: {icons: string, navigationUrl: string}[];
    poBOLinksData: LinkObject[]
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class ContactUsHelpController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start

    sentContactRequestApiCallId: string = ""
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            contactUSForm: {
                nameType: "Mr.",
                fullName: "",
                email: "",
                countryCode: "United Kingdom (+44)",
                phoneNumber: "",
                message: "",
                inquiryType: "",
                contactType: "",
                file: null,
                otherValue: "",
                lastName: ""

            },
            NameType: [
                { id: 1, type: "Mr." },
                { id: 2, type: "Miss." },
                { id: 3, type: "Mrs." },
                { id: 4, type: "Mx." }
            ],
            inquiryType: [
                { id: 1, inquiryName: "Website Support", inquiryValue: "" },
                { id: 2, inquiryName: "Payment Issues", inquiryValue: "" },
                { id: 3, inquiryName: "Urgent Assistance", inquiryValue: "" },
                { id: 4, inquiryName: "Feedback", inquiryValue: "" },
                { id: 5, inquiryName: "Others", inquiryValue: "" }

            ],
            namePopupStatus: false,
            custoryCodeStatus: false,
            contactUsErrorForm: {
                nameType: "",
                fullName: "",
                email: "",
                countryCode: "",
                phoneNumber: "",
                message: "",
                inquiryType: "",
                contactType: "",
                file: null,
                otherValue: "",
                lastName: ""
            },
            sentRequest: {},
            openToasterStatus: false,
            activeTab: 4,
            inquiryBoxStatus: false,
            pdfError: "",
            documentUploadStatus: false,
            pageLoader: false,
            socialLinksData: [],
            poBOLinksData: []
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        if (apiRequestCallId === this.sentContactRequestApiCallId) {
            if (responseJson && responseJson.message) {
                this.setState({
                    openToasterStatus: true,
                    sentRequest: responseJson.message[0],
                    pageLoader:false,
                    contactUSForm: {
                        nameType: "Mr.",
                        fullName: "",
                        email: "",
                        countryCode: "United Kingdom (+44)",
                        phoneNumber: "",
                        message: "",
                        inquiryType: "",
                        contactType: "",
                        file: null,
                        otherValue: "",
                        lastName: ""
                    },
                }, () => {
                    setTimeout(() => {
                        handleNavigation('LandingPage', {}, this.send, this.props);
                    }, 1000)
                });
            }else{
                this.setState({pageLoader:false})
            }
        }

        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        const mySocialLinksData = await getStorageData("footerNavigationUrl", true);
        this.setState({socialLinksData: mySocialLinksData });    
        const allLinksData = await getStorageData("BOandPOLinks",true)
        this.setState({poBOLinksData: allLinksData})  
    };

    handleClosePopup = (inputType: string) => {
        if (inputType === "nameType") {
            this.setState({ namePopupStatus: false })
        } else if (inputType === 'inquiry') {
            this.setState({ inquiryBoxStatus: false })
        }
        else {
            this.setState({ custoryCodeStatus: false })
        }
    }
    handleOpenNameTypePopup = () => {
        this.setState({
            namePopupStatus: true,
        })
    }

    selectNameType = (nameType: string) => {
        this.setState({
            contactUSForm: { ...this.state.contactUSForm, nameType: nameType }
        }, () => {
            this.handleClosePopup("nameType")
        })
    }
    selectCountryCode = (countryName: string, countryCode: string) => {
        this.setState({
            contactUSForm: { ...this.state.contactUSForm, countryCode: `${countryName}${countryCode}` }
        }, () => {
            this.handleClosePopup("countryCode")
        })
    }

    handleOpenCountryCodePopup = () => {
        this.setState({
            custoryCodeStatus: true
        })
    }

    handleContactUsForm = (event: React.ChangeEvent<HTMLInputElement>) => {
        const errors = { ...this.state.contactUsErrorForm };
        let { name, value } = event.target
        { name === 'message' && this.validateMessageField(value, errors) }
        { name === 'phoneNumber' && this.validatePhoneNumber(value, errors) }
        { name === 'email' && this.validateEmail(value, errors) }
        { name === 'fullName' && this.validateFulName(value, errors) }
        { name === 'lastName' && this.validateLastName(value, errors) }
        { name === 'otherValue' && this.validateOtherField(value, errors) }
        this.setState({
            contactUSForm: { ...this.state.contactUSForm, [event.target.name]: event.target.value },
            contactUsErrorForm: errors

        });
    }

    validateOtherField = (value: string, errors: HelpForm) => {
        if (!value) {
            errors.otherValue = "Please enter the inquiry message"
        } else {
            errors.otherValue = ""
        }
    }

    validateMessageField = (values: string, errors: HelpForm) => {

        if (!values) {
            errors.message = "Please enter the message";
        } else {
            errors.message = ""
        }
    }

    validateLastName = (lastName: string, errors: HelpForm) => {
        const nameRegex = configJSON.nameRegex
        if (!lastName) {
            errors.lastName = `Please enter your last name`

        } else if (!nameRegex.test(lastName)) {
            errors.lastName = "Please enter alphabetical characters";
        } else {
            errors.lastName = ""
        }
    }

    validatecontactUsFormDetails = (values: HelpForm) => {
        const errors = { ...this.state.contactUsErrorForm }
        this.validateFulName(values.fullName, errors);
        this.validateEmail(values.email, errors)
        this.validateLastName(values.lastName, errors);
        this.validatePhoneNumber(values.phoneNumber, errors);
        this.validateMessageField(values.message, errors)
        if (values.contactType === "") {
            errors.contactType = "Please select your contact preferences"
        } else {
            errors.contactType = ""
        }
        if (values.inquiryType === 'Others') {
            this.validateOtherField(values.otherValue, errors)
        }

        return errors;
    };

    validateEmail = (email: string, errors: HelpForm) => {
        const regexEmail = configJSON.emailRegex;
        if (!email) {
            errors.email = "Please enter email address";
        } else if (!regexEmail.test(email)) {
            errors.email = "Please check and enter  valid email address";
        } else {
            errors.email = ""
        }
    };
    validateFulName = (fullName: string, errors: HelpForm) => {
        const nameRegex = configJSON.nameRegex
        if (!fullName) {
            errors.fullName = `Please enter your first name`

        } else if (!nameRegex.test(fullName)) {
            errors.fullName = "Please enter alphabetical characters";
        } else {
            errors.fullName = ""
        }
    }
    validatePhoneNumber = (phoneNumber: string, errors: HelpForm) => {
        const regexNumbers = configJSON.numerRegex
        if (!phoneNumber) {
            errors.phoneNumber = "Phone number is required";
        } else if (!regexNumbers.test(phoneNumber)) {
            errors.phoneNumber = "Please enter in numerical characters";
        } else {
            errors.phoneNumber = ""
        }
    }


    handleDetailsSubmit = () => {
        const errors = this.validatecontactUsFormDetails(this.state.contactUSForm);
        this.setState({ contactUsErrorForm: errors }, () => {
            if (Object.keys(errors).every(key => !(errors[key as keyof HelpForm]))) {
                this.sendContactRequest();
            }
        });
    }

    handleRadioSelectContact = (contactType: string) => {
        this.setState({
            contactUSForm: { ...this.state.contactUSForm, contactType: contactType }
        })
    }

    handleOpenEnquiryBox = () => {
        this.setState({ inquiryBoxStatus: true })

    }

    handleSelectInquiryType = (inquiry: InquiryType, type: string) => {
        this.setState({
            contactUSForm: { ...this.state.contactUSForm, inquiryType: inquiry.inquiryName }
        }, () => {
            this.handleClosePopup(type)
        })

    }


    validatePdf = (pdfName: string): string => {
        const imageExtensions = /\.(jpg|jpeg|png)$/i;
        const pdfExtension = /\.pdf$/i;
        let error = '';

        if (!imageExtensions.test(pdfName) && !pdfExtension.test(pdfName)) {
            error = 'Please select only JPEG/PNG or PDF document';
        }

        return error;
    }

    handleUploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        let pdfError = this.validatePdf(event.target.value);
        this.setState({
            contactUSForm: { ...this.state.contactUSForm, file: file },
            pdfError: pdfError,
            documentUploadStatus: !pdfError,
        });
    }

    sendContactRequest = () => {
        let headers={

        }
        this.setState({ pageLoader: true })
        let contactusformBody = new FormData();
        contactusformBody.append("data[name]", `${this.state.contactUSForm.nameType} ${this.state.contactUSForm.fullName}`);
        contactusformBody.append("data[last_name]", this.state.contactUSForm.lastName);
        contactusformBody.append("data[email]", this.state.contactUSForm.email);
        contactusformBody.append("data[phone_number]", `${this.state.contactUSForm.countryCode} ${this.state.contactUSForm.phoneNumber}`);
        contactusformBody.append("data[description]", this.state.contactUSForm.message);
        contactusformBody.append("data[contact_preference]", this.state.contactUSForm.contactType);
        contactusformBody.append("data[inquiry_type]", this.state.contactUSForm.inquiryType);
        if (this.state.contactUSForm.file) {
            contactusformBody.append("data[contact_attachment]", this.state.contactUSForm.file);
        }
        if(this.state.contactUSForm.inquiryType === 'Others' ) {
            contactusformBody.append("data[others]", this.state.contactUSForm.otherValue);

        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.sentContactRequestApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.contactRequestApiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage), contactusformBody
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpPostMethod
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    // Customizable Area End
}

//@ts-nocheck
import React, { Fragment } from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CancelIcon from '@material-ui/icons/Cancel';
// Customizable Area Start
import Grid from "@material-ui/core/Grid";
import { styled } from "@material-ui/core/styles";
import {
  Container,
  Typography,
  FormControl,
  Paper,
  Box,
  Button,
  Menu,
  MenuItem,
  Divider
} from "@material-ui/core";
import { addimg } from "./assets";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { CustomSelect } from "../../../components/src/CustomizedComponents.web";
import Navbar from "../../../components/src/Navbar";
import Footer from "../../../components/src/Footer.web";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import CountryMenu from "../../../components/src/CountryMenu";
// Customizable Area End

import ContentManagementController, {
  Props,
  configJSON,
} from "./ContentManagementController";
// Customizable Area Start


// Customizable Area End


export default class ContentManagement extends ContentManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start



    // Customizable Area End
  }

  // Customizable Area Start
  getValError = (errorsMessage: string) => {

    return (
      <Typography variant="body1" color="error">{errorsMessage}</Typography>
    );


  };


  renderGenderMenu = () => {
    return (
      <>
        <Menu
          transformOrigin={{ vertical: 'top', horizontal: 'center', }}
          getContentAnchorEl={null}
          open={Boolean(this.state.anchorElgendertitle)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          anchorEl={this.state.anchorElgendertitle as Element | null}
          data-test-id="handlegenderchange4"
          onClose={() => this.salutationpoClose("Mr.")}
          PaperProps={{
            style: {
              padding: "0px 10px", width: "70px", maxHeight: "219px", cursor: "pointer",
              overflowY: "auto",marginTop: "5px",scrollbarWidth: "thin",borderBottomRightRadius: "50% !important",borderRadius: "8px",
            }
          }} >
          <MenuItem  onClick={() => this.salutationpoClose("Mr.")}
            style={{ backgroundColor: this.state.profileForm.salutation === "Mr." ? '#C1E9F9' : 'transparent', borderRadius: "8px", width: "90%", height: "44px" }}
            data-test-id="mrvalue3"
            value="Mr."
          >Mr.
          </MenuItem>
          <Divider style={{ margin: "2px", backgroundColor: "#F1F5F9" }} />
          <MenuItem value="Miss" onClick={() => this.salutationpoClose("Miss")}
            style={{ backgroundColor: this.state.profileForm.salutation === "Miss" ? '#C1E9F9' : 'transparent', borderRadius: "8px", width: "90%", height: "44px" }}
            data-test-id="missvalue3"
          >Miss
          </MenuItem>
          <Divider style={{ margin: "2px", backgroundColor: "#F1F5F9" }} />
          <MenuItem value="mrs." onClick={() => this.salutationpoClose("Mrs.")}
            style={{ backgroundColor: this.state.profileForm.salutation === "Mrs." ? '#C1E9F9' : 'transparent', borderRadius: "8px", width: "90%", height: "44px" }}
            data-test-id="mrsvalue3"
          >Mrs.
          </MenuItem>
          <Divider style={{ margin: "2px", backgroundColor: "#F1F5F9" }} />
          <MenuItem value="Mx." onClick={() => this.salutationpoClose("Mx.")}
            style={{ backgroundColor: this.state.profileForm.salutation === "Mx." ? '#C1E9F9' : 'transparent', borderRadius: "8px", width: "90%", height: "44px" }}
            data-test-id="mxvalue3"
          >Mx.
          </MenuItem>
        </Menu>
      </>
    )
  }

  // Customizable Area End

  render() {

    return (
      // Customizable Area Start
      <>
      
        <Navbar navType="fullNavbar" pageName={'profile'}
        //  profilePhoto={this.state.navbarProfilePhoto} 
        />

        {this.state.popupedit ? <Box
          onClick={() => this.setState({ popupedit: false })}
          style={{ backgroundColor: "transparent", zIndex: 1, position: "absolute", width: "100%", height: "100%", top: "0px", left: "0px" }}>
        </Box> : null}
        <Box style={secondformstyle.contentManagementLayout}>
          <Container style={{ padding: "0% 10%", fontFamily: "inter" }}>
            <Paper
              elevation={3}
              style={{
                margin: "auto",
                borderBottomRightRadius: "35px",
                width: "100%",
                fontFamily: "inter",
                marginBottom: "10%"
              }}
            >
              <Grid
                container
                style={secondformstyle.maingrid as React.CSSProperties}
              >
                <Grid
                  item
                  style={secondformstyle.firstgrid as React.CSSProperties}
                  lg={3}
                  xs={12}
                  sm={3}
                >


                  <img
                    src={this.state.imageUrl || addimg}
                    style={{
                      width: "156px",
                      height: "156px",
                      marginTop: 20,
                      objectFit: "cover",
                      marginLeft: "12%",
                      borderRadius: "50%",
                      border: "solid 5px #B2E4F8",
                      //  position:"relative"
                    }}
                    onClick={() => this.setState((prevState) => ({ popupedit: !prevState.popupedit }))
                    }
                    data-test-id="popupbtn"
                  />

                  {this.state.popupedit ?
                    <Box
                      style={{
                        height: "82px",
                        width: "192px",
                        position: "absolute",
                        backgroundColor: "#fff",
                        marginLeft: "100%",
                        zIndex: 9,
                        boxShadow: "0px 0px 2px black",
                        marginTop: "55%",
                        padding: "2px",
                        borderRadius: "8px",
                      }}

                    >
                      <label
                        htmlFor="icon-button-file"
                      >
                        <input
                          name="imageUplode"
                          accept="image/*"
                          id="icon-button-file"
                          type="file"
                          style={{ display: "none" }}
                          onChange={this.handleFileChange}
                          data-test-id="handleFileChangeImage"
                          ref={this.fileInputRef}
                        />
                        <Button
                          style={{
                            ...secondformstyle.editbutton1,
                            backgroundColor: this.state.hovermouse === 2 ? "#B2E4F8" : "white",textTransform:"none"
                          }}
                          data-test-id="editphoto"
                          onMouseEnter={() => this.setState({ hovermouse: 2 })}
                          onMouseLeave={() => this.setState({ hovermouse: 0 })}
                          onClick={() => this.fileInputRef.current.click()}

                        >

                          < EditIcon style={{ marginRight: "9%" }} />

                          <Typography style={{ color: "black" }}>Add Photo</Typography>
                        </Button>
                      </label>
                      <Button
                        style={{
                          backgroundColor: this.state.hovermouse === 1 ? "#B2E4F8" : "white",
                          ...secondformstyle.editbutton2,textTransform:"none"
                        }}
                        onMouseLeave={() => this.setState({ hovermouse: 0 })}
                        onMouseEnter={() => this.setState({ hovermouse: 1 })}
                        onClick={this.photodelete}
                        data-test-id="deletephoto"
                      >
                        <DeleteOutlineIcon style={{ marginRight: "10%" }} />

                        <Typography style={{ color: "black" }}>Delete Photo</Typography>

                      </Button>
                    </Box> : null}

                </Grid>
                <Grid
                  item
                  lg={6}
                  xs={12}
                  sm={3}
                  style={{ padding: "20px", marginLeft: "8%", marginTop: "2%", display: "flex", alignItems: "flex-start" }}
                >
                  <Typography style={{ color: "#4386A1", fontSize: "36px", fontFamily: "inter", fontWeight: 400 }}>
                    Welcome
                  </Typography>
                  
                </Grid>
              </Grid>
              <Grid container style={{ display: "flex", padding: "7%" }}>
                <Grid item lg={4} xs={12} sm={3}>
                  <span
                    style={{ fontSize: "24px", fontWeight: 400, color: "#0F172A", fontFamily: "inter" }}
                  >
                    Personal Information
                  </span>
                </Grid>
                <Grid item lg={8} xs={12} sm={9}>
                  <div style={{ marginBottom: "5px" }}><label style={secondformstyle.lableText as React.CSSProperties}>Full Name*</label></div>
                  <Contentdiv style={{ width: "100%", display: "flex", height: "44px", backgroundColor: "#E0F4FC" }}>
                  
                    <div onClick={this.handlesalutationpoopen}
                      style={{ ...secondformstyle.salutationSelect, width: "15%", cursor: "pointer",textTransform:"capitalize" } as React.CSSProperties} >
                      <span style={secondformstyle.salutationSpan as React.CSSProperties}>
                        {this.state.profileForm.salutation}
                      </span> <ExpandMoreIcon />
                    </div>

                    {this.renderGenderMenu()}
                    <input
                      type="text"
                      name="firstName"
                      value={this.state.profileForm.firstName}
                      onChange={this.handleProfileChange}
                      data-test-id="firstName"
                      placeholder="First Name"
                      style={{
                        border: "1px solid #CBD5E1",
                        width: "95%",
                        borderLeft: "none",
                        fontSize: "16px",
                        borderTopRightRadius: "8px",
                        borderBottomRightRadius: "8px",
                        paddingLeft: "10px",
                        height: "44px"
                      }}
                    ></input>
                  </Contentdiv>
                  {this.getValError(this.state.isProfileErrors?.firstName)}
                  <div style={{ marginTop: "3%" }}>
                    <label
                      style={secondformstyle.lableText as React.CSSProperties}
                    >
                      Surname*
                    </label>
                    <input
                      type="test"
                      name="surname"
                      value={this.state.profileForm.surname}
                      onChange={this.handleProfileChange}
                      data-test-id="surname"
                      style={secondformstyle.inputbox as React.CSSProperties}
                      placeholder="Last Name"
                    />

                  </div>
                  {this.getValError(this.state.isProfileErrors?.surname)}
                  <div style={{ marginTop: "3%" }}>
                    <label
                      style={secondformstyle.lableText as React.CSSProperties}
                    >
                      Email*
                    </label>
                    <input
                      name="email"
                      value={this.state.profileForm.email}
                      onChange={this.handleProfileChange}
                      data-test-id="email"
                      style={secondformstyle.inputbox as React.CSSProperties}
                      placeholder="kevinbarrett@iamcat.com"
                    />

                  </div>
                  {this.getValError(this.state.isProfileErrors?.email)}

                  <div style={{ marginTop: "3%" }}>
                    <div style={{ marginBottom: "5px" }}><label style={secondformstyle.lableText as React.CSSProperties}>Phone Number*</label></div>

                    <Contentdiv style={{ width: "100%", display: "flex", height: "40px",cursor:"pointer" }}>
                    

                      <div
                        style={{...secondformstyle.salutationSelect} as React.CSSProperties}
                        onClick={this.BoCountryOpen}
                      >
                        <span style={secondformstyle.salutationSpan as React.CSSProperties}>{this.state.profileForm.phoneNumberCountryCode}</span>
                        <ExpandMoreIcon
                        />
                      </div>


                      <CountryMenu anchorEl={this.state.anchorPocountrycode} onClose={this.handelBoCloses} configJSON={configJSON} listType="countries" user="po" width="14.7%"/>

                      <input
                        onChange={this.handleProfileChange}
                        data-test-id="phoneNumber"
                        value={this.state.profileForm.phoneNumber}
                        name="phoneNumber"
                        placeholder="Phone Number"
                        style={{
                          border: "1px solid #CBD5E1",
                          width: "58%",
                          borderLeft: "none",
                          fontSize: "16px",
                          borderTopRightRadius: "8px",
                          borderBottomRightRadius: "8px",
                          paddingLeft: "16px",
                          height: "44px"
                        }}
                      ></input>

                    </Contentdiv>

                  </div>
                  {this.getValError(this.state.isProfileErrors?.phoneNumber)}
                </Grid>
                <Grid item lg={4} xs={12} sm={3} style={{ marginTop: "4.3%" }}>
                  <span
                    style={{ fontSize: "24px", fontWeight: 400, color: "#0F172A", fontFamily: "inter" }}
                  >
                    Address
                  </span>
                </Grid>
                <Grid item lg={8} xs={12} sm={9} style={{ marginTop: "2%" }}>
                  <form>
                    <FormControl style={{ width: "100%" }}>
                      <div style={{ marginTop: "3%" }}>
                        <div style={{ marginBottom: "5px" }}>
                          <Typography style={secondformstyle.lableText as React.CSSProperties}>
                            Country / Region*
                          </Typography>
                        </div>
                        <Contentdiv>
                  

                          <div
                            style={secondformstyle.countryselect as React.CSSProperties}
                            onClick={this.handleCountryOpen}
                          >
                            <span style={secondformstyle.salutationSpan as React.CSSProperties}>{this.state.country}</span>
                            <ExpandMoreIcon
                            />
                          </div>


                          <CountryMenu anchorEl={this.state.anchorElcounrty} onClose={this.countryCloses} configJSON={configJSON} listType="country" user="po" width="36%"/>

                        </Contentdiv>
                      </div>

                    </FormControl>
                    <div style={{ marginTop: "3%" }}>
                      <label style={secondformstyle.lableText as React.CSSProperties}>Address Line 1*</label>
                      <input
                        type="text"
                        onChange={this.handleProfileChange}
                        data-test-id="addressLine1"
                        value={this.state.profileForm.addressLine1}
                        name="addressLine1"
                        style={secondformstyle.inputbox as React.CSSProperties}
                        placeholder="E.g. 125 High Street"
                      ></input>

                    </div>
                    { this.getValError(this.state.isProfileErrors?.addressLine1)}
                    <div style={{ marginTop: "3%" }}>
                      <label style={secondformstyle.lableText as React.CSSProperties}>Address Line 2 </label>
                      <input
                        type="text"
                        onChange={this.handleProfileChange}
                        data-test-id="addressline2"
                        value={this.state.profileForm.addressline2}
                        name="addressline2"
                        style={secondformstyle.inputbox as React.CSSProperties}
                        placeholder="E.g. Kensington"

                      ></input>
                      {this.getValError(this.state.isProfileErrors?.addressline2)}

                    </div>
                    <div style={{ marginTop: "3%" }}>
                      <label style={secondformstyle.lableText as React.CSSProperties}>Town / City*</label>
                      <input
                        type="text"
                        onChange={this.handleProfileChange}
                        data-test-id="town"
                        value={this.state.profileForm.town}
                        name="town"
                        style={secondformstyle.inputbox}
                        placeholder="E.g. London "
                      ></input>
                      {this.getValError(this.state.isProfileErrors?.town)}

                    </div>

                    <div style={{ marginTop: "3%" }}>
                      <label style={secondformstyle.lableText as React.CSSProperties}>County</label>
                      <input
                        type="text"
                        onChange={this.handleProfileChange}
                        data-test-id="county"
                        value={this.state.profileForm.county}
                        name="county"
                        style={secondformstyle.inputbox}
                        placeholder="E.g. Greater London "
                      ></input>
                      {this.getValError(this.state.isProfileErrors?.county)}

                    </div>
                    <div style={{ marginTop: "3%" }}>
                      <label style={secondformstyle.lableText as React.CSSProperties}>Postcode*</label>
                      <input
                        type="text"
                        onChange={this.handleProfileChange}
                        data-test-id="postcode"
                        value={this.state.profileForm.postcode}
                        name="postcode"
                        style={secondformstyle.inputbox}
                        placeholder="SW1A 1AA"
                      ></input>
                      {this.getValError(this.state.isProfileErrors?.postcode)}

                    </div>


                    <Typography
                      style={{ ...secondformstyle.button, marginTop: "70px" , textDecorationLine:"underline" } as React.CSSProperties}
                      data-test-id="save_btn"
                      onClick={this.submitProfileForm}

                    >
                      Save and Continue
                      <KeyboardArrowRightIcon style={{ fontSize: "30px", marginLeft: "10px" }} />
                    </Typography>
                  </form>
                </Grid>
              </Grid>
            </Paper>
          </Container>
        </Box>
        <Footer color={'#E0F4FC'} footerNavigationData={this.state.socialLinksData} boAndPOLinks={this.state.poBOLinksData}/>
      </>
      // Customizable Area End

    );
  }
}
// Customizable Area Start

const Contentdiv: any = styled(Box)({


})

const secondformstyle = {
  contentManagementLayout: {
    width: "100%",
    backgroundColor: "E0F4FC",
    marginTop: 90
  },
  editbutton1: {
    width: "100%",
    height: "50%",
    display: "flex",
  
    borderRadius: "8px",
    color: "black",
    justifyContent: "flex-start",
  },

  editbutton2: {
    height: "50%",
    width: "100%",
    display: "flex",
    color: "black",
    borderRadius: "8px",
    justifyContent: "flex-start",
  },
  inputbox: {
    border: "1px solid #CBD5E1",
    fontSize: "16px",
    width: "100%",
    height: "44px",
    borderRadius: "8px",
    marginTop: "8px",
    marginBottom: "3px",
    paddingLeft: "10px"
  },
  button: {
    display: "flex",
    backgroundColor: "#fff",
    borderRadius: "8px",
    border: "none",
    color: "#EA0C78",
    marginTop: "15px",
    fontSize: "18px",
    height: "40px",
    width: "100%",
    minWidth: "210px",
    alignItems: "center",
    fontWeight: 650,
    justifyContent: "flex-start",
    cursor: "pointer",
    paddingLeft: "0px",
    // textDecorationLine: 'underline 2px'

  },
  fullnameinput: {
    border: "1px solid #CBD5E1",
    width: "100%",
    fontSize: "16px",
    height: "40px",
    borderRadius: "4px",
    marginTop: "8px",
    marginBottom: "10px",
  },
  fullnameselect: {
    border: "none",
    backgroundColor: "#e8eaf6",
    height: "38px",
    fontSize: "16px",
    width: "60px",
  },
  lableText: {
    fontSize: "14px",
    fontFamily: "inter",
    color: "#000",
    fontWeight: 700
  },
  fullnameselect2: {
    border: "none",
    backgroundColor: "#e8eaf6",
    height: "38px",
    fontSize: "16px",
    width: "190px",
  },
  avtar: {
    padding: "15px",
    bottom: "-40px",
    margin: "15px",
    width: "auto",
    backgroundColor: "red",
  },
  firstgrid: {
    color: "#e8eaf6",
    display: "flex",
    justifyContent: "center",
    height: "120px",
    position: "relative",
  },
  maingrid: {
    display: "flex",
    background:
      "linear-gradient(90deg, rgba(240,250,254,1) 0%, rgba(204,237,250,1) 100%)",
    borderBottomRightRadius: "35px",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    height: "auto",
    minHeight: "130px",
    flexWrap: "wrap-reverse",
    marginBottom: "5%",
    fontFamily: "inter",
  },
  salutationSelect: {
    width: "40%",
    paddingLeft: "10px",
    border: "1px solid #B2E4E7",
    height: "42px",
    backgroundColor: "#E0F4FC",
    borderRadius: "8px 0px 0px 8px",
    display: "flex",
    position: "relative",
    alignItems: "center",
    cursor: "pointer",
    justifyContent: "space-between",
    paddingRight:"10px"
  },

  countryselect: {
    width: "96%",
    paddingLeft: "10px",
    border: "1px solid #B2E4E7",
    height: "42px",
    backgroundColor: "#E0F4FC",
    borderRadius: "8px ",
    display: "flex",
    position: "relative",
    alignItems: "center",
    cursor: "pointer",
    justifyContent: "space-between",
    paddingRight:"10px"
  },
  salutationSpan: {
    color: "#0F172A",
    fontSize: "16px",
    fontWeight: 400, fontFamily: "inter", textTransform: "capitalize"
  },
};
// Customizable Area End

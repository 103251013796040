import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { setStorageData } from "../../../framework/src/Utilities";

// Customizable Area Start
export const configJSON = require("./config");
interface SignUpResponse {
  meta: { token: string };

  errors?:
    | {
        account?: string;
      }[]
    | { account?: string; password_confirmation?: string[] };
}
export interface Props {
  id: string;
  navigation:any;
}
// Customizable Area End

export interface S {
  // Customizable Area Start

  email: string;
  password: string;
  showPassword: boolean;
  confirmPassword: string;
  showConfirmPassword: boolean;
  isCheckboxChecked: boolean;
  apiError: string;
  showYourPassword: boolean;
  flashEmailForBusiness:boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class EmailAccountRegistrationPetController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  signUpCallBoId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area End
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      email: "",
      password: "",
      showPassword: false,
      confirmPassword: "",
      showConfirmPassword: false,
      isCheckboxChecked: false,
      apiError: "",
      showYourPassword: false,
      flashEmailForBusiness:false,
      // Customizable Area End
    };

    // Customizable Area Start

    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.signUpCallBoId) {
      this.handleResSignUpForBo(responseJson);
    }
    // Customizable Area End
  }
  // Customizable Area Start
  handleResSignUpForBo = (responseJson: SignUpResponse) => {
    localStorage.removeItem("registerBo")
    if (responseJson.meta) {
      localStorage.setItem("userType", "BO");
      this.setState({ apiError: "" });
      this.setState({
        email: "",
        password: "",
        confirmPassword: "",
        flashEmailForBusiness:true
      },()=>{
        this.OnLoginpageBo()
      });
      localStorage.removeItem("route");
    } else if (Array.isArray(responseJson.errors)) {
      if (responseJson.errors[0].account) {
        this.setState({ apiError: responseJson.errors[0].account });
      }
    } else if (responseJson.errors?.account) {
      this.setState({ apiError: 'Password does not meet the requirements' });
    } else if (responseJson.errors?.password_confirmation) {
      this.setState({
        apiError: 'The passwords you entered did not match',
      });
    }
  };

  navigate = (route: string) => {
    const registerBo = {
      email: this.state.email,
      password: this.state.password,
      confirmPassword: this.state.confirmPassword,
    };
    localStorage.setItem("registerBo", JSON.stringify(registerBo));
    const pathName = window.location.pathname
    const trimmedNextRout = pathName?.substring(1);
    setStorageData("pathName",trimmedNextRout)
    this.props.navigation.navigate(route);
  };

  handleCheckboxChange = () => {
    this.setState((prevState) => ({
      isCheckboxChecked: !prevState.isCheckboxChecked,
    }));
  };
  handlePasswordToggle = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  handleCloseBusinessPopUp=()=>{
    this.setState({ flashEmailForBusiness:false });
  }
  handleYourPasswordToggle = () => {
    this.setState((prevState: S) => ({
      showYourPassword: !prevState.showYourPassword,
    }));
  };

  handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ password: event.target.value });
  };

  handleConfirmPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    this.setState({ confirmPassword: event.target.value });
  };

  getEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      email: event.target.value,
    });
  };

  setData =(parsedData: { email: string; password: string; confirmPassword: string; })=>{
    this.setState({
      email: parsedData?.email || "",
      password: parsedData.password || "",
      confirmPassword: parsedData.confirmPassword || "",
    });
  }

  validatePasswordBoRes = () => {
    const setPassword = this.state.password;

    const isCapitalLetter = /[A-Z]/.test(setPassword);
    const isLowerCaseLetter = /[a-z]/.test(setPassword);
    const isNumber = /\d/.test(setPassword);
    const isMinLength = setPassword.length >= 8;
    const isSpecialChracter= /\W+/.test(setPassword)

    const isValid =
      isCapitalLetter && isLowerCaseLetter && isNumber && isMinLength && isSpecialChracter;

    return {
      isValid,
      isCapitalLetter,
      isLowerCaseLetter,
      isNumber,
      isMinLength,
      isSpecialChracter
    };
  };

  async componentDidMount() {
    // Customizable Area Start
   localStorage.setItem("route","/EmailAccountRegistrationBusinessOwner");
   const data = (localStorage.getItem("registerBo"));
   const parsedData = JSON.parse(data as any)||{}

   this.setData(parsedData)
    // Customizable Area End
  }

  getStartedchange = () => {
  
    localStorage.setItem("route", window.location.pathname);
  };

  ApiForBoSignUp = (event: any) => {
    event.preventDefault()
    const loginHeader = {
      "Content-Type": "application/json",
    };

    const businessOwnerSignUpRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    businessOwnerSignUpRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts`
    );

    businessOwnerSignUpRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(loginHeader)
    );

    businessOwnerSignUpRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        data: {
          attributes: {
            email: this.state.email,
            password: this.state.password,
            password_confirmation: this.state.confirmPassword,
            is_accepted_terms_and_conditions: this.state.isCheckboxChecked.toString(),
          },
          type: "business_owner",
        },
      })
    );

    businessOwnerSignUpRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    this.signUpCallBoId = businessOwnerSignUpRequestMessage.messageId;
    runEngine.sendMessage(businessOwnerSignUpRequestMessage.id, businessOwnerSignUpRequestMessage);
  };


  OnLoginpageBo = () => {
    
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'LogInBusinessOwner');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);


    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), { flashEmailForBusiness:this.state.flashEmailForBusiness });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  }
  // Customizable Area End
}

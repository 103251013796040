import React, { Component } from 'react'
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import CommentOutlinedIcon from '@material-ui/icons/CommentOutlined';
import { Link, NavLink } from "react-router-dom";
export const Logo = require('./group_layer.png')
import Avatar from '@material-ui/core/Avatar';
import {
  getStorageData,
} from "../../framework/src/Utilities";

import { Theme, Typography, WithStyles, createStyles, withStyles } from '@material-ui/core';
import { withRouter, RouteComponentProps } from "react-router-dom";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';


interface Props extends WithStyles {
  active?: number,
  navigation?: any
  profilePhoto?: any,
  iconPage?:string

}


export class BussinessOwnerSideBar extends Component<Props & RouteComponentProps> {

  profileId: string = "";
  state = {
    additionalPrice: "",
    profilePicture: ""
  };


  async componentDidMount() {
    const profilePicture = await getStorageData('accountDetails', true) || {}
    this.setState({ profilePicture: profilePicture?.photo });
  }

  componentDidUpdate() {
    if (window.location.href.includes("BownerDetaild")) {

    }
  }

  handlePropertyNavigation = (type:string) => {
   if(type==='property'){
    this.props.history.push('/PropertyDetails')
   }else if(type==='reservations'){
    this.props.history.push('/ReservationsList')
   }else if(type==='scheduling'){
    this.props.history.push('/Scheduling')}
   else if(type==='chat'){
    this.props.history.push('/Chat')
   }else if(type==='chat'){
    this.props.history.push('/Chat')
   }
  }

  getProfilePicture = () => {
    if(this.props.profilePhoto){
      return `${this.props.profilePhoto}`
    } else {
      return `${this.state.profilePicture}`
    }
  }
  
   handleLogoutUser = () => {
    localStorage.clear()
    setTimeout(async() => {
      this.props.history.push('/LandingPagePO')
  }, 1000);
  }



  render() {
    const { classes } = this.props;
    return (
      <>

       <div className={`${classes.sidebar} ${classes.hideScrollbar}`}>
        <div style={{ cursor: 'pointer', maxHeight: 'calc(100vh - 140px)', overflowY: 'auto' }}></div>
          <div style={{ cursor: "pointer" }} >
              <img alt="logo" src={Logo} style={{marginTop:20}} onClick={()=>this.handlePropertyNavigation('property')}/>
            <div style={{marginTop:70}} >
              <div className={this.props.active == 1 ? classes.activeLink : classes.nonActiveLink}
              onClick={()=>this.handlePropertyNavigation('property')}
              >
                <HomeOutlinedIcon style={{ fontSize: "32px" }} />
                <Typography variant='body2' className={this.props.active == 1 ? classes.activeText : classes.nonActiveText} >Dashboard</Typography>


              </div>
              <div onClick={()=>this.handlePropertyNavigation('reservations')}
                className={this.props.active == 2 ? classes.activeLink : classes.nonActiveLink}
              >
                <EventAvailableIcon style={{ fontSize: "28px" }} />
                <Typography variant='body2' className={this.props.active == 2 ? classes.activeText : classes.nonActiveText} >Reservations</Typography>
              </div>
              <div onClick={()=>this.handlePropertyNavigation('scheduling')}
                className={this.props.active == 3 ? classes.activeLink : classes.nonActiveLink}
              >
                <CalendarTodayIcon style={{ fontSize: "25px" }} />
                <Typography variant='body2' className={this.props.active == 3 ? classes.activeText : classes.nonActiveText}>Calendar </Typography>

              </div>
              <div onClick={()=>this.handlePropertyNavigation('chat')}
                className={this.props.active == 4 ? classes.activeLink : classes.nonActiveLink}>
                <CommentOutlinedIcon style={{ fontSize: "28px" }} />
                <Typography variant='body2' className={this.props.active == 4 ? classes.activeText : classes.nonActiveText} >Inbox</Typography>


              </div>
              <Link  to={'/Transaction/List'} style={{textDecoration:'none'}}>
              <div
                className={this.props.active == 5 ? classes.activeLink : classes.nonActiveLink}>
                <CreditCardIcon style={{ fontSize: "28px" }} />
                <Typography variant='body2' className={this.props.active == 5 ? classes.activeText : classes.nonActiveText} >Transactions</Typography>

              </div>
              </Link>
              
              <Link  to={'/Settings/Bussinessowner'} style={{textDecoration:'none'}}>
              <div 
                className={this.props.active == 6 ? classes.activeLink : classes.nonActiveLink}>
                <SettingsIcon style={{ fontSize: "28px" }} />
                <Typography variant='body2' className={this.props.active == 6 ? classes.activeText : classes.nonActiveText} >Settings</Typography>
              </div>
              </Link>
              <div onClick={this.handleLogoutUser}
                className={this.props.active == 8 ? classes.activeLink : classes.nonActiveLink}>
                <ExitToAppIcon style={{ fontSize: "28px" }} />
                <Typography variant='body2' className={classes.nonActiveText} >Logout</Typography>


              </div>
            </div>
          </div>

          {this.props.iconPage!=='profileBo'&&<div className={classes.profileAvatarLayout}>
            <NavLink to={'/ContentManagementBo'}>
              <Avatar style={{ backgroundColor: '#B2E4F8', width: '70px', height: '70px' }} src={this.getProfilePicture()}>
              
              </Avatar>
            </NavLink>
          </div>}
        </div>
      </>
    )
  }
}


const sideNavStyles = (theme: Theme) =>
  createStyles({

    nonActiveLink: {
      display: "flex" as const,
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      color: theme.palette.info.dark,
      marginBottom: "40px",
      cursor: 'pointer'
    },
    activeLink: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      color: theme.palette.secondary.main,
      marginBottom: "40px",
      cursor: 'pointer'
    },
    activeText: {
      color: theme.palette.secondary.main,
      fontWeight: theme.typography.fontWeightRegular,
      textDecoration: 'none'
    },
    nonActiveText: {
      color: theme.palette.info.dark,
      fontWeight: theme.typography.fontWeightRegular,
      textDecoration: 'none'

    },
    sidebar: {
      position: 'fixed',
      top: 0,
      left: 0,
      height: '100vh',
      backgroundColor: theme.palette.common.white,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      width:'140px',
      overflowY: 'auto', // Enable vertical scrolling
    },
    hideScrollbar: {
      scrollbarWidth: 'none', // Firefox
      '-ms-overflow-style': 'none', // Internet Explorer
      '&::-webkit-scrollbar': {
        display: 'none', // Webkit (Chrome, Safari, etc.)
      },
    },
    profileAvatarLayout:{
      marginTop:60,
      paddingBottom:60
    },
   


  })

const routes = withRouter(BussinessOwnerSideBar)
export default withStyles(sideNavStyles)(routes)
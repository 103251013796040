import { Box, Divider, Grid, Link, Theme, Typography, WithStyles, createStyles, withStyles } from "@material-ui/core";
import React, { Component } from "react";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { setStorageData } from "../../framework/src/Utilities";
import { colors } from "./theme";
import { dotBlack, dotBlue,fb } from "../../blocks/landingpage/src/assets";

interface LinkObject {
  url: string;
  title: string;
}

interface Props extends WithStyles {
  color?: string;
  drawer?: boolean;
  divider?:string;
  footerNavigationData?: {icons: string, navigationUrl: string}[];
  boAndPOLinks?: LinkObject[];
}

class Footer extends Component<Props & RouteComponentProps> {
  navigate = (route: string) => {
    const pathName = window.location.pathname;
    const trimmedNextRout = pathName.substring(1);
    setStorageData("pathName", trimmedNextRout)
    this.props.history.push(route);
  };
  render() {
    const { classes } = this.props
    return (
      <Grid item xs={12} style={{ backgroundColor: this.props.color,margin:'0 10px' }} >
        <Grid container direction="row" alignItems="flex-end" spacing={2} className={classes.footerLayout}>
          {this.props.divider !='hide'
            && <Grid item xs={12}>
              <Divider className={this.props.drawer ? classes.mobileDivider : classes.dividerStyle} />
            </Grid>}
          <Grid item xs={12} sm={8}>
            <Grid container direction="row" spacing={2} className={classes.linkslayout}>
              <Grid item>
                <Typography variant="body1" color="secondary"

                  className={classes.listText}
                >
                  2024 PETHOTEL&copy;</Typography>
              </Grid>
              <Grid item className={classes.dotLayout}>
                <div className={this.props.drawer ? classes.blueDots : classes.singleDot} />&nbsp;&nbsp;&nbsp;
                <Typography variant="body1"
                  className={classes.listText}
                  onClick={() => this.navigate("/PrivacyPolicy")}>
                  Privacy Policy</Typography>
              </Grid>
              <Grid item className={classes.dotLayout}>
                <div className={this.props.drawer ? classes.blueDots : classes.singleDot} />&nbsp;&nbsp;&nbsp;
                <Typography variant="body1"
                  className={classes.listText}

                  onClick={() => this.navigate("/TermsConditions")}>

                  Terms & Conditions
                </Typography>
              </Grid>
            </Grid>
          </Grid>
              <Grid item xs={12} sm={3}>
            <Grid container direction="row" justifyContent="flex-end"  style={{gap:"10px"}}>
            {this.props.boAndPOLinks && this.props.boAndPOLinks.map((link, index) => (
            <Box key={index}>
              <Link className={classes.listText} style={{color: '#EA0C78', fontFamily: "inter"}} href={link.url} target="_blank" rel="noopener noreferrer">
                {link.title}
              </Link>
            </Box>
          ))}
            </Grid>
          </Grid>
          <Grid item xs={12} sm={1}>
            <Grid container direction="row" justifyContent="center" spacing={2}>
            {this.props.footerNavigationData && this.props.footerNavigationData.map((navigationData:{icons: string, navigationUrl: string})=>{
                  return (<Grid item>
                  <Typography component={'a'}
                    href={navigationData.navigationUrl}
                    target="_blank"
                    style={{ color: "#334155" }}
                  >
                    <img src={navigationData.icons} style={{height:"22px",width:"22px"}} />
                  </Typography>
                </Grid>)
            })}
            </Grid>
          </Grid>

        </Grid>

      </Grid>
    );
  }
}

const FooterStyles = (theme: Theme) =>
  createStyles({
    footerLayout: {
      [theme.breakpoints.up('sm')]: {

        padding: '1rem',
      },
      [theme.breakpoints.down('sm')]: {
        margin: "40px 0 0 0",
      }
    },
    linkslayout: {
      [theme.breakpoints.down('xs')]: {
        justifyContent: "space-around"

      }
    },
    mobileDivider: {
      backgroundColor: theme.palette.primary.main

    },
    dividerStyle: {
      height: "2px",
      backgroundColor: theme.palette.primary.light,
    },
    listText: {
      textDecoration: "none",
      color: colors.darkBlack,
      cursor: "pointer",
      fontSize: 14,
      [theme.breakpoints.down('xs')]: {
        color: colors.primaryLightBlue,
        fontSize: 12
      }
    },
    dotImage: {
      width: 4,
      height: 4,
      borderRadius: '50%',
      backgroundImage: `url(${dotBlack})`,
    },
    blueDots: {
      width: 4,
      height: 4,
      borderRadius: '50%',
      backgroundImage: `url(${dotBlue})`,
    },
    dotLayout: {
      display: 'flex',
      alignItems: "center"
    },
    singleDot: {
      width: "5px",
      height: "5px",
      backgroundColor: theme.palette.primary.light,
      borderRadius: "50%",
  }

  })
const styles = withStyles(FooterStyles)(Footer)
export default withRouter(styles);
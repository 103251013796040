import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { baseURL } from "../../bulkuploading/src/BulkUploadingController";
import { DateObject } from "react-multi-date-picker";
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { handleNavigation } from "../../../components/src/CommonFunctions";
// Customizable Area Start
export const configJSON = require("./config");
interface SignUpResponse {
  meta: { token: string };

  errors?:
  | {
    account?: string;
  }[]
  | { account?: string; password_confirmation?: string[] };
}
interface LinkObject { 
  url: string; 
  title: string; 
}
export interface Props {
  id: string;
  navigation: any;

}





// Customizable Area End

export interface S {
  // Customizable Area Start
  selectedPetId: string;
  oldDataCheck: boolean;
  selectedFile: File | null;
  imageUrl: string | null;
  selctedCertificate: File | null;
  fileName: string | null;
  uploadedFileName: string | null;
  inputValueDate: string;
  inputValueMonth: string;
  inputValueYear: string;
  inputValueDateVacction: string;
  inputValueMonthVacction: string;
  inputValueYearVacction: string;
  inputValueDateLastTreatment: string;
  inputValueMonthLastTreatment: string;
  inputValueYearLastTreatment: string;
  inputValueDateWorming: string;
  inputValueMonthWorming: string;
  inputValueYearWorming: string;
  selectedValueSpayed: string;
  petBreed: string;
  petName: string;
  sex: string;
  petColor: string;
  petWeight: string;
  clinicName: string;
  phoneNumber: string;
  clinicAddress: string;
  insurenceProvider: string;
  policyNumber: string;
  microchipNumber: string;
  description: string;
  daietary: string;
  behavior: string;
  petType: string;
  medicalTreatment: string;
  phoneCountry: string;
  isFormVisible: boolean;
  anchorElMenu: any;
  modelOpen: boolean;
  modelOpenCart: boolean;
  afterDetaisFill: boolean;
  error: {
    [key: string]: string;
  };
  MesErr: typeof configJSON.MesErr;
  savedPet: any;
  selectedpet: string,
  guardianmail: string,
  emergencyemail: string,
  genderemergency: string,
  genderguardian: string,
  guardiancheckbox: boolean,
  savepetinfonumber: number,
  dogselected: boolean,
  catselected: boolean,
  emergency_contact_name: string,
  emergency_contact_number: string,
  guardian_name: string,
  guardian_contact_number: string,
  dogbirthdate: any,
  catbirthdate: any,
  vaccination_date: any,
  flea_date: any,
  worming_date: any,
  vaccineerror: boolean,
  fleaerror: boolean,
  wormingError: boolean,
  savedPetbyid: any,
  openEditModal: boolean,
  proceduredate: any,
  emergencyContactCountry: string,
  legalContactCountry: string,
  onHover: number,
  emergencysurname: string,
  guardiansurname: string,
  anchorElgenderemergency: any,
  anchorElgenderguardian: any,
  anchorEmergencycountry: any,
  anchorguardiancountry: any,
  anchorcliniccountry: any,
  anchorbreed: any,
  anchorelsex: any,
  procedureerror: boolean,
  editValue: string,
  from: string,
  EditPetLoader: boolean,
  EditSlackPo: boolean,
  DogbreedList: any[];
  catBreedList: any[];
  selectedBreed: string,
  isOtherBreed: boolean,
  canineParvo: boolean,
  canineInfect: boolean,
  distemper: boolean,
  lepto: boolean,
  kennel: boolean,
  parvoDate: any,
  infectDate: any,
  distemperDate: any,
  leptoDate: any,
  kennelDate: any,
  dogVaccDateError: string,
  dogVaccFileError: string,
  dogVaccError: boolean,
  pravoDateError: boolean,
  infectDateError: boolean,
  disTemperDateError: boolean,
  leptoDateError: boolean,
  kennelDateError: boolean,
  dateErrorMessage: string
  pravoFileError: boolean,
  infectFileError: boolean,
  disTemperFileError: boolean,
  leptoFileError: boolean,
  kennelFileError: boolean,
  apiFetched: boolean;
  vetName:string
  socialLinksData: {icons: string, navigationUrl: string}[];
  poBOLinksData: LinkObject[],
  vaccinationCertificate: File | null,
  dogVaccinationCertName:string,
  dogVaccinationUrl:string;
  genderPopup:boolean

  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class AddPetDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  addPetCallPoId: string = "";
  deletePetCallPoId: string = "";
  getpetApicallID: string = "";
  getPetById: string = "";
  getBreed: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseData),
      // Customizable Area End
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      selectedPetId: "",
      oldDataCheck: false,
      fileName: null,
      uploadedFileName: null,
      imageUrl: null,
      selectedFile: null,
      selctedCertificate: null,
      inputValueDate: "",
      inputValueYearVacction: "",
      inputValueDateLastTreatment: "",
      inputValueMonth: "",
      inputValueYearLastTreatment: "",
      inputValueMonthLastTreatment: "",
      inputValueYear: "",
      inputValueDateVacction: "",
      sex: "",
      petColor: "",
      clinicName: "",
      petWeight: "",
      petBreed: "",
      inputValueMonthVacction: "",
      inputValueDateWorming: "",
      selectedValueSpayed: "",
      petName: "",
      phoneNumber: "",
      inputValueMonthWorming: "",
      inputValueYearWorming: "",
      clinicAddress: "",
      policyNumber: "",
      behavior: "",
      insurenceProvider: "",
      description: "",
      daietary: "",
      microchipNumber: "",
      error: {},
      petType: "",
      isFormVisible: false,
      modelOpen: false,
      medicalTreatment: "true",
      modelOpenCart: false,
      phoneCountry: "United kingdom (+44)",
      MesErr: configJSON.MesErr,
      afterDetaisFill: false,
      savedPet: [],
      anchorElMenu: null,
      selectedpet: "",
      guardianmail: "",
      emergencyemail: "",
      genderemergency: "Mr.",
      genderguardian: "Mr.",
      guardiancheckbox: false,
      savepetinfonumber: 0,
      dogselected: false,
      catselected: false,
      emergency_contact_name: "",
      emergency_contact_number: "",
      guardian_name: "",
      guardian_contact_number: "",
      dogbirthdate: new Date(),
      catbirthdate: new Date(),
      vaccination_date: "",
      flea_date: new Date(),
      worming_date:new Date(),
      vaccineerror: false,
      fleaerror: false,
      wormingError: false,
      savedPetbyid: "",
      openEditModal: false,
      proceduredate: "",
      emergencyContactCountry: "United kingdom (+44)",
      legalContactCountry: "United kingdom (+44)",
      onHover: 0,
      emergencysurname: "",
      guardiansurname: "",
      anchorElgenderemergency: null,
      anchorElgenderguardian: null,
      anchorEmergencycountry: null,
      anchorguardiancountry: null,
      anchorcliniccountry: null,
      anchorbreed: null,
      anchorelsex: null,
      procedureerror: false,
      editValue: "",
      from: "",
      EditPetLoader: false,
      EditSlackPo: false,
      DogbreedList: [],
      catBreedList: [],
      selectedBreed: "",
      isOtherBreed: false,
      canineParvo: false,
      canineInfect: false,
      distemper: false,
      lepto: false,
      kennel: false,
      parvoDate: new Date(),
      infectDate: new Date(),
      distemperDate: new Date(),
      leptoDate: new Date(),
      kennelDate: new Date(),
      dogVaccDateError: "",
      dogVaccFileError: "",
      dogVaccError: false,
      pravoDateError: false,
      infectDateError: false,
      disTemperDateError: false,
      leptoDateError: false,
      kennelDateError: false,
      dateErrorMessage: "",
      pravoFileError: false,
      infectFileError: false,
      disTemperFileError: false,
      leptoFileError: false,
      kennelFileError: false,
      apiFetched: false,
      vetName:"",
      socialLinksData: [],
      poBOLinksData: [],
      vaccinationCertificate:null,
      dogVaccinationCertName:"",
      dogVaccinationUrl:"",
      genderPopup:false

      // Customizable Area End
    };

    // Customizable Area Start

    // Customizable Area End
  }
  async receive(_from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {

      this.getBreedforPet()

      const sessionData = message.getData(getName(MessageEnum.SessionResponseData));



      if (sessionData) {

        this.setState({
          petType: sessionData.typePet,
          selectedPetId: sessionData.selectedPetId,
          savepetinfonumber: sessionData.savepetnum,
          dogselected: sessionData.dogselected,
          isFormVisible: sessionData.formvisible,
          catselected: sessionData.catselected,
          editValue: sessionData.value,
          from: sessionData.from
        },()=>{
          setStorageData("editPet",sessionData.selectedPetId)
          setStorageData("editPetSetting",sessionData.value)

        });


        const pagefrom = await getStorageData('pagefrom')
        const pagecomefrom = await getStorageData('pagecomefrom')



        if (pagefrom === 'AddPetDetails' || pagecomefrom === "Settings2") {


          this.getpetbyID()


        }

      }

      return;
    }
    // Customizable Area End
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      // Customizable Area Start

      // Customizable Area End
      return;
    }
    // Customizable Area Start

    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (apiRequestCallId === this.getpetApicallID) {
      this.setState({ savedPet: responseJson?.data ,EditPetLoader:false });
    }

    if (apiRequestCallId === this.getPetById) {
      this.setState(this.mapResponseJsonToState(responseJson), () =>
        this.checkOther()
      )
      await removeStorageData('pagefrom');
    }

    if (apiRequestCallId === this.addPetCallPoId) {

      this.handleAddPetResponse(responseJson);
      this.callApiForgetpet()
    }

    if (apiRequestCallId === this.deletePetCallPoId) {
      this.handleDeletePetResponse(responseJson);
    }

    if (apiRequestCallId === this.getBreed) {
      this.setState({ DogbreedList: this.sortArrayWithOther(responseJson.dog_breeds), catBreedList: this.sortArrayWithOther(responseJson.cat_breeds) })
    }

    // Customizable Area End
  }
  // Customizable Area Start

  sortArrayWithOther(array: any[]) {

    function customSort(a: string, b: string) {
      if (a === "Other") {
        return 1;
      } else if (b === "Other") {
        return -1;
      } else {
        return a.localeCompare(b);
      }
    }


    array.sort(customSort);

    return array;
  }


  checkOther = () => {
    if (this.state.isOtherBreed) {
      this.setState({ petBreed: "Other", EditPetLoader: false })
    } else {
      this.setState({ petBreed: this.state.selectedBreed, EditPetLoader: false })
    }
  }


  mapResponseJsonToState(responseJson: { pet: { data: { attributes: any }; }; }) {
    const attributes = responseJson.pet?.data.attributes || {};

    const toDateOrNull = (dateString: string | undefined): Date | "" => {
      return dateString ? new Date(dateString) : "";
    };

    const getCertificateName = (certificates: any[], index: number): string => {
      return certificates?.[index]?.name || "";
    };

    return {
      EditPetLoader: false,
      petType: attributes.pet_type,
      petName: attributes.name,
      sex: attributes.gender,
      dogbirthdate: toDateOrNull(attributes.date_of_birth),
      catbirthdate: toDateOrNull(attributes.date_of_birth),
      selectedBreed: attributes.breed,
      petColor: attributes.colour,
      petWeight: attributes.weight,
      imageUrl: attributes.pet_photo,
      medicalTreatment: attributes.medical_status?.toString(),
      selectedValueSpayed: attributes.spayed,
      behavior: attributes.behavioral_notes,
      daietary: attributes.dietary_requirements,
      emergency_contact_name: attributes.emergency_contact_name,
      emergency_contact_number: attributes.emergency_contact_number,
      emergencyemail: attributes.emergency_contact_email,
      guardian_name: attributes.guardian_name,
      guardian_contact_number: attributes.guardian_contact_number,
      guardianmail: attributes.guardian_email,
      policyNumber: attributes.policy_number,
      microchipNumber: attributes.microchip_number,
      vaccination_date: toDateOrNull(attributes.vaccination_date),
      flea_date: toDateOrNull(attributes.last_flea_treatment_date),
      worming_date: toDateOrNull(attributes.last_worming_date),
      clinicName: attributes.vet_clinic_name,
      vetName:attributes.vet_name,
      clinicAddress: attributes.vet_address,
      phoneNumber: attributes.vet_phone_number,
      emergencyContactCountry: attributes.emergency_country_code,
      legalContactCountry: attributes.guardian_country_code,
      phoneCountry: attributes.vat_clinic_phone_country_code,
      genderemergency: attributes.emergency_salutation,
      genderguardian: attributes.guardian_salutation,
      emergencysurname: attributes.emergency_surname,
      guardiansurname: attributes.guardian_surname,
      proceduredate: attributes.last_procedure_date,
      insurenceProvider: attributes.insurance_provider,
      description: attributes.medical_treatment,
      uploadedFileName: attributes.vaccination_certificate?.filename || "",
      guardiancheckbox: attributes.legal_guardian_details_status || false,
      isOtherBreed: attributes.other_breed,
      canineParvo: attributes.cainine_parvovirus,
      canineInfect: attributes.cainine_infectious,
      distemper: attributes.distemper,
      lepto: attributes.leptospirosis,
      kennel: attributes.kennel_cough,
      parvoDate: toDateOrNull(attributes.cainine_parvovirus_date),
      infectDate: toDateOrNull(attributes.cainine_infectious_date),
      distemperDate: toDateOrNull(attributes.distemper_date),
      leptoDate: toDateOrNull(attributes.leptospirosis_date),
      kennelDate: toDateOrNull(attributes.kennel_cough_date),
      dogVaccinationCertName:attributes?.vaccination_certificate?.filename,
      dogVaccinationUrl:attributes?.vaccination_certificate?.vaccination_certificate,

  
    };
  }


  async handleAddPetResponse(responseJson: { data: { id: string; }; }) {

    if(responseJson.data){

    
    this.setState((prevState) => {
      let updatedSavedPet;
      if (prevState.savedPet.length <= 3) {
        updatedSavedPet = [...prevState.savedPet, responseJson?.data];
      } else {
        updatedSavedPet = [...prevState.savedPet];
      }
      return { savedPet: updatedSavedPet, openEditModal: false,EditPetLoader:false };
    });

    if (this.state.selectedPetId === "") {
      this.props.navigation.navigate("AddPetDetails");
    } else {
      this.setState({ EditSlackPo: true })
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }


  }

  handleDeletePetResponse(responseJson: { data: { id: any; }; }) {
    if(responseJson?.data){
   this.setState((prevState) => {
      const updatedSavedPet = prevState.savedPet.filter((obj: any) => obj.id !== responseJson?.data?.id);
      return { savedPet: updatedSavedPet, selectedPetId: "" };
    });
  }
  }



  callApiForgetpet = () => {
    const loginHeader = {
      "Content-Type": "application/json",
      token: localStorage.getItem("authToken")
    };

    const loginRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
this.setState({EditPetLoader:true})
    loginRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_catalogue/pets/pet_details`
    );

    loginRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(loginHeader)
    );

    loginRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `GET`
    );
    this.getpetApicallID = loginRequestMessage.messageId;
    runEngine.sendMessage(loginRequestMessage.id, loginRequestMessage);

  };

  getpetbyID = () => {
    const loginHeader = {
      "Content-Type": "application/json",
      token: localStorage.getItem("authToken")
    };

    const loginRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    loginRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_catalogue/pets/${this.state.selectedPetId}`
    );

    loginRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(loginHeader)
    );

    loginRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `GET`
    );
    this.getPetById = loginRequestMessage.messageId;
    runEngine.sendMessage(loginRequestMessage.id, loginRequestMessage);

  };


  handleInputChange = (
    event: any
  ) => {
    const { name, value, type } = event.target;
    let newValue: any = value;

    if (type === "radio") {
      newValue = event.target.value;
    } else if (type === "text") {
      if (/\d/.test(value)) {
        newValue = value;
      }
    } else if (type === "number") {
      const numericValue = parseFloat(value);
      newValue = isNaN(numericValue) || numericValue < 0 ? "" : numericValue;
    }

    this.setState((prevState) => ({
      ...prevState,
      [name]: newValue,
      error: {
        ...prevState.error,
        [`${name}Error`]: newValue ? "" : `${name} is required`,
      },
    }),
      () => {
    if(this.state.microchipNumber != ""){
      this.setState({
        error: {
         ...this.state.error,
          microchipNumberError: "",
          addressError:""
        },
      });
    }
});
  };



  setOldDataHandle = (event: React.ChangeEvent<
    HTMLInputElement
  >) => {
    if (event.target.checked) {
      this.setState({
        oldDataCheck: true
      })
      if (this.state.savedPet.length === 1) {
        this.state.savedPet.forEach((item: any) => {
          this.setState({
            clinicName: item.attributes.vet_clinic_name,
      vetName:item.attributes.vet_name,

            phoneNumber: item.attributes.vet_phone_number,
            clinicAddress: item.attributes.vet_address,

          },()=>{
            
            this.setState((prevState) => ({
              
              error: {
                  ...prevState.error,
                  vetNameError:"",
                  clinicNameError: "",
                  phoneNumberError: "",
                  clinicAddressError: "",
              }
          }))





          })
        })
      }
      
    } else {
      this.setState({
        oldDataCheck: false,
        clinicName: "",
        phoneNumber: "",
        clinicAddress: "",
        vetName:""

      })
    }
  }

  handleSelectedDataFill = (event: React.ChangeEvent<HTMLSelectElement>) => {


    const selectedPet = this.state.savedPet.find(
      (pet: any) => pet.id === event.target.value
    );
    this.setState({
      clinicName: selectedPet.attributes.vet_clinic_name,
      vetName:selectedPet.attributes.vet_name,
      phoneNumber: selectedPet.attributes.vet_phone_number,
      clinicAddress: selectedPet.attributes.vet_address,

    },()=>{

      this.setState((prevState) => ({
              
        error: {
            ...prevState.error,
            vetNameError:"",
            clinicNameError: "",
            phoneNumberError: "",
            clinicAddressError: "",
        }
    }))


    })
  }

  setContactData = (event: React.ChangeEvent<
    HTMLInputElement
  >) => {

    if (event.target.checked) {
      this.setState({
        oldDataCheck: true
      })
      if (this.state.savedPet.length === 1) {

        this.state.savedPet.forEach((item: any) => (


          this.setState({
            emergency_contact_name: item.attributes.emergency_contact_name,
            emergency_contact_number: item.attributes.emergency_contact_number,
            emergencyemail: item.attributes.emergency_contact_email,
            guardian_name: item.attributes.guardian_name,
            guardian_contact_number: item.attributes.guardian_contact_number,
            guardianmail: item.attributes.guardian_email,
            legalContactCountry: item.attributes.guardian_country_code,
            emergencyContactCountry: item.attributes?.emergency_country_code,
            genderemergency: item.attributes.emergency_salutation,
            genderguardian: item.attributes.guardian_salutation,
            guardiancheckbox: item.attributes.legal_guardian_details_status,
            emergencysurname: item.attributes.emergency_surname,
            guardiansurname: item.attributes.guardian_surname,
            oldDataCheck: false,

            error: {
              emergencyemailError: '',
              emergency_contact_numberError: '',
              guardianmailError: '',
              guardian_contact_numberError: '',
            },
          })
        ))

      }
    } else {
      this.setState({
        oldDataCheck: false,
        emergency_contact_name: "",
        emergency_contact_number: "",
        emergencyemail: "",
        guardian_name: "",
        guardian_contact_number: "",
        guardianmail: "",
        legalContactCountry: "United Kingdom (+44)",
        emergencyContactCountry: "United Kingdom (+44)",
        genderemergency: "Mr.",
        genderguardian: "Mr.",
        guardiancheckbox: false,
        emergencysurname: "",
        guardiansurname: ""
      })
    }
  }

  handleContactFill = (event: React.ChangeEvent<HTMLSelectElement>) => {


    const selectedPet = this.state.savedPet.find(
      (pet: any) => pet.id === event.target.value
    );


    this.setState({
      emergency_contact_name: selectedPet.attributes.emergency_contact_name,
      emergency_contact_number: selectedPet.attributes.emergency_contact_number,
      emergencyemail: selectedPet.attributes.emergency_contact_email,
      guardian_name: selectedPet.attributes.guardian_name,
      guardian_contact_number: selectedPet.attributes.guardian_contact_number,
      guardianmail: selectedPet.attributes.guardian_email,
      legalContactCountry: selectedPet.attributes.guardian_country_code,
      emergencyContactCountry: selectedPet.attributes?.emergency_country_code,
      genderemergency: selectedPet.attributes.emergency_salutation,
      genderguardian: selectedPet.attributes.guardian_salutation,
      guardiancheckbox: selectedPet.attributes.legal_guardian_details_status,
      emergencysurname: selectedPet.attributes.emergency_surname,
      guardiansurname: selectedPet.attributes.guardian_surname,
      oldDataCheck: false,
      error: {
        emergencyemailError: '',
        guardianmailError: '',
        guardian_contact_numberError: '',
        emergency_contact_numberError: '',
      },
    })
  }



  toggleForm = () => {
    this.setState((prevState) => ({
      isFormVisible: !prevState.isFormVisible,
      savepetinfonumber: 1
    }));
  };

  handleClearInput = () => {
    this.setState({
      selectedFile: null,
      imageUrl: null,
      selctedCertificate: null,
      fileName: null,
      uploadedFileName: null,
      oldDataCheck: false,
      selectedValueSpayed: "",
      petBreed: "",
      petName: "",
      sex: "",
      petColor: "",
      petWeight: "",
      clinicName: "",
      vetName:"",
      phoneNumber: "",
      clinicAddress: "",
      insurenceProvider: "",
      policyNumber: "",
      microchipNumber: "",
      description: "",
      daietary: "",
      behavior: "",
      petType: "",
      medicalTreatment: "true",
      emergency_contact_name: "",
      emergency_contact_number: "",
      guardian_name: "",
      guardian_contact_number: "",
      guardiancheckbox: false,
      dogbirthdate: new Date(),
      dogselected: false,
      catselected: false,
      savepetinfonumber: 0,
      emergencyemail: "",
      guardianmail: "",
      emergencysurname: "",
      guardiansurname: "",
      catbirthdate: new Date(),
      vaccination_date: new Date(),
      flea_date: new Date(),
      worming_date: new Date(),
      proceduredate: new Date(),
      genderemergency: "Mr.",
      genderguardian: "Mr.",
      phoneCountry: "United kingdom (+44)",
      selectedBreed: "",
      canineParvo: false,
      canineInfect: false,
      distemper: false,
      lepto: false,
      kennel: false,
      parvoDate: "",
      infectDate: "",
      distemperDate: "",
      leptoDate: "",
      kennelDate: "",

    });
  };

  handleClick = (event: React.MouseEvent<HTMLButtonElement>, id: string) => {
    this.setState({ anchorElMenu: event.target, selectedPetId: id });
  };
  handleClose = () => {
    this.setState({ anchorElMenu: null });
  };

  handleClickModelOpen = () => {
    this.setState({ modelOpen: true });
    this.handleClose();
  };

  handleClickModelOpenAddCart = () => {
    if (this.state.from === "ShoppingCart") {
      this.onShoppingCart()
    } else {
      this.props.navigation.navigate("Settings2")
    }
  };

  handleDeletData = async () => {

    let token = localStorage.getItem("authToken");
    const { selectedPetId } = this.state;

    if (!selectedPetId) {
      return;
    }

    const endpoint = `bx_block_catalogue/pets/${selectedPetId}`;
    const headers = {
      token: token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({})
    );
    this.deletePetCallPoId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState((prevState) => ({
      savedPet: prevState.savedPet.filter((pet: any) => pet.id !== selectedPetId),
      isFormVisible: false,
    }));

    this.handleCloseModel();

  };

  handleCloseModel = () => {
    this.setState({ modelOpen: false });
  };

  handleCloseModelAddCart = () => {
    this.setState({ modelOpenCart: false });
  };

  handleFileImgChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
   
    const maxSize = 5 * 1024 * 1024; // 5MB in bytes

    if (file) {
      if (file.size > maxSize) {
        // File is too large, set an error
        this.setState((prevState) => ({
          ...prevState,
          selectedFile: null,
          error: {
            ...prevState.error,
            [`imageUrlSizeError`]: "Pet image file size should not exceed 5MB.",
          },
        }));
      } else {
        // File is within the size limit, update state
        this.setState((prevState) => ({
          ...prevState,
          selectedFile: file,
          imageUrl: URL.createObjectURL(file),
          error: {
            ...prevState.error,
            [`imageUrlSizeError`]: "",
            [`imageUrlError`]: "", // Clear any previous errors
          },
        }));
      }
    }
  };
  handleFileChangeVac = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileSec = e.target.files ? e.target.files[0] : null;
    if (fileSec) {
      this.setState((prevState) => ({
        ...prevState,
        selctedCertificate: fileSec,
        uploadedFileName: fileSec.name,
        error: {
          ...prevState.error,
          [`uploadedFileNameError`]: "",
        },
      }));
    }
  };





  handleApi = async () => {
    const fields: (keyof S)[] = [
      "petType",
      "selectedValueSpayed",
      "petName",
      "sex",
      "petWeight",
      "clinicName",
      "vetName",
      "phoneNumber",
      "clinicAddress",
      "daietary",
      "emergency_contact_name",
      "emergency_contact_number",
      "emergencyemail",
      "emergencysurname",
      "microchipNumber",
    ];

    const representativeFieldsValid = this.validateVaccinationFields();
    if (!representativeFieldsValid) {

      return;
    }

    let hasError = false;
    fields.forEach((field) => {
      if (!this.checkAllFieldValidate(field) || !representativeFieldsValid) {
        hasError = true;
        this.setState({ openEditModal: false});
      }
    });

    if (!hasError) {
      this.setState({ afterDetaisFill: true });
      this.setState({ isFormVisible: false });

      let tokenValue = localStorage.getItem("authToken");
      const headers = {
        token: tokenValue,
      };

      const formdata = this.createFormData();

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        this.state.selectedPetId
          ? `bx_block_catalogue/pets/${this.state.selectedPetId}`
          : "bx_block_catalogue/pets"
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        this.state.selectedPetId ? "PUT" : "POST"
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formdata
      );
      this.setState({EditPetLoader:true})
      this.addPetCallPoId = requestMessage.messageId;
      runEngine.sendMessage(requestMessage.id, requestMessage);
      this.state.selectedPetId &&
        this.setState((prevState) => ({
          savedPet: prevState.savedPet.filter(
            (pet: any) => pet.id !== prevState.selectedPetId
          ),
        }));

      if (this.state.selectedPetId === "") {
        this.handleClearInput();
      }
    }
  };

  createFormData = () => {
    const formdata = new FormData();
    formdata.append("pet[guardian_salutation]", this.state.genderguardian);
    formdata.append("pet[emergency_salutation]", this.state.genderemergency);
    formdata.append("pet[name]", this.state.petName);
    formdata.append("pet[colour]", this.state.petColor);
    formdata.append("pet[breed]", this.state.selectedBreed);
    formdata.append("pet[gender]", this.state.sex);
    formdata.append("pet[pet_type]", this.state.petType);
    formdata.append("pet[date_of_birth]", this.state.dogbirthdate || this.state.catbirthdate);
    formdata.append("pet[weight]", this.state.petWeight);
    formdata.append("pet[spayed]", this.state.selectedValueSpayed);
    formdata.append("pet[vet_clinic_name]", this.state.clinicName);
    formdata.append("pet[vet_name]", this.state.vetName);
    formdata.append("pet[vet_phone_number]", this.state.phoneNumber);
    formdata.append("pet[vet_address]", this.state.clinicAddress);
    formdata.append("pet[insurance_provider]", this.state.insurenceProvider);
    formdata.append("pet[policy_number]", this.state.policyNumber);
    formdata.append("pet[microchip_number]", this.state.microchipNumber);
    formdata.append("pet[medical_treatment]", this.state.description);
    formdata.append("pet[emergency_contact_name]", this.state.emergency_contact_name);
    formdata.append("pet[emergency_contact_number]", this.state.emergency_contact_number);
    formdata.append("pet[emergency_contact_email]", this.state.emergencyemail);
    formdata.append("pet[guardian_name]", this.state.guardian_name);
    formdata.append("pet[guardian_email]", this.state.guardianmail);
    formdata.append("pet[guardian_contact_number]", this.state.guardian_contact_number);
    formdata.append("pet[vaccination_date]", this.state.vaccination_date);
    formdata.append(
      "pet[last_flea_treatment_date]", this.state.flea_date);
    formdata.append(
      "pet[last_worming_date]", this.state.worming_date);
    formdata.append("pet[dietary_requirements]", this.state.daietary);
    formdata.append("pet[behavioral_notes]", this.state.behavior);
    this.state.selectedFile &&
      formdata.append("pet[pet_photo]", this.state.selectedFile);
    this.state.selctedCertificate &&
      formdata.append(
        "pet[vaccination_certificate]",
        this.state.selctedCertificate
      );
    formdata.append("pet[emergency_country_code]", this.state.emergencyContactCountry)
    formdata.append("pet[guardian_country_code]", this.state.legalContactCountry)
    formdata.append("pet[vat_clinic_phone_country_code]", this.state.phoneCountry)
    formdata.append("pet[emergency_surname]", this.state.emergencysurname)
    formdata.append("pet[guardian_surname]", this.state.guardiansurname)
    formdata.append("pet[last_procedure_date]", this.state.proceduredate || "")
    formdata.append("pet[medical_status]", this.state.medicalTreatment)
    formdata.append("pet[legal_guardian_details_status]", this.state.guardiancheckbox.toString())
    formdata.append("pet[other_breed]", this.state.isOtherBreed.toString())

    formdata.append("pet[cainine_parvovirus]", this.state.canineParvo.toString())
    formdata.append("pet[cainine_infectious]", this.state.canineInfect.toString())
    formdata.append("pet[distemper]", this.state.distemper.toString())
    formdata.append("pet[leptospirosis]", this.state.lepto.toString())
    formdata.append("pet[kennel_cough]", this.state.kennel.toString())

    formdata.append("pet[cainine_parvovirus_date]", this.state.parvoDate)
    formdata.append("pet[cainine_infectious_date]", this.state.infectDate)
    formdata.append("pet[distemper_date]", this.state.distemperDate)
    formdata.append("pet[leptospirosis_date]", this.state.leptoDate)
    formdata.append("pet[kennel_cough_date]", this.state.kennelDate)
    this.state.vaccinationCertificate &&
      formdata.append("pet[vaccination_certificate]", this.state.vaccinationCertificate)
    return formdata;
  };


  async componentDidMount() {
    // Customizable Area Start
    this.callApiForgetpet()
    this.getBreedforPet()
    const mySocialLinksData = await getStorageData("footerNavigationUrl", true);
    this.setState({socialLinksData: mySocialLinksData });
    const allLinksData = await getStorageData("BOandPOLinks",true)
    const editPetId=await getStorageData('editPet')
    const editPetSetting=await getStorageData('editPetSetting')
    if(editPetId){
      this.setState({selectedPetId:editPetId,editValue:editPetSetting},()=>{
        this.getpetbyID()
      })
    }

    this.setState({poBOLinksData: allLinksData}) 
    window.scrollTo({ top: 0, behavior: 'smooth' });
    // Customizable Area End
  }

   async componentWillUnmount(): Promise<void> {
    removeStorageData('editPet')
    removeStorageData('editPetSetting')

  }

  checkAllFieldValidate = (field: keyof S): boolean => {

    const MesErr = configJSON.MesErr;

    
    
      if (!this.state[field] || this.state.error[field + "Error"] ) {


        this.setState((prevState) => ({
          error: {
            ...prevState.error,
            [`${field}Error`]: MesErr[`${field}` as keyof typeof MesErr],
          },
        }));

        return false;
      } else {

        this.setState((prevState) => ({
          error: {
            ...prevState.error,
            [`${field}Error`]: ``,
            vetNameError:""

          },
        }));
        return true;
     
    }

  };

  naviateToNewTicketScreen() {

    setStorageData('pagefrom', "AddPetDetails")
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'EditPetDetails');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);


    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), { selectedPetId: this.state.selectedPetId, value: 'Edit' });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);



  }



  handleOptionDog = () => {
    this.setState((prevState) => ({
      dogselected: !prevState.dogselected,
      catselected: false,
      petType: "dog"
    }));
  }

  handleOptionCat = () => {
    this.setState((prevState) => ({
      dogselected: false,
      catselected: !prevState.catselected,
      petType: "cat"
    }));

  }



  handleInputtextChange = (
    event: any
  ) => {



    const { name, value, type } = event.target;

    let newValues = value;
    let errors = "";


    if (type === "email") {
      const emailRegex = configJSON.emailRegex;
      if (!emailRegex.test(value)) {
        errors = `Please enter a valid email address`;
      }
    } else if (type === "text") {
      if (/\d/.test(value)) {
        newValues = value.replace(/\d/g, "");
        errors = newValues ? "" : `${name} is required`;
      }
    }

    this.setState((prevState) => ({
      ...prevState,
      [name]: newValues,
      error: {
        ...prevState.error,
        [`${name}Error`]: errors,
      },
    }));
    
  };






  handleNext = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.setState((prevState) => ({
      savepetinfonumber: prevState.savepetinfonumber + 1
    }));
  };

  handleNext1 = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });


    const fieldsFor1: (keyof S)[] = [
      "imageUrl",
      "petBreed",
      "petName",
      "sex",
      "petWeight",
      "selectedValueSpayed",
      "dogbirthdate",
      "catbirthdate",
      "proceduredate",
      "selectedBreed"
    ];

    const fieldsFor2: (keyof S)[] = [
      "imageUrl",
      "petBreed",
      "petName",
      "sex",
      "petWeight",
      "selectedValueSpayed",
      "dogbirthdate",
      "catbirthdate",
      "selectedBreed"
    ];

    const fieldsCheck = this.state.selectedValueSpayed === 'ChemicallyCastrated' ? fieldsFor1 : fieldsFor2;

    let errormsg = true
    fieldsCheck.forEach((field) => {

      if (!this.checkAllFieldValidate(field)) {
        errormsg = false
      }
    });

    if (!errormsg) {
      this.setState({ savepetinfonumber: 2 })

    } else {
    this.setState({ savepetinfonumber: 3 })

    }

  };

  emergencyCheckbox = () => {
    const fieldsemergency: (keyof S)[] = [
      "emergency_contact_name",
      "emergency_contact_number",
      "emergencyemail",
      "emergencysurname"
    ]

    let errormsg1 = true
    fieldsemergency.forEach((field) => {
      if (!this.checkAllFieldValidate(field)) {
        errormsg1 = false
      }

    });

    if (errormsg1) {
      this.setState(prevState => ({ guardiancheckbox: !prevState.guardiancheckbox }), () => {
        if (this.state.guardiancheckbox) {
          this.setState({
            guardian_name: this.state.emergency_contact_name,
            guardianmail: this.state.emergencyemail,
            guardian_contact_number: this.state.emergency_contact_number,
            legalContactCountry: this.state.emergencyContactCountry,
            genderguardian: this.state.genderemergency,
            guardiansurname: this.state.emergencysurname,
            error: {}
          });
        } else {
          this.setState({
            guardian_name: "",
            guardianmail: "",
            guardian_contact_number: "",
            legalContactCountry: "United kingdom (+44)",
            genderguardian: "Mr.",
            guardiansurname: "",
          });
        }
      });
    }
  };

  handleNext2 = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    const fields1: (keyof S)[] = [
      "emergency_contact_name",
      "emergency_contact_number",
      "emergencyemail",
      "guardian_name",
      "guardian_contact_number",
      "guardianmail",
      "guardiansurname",
      "emergencysurname"
    ];

    let errormsg = true

    fields1.forEach((field) => {
      if (!this.checkAllFieldValidate(field)) {

        errormsg = false
        
      }

    });
   

    if (!errormsg ) {
      this.setState({ savepetinfonumber: 3 })
    } else {
      this.setState({ savepetinfonumber: 4 })
    }

  }
  noVaccinationError = () => {
    this.setState({
      dogVaccError: false,
      dateErrorMessage: '',
      dogVaccFileError: '',
      pravoFileError: false,
      infectFileError: false,
      disTemperFileError: false,
      leptoFileError: false,
      kennelFileError: false,
      kennelDateError: false,
      pravoDateError: false,
      infectDateError: false,
      disTemperDateError: false,
      leptoDateError: false,




    })
  }

  handleNext3 = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });  
    const fieldsForYes: (keyof S)[] = [
      "daietary",
      "uploadedFileName",
      "vaccination_date",
      "flea_date"
    ];
  
    const dogFieldsForYes: (keyof S)[] = [
      "daietary",
      "medicalTreatment",
    ];
  
    const fields = this.state.petType === 'dog' || this.state.petType === 'Dog' ? dogFieldsForYes : fieldsForYes;
  
    let errormsg = this.checkFields(fields);
  
    if (this.state.petType === 'dog' || this.state.petType === 'Dog') {
      errormsg = this.conditionsCheck(errormsg);
    }
    if (!errormsg) {
      this.setState({ savepetinfonumber: 4 });
    } else {
      this.setState({ savepetinfonumber: 5 });
    }
  }
  
  checkFields = (fields: any) => {
    let errormsg = true;
  
    fields.forEach((field: any) => {
      if (!this.checkAllFieldValidate(field)) {
        errormsg = false;
      }
    });
  
    if (this.state.petType === 'dog' || this.state.petType === 'Dog') {
      if (!(this.state.canineParvo || this.state.canineInfect || this.state.distemper || this.state.lepto || this.state.kennel)) {
        errormsg = false;
        this.setState({ vaccineerror: true });
      } else {
        this.setState({ vaccineerror: false });
      }
    }
  
    return errormsg;
  }
  
  conditionsCheck = (errormsg: any) => {
    if (this.state.canineParvo) {
      errormsg = this.handleCanineParvo(errormsg);
    }
    if (this.state.canineInfect) {
      errormsg = this.handleCanineInfect(errormsg);
    }
    if (this.state.distemper) {
      errormsg = this.handleDistemper(errormsg);
    }
    if (this.state.lepto) {
      errormsg = this.handleLepto(errormsg);
    }
    if (this.state.kennel) {
      errormsg = this.handleKennel(errormsg);
    }
    return errormsg;
  }
  
  handleCanineParvo = (errormsg: any) => {
    if (!this.state.parvoDate) {
      errormsg = false;
      this.setState({
        pravoDateError: !this.state.parvoDate,
        dateErrorMessage: !this.state.parvoDate ? 'Please select the date' : "",
      });
    } else {
      this.noVaccinationError();
    }
    return errormsg;
  }
  
  handleCanineInfect = (errormsg: any) => {
    if (!this.state.infectDate) {
      errormsg = false;
      this.setState({
        infectDateError: !this.state.infectDate,
        dateErrorMessage: !this.state.infectDate ? 'Please select the date' : "",
      });
    } else {
      this.noVaccinationError();
    }
    return errormsg;
  }
  
  handleDistemper = (errormsg: any) => {
    if (!this.state.distemperDate) {
      errormsg = false;
      this.setState({
        disTemperDateError: !this.state.distemperDate,
        dateErrorMessage: !this.state.distemperDate ? 'Please select the date' : "",
      });
    } else {
      this.noVaccinationError();
    }
    return errormsg;
  }
  
  handleLepto = (errormsg: any) => {
    if (!this.state.leptoDate) {
      errormsg = false;
      this.setState({
        leptoDateError: !this.state.leptoDate,
        dateErrorMessage: !this.state.leptoDate ? 'Please select the date' : "",
      });
    } else {
      this.noVaccinationError();
    }
    return errormsg;
  }
  
  handleKennel = (errormsg: any) => {
    if (!this.state.kennelDate) {
      errormsg = false;
      this.setState({
        kennelDateError: !this.state.kennelDate,
        dateErrorMessage: !this.state.kennelDate ? 'Please select the date' : "",
      });
    } else {
      this.noVaccinationError();
    }
    return errormsg;
  }


  handleBack = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.setState((prevState) => ({
      savepetinfonumber: prevState.savepetinfonumber - 1
    }));
    if (this.state.savepetinfonumber === 1) {
      this.setState((prevState) => ({
        isFormVisible: !prevState.isFormVisible,
      }));
    }
  };

  handleBirthChange = (date: DateObject) => {

    const dateAsDate = date.toDate();

    this.setState({ dogbirthdate: dateAsDate, catbirthdate: dateAsDate });
  };

  handlecatBirthChange = (date: DateObject) => {
    const dateAsDate = date.toDate();

    this.setState({ dogbirthdate: dateAsDate, catbirthdate: dateAsDate });

  };

  handlevaccinedate = (date: DateObject) => {

    const dateAsDate1 = date.toDate();

    const { dogbirthdate, catbirthdate } = this.state;

    if ((dogbirthdate && dateAsDate1 < dogbirthdate) || (catbirthdate && dateAsDate1 < catbirthdate)) {
      this.setState({ vaccineerror: true, vaccination_date: "" });
    } else {
      this.setState({ vaccineerror: false, vaccination_date: dateAsDate1 },()=>{
            

        this.setState((prevState) => ({
              
          error: {
              ...prevState.error,
              vaccination_dateError:'',
          }
      }))



      });
    }

  };

  handlproceduredate = (date: DateObject) => {

    const dateAsDate4 = date.toDate();

    const { dogbirthdate, catbirthdate } = this.state;

    if ((dogbirthdate && dateAsDate4 < dogbirthdate) || (catbirthdate && dateAsDate4 < catbirthdate)) {
      this.setState({ procedureerror: true, proceduredate: "" });
    } else {
      this.setState({ proceduredate: dateAsDate4, procedureerror: false });
    }

  };


  handlefleadate = (date: DateObject) => {
    const dateAsDate2 = date.toDate();

    const { dogbirthdate, catbirthdate } = this.state;

    if ((dogbirthdate && dateAsDate2 < dogbirthdate) || (catbirthdate && dateAsDate2 < catbirthdate)) {
      this.setState({ fleaerror: true, flea_date: dateAsDate2 });
    } else {
      this.setState({ fleaerror: false, flea_date: dateAsDate2 });
    }


  };

  handlewormingdate = (date: DateObject) => {
    const dateAsDate3 = date.toDate();

    const { dogbirthdate, catbirthdate } = this.state;

    if ((dogbirthdate && dateAsDate3 < dogbirthdate) || (catbirthdate && dateAsDate3 < catbirthdate)) {
      this.setState({ wormingError: true, worming_date: dateAsDate3 });
    } else {
      this.setState({ wormingError: false, worming_date: dateAsDate3 });
    }
  };

  emergencyGenderClose = (value: string) => {
    this.setState({
      genderemergency: value ? value : "Mr.",
      anchorElgenderemergency: null
    })
  }

  handlepetsexclose = (value:string) => {
    this.setState((prevState) => ({
        sex: value || "Male",
        anchorelsex: null,

        error: {
           ...prevState.error,
            sexError: ''
        }
    }),()=>{
      this.closeGenderPopUp()
    });
}


  guardianGenderClose = (value: string) => {
    this.setState({
      genderguardian: value || "Mr.",
      anchorElgenderguardian: null
    })
  }

  handleEmergencyopen = (event: React.MouseEvent<HTMLDivElement>) => {
    if (this.state.editValue === "View") {
      this.setState({ anchorElgenderemergency: null })

    } else {
      this.setState({ anchorElgenderemergency: event.currentTarget })
    }
  }

  handleguardianopen = (event: React.MouseEvent<HTMLDivElement>) => {
    if (this.state.editValue === "View") {
      this.setState({ anchorElgenderguardian: null })

    } else {
      this.setState({ anchorElgenderguardian: event.currentTarget })
    }
  }

  handlesexopen = (event: React.MouseEvent<HTMLDivElement>) => {
    if (this.state.editValue === "View") {
      this.setState({ anchorelsex: null })

    } else {

      this.setState({ anchorelsex: event.currentTarget })
    }
  }
  openGenderPopup=()=>{
    if (this.state.editValue === "View") {
      this.setState({ genderPopup: false })

    }else{
      this.setState({ genderPopup: true })

    }

  }
  closeGenderPopUp=()=>{
    this.setState({ genderPopup: false })

  }

  emergencyCountryOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    if (this.state.editValue === "View") {
      this.setState({ anchorEmergencycountry: null })

    } else {

      this.setState({ anchorEmergencycountry: event.currentTarget });
    }
  }

  guardianCountryOpen = (event: React.MouseEvent<HTMLDivElement>) => {

    if (this.state.editValue === "View") {
      this.setState({ anchorguardiancountry: null })
    } else {
      this.setState({ anchorguardiancountry: event.currentTarget });
    }
  }

  clinicCountryOpen = (event: React.MouseEvent<HTMLDivElement>) => {

    if (this.state.editValue === "View") {
      this.setState({ anchorcliniccountry: null })
    } else {
      this.setState({ anchorcliniccountry: event.currentTarget });
    }
  }

  breedOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    if (this.state.editValue === "View") {
      this.setState({ anchorbreed: null })
    } else {
      this.setState({ anchorbreed: event.currentTarget });
    }
  }

  breedClose = (value:string) => {
    this.setState((prevState) => ({
        petBreed: value,
        anchorbreed: null,
        error: {
            ...prevState.error,
            petBreedError: ""
        }
    }), () => {
        if (value === "Other") {
            this.setState((prevState) => ({
                isOtherBreed: true,
                selectedBreed: ""
            }));
        } else {
            this.setState((prevState) => ({
                isOtherBreed: false,
                selectedBreed: value,
                error: {
                   ...prevState.error,
                    selectedBreedError: ""
                }
            }));
        }
    });
};



  handleInputChange1 = (
    event: any
  ) => {
    const { value } = event.target;

    if (this.state.petBreed === "Other") {
      this.setState({ selectedBreed: value });
    }


  };






  handelcliniccountryCloses = (value: any, code: any) => {
    const countryCode = code ? code : "(+44)";
    const selectedValue = value ? value : "United Kingdom";
    const phNoCountry = selectedValue + countryCode;

    this.setState({
      phoneCountry: phNoCountry,
      anchorcliniccountry: null
    });
  };


  handelPhCountryCloses = (value: any, code: any) => {
    const countryCode = code ? code : "(+44)";
    const selectedValue = value ? value : "United Kingdom";
    const phNoCountry = selectedValue + countryCode;

    this.setState({
      emergencyContactCountry: phNoCountry,
      anchorEmergencycountry: null
    });
  };

  handelguardianCountryCloses = (value: any, code: any) => {
    const countryCode = code ? code : "(+44)";
    const selectedValue = value ? value : "United Kingdom";
    const phNoCountry = selectedValue + countryCode;

    this.setState({
      legalContactCountry: phNoCountry,
      anchorguardiancountry: null
    });
  };

  onShoppingCart = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), 'ShoppingCartOrders');
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);


    const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), { orderID: "", fromReservation: false });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  }

  getBreedforPet = () => {
    const breedHeader = {
      "Content-Type": "application/json",
      token: localStorage.getItem("authToken")
    };

    const loginRequestMessage1 = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    loginRequestMessage1.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_catalogue/pets/pet_breeds`
    );

    loginRequestMessage1.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(breedHeader)
    );

    loginRequestMessage1.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `GET`
    );

    this.getBreed = loginRequestMessage1.messageId;
    runEngine.sendMessage(loginRequestMessage1.id, loginRequestMessage1);

  };

  canineParvo = () => {
    this.setState({ canineParvo: !this.state.canineParvo })
  }

  canineInfect = () => {
    this.setState({ canineInfect: !this.state.canineInfect })
  }

  distemper = () => {
    this.setState({ distemper: !this.state.distemper })
  }

  lepto = () => {
    this.setState({ lepto: !this.state.lepto })
  }

  kennel = () => {
    this.setState({ kennel: !this.state.kennel })
  }

  parvoDate = (date: DateObject) => {
    const dateAsDate1 = date.toDate();
    const { dogbirthdate, catbirthdate } = this.state;
    if ((dogbirthdate && dateAsDate1 < dogbirthdate) || (catbirthdate && dateAsDate1 < catbirthdate)) {
      this.setState({
        pravoDateError: true, parvoDate: "",
        dateErrorMessage: "Vaccination date should be greater than birthdate"
      });
    } else {
      this.setState({ pravoDateError: false, parvoDate: dateAsDate1, dateErrorMessage: "" });
    }

  };

  infectDate = (date: DateObject) => {

    const dateAsDate1 = date.toDate();

    const { dogbirthdate, catbirthdate } = this.state;

    if ((dogbirthdate && dateAsDate1 < dogbirthdate) || (catbirthdate && dateAsDate1 < catbirthdate)) {
      this.setState({
        infectDateError: true, infectDate: "",
        dateErrorMessage: "Vaccination date should be greater than birthdate"
      });
    } else {
      this.setState({ infectDateError: false, infectDate: dateAsDate1, dateErrorMessage: "" });
    }

  };

  distemperDate = (date: DateObject) => {

    const dateAsDate1 = date.toDate();

    const { dogbirthdate, catbirthdate } = this.state;

    if ((dogbirthdate && dateAsDate1 < dogbirthdate) || (catbirthdate && dateAsDate1 < catbirthdate)) {
      this.setState({
        disTemperDateError: true, distemperDate: "",
        dateErrorMessage: "Vaccination date should be greater than birthdate"
      });
    } else {
      this.setState({ disTemperDateError: false, distemperDate: dateAsDate1, dateErrorMessage: "" });
    }

  };

  leptoDate = (date: DateObject) => {

    const dateAsDate1 = date.toDate();

    const { dogbirthdate, catbirthdate } = this.state;

    if ((dogbirthdate && dateAsDate1 < dogbirthdate) || (catbirthdate && dateAsDate1 < catbirthdate)) {
      this.setState({
        leptoDateError: true, leptoDate: "",
        dateErrorMessage: "Vaccination date should be greater than birthdate"
      });
    } else {
      this.setState({ leptoDateError: false, leptoDate: dateAsDate1, dateErrorMessage: "" });
    }

  };

  kennelDate = (date: DateObject) => {

    const dateAsDate1 = date.toDate();

    const { dogbirthdate, catbirthdate } = this.state;

    if ((dogbirthdate && dateAsDate1 < dogbirthdate) || (catbirthdate && dateAsDate1 < catbirthdate)) {
      this.setState({
        kennelDateError: true, kennelDate: "",
        dateErrorMessage: "Vaccination date should be greater than birthdate"
      });
    } else {
      this.setState({ kennelDateError: false, kennelDate: dateAsDate1, dateErrorMessage: "" });
    }

  };
  handleVaccCertificate = (event: React.ChangeEvent<HTMLInputElement>,) => {
    const fileSec = event.target.files ? event.target.files[0] : null;
    if (fileSec) {
      this.setState((prevState) => ({
        ...prevState,
        vaccinationCertificate: fileSec,
      }));
    }
  };

  onViewPet = (value: any) => {
    setStorageData('pagefrom', "AddPetDetails")

    const message: Message = new Message(getName(MessageEnum.NavigationMessage))
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'EditPetDetails'
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), { selectedPetId: this.state.selectedPetId, value: value })
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }
  validateVaccinationFields = () => {
    let hasError = false;
    if (this.state.petType == 'dog' || this.state.petType == 'Dog') {
      if (!(this.state.canineParvo || this.state.canineInfect || this.state.distemper || this.state.lepto || this.state.kennel)) {
        hasError = true;
        this.setState({
          vaccineerror: true, openEditModal: false
        })
      } else {
        this.setState({ vaccineerror: false })

      }

      if (this.state.canineParvo) {
        if (!this.state.parvoDate) {
          hasError = true;
          this.setState({
            pravoDateError: true,
            dateErrorMessage: 'Please select the date',
            pravoFileError: true,
            dogVaccFileError: 'Please upload the vaccine certificate',
            openEditModal: false

          })
        } else {
          this.noVaccinationError()

        }
      }
      if (this.state.canineInfect) {
        if (!this.state.infectDate) {
          hasError = true;
          this.setState({
            infectDateError: true,
            dateErrorMessage: 'Please select the date',
            dogVaccFileError: 'Please upload the vaccine certificate',
            infectFileError: true,
            openEditModal: false

          })
        } else {
          this.noVaccinationError()

        }
      }
      if (this.state.distemper) {
        if (!this.state.distemperDate) {
          hasError = true;
          this.setState({
            disTemperDateError: !this.state.distemperDate,
            dateErrorMessage: !this.state.distemperDate ? 'Please select the date' : "",
            openEditModal: false

          })
        } else {
          this.noVaccinationError()

        }
      }
      if (this.state.lepto) {
        if (!this.state.leptoDate) {
          hasError = true;
          this.setState({
            leptoDateError: true,
            dateErrorMessage: 'Please select the date',
            dogVaccFileError: 'Please upload the vaccine certificate',
            leptoFileError: true,
            openEditModal: false
          })
        } else {
          this.noVaccinationError()

        }
      }
      if (this.state.kennel) {
        if (!this.state.kennelDate) {
          hasError = true;
          this.setState({
            kennelDateError: true,
            dateErrorMessage: 'Please select the date',
            dogVaccFileError: 'Please upload the vaccine certificate',
            kennelFileError: true,
            openEditModal: false


          })
        } else {
          this.noVaccinationError()

        }
      }

    }
    return !hasError
  }
  // Customizable Area End
}

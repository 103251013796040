import React from "react";
// Customizable Area Start
import { Box, Typography, Grid, ClickAwayListener, Divider, MenuItem } from "@material-ui/core";

import paymentInformationcontroller, { Props, configJSON } from "./PaymentInformationController.web";
import { CustomizedTextField } from "./SinglePropertiesDetails.web";
import { arrowDown, arrowUp } from "../../settings2/src/assets";
import { BusinessType, MonthData } from "./types";
import { calenderIcon, deleteIcon, tickIcon, uploadIcon } from "../../user-profile-basic/src/assets";
import { downArrow } from "../../contactus/src/assets";
import Loader from "../../../components/src/Loader.web";
import CustomizedSnackbars from "../../../components/src/CustomSnackbar.web";
import { boxShadows, theme } from "../../../components/src/theme";
import SubscriptionSettings from "../../customisableusersubscriptions/src/SubscriptionSettings.web";
import { CustomCheckboxSecondary } from "../../../components/src/CustomizedComponents.web";
import { Identity } from "../../user-profile-basic/src/StripeAccountDetailsController.web";
const countryCodes = require('../../settings2/src/config')
// Customizable Area End

export default class PaymentInformation extends paymentInformationcontroller {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    renderCountryText=()=>{
        const  {classes}=this.props;
        return(
            <>
             <Grid item xs={12}>
                                            <Typography variant="body1" className={classes.labelText}>{configJSON.paymentInformation.country}
                                                <Typography display="inline" variant="body1" className={classes.licienceText}>{configJSON.paymentInformation.optional}</Typography>

                                            </Typography>

                                        </Grid>
            </>
        )
    }

    renderVerificationText = (text: string) => {
        const { classes } = this.props;
        return (
            <>
                {text === 'verified' && (
                    <Typography variant="subtitle2" className={classes.successText}>
                        {configJSON.verifiactionKeys.verified}
                    </Typography>
                )}
                {(text === 'pending' || text === 'new') && (
                    <Typography variant="subtitle2" className={classes.warningText}>
                        {configJSON.verifiactionKeys.pending}
                    </Typography>
                )}
                {(text === 'unverified' || text === 'verification_failed') && (
                    <Typography variant="subtitle2" className={classes.failedText}>
                        {configJSON.verifiactionKeys.failed}
                    </Typography>
                )}

            </>
        )



    }
    renderBussinessType = () => {
        const { classes } = this.props;
        return (
            <ClickAwayListener onClickAway={() => this.handleClosedropDowns('bussiness')}>
                <Box className={classes.typeInner}>
                    <Box className={classes.outer}>
                        {
                            this.state.bussinessDropDowns.map((item: BusinessType) => {
                                return (
                                    <>
                                        <MenuItem className={classes.dropDownText}
                                            data-test-id="select_nameType"
                                            key={item.id}
                                            value={item.name}
                                            onClick={() => { this.handleSelectBussiness(item) }}
                                        >
                                            {item.name}
                                        </MenuItem>
                                        <Divider />
                                    </>
                                )
                            })
                        }


                    </Box>
                </Box>
            </ClickAwayListener>)

    }

    rendernameTypes = (type: string) => {
        const { classes } = this.props;
        return (
            <ClickAwayListener onClickAway={() => this.handleClosedropDowns(type)}>
                <Box className={classes.typeInner}>
                    <Box className={classes.outer}>
                        {
                            ["Mr.", "Miss", "Mrs.", "Mrx."].map((value: string, index: number) => {
                                return (
                                    <>
                                        <MenuItem className={classes.dropDownText}
                                            data-test-id="select_nameType"
                                            key={index}
                                            value={value}
                                            onClick={() => { this.handleSelectNameType(value, type) }}
                                        >
                                            {value}
                                        </MenuItem>
                                        <Divider />
                                    </>
                                )
                            })
                        }


                    </Box>
                </Box>
            </ClickAwayListener>)

    }

    renderCountryCodes = (type: string) => {
        const { classes } = this.props;
        return (
            <>
                <ClickAwayListener onClickAway={() => this.handlecloseCountryCodes(type)}>
                    <Box style={webstyle.typeInner as React.CSSProperties}>
                        <Box className={classes.outer}>
                            {
                                countryCodes.countries.map((country: any, index: any) => (
                                    <>
                                        <MenuItem className={classes.menuItemText}
                                            data-test-id="select_country_code"
                                            key={index}
                                            value={`${country.label} ${country.phone}`}
                                            onClick={() => this.selectPhoneNumberCountry(country.label, country.phone)}

                                        >
                                            {`${country.label} ${country.phone}`}
                                        </MenuItem>

                                        <Divider />
                                    </>
                                ))}


                        </Box>
                    </Box>
                </ClickAwayListener>
            </>
        )
    }

    renderDob = (openType: string) => {
        const { classes } = this.props;

        return (
            <ClickAwayListener onClickAway={() => { this.closeDobDropDowns(openType) }}>
                <Box className={classes.dobDropDownslayout}>
                    <Box className={classes.outer}>
                        {openType === 'day' && this.renderDayList()}
                        {openType === 'month' && this.renderMonthsList()}
                        {openType === 'year' && this.renderYearsList()}


                    </Box>
                </Box>

            </ClickAwayListener>
        )
    }

    renderDayList = () => {
        const { classes } = this.props;
        return (
            <>
                {this.state.daysList.map((day: number, index: number) => {
                    return (
                        <>
                            <MenuItem className={classes.dropDownText}
                                data-test-id="select_day"
                                key={index}
                                value={day}
                                onClick={() => this.selectDay(day)}

                            >
                                {day}
                            </MenuItem>
                            <Divider />

                        </>
                    )
                })

                }
            </>
        )
    }
    renderMonthsList = () => {
        const { classes } = this.props;
        return (
            <>
                {this.state.monthData.map((month: MonthData, index: number) => {
                    return (
                        <>
                            <MenuItem className={classes.dropDownText}
                                data-test-id="select_month"
                                key={index}
                                value={month.id}

                                onClick={() => { this.selectMonth(month.monthName, month.id) }}

                            >
                                {month.monthName}
                            </MenuItem>
                            <Divider />

                        </>
                    )
                })

                }
            </>
        )

    }
    renderYearsList = () => {
        const { classes } = this.props;
        return (
            <>
                {this.state.yearsList.map((year: string, index: number) => {

                    return (
                        <>
                            <MenuItem className={classes.dropDownText}
                                data-test-id="select_year"
                                key={index}
                                value={year}
                                onClick={() => this.selectYear(year)}
                            >
                                {year}
                            </MenuItem>
                            <Divider />
                        </>
                    )
                })}
            </>
        )

    }

    noInformationfound = () => {
        const { classes } = this.props;
        return (
            <>
                <Grid item xs={12}>
                    <Grid container direction="row" justifyContent="center" alignItems="center" style={{ height: "250px" }}>
                        <Typography variant="h4" className={classes.labelText}>Payment Information Not Found</Typography>

                    </Grid>
                </Grid>
            </>
        )
    }

    paymentInformation = () => {
        const { classes } = this.props
        return (
            <>
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="body1" className={classes.labelText}>{configJSON.paymentInformation.swift}</Typography>

                        </Grid>
                        <Grid item xs={12}>
                            {this.state.editPaymentInformatation && this.state.documentStatus.external_account_status === 'verification_failed' ?
                                <>
                                    <CustomizedTextField
                                        value={this.state.paymentInformationForm.swiftCode}
                                        onChange={this.handleFormChange}
                                        fullWidth
                                        variant="outlined"
                                        name='swiftCode'
                                        data-test-id='swiftCode'
                                    />
                                    <Typography variant="body1" color="error">

                                        {this.state.paymentFormErrors.swiftCode}</Typography>
                                </>
                                :
                                <Typography variant="body1" className={classes.licienceText}>
                                    {this.state.paymentInformationForm.swiftCode}</Typography>
                            }


                        </Grid>

                    </Grid>

                </Grid>
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="body1" className={classes.labelText}>{configJSON.paymentInformation.number}</Typography>

                        </Grid>
                        <Grid item xs={12}>
                            {this.state.editPaymentInformatation && this.state.documentStatus.external_account_status === 'verification_failed' ?
                                <>
                                    <CustomizedTextField
                                        fullWidth
                                        variant="outlined"
                                        name='accountNumber'
                                        value={this.state.paymentInformationForm.accountNumber}
                                        onChange={this.handleFormChange}
                                        data-test-id='accountNumber'
                                    />
                                    <Typography variant="body1" color="error">
                                        {this.state.paymentFormErrors.accountNumber}</Typography>
                                </>

                                :
                                <Typography variant="body1" className={classes.licienceText}>
                                    {this.state.paymentInformationForm.accountNumber}</Typography>
                            }
                        </Grid>
                    </Grid>

                </Grid>
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={3}>
                        <Grid item xs={12}>
                            <Grid container direction="row" spacing={2} alignItems="center">
                                <Grid item>
                                    <Typography variant="body1" className={classes.labelText}>{configJSON.paymentInformation.document}</Typography>
                                </Grid>
                                <Grid item>
                                    {this.renderVerificationText(this.state.documentStatus.identity_document_status)}
                                </Grid>

                            </Grid>
                        </Grid>
                        {this.state.editPaymentInformatation &&
                            <Grid item xs={12} style={{position:'relative'}}>
                                {this.state.documentStatus.identity_document_status === 'unverified' &&
                                    <>
                                        <CustomizedTextField
                                            variant="outlined"
                                            fullWidth

                                            placeholder="Please select which document you’d like to upload in order to verify the identity"
                                            InputProps={{
                                                readOnly: true,
                                                endAdornment: <img src={downArrow} />
                                            }}
                                            className={classes.singleInputField}
                                            onClick={() => this.handleOpendropDowns('identity')}
                                            data-test-id='identity'
                                        />
                                        {this.state.documentType&&this.renderVerificationDocumentType()}
                                        <Typography variant="body1" color="error">{this.state.pdfError}</Typography>


                                        {this.state.documentUploadStatus ?
                                            <>
                                                <Grid item xs={12} style={{ marginTop: '15px' }}>
                                                    <Box style={webstyle.fileBox}>
                                                        <Box >
                                                            <Grid item xs={12}>
                                                                <Grid container spacing={2} alignItems="center">
                                                                    <Box>
                                                                        <img src={tickIcon} />

                                                                    </Box>
                                                                    <Box>
                                                                        <Typography>{this.state.PdfFile&&this.state.PdfFile.name}</Typography>
                                                                        <Typography>{this.state.fileuploadedSize}</Typography>

                                                                    </Box>
                                                                </Grid>

                                                            </Grid>


                                                        </Box>
                                                        <Box data-test-id='delete' >
                                                            <img src={deleteIcon} style={{ cursor: 'pointer' }} />
                                                        </Box>
                                                    </Box>

                                                </Grid>
                                            </>

                                            : <Grid item xs={12} >
                                                <label htmlFor="icon-button-file">
                                                    <input
                                                        // accept="image/*"
                                                        type="file"
                                                        name="pdfPath"
                                                        id="icon-button-file"
                                                        onChange={this.handlePdfUploadFile}
                                                        style={{ display: "none" }}
                                                        data-test-id="pdfUpload"
                                                    />

                                                    <Grid container justifyContent="space-between" alignItems="center">
                                                        <Grid item >
                                                            <Typography color="error" variant="body1">{this.state.paymentFormErrors.pdfPath}</Typography>
                                                        </Grid>
                                                        <Grid item >

                                                            <Grid container spacing={1} alignItems="center">
                                                                <Grid item>
                                                                    <img src={uploadIcon} width={16} height={16} />

                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography variant="subtitle2" display="inline" color="secondary" className={classes.Uploadtext}>Upload documents</Typography>

                                                                </Grid>

                                                            </Grid>
                                                        </Grid>

                                                    </Grid>


                                                </label>
                                            </Grid>

                                        }
                                    </>

                                }
                            </Grid>
                        }


                    </Grid>

                </Grid>
            </>
        )
    }

    renderVerificationDocumentType = () => {
        const { classes } = this.props;
        return (
            <>
             <ClickAwayListener onClickAway={() => { this.handleClosedropDowns('identity') }}>
                <Box className={classes.dobDropDownslayout}>
                    <Box className={classes.outer}>
                {this.state.identityDocuments.map((identity: Identity) => {

                    return (
                        <Box display={'flex'} alignItems={'center'} className={classes.documentHover}>
                            <CustomCheckboxSecondary
                                data-test-id='select_type'
                                name=""
                                checked={identity.selected}
                                onChange={() => this.selectVerificatioDocument(identity.identity_id, identity.identity_name)}
                            />
                            <MenuItem className={classes.identityMenuText} key={identity.identity_id}
                                value={identity.identity_name}
                            >
                                {identity.identity_name}
                            </MenuItem>
                        </Box>
                    )
                }
                )}
                </Box>
                </Box>
                </ClickAwayListener>
            </>
        )
    }

    renderCompany = () => {
        const { classes } = this.props;
        return (
            <>
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="body1" className={classes.labelText}>{configJSON.paymentInformation.url}</Typography>

                        </Grid>
                        <Grid item xs={12}>
                            {this.state.editPaymentInformatation ?
                                <CustomizedTextField
                                    fullWidth
                                    variant="outlined"
                                    name='merchantUrl'
                                    value={this.state.paymentInformationForm.merchantUrl}
                                    data-test-id='merchanturl'
                                    onChange={this.handleFormChange}
                                /> :
                                <Typography variant="body1" className={classes.licienceText}>
                                    {this.state.paymentInformationForm.merchantUrl}
                                </Typography>
                            }

                        </Grid>

                    </Grid>

                </Grid>
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="body1" className={classes.labelText}>{configJSON.paymentInformation.businessName}</Typography>

                        </Grid>
                        <Grid item xs={12}>
                            {this.state.editPaymentInformatation ?
                                <CustomizedTextField
                                    fullWidth
                                    variant="outlined"
                                    name='businessName'
                                    value={this.state.paymentInformationForm.businessName}
                                    data-test-id='businessName'
                                    onChange={this.handleFormChange}
                                /> :
                                <Typography variant="body1" className={classes.licienceText}>
                                    {this.state.paymentInformationForm.businessName}
                                </Typography>
                            }

                        </Grid>

                    </Grid>

                </Grid>
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="body1" className={classes.labelText}>{configJSON.paymentInformation.companyName}</Typography>

                        </Grid>
                        <Grid item xs={12}>
                            {this.state.editPaymentInformatation ?
                                <CustomizedTextField
                                    fullWidth
                                    variant="outlined"
                                    name='companyName'
                                    value={this.state.paymentInformationForm.companyName}
                                    data-test-id='companyName'
                                    onChange={this.handleFormChange}
                                /> :
                                <Typography variant="body1" className={classes.licienceText}>
                                    {this.state.paymentInformationForm.companyName}
                                </Typography>
                            }

                        </Grid>

                    </Grid>

                </Grid>
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="body1" className={classes.labelText}>{configJSON.paymentInformation.companyEmail}</Typography>

                        </Grid>
                        <Grid item xs={12}>
                            {this.state.editPaymentInformatation ?
                                <CustomizedTextField
                                    fullWidth
                                    variant="outlined"
                                    name='companyEmail'
                                    value={this.state.paymentInformationForm.companyEmail}
                                    data-test-id='companyEmail'
                                    onChange={this.handleFormChange}

                                /> :
                                <Typography variant="body1" className={classes.licienceText}>
                                    {this.state.paymentInformationForm.companyEmail}
                                </Typography>
                            }

                        </Grid>

                    </Grid>

                </Grid>
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="body1" className={classes.labelText}>{configJSON.paymentInformation.companyPhone}</Typography>

                        </Grid>
                        <Grid item xs={12}>
                            {this.state.editPaymentInformatation ?
                                <>
                                    <Grid container>
                                        <Grid item xs={5} className={classes.position}>
                                            <CustomizedTextField
                                                InputProps={{
                                                    readOnly: true,
                                                    endAdornment: (
                                                        <img src={this.state.phoneCountrycodeDropDownStatus ? arrowUp : arrowDown} />

                                                    )
                                                }}
                                                className={classes.countryDropDow}
                                                fullWidth
                                                data-test-id='phonecountry_company'
                                                variant="outlined"
                                                value={this.state.paymentInformationForm.phoneNumberCountryCode}

                                                onClick={() => this.handleOpenCountryCodes('phonecountry')}
                                            />
                                            {this.state.phoneCountrycodeDropDownStatus &&
                                                this.renderCountryCodes('phonecountry')}

                                        </Grid>
                                        <Grid item xs={7}>
                                            <CustomizedTextField
                                                inputProps={{
                                                    inputMode: 'numeric',
                                                    pattern: '[0-9]*',
                                                    onInput: (e: React.ChangeEvent<HTMLInputElement>) => {
                                                        e.target.value = e.target.value.replace(/\D/g, '');
                                                    }
                                                }}
                                                variant="outlined"
                                                fullWidth
                                                className={classes.dropDown}
                                                onChange={this.handleFormChange}
                                                name='phonenumber'
                                                value={this.state.paymentInformationForm.companyPhone}
                                                data-test-id='phoneNumber_company'

                                            />

                                        </Grid>

                                    </Grid>

                                    <Typography variant="body1" color="error">
                                        {this.state.paymentFormErrors.companyPhone}
                                    </Typography>
                                </>
                                :
                                <Typography variant="body1" className={classes.licienceText}>
                                    {this.state.paymentInformationForm.companyPhone}
                                </Typography>
                            }


                        </Grid>

                    </Grid>

                </Grid>
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="body1" className={classes.labelText}>{configJSON.paymentInformation.companyAddress}</Typography>

                        </Grid>
                        <Grid item xs={12}>
                            {this.state.editPaymentInformatation ?
                                <CustomizedTextField
                                    fullWidth
                                    variant="outlined"
                                    name='companyAddress'
                                    value={this.state.paymentInformationForm.companyAddress}
                                    data-test-id='companyAddress'
                                    onChange={this.handleFormChange}

                                /> :
                                <Typography variant="body1" className={classes.licienceText}>
                                    {this.state.paymentInformationForm.companyAddress}
                                </Typography>
                            }

                        </Grid>

                    </Grid>

                </Grid>
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="body1" className={classes.labelText}>{configJSON.paymentInformation.companyState}</Typography>

                        </Grid>
                        <Grid item xs={12}>
                            {this.state.editPaymentInformatation ?
                                <CustomizedTextField
                                    fullWidth
                                    variant="outlined"
                                    name='companyState'
                                    value={this.state.paymentInformationForm.companyState}
                                    data-test-id='companyState'
                                    onChange={this.handleFormChange}

                                /> :
                                <Typography variant="body1" className={classes.licienceText}>
                                    {this.state.paymentInformationForm.companyState}
                                </Typography>
                            }

                        </Grid>

                    </Grid>

                </Grid>
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="body1" className={classes.labelText}>{configJSON.paymentInformation.companyCity}</Typography>

                        </Grid>
                        <Grid item xs={12}>
                            {this.state.editPaymentInformatation ?
                                <CustomizedTextField
                                    fullWidth
                                    variant="outlined"
                                    name='companyCity'
                                    value={this.state.paymentInformationForm.companyCity}
                                    data-test-id='companyCity'
                                    onChange={this.handleFormChange}

                                /> :
                                <Typography variant="body1" className={classes.licienceText}>
                                    {this.state.paymentInformationForm.companyCity}
                                </Typography>
                            }

                        </Grid>

                    </Grid>

                </Grid>
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="body1" className={classes.labelText}>{configJSON.paymentInformation.companyPostcode}</Typography>

                        </Grid>
                        <Grid item xs={12}>
                            {this.state.editPaymentInformatation ?
                                <CustomizedTextField
                                    fullWidth
                                    variant="outlined"
                                    name='companyPostalCode'
                                    value={this.state.paymentInformationForm.companyPostalCode}
                                    data-test-id='companyPostalCode'
                                    onChange={this.handleFormChange}
                                /> :
                                <Typography variant="body1" className={classes.licienceText}>
                                    {this.state.paymentInformationForm.companyPostalCode}
                                </Typography>
                            }

                        </Grid>

                    </Grid>

                </Grid>

            </>
        )
    }

    renderIndiduval = () => {
        const { classes } = this.props;
        return (
            <>
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="body1" className={classes.labelText}>{configJSON.paymentInformation.merchantcode}</Typography>

                        </Grid>
                        <Grid item xs={12}>
                            {this.state.editPaymentInformatation ?
                                <CustomizedTextField
                                    fullWidth
                                    variant="outlined"
                                    name='merchantCode'
                                    value={this.state.paymentInformationForm.merchantCode}
                                    onChange={this.handleFormChange}
                                    data-test-id='merchantCode'
                                    disabled
                                /> :
                                <Typography variant="body1" className={classes.licienceText}>
                                    {this.state.paymentInformationForm.merchantCode}
                                </Typography>
                            }


                        </Grid>

                    </Grid>

                </Grid>
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="body1" className={classes.labelText}>{configJSON.paymentInformation.name}</Typography>

                        </Grid>
                        <Grid item xs={12}>
                            {this.state.editPaymentInformatation ?
                                <>
                                    <Grid container>
                                        <Grid item xs={2} className={classes.position}>
                                            <CustomizedTextField
                                                className={classes.countryDropDow}
                                                fullWidth
                                                value={this.state.paymentInformationForm.mechantNameSalutation}
                                                variant="outlined"
                                                InputProps={{
                                                    readOnly: true,
                                                    endAdornment: (
                                                        <img src={this.state.merchantNameTypeDropDownStatus ? arrowUp : arrowDown} />

                                                    )
                                                }}
                                                onClick={() => this.handleOpendropDowns('merchantName')}
                                                data-test-id='merchantName'
                                            />
                                            {this.state.merchantNameTypeDropDownStatus &&
                                                this.rendernameTypes('merchantName')}

                                        </Grid>
                                        <Grid item xs={10}>
                                            <CustomizedTextField
                                                className={classes.dropDown}
                                                fullWidth
                                                variant="outlined"
                                                name='merchantName'
                                                onChange={this.handleFormChange}
                                                value={this.state.paymentInformationForm.merchantName}
                                                data-test-id='merchantname'
                                            />

                                        </Grid>

                                    </Grid>
                                    <Typography variant="body1" color="error">
                                        {this.state.paymentFormErrors.merchantName}
                                    </Typography>
                                </>
                                :
                                <Typography variant="body1" className={classes.licienceText}>
                                    {this.state.paymentInformationForm.merchantName}
                                </Typography>
                            }

                        </Grid>

                    </Grid>

                </Grid>
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={3}>
                        <Grid item xs={12}>
                            <Typography variant="body1" className={classes.labelText}>{configJSON.paymentInformation.url}</Typography>

                        </Grid>
                        <Grid item xs={12}>
                            {this.state.editPaymentInformatation ?
                                <CustomizedTextField
                                    name='merchantUrl'
                                    fullWidth
                                    variant="outlined"
                                    value={this.state.paymentInformationForm.merchantUrl}
                                    disabled
                                    onChange={this.handleFormChange}
                                    data-test-id='merchanturl'
                                /> :
                                <Typography variant="body1" className={classes.licienceText}>
                                    {this.state.paymentInformationForm.merchantUrl}
                                </Typography>
                            }

                        </Grid>

                    </Grid>

                </Grid>
                <Grid item xs={12}>
                    <Grid container direction="row" spacing={3}>
                        {this.renderCountryText()}
                        <Grid item xs={12}>
                            {this.state.editPaymentInformatation ?
                                <CustomizedTextField
                                    fullWidth
                                    variant="outlined"
                                    className={classes.singleInputField}
                                    name='merchantCountryCode'
                                    value={this.state.paymentInformationForm.merchantCountry}
                                    onChange={this.handleFormChange}
                                    data-test-id='merchantCountryCode'
                                    InputProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <img src={arrowUp} />
                                        )
                                    }}
                                /> :
                                <Typography variant="body1" className={classes.licienceText}>
                                    {this.state.paymentInformationForm.merchantCountry}
                                </Typography>
                            }

                        </Grid>

                    </Grid>

                </Grid></>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { date, month, year } = this.state.paymentInformationForm
        return (
            <>
                <CustomizedSnackbars openToaster={this.state.toasterStatus}
                    message={this.state.toasterMessage}
                    toasterType={this.state.toasterType}
                    closeStatus={this.closeToasterStatus}
                    duration={2000}
                />
                <SubscriptionSettings id={""} {...this.props} />
                {this.state.paymentDetailsStatus ?
                    <>
                        <Grid item xs={12}>
                            <Grid container direction="row" spacing={3}>
                                <Grid item xs={12}>
                                    <Grid container justifyContent="space-between" alignItems="center">
                                        <Grid item>
                                            <Typography variant="subtitle2" className={classes.labelText}>{configJSON.paymentInformation.heading}</Typography>

                                        </Grid>
                                        <Grid item>
                                            <Typography

                                                onClick={
                                                    this.state.editPaymentInformatation ? this.updatePayment : this.editPaymentInformatation
                                                }
                                                data-test-id='edit_information' variant="body1"
                                                className={classes.editText}>
                                                {this.state.editPaymentInformatation ? configJSON.propertyText.save : configJSON.propertyText.edit}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container direction="row" spacing={3}>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" className={classes.labelText}>{configJSON.paymentInformation.bussniessLabel}</Typography>

                                        </Grid>
                                        <Grid item xs={12} className={classes.position}>
                                            {this.state.editPaymentInformatation ?
                                                <>
                                                    <CustomizedTextField
                                                        fullWidth
                                                        variant="outlined"
                                                        className={classes.singleInputField}
                                                        data-test-id='bussinessType'
                                                        value={this.state.paymentInformationForm.bussinessType}
                                                        InputProps={{
                                                            readOnly: true,
                                                            endAdornment: (
                                                                <img src={this.state.bussinessTypeDropDownStatus ? arrowUp : arrowDown} />
                                                            )
                                                        }}
                                                    />
                                                </>

                                                :

                                                <Typography variant="body1" className={classes.licienceText}>
                                                    {this.state.paymentInformationForm.bussinessType}
                                                </Typography>
                                            }
                                        </Grid>

                                    </Grid>

                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container direction="row" spacing={3}>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" className={classes.labelText}>{configJSON.paymentInformation.firstName}</Typography>

                                        </Grid>
                                        <Grid item xs={12}>
                                            {this.state.editPaymentInformatation ?
                                                <>
                                                    <Grid container direction="row">
                                                        <Grid item xs={2} className={classes.position}>
                                                            <CustomizedTextField
                                                                className={classes.countryDropDow}
                                                                fullWidth
                                                                value={this.state.paymentInformationForm.firstNameSalutation}
                                                                variant="outlined"
                                                                InputProps={{
                                                                    readOnly: true,
                                                                    endAdornment: (
                                                                        <img src={this.state.firstNameTypeDropDownStatus ? arrowUp : arrowDown} />
                                                                    )
                                                                }}
                                                                onClick={() => this.handleOpendropDowns('firstName')}
                                                                data-test-id='firstNameDropDown'
                                                            />
                                                            {this.state.firstNameTypeDropDownStatus &&
                                                                this.rendernameTypes('firstName')}
                                                        </Grid>
                                                        <Grid item xs={10}>
                                                            <CustomizedTextField
                                                                fullWidth
                                                                className={classes.dropDown}
                                                                value={this.state.paymentInformationForm.firstName}
                                                                variant="outlined"
                                                                name='firstName'
                                                                onChange={this.handleFormChange}

                                                                data-test-id='firstName'
                                                            />


                                                        </Grid>
                                                        <Typography variant="body1" color="error">
                                                            {this.state.paymentFormErrors.firstName}
                                                        </Typography>
                                                    </Grid>
                                                </>
                                                :
                                                <Typography variant="body1" className={classes.licienceText}>
                                                    {this.state.paymentInformationForm.firstName}
                                                </Typography>
                                            }
                                        </Grid>

                                    </Grid>

                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container direction="row" spacing={3}>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" className={classes.labelText}>{configJSON.paymentInformation.surname}</Typography>

                                        </Grid>
                                        <Grid item xs={12}>
                                            {this.state.editPaymentInformatation ?
                                                <>
                                                    <CustomizedTextField
                                                        fullWidth
                                                        variant="outlined"
                                                        value={this.state.paymentInformationForm.surname}
                                                        onChange={this.handleFormChange}
                                                        name='surname'
                                                        data-test-id='surname'
                                                    />
                                                    <Typography variant="body1" color="error">
                                                        {this.state.paymentFormErrors.surname}
                                                    </Typography>
                                                </>
                                                :
                                                <Typography variant="body1" className={classes.licienceText}>
                                                    {this.state.paymentInformationForm.surname}
                                                </Typography>
                                            }

                                        </Grid>

                                    </Grid>

                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container direction="row" spacing={3}>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" className={classes.labelText}>{configJSON.paymentInformation.email}</Typography>

                                        </Grid>
                                        <Grid item xs={12}>
                                            {this.state.editPaymentInformatation ?
                                                <>
                                                    <CustomizedTextField
                                                        fullWidth
                                                        variant="outlined"
                                                        value={this.state.paymentInformationForm.email}
                                                        onChange={this.handleFormChange}
                                                        name='email'
                                                        data-test-id='email'
                                                    />
                                                    <Typography variant="body1" color="error">
                                                        {this.state.paymentFormErrors.email}
                                                    </Typography>

                                                </> :
                                                <Typography variant="body1" className={classes.licienceText}>
                                                    {this.state.paymentInformationForm.email}
                                                </Typography>
                                            }


                                        </Grid>

                                    </Grid>

                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container direction="row" spacing={3}>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" className={classes.labelText}>{configJSON.paymentInformation.Phonenumber}</Typography>

                                        </Grid>
                                        <Grid item xs={12}>
                                            {this.state.editPaymentInformatation ?
                                                <>
                                                    <Grid container>
                                                        <Grid item xs={5} className={classes.position}>
                                                            <CustomizedTextField
                                                                className={classes.countryDropDow}
                                                                fullWidth
                                                                value={this.state.paymentInformationForm.phoneNumberCountryCode}
                                                                variant="outlined"
                                                                InputProps={{
                                                                    readOnly: true,
                                                                    endAdornment: (
                                                                        <img src={this.state.phoneCountrycodeDropDownStatus ? arrowUp : arrowDown} />

                                                                    )
                                                                }}
                                                                onClick={() => this.handleOpenCountryCodes('phonecountry')}
                                                                data-test-id='phonecountry'
                                                            />
                                                            {this.state.phoneCountrycodeDropDownStatus &&
                                                                this.renderCountryCodes('phonecountry')}

                                                        </Grid>
                                                        <Grid item xs={7}>
                                                            <CustomizedTextField
                                                                fullWidth
                                                                variant="outlined"
                                                                className={classes.dropDown}
                                                                value={this.state.paymentInformationForm.phonenumber}
                                                                onChange={this.handleFormChange}
                                                                name='phonenumber'
                                                                data-test-id='phoneNumber'
                                                                inputProps={{
                                                                    inputMode: 'numeric',
                                                                    pattern: '[0-9]*',
                                                                    onInput: (e: React.ChangeEvent<HTMLInputElement>) => {
                                                                        e.target.value = e.target.value.replace(/\D/g, '');
                                                                    }
                                                                }}
                                                            />

                                                        </Grid>

                                                    </Grid>

                                                    <Typography variant="body1" color="error">
                                                        {this.state.paymentFormErrors.phonenumber}
                                                    </Typography>
                                                </>
                                                :
                                                <Typography variant="body1" className={classes.licienceText}>
                                                    {this.state.paymentInformationForm.phonenumber}
                                                </Typography>
                                            }


                                        </Grid>

                                    </Grid>

                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container direction="row" spacing={3}>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" className={classes.labelText}>{configJSON.paymentInformation.dob}</Typography>

                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container direction="row" alignItems="center" spacing={3}>
                                                {this.state.editPaymentInformatation ?
                                                    <>
                                                        <Grid item xs={12} sm={4} className={classes.position}>
                                                            <CustomizedTextField
                                                                fullWidth
                                                                value={this.state.paymentInformationForm.date}
                                                                variant="outlined"
                                                                // data-test-id='dayDropDown'
                                                                onClick={() => this.openDobDropDowns('day')}
                                                                InputProps={{
                                                                    readOnly: true,
                                                                    startAdornment: (
                                                                        <img src={calenderIcon} width={24} height={24} />

                                                                    )
                                                                }}

                                                                data-test-id='day'
                                                            />
                                                            {this.state.dayDropDownStatus &&
                                                                this.renderDob('day')}

                                                        </Grid>
                                                        <Grid item xs={12} sm={4} className={classes.position}>

                                                            <CustomizedTextField
                                                                fullWidth
                                                                value={this.state.paymentInformationForm.month}
                                                                variant="outlined"
                                                                onClick={() => this.openDobDropDowns('month')}
                                                                InputProps={{
                                                                    readOnly: true,
                                                                    startAdornment: (
                                                                        <img src={calenderIcon} width={24} height={24} />

                                                                    )
                                                                }}

                                                                data-test-id='month'
                                                            />
                                                            {this.state.monthDropDownStatus &&
                                                                this.renderDob('month')}
                                                        </Grid>
                                                        <Grid item xs={12} sm={4} className={classes.position}>
                                                            <CustomizedTextField
                                                                fullWidth
                                                                value={this.state.paymentInformationForm.year}
                                                                variant="outlined"
                                                                onClick={() => this.openDobDropDowns('year')}
                                                                InputProps={{
                                                                    readOnly: true,
                                                                    startAdornment: (
                                                                        <img src={calenderIcon} width={24} height={24} />

                                                                    )
                                                                }}

                                                                data-test-id='year'
                                                            />

                                                            {this.state.yearDropDownStatus &&
                                                                this.renderDob('year')}


                                                        </Grid>
                                                    </> :
                                                    <>
                                                        <Grid item>
                                                            <img src={calenderIcon} />
                                                        </Grid>
                                                        <Grid item >
                                                            <Typography variant="body1" className={classes.licienceText}>
                                                                {date}/{month}/{year}
                                                            </Typography>
                                                        </Grid>
                                                    </>
                                                }

                                            </Grid>

                                        </Grid>




                                    </Grid>

                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6" className={classes.labelText}>
                                        {configJSON.paymentInformation.address}
                                        <Typography display="inline" variant="h6" className={classes.menuText}>
                                            {configJSON.paymentInformation.addressSubText}</Typography>
                                    </Typography>

                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container direction="row" spacing={3}>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" className={classes.labelText}>{configJSON.paymentInformation.line1}</Typography>

                                        </Grid>
                                        <Grid item xs={12}>
                                            {this.state.editPaymentInformatation ?
                                                <>
                                                    <CustomizedTextField
                                                        fullWidth
                                                        value={this.state.paymentInformationForm.line1}
                                                        onChange={this.handleFormChange}
                                                        variant="outlined"
                                                        name='line1'
                                                        data-test-id='line1'
                                                    />
                                                    <Typography variant="body1" color="error">
                                                        {this.state.paymentFormErrors.line1}
                                                    </Typography>
                                                </> :
                                                <Typography variant="body1" className={classes.licienceText}>
                                                    {this.state.paymentInformationForm.line1}
                                                </Typography>
                                            }


                                        </Grid>

                                    </Grid>

                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container direction="row" spacing={3}>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" className={classes.labelText}>{configJSON.paymentInformation.city}</Typography>

                                        </Grid>
                                        <Grid item xs={12}>
                                            {this.state.editPaymentInformatation ?
                                                <>
                                                    <CustomizedTextField
                                                        fullWidth
                                                        variant="outlined"
                                                        onChange={this.handleFormChange}
                                                        value={this.state.paymentInformationForm.city}
                                                        name='city'
                                                        data-test-id='city'
                                                    />
                                                    <Typography variant="body1" color="error">
                                                        {this.state.paymentFormErrors.city}
                                                    </Typography>
                                                </> :
                                                <Typography variant="body1" className={classes.licienceText}>
                                                    {this.state.paymentInformationForm.city}
                                                </Typography>
                                            }


                                        </Grid>

                                    </Grid>

                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container direction="row" spacing={3}>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" className={classes.labelText}>{configJSON.paymentInformation.state}</Typography>

                                        </Grid>
                                        <Grid item xs={12}>
                                            {this.state.editPaymentInformatation ?
                                                <>
                                                    <CustomizedTextField
                                                        fullWidth
                                                        variant="outlined"
                                                        name='state'
                                                        value={this.state.paymentInformationForm.state}
                                                        onChange={this.handleFormChange}

                                                        data-test-id='state'

                                                    />
                                                    <Typography variant="body1" color="error">
                                                        {this.state.paymentFormErrors.state}
                                                    </Typography>
                                                </> :
                                                <Typography variant="body1" className={classes.licienceText}>
                                                    {this.state.paymentInformationForm.state}
                                                </Typography>
                                            }


                                        </Grid>

                                    </Grid>

                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container direction="row" spacing={3}>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" className={classes.labelText}>{configJSON.paymentInformation.postcode}</Typography>

                                        </Grid>
                                        <Grid item xs={12}>
                                            {this.state.editPaymentInformatation ?
                                                <>
                                                    <CustomizedTextField
                                                        fullWidth
                                                        variant="outlined"
                                                        value={this.state.paymentInformationForm.postcode}
                                                        onChange={this.handleFormChange}
                                                        name='postcode'
                                                        data-test-id='postcode'
                                                    />
                                                    <Typography variant="body1" color="error">
                                                        {this.state.paymentFormErrors.postcode}
                                                    </Typography>
                                                </>

                                                :
                                                <Typography variant="body1" className={classes.licienceText}>
                                                    {this.state.paymentInformationForm.postcode}
                                                </Typography>
                                            }


                                        </Grid>

                                    </Grid>

                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container direction="row" spacing={3}>
                                        {this.renderCountryText()}
                                        <Grid item xs={12}>
                                            {this.state.editPaymentInformatation ?
                                                <CustomizedTextField
                                                    fullWidth
                                                    variant="outlined"
                                                    className={classes.singleInputField}
                                                    data-test-id='country'
                                                    value={this.state.paymentInformationForm.countryCode}
                                                    InputProps={{
                                                        readOnly: true,
                                                        endAdornment: (
                                                            <img src={arrowDown} />

                                                        )
                                                    }}
                                                />
                                                :
                                                <Typography variant="body1" className={classes.licienceText}>
                                                    {this.state.paymentInformationForm.countryCode}
                                                </Typography>
                                            }

                                        </Grid>

                                    </Grid>

                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6" className={classes.labelText}>{configJSON.paymentInformation.pofile}</Typography>

                                </Grid>
                                {this.state.paymentInformationForm.bussinessType === "Individual" ?
                                    <>
                                        {this.renderIndiduval()}
                                    </> :
                                    <>
                                        {this.renderCompany()}
                                    </>}
                                <Grid item xs={12}>
                                    <Grid container direction="row" spacing={1} alignItems="center">
                                        <Grid item>
                                            <Typography variant="h6" className={classes.labelText}>
                                                {configJSON.paymentInformation.external}

                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            {this.renderVerificationText(this.state.documentStatus.external_account_status)}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" className={classes.labelText}>{configJSON.paymentInformation.type}</Typography>

                                </Grid>
                                <Grid item xs={12}>
                                    {this.state.editPaymentInformatation && this.state.documentStatus.external_account_status === 'verification_failed' ?
                                        <CustomizedTextField
                                            value={this.state.paymentInformationForm.accountType}
                                            className={classes.singleInputField}

                                            fullWidth
                                            variant="outlined"
                                            name='accountType'
                                            data-test-id='accountType'
                                            InputProps={{
                                                readOnly: true,
                                                endAdornment: (
                                                    <img src={arrowDown} />
                                                )
                                            }}
                                        /> :
                                        <Typography variant="body1" className={classes.licienceText}>
                                            {this.state.paymentInformationForm.accountType}
                                        </Typography>
                                    }


                                </Grid>

                            </Grid>

                        </Grid>
                        <Grid item xs={12}>
                            <Grid container direction="row" spacing={3}>
                                <Grid item xs={12}>
                                    <Typography variant="body1" className={classes.labelText}>{configJSON.paymentInformation.country}</Typography>

                                </Grid>
                                <Grid item xs={12}>
                                    {this.state.editPaymentInformatation && this.state.documentStatus.external_account_status === 'verification_failed' ?
                                        <CustomizedTextField
                                            value={this.state.paymentInformationForm.merchantCountry}
                                            className={classes.singleInputField}
                                            fullWidth
                                            variant="outlined"
                                            name='currency'
                                            data-test-id='currency'
                                            InputProps={{
                                                readOnly: true,
                                                endAdornment: (
                                                    <img src={arrowDown} />
                                                )
                                            }}
                                        /> :
                                        <Typography variant="body1" className={classes.licienceText}>
                                            {this.state.paymentInformationForm.currency}
                                        </Typography>
                                    }


                                </Grid>

                            </Grid>

                        </Grid>
                        <Grid item xs={12}>
                            <Grid container direction="row" spacing={3}>
                                <Grid item xs={12}>
                                    <Typography variant="body1" className={classes.labelText}>{configJSON.paymentInformation.currency}</Typography>

                                </Grid>
                                <Grid item xs={12}>
                                    {this.state.editPaymentInformatation && this.state.documentStatus.external_account_status === 'verification_failed' ?
                                        <CustomizedTextField
                                            value={this.state.paymentInformationForm.currency}
                                            className={classes.singleInputField}
                                            fullWidth
                                            variant="outlined"
                                            name='currency'
                                            data-test-id='currency'
                                            InputProps={{
                                                readOnly: true,
                                                endAdornment: (
                                                    <img src={arrowDown} />
                                                )
                                            }}
                                        /> :
                                        <Typography variant="body1" className={classes.licienceText}>
                                            {this.state.paymentInformationForm.currency}
                                        </Typography>
                                    }


                                </Grid>

                            </Grid>

                        </Grid>
                        <Grid item xs={12}>
                            <Grid container direction="row" spacing={3}>
                                <Grid item xs={12}>
                                    <Typography variant="body1" className={classes.labelText}>{configJSON.paymentInformation.accountName}</Typography>

                                </Grid>
                                <Grid item xs={12}>
                                    {this.state.editPaymentInformatation && this.state.documentStatus.external_account_status === 'verification_failed' ?
                                        <>
                                            <CustomizedTextField
                                                fullWidth
                                                onChange={this.handleFormChange}
                                                value={this.state.paymentInformationForm.accountName}
                                                variant="outlined"
                                                name='accountName'
                                                data-test-id='accountName'
                                            />
                                            <Typography variant="body1" color="error">
                                                {this.state.paymentFormErrors.accountName}
                                            </Typography>
                                        </>
                                        :
                                        <Typography variant="body1" className={classes.licienceText}>
                                            {this.state.paymentInformationForm.accountName}
                                        </Typography>
                                    }


                                </Grid>

                            </Grid>

                        </Grid>
                        {this.paymentInformation()}

                    </> :
                    this.noInformationfound()
                }
                <Loader loading={this.state.stripePageLoading} />
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webstyle = {
    typeInner: {
        width: "100%",
        height: '200px',
        overflowY: 'auto',
        position: "absolute",
        background: theme.palette.common.white,
        borderRadius: 8,
        zIndex: 99,
        boxShadow: boxShadows.whiteShadow,
    },
    fileBox:{
        display: "flex",
        height: 55,
        padding: "1px 20px 1px 20px",
        alignItems: "center",
        gap: "16px",
        justifyContent: 'space-between',
        background: theme.palette.primary.main,
        borderRadius: 8
    }
}
// Customizable Area End

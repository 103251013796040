import React from "react";

// Customizable Area Start
import { Container, Grid, Typography } from "@material-ui/core";
import Navbar from "../../../components/src/Navbar";
import Footer from "../../../components/src/Footer.web";
import { theme } from "../../../components/src/theme";
import { errorImage } from "../../../components/src/CommonFunctions";
// Customizable Area End

import AboutUsController, { Props } from "./AboutUsController.web";
import Loader from "../../../components/src/Loader.web";


export default class AboutUs extends AboutUsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  description = (description: string) => {

    return (
      <>
        {description && description.split(/\r?\n/).map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ))}
      </>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const location = window.location.pathname;
    const { details } = this.state

    return (
      <>
        <Navbar navType="fullNavbar" activeLink={location === '/WhyRegister' ? 3 : 2} />
        <Container maxWidth={false} style={styles.bgContainer as React.CSSProperties}>
          <div style={{ padding: "0 135px" }}>
            <Container maxWidth={false} style={{ background: "#fff", padding: 16, borderRadius: 8 }}>
              <Grid item xs={12} style={styles.mainDiv as React.CSSProperties}>
                <Grid container direction="row" justifyContent="center" style={{ gap: '5px' }}>
                  <Grid item xs={12} sm={4} style={styles.bigImage} >
                    <img width={"100%"} height={"536px"} src={details.images[0]?.url} onError={errorImage} style={styles.imageLayout as React.CSSProperties} />
                  </Grid>
                  <Grid item xs={12} sm={4} style={styles.bigImage} >
                    <img src={details.images[1]?.url} onError={errorImage} style={styles.imageLayout as React.CSSProperties} />

                  </Grid>
                  <Grid item xs={12} sm={4} style={styles.bigImage} >
                    <img src={details.images[2]?.url} onError={errorImage} style={styles.imageLayout as React.CSSProperties} />
                  </Grid>
                  <Grid item xs={12}>
                    <div style={styles.textFieldText as React.CSSProperties}>
                      <Grid item xs={12}>
                        <Grid container direction="row" justifyContent="flex-start">
                          <Grid item xs={12}>
                            <Typography variant="h4" component={'div'} style={styles.typographyText as React.CSSProperties}
                              dangerouslySetInnerHTML={{
                                __html: details.heading,
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} style={styles.descriptionAboutUsLayout}>
                            <Typography variant="body1" component={'div'} style={styles.descriptionAboutUs as React.CSSProperties}
                              dangerouslySetInnerHTML={{
                                __html: details.description,
                              }}
                            />
                          </Grid>
                        </Grid>

                      </Grid>

                    </div>

                  </Grid>

                </Grid>

              </Grid>

            </Container>
          </div>
          <Grid item xs={12}
            style={styles.footerStyles}
          >

          </Grid>
          <Footer footerNavigationData={this.state.socialLinksData} boAndPOLinks={this.state.poBOLinksData}/>
        </Container>
        <Loader loading={this.state.pageLoader} />
      </>

    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = {
  bgContainer: {
    background: theme.palette.primary.main,
    // padding: '0 135px'
  },
  mainDiv: {
    // display: "flex",
    // flexDirection: "column",
    // alignItems: "center",
    marginTop: "112px",

  },
  img: {
    maxWidth: "100%"
  },
  textFieldText: {
    // maxWidth: "900px",
    padding: "20px",
    borderTop: "1px solid #EA0C78",
    marginTop: "20px",
  },
  typographyText: {
    fontWeight: 400,
    lineHeight: "40px",
    color: theme.palette.common.black,
    textAlign: "justify",
    fontSize: 30
  },
  secoundText: {
    fontFamily: "inter",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    color: "#0F172A",
    marginTop: "20px",
    textAlign: "justify"
  },
  threedText: {
    fontFamily: "inter",
    fontWeight: 400,
    fontSize: "30px",
    lineHeight: "40px",
    color: "#334155",
    marginTop: "20px",
    textAlign: "justify"
  },
  footerStyles: {
    marginTop: '100px'
  },
  imageBackgroundImage: {
    background: theme.palette.primary.main,
    borderRadius: 8,
    display: 'flex',
    justify: 'content',
    maxHeight: "276px",
    width: "100%",
    height: '100%'
  },
  imageLayout: {
    width: 'auto',
    height: 'auto',
    maxWidth: '100%',
    maxHeight: '273px',
    borderRadius: 8,

  }, smallImageLayout: {
    width: 'auto',
    height: 'auto',
    maxWidth: '100%',
    maxHeight: '268px',
    borderRadius: 8
  },
  descriptionAboutUs: {
    fontWeight: 400,
    color: theme.palette.common.black,
    textAlign: "justify",
    lineHeight: '24px',
  },
  descriptionAboutUsLayout: {
    margin: "40px 0"
  },
  bigImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.primary.main,
    borderRadius: 8,
    height: '276px',
    maxWidth: '32.5%'
  },
  smalImages: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.primary.main,
    borderRadius: 8,
    height: '268px'
  }
};
// Customizable Area End

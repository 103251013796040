import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";
import React, { RefObject } from "react";
import { AllChatsList, AllchatsData, ChatMessagesData, Root } from "./types";
import { checkApiErrorResponce, handleNavigation } from "../../../components/src/CommonFunctions";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface IChat {
  id: string;
  muted: boolean;
  unreadCount: number;
  lastMessage: string;
  name: string;
}
interface IChatResponse {
  id: string;
  attributes: {
    name: string;
    accounts_chats: [
      {
        id: string;
        attributes: {
          account_id: number;
          muted: boolean;
          unread_count: number;
        };
      }
    ];
    messages: {
      id: string;
      attributes: { id: number; message: string };
      message: string;
    };
  };
}
interface LinkObject {
  url: string;
  title: string;
}
// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  accountId: number;
  chatName: string;
  chatList: IChat[];
  isVisibleModal: boolean;
  searchChatsName: string;
  deletePopupStatus: boolean;
  userType: string,
  allChats: AllchatsData[]
  chatId: number,
  previewMessages: ChatMessagesData,
  chatLoader: boolean;
  sendMessageText: string;
  uploadImage:File|null;
  imageError:string;
  pageComing:string
  socialLinksData: {icons: string, navigationUrl: string}[];
  poBOLinksData: LinkObject[];
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChatController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getChatListApiCallId: string = "";
  createChatRoomApiCallId: string = "";
  seeChatMessagesApiCallId: string = "";
  deleteChatApiCallId: string = "";
  sendMessageApiCallId: string = "";
  refreshChatIntervalId: NodeJS.Timeout | null = null;
  messagesEndRef: RefObject<HTMLDivElement>;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      accountId: -1,
      chatName: "",
      chatList: [],
      isVisibleModal: false,
      searchChatsName: "",
      deletePopupStatus: false,
      userType: "",
      allChats: [],
      chatId: 0,
      previewMessages:{
        id: 0,
        type: "",
        attributes: {
          id: 0,
          name: "",
          profile_pic: {
            id: 0,
            url: "",
            name: "",
            content_type: "",
            size: 0
          },
        
        }
      },
      chatLoader: false,
      sendMessageText: "",
      uploadImage:null,
      imageError:"",
      pageComing:"",
      socialLinksData: [],
      poBOLinksData: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.messagesEndRef=React.createRef()
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    const userType = await getStorageData('userType')
    this.setState({ userType: userType })
    this.getChatList()
    this.getToken();
    if (!this.isPlatformWeb()) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    const mySocialLinksData = await getStorageData("footerNavigationUrl", true);
    this.setState({socialLinksData: mySocialLinksData });
    const allLinksData = await getStorageData("BOandPOLinks",true)
    this.setState({poBOLinksData: allLinksData})
  }

  async componentDidUpdate(prevProps: Props, prevState: S) {
    if (prevState.previewMessages !== this.state.previewMessages) {
      this.scrollToBottom();
    }

    if (prevState.chatId !== this.state.chatId) {
      if (this.refreshChatIntervalId) {
        clearInterval(this.refreshChatIntervalId);
      }
  
      this.refreshChatIntervalId = setInterval(() => {
        this.seeChatMessages();
      }, 30000);
  
      this.refreshChatIntervalId = setInterval(() => {
        this.getChatList();
      }, 30000);
    }

  }

  scrollToBottom = () => {
    if (this.messagesEndRef.current) {
      this.messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };



  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  isStringNullOrBlank = (string: string) => {
    return string === undefined || string === null || string.length === 0;
  };

  showModal = () => {
    this.setState({ isVisibleModal: true });
  };

  hideModal = () => {
    this.setState({ isVisibleModal: false });
  };

  navigateToChatView = (chatId: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ViewChat");

    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), {
      chatId: chatId,
    });
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(message);
  };

  getChatList = async (chatName?:string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData('authToken'),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const endPoint = this.state.searchChatsName
      ? `${configJSON.searchMessageApiEndPoint}=${chatName}`
      : configJSON.getMyChatsApiEndpoint;

    this.getChatListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createChatRoom = (chatName: string) => {
    if (this.isStringNullOrBlank(chatName)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory,
        ""
      );
    } else {
      const header = {
        "Content-Type": configJSON.apiContentType,
        token: this.state.token,
        "Access-Control-Allow-Origin": "*",
      };
      const bodyData = {
        name: chatName,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.createChatRoomApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.createChatRoomApiEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(bodyData)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.postApiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  inputRoomNameProps = {
    onChangeText: (chatName: string) => {
      this.setState({ chatName });
    },
  };

  btnAddRoomProps = {
    onPress: () => this.createChatRoom(this.state.chatName),
  };

  btnCloseModalProps = {
    onPress: () => this.hideModal(),
  };

  btnShowAddModalProps = {
    onPress: () => {
      this.showModal();
    },
  };

  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {

      const sessionData = message.getData(getName(MessageEnum.HotelDetails));
      if (sessionData) {

        this.setState({ chatId: sessionData.chatId,pageComing:sessionData.page }, () => {
          this.seeChatMessages()
        })
      }
      return;

    }
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      const token: string = message.getData(
        getName(MessageEnum.SessionResponseToken)
      );
      runEngine.debugLog("TOKEN", token);
      if (token) {
        this.setState({ token }, () => this.getChatList());
      }
    }
    if (apiRequestCallId === this.getChatListApiCallId) {
     this.chatListApiResponce(responseJson)
    } if (apiRequestCallId === this.seeChatMessagesApiCallId) {
      this.seeChatMessagesApiResponce(responseJson)
    }
    if (apiRequestCallId === this.deleteChatApiCallId) {
     this.deleteChatApiResponce(responseJson)
    } if (apiRequestCallId === this.sendMessageApiCallId) {
      if (responseJson && !responseJson.errors) {
        this.seeChatMessages()
        this.setState({sendMessageText:"",uploadImage:null})
      }
    }

  }

  chatListApiResponce=(responseJson:AllChatsList)=>{
    if (responseJson && !responseJson.errors) {

      this.setState({
        allChats: responseJson.all_chats.data,
        chatLoader: false
      });
    }
    else if(responseJson.errors){
      checkApiErrorResponce(responseJson, this.props.navigation)

    }
  }

  seeChatMessagesApiResponce=(responseJson:Root)=>{
    if (responseJson && !responseJson.errors) {
      this.setState({
        previewMessages: responseJson.chat_messages?.data,
        chatId:responseJson?.chat_messages?.data?.id
      },()=>{
        this.getChatList()

      })
    }
  }

  deleteChatApiResponce=(responseJson:Root)=>{
    if (responseJson && !responseJson.errors) {
      this.setState({
        previewMessages: responseJson.chat_messages?.data
      }, () => {
        this.getChatList()
      })
    }
  }
  handleChangeSearchChats = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchChatsName: event.target.value }, () => {
      this.getChatList(this.state.searchChatsName)
    })


  }


  selectChat = (chatId: number) => {
    this.setState({ chatId: chatId }, () => {
      this.seeChatMessages()
    })

  }

  seeChatMessages = async () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData('authToken'),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.seeChatMessagesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.seeChatsEndPoint}/${this.state.chatId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  deleteChat = async () => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: await getStorageData('authToken'),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setState({ chatLoader: true })
    this.deleteChatApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.seeChatsEndPoint}/${this.state.chatId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  handleEnterMessage = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ sendMessageText: event.target.value,imageError:"" })
  }
  sendMessage = async (event: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    event.preventDefault()

    const { sendMessageText, uploadImage, chatId } = this.state;

    if (!sendMessageText && !uploadImage) {
      this.setState({imageError:"No message text or image to send."})
      return;
    }
    this.setState({imageError:""})

    const header = {
      token: await getStorageData('authToken'),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    let sendMessageFormData = new FormData();
    sendMessageFormData.append('chat_id', chatId.toString())

    sendMessageFormData.append('message[message]', this.state.sendMessageText)
     if(this.state.uploadImage){
      sendMessageFormData.append('message[attachments][]',this.state.uploadImage)
     }


    this.sendMessageApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.sendMessageEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage), sendMessageFormData

    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleUploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];

    if (file) {
      const isValid = this.validateImage(file);
      if (isValid) {
        this.setState({ uploadImage: file });
      }
    }
  }


  validateImage = (file: File): boolean => {
    const validImageTypes = ["image/jpeg", "image/png"];
  
    if (!validImageTypes.includes(file.type)) {
      this.setState({imageError:"Only JPEG, PNG are allowed."})
      return false;
    }else{
      this.setState({imageError:""})

    }
    return true;
  };

  // Customizable Area End
}

Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "settings2";
exports.labelBodyText = "settings2 Body";

exports.deleteUserApiEndPoint="account_block/delete_account";
exports.deleteApiMehod="DELETE"

exports.btnExampleTitle = "CLICK ME";

exports.countries = [
  { label: 'United Kingdom', phone: '(+44)' },
  { code: 'AF', 
  label: 'Afghanistan', 
  phone: '(+93)' },
  {label: 'Albania', code: 'AL',  phone: '(+355)' },
  {phone: '(+358)', code: 'AX', label: 'Alland Islands'  },
  {   code: 'AD', label: 'Andorra', 
  phone: '(+376)' },
  {  ce: 'AI', 
  label: 'Anguilla', phone: '(+1-264)' },
  { code: 'AG', label: 'Antigua and Barbuda',
   phone: '(+1-268)' },
  { code: 'AR', 
  label: 'Argentina', phone: '(+54)' },
  { label: 'Armenia',
   phone: '(+374)' },
  { coe: 'AW', 
  label: 'Aruba', phone: '(+297)' },
  { label: 'Australia',
   phone: '(+61)' },
  { code: 'AT', 
  label: 'Austria', phone: '(+43)' },
  { label: 'Azerbaijan',
   phone: '(+994)' },
  { code: 'BS',
   label: 'Bahamas', phone: '(+1-242)' },
  { label: 'Bermuda',
   phone: '(+1-441)' },
  { o: 'BT', 
  label: 'Bhutan',
   phone: '(+975)' },
  { ce: 'BO', 
  label: 'Bolivia', phone: '(+591)' },
  { code: 'BA', 
  label: 'Bosnia and Herzegovina',
   phone: '(+387)' },
  { label: 'Bouvet Island', 
  phone: '(+47)' },
  { label: 'Brazil', phone: '(+55)' },
  { label: 'British Indian Ocean Territory',
   phone: '(+246)' },
  { code: 'BN',
   label: 'Brunei Darussalam', phone: '(+673)' },
  { label: 'Burundi',
   phone: '(+257)', co: 'hdfj' },
  { coe: 'CM', label: 'Cameroon', phone: '(+237)' },
  { code: 'CV', 
  label: 'Cape Verde', phone: '(+238)' },
  { code: 'CN', label: 'China', 
  phone: '(+86)' },
  { ce: 'CX', 
  label: 'Christmas Island', phone: '(+61)' },
  { label: 'Colombia', 
  phone: '(+57)' },
  { code: 'KM', 
  label: 'Comoros', phone: '(+269)' },
  { code: 'CK', label: 'Cook Islands', 
  phone: '(+682)' },
  { code: 'CR', 
  label: 'Costa Rica', phone: '(+506)' },
  { label: 'Cuba', 
  phone: '(+53)' },
  { code: 'CW', label: 'Curacao', phone: '(+599)' },
  { label: 'Cyprus', 
  phone: '(+357)' },
  { code: 'CZ', 
  label: 'Czech Republic', phone: '(+420)' },
  { ode: 'FO', label: 'Faroe Islands',
   phone: '(+298)' },
  { code: 'FR',
   label: 'France', phone: '(+33)' },
  { label: 'French Guiana',
   phone: '(+594)' },
  { ce: 'GM', 
  label: 'Gambia', phone: '(+220)' },
  { label: 'Georgia', 
  phone: '(+995)' },
  { ode: 'DE', 
  label: 'Germany', phone: '(+49)' },
  { code: 'GH', label: 'Ghana',
   phone: '(+233)' },
  { label: 'Grenada', 
  phone: '(+1-473)' },
  { label: 'Guadeloupe', phone: '(+590)' },
  { code: 'GG',
   label: 'Guernsey', phone: '(+44)' },
  { code: 'GN', label: 'Guinea', 
  phone: '(+224)' },
  { code: 'HU',
   label: 'Hungary', phone: '(+36)' },
  { code: 'IN', label: 'India', 
  phone: '(+91)' },
  { label: 'Iraq', phone: '(+964)' },
  { code: 'IE', 
  label: 'Ireland', phone: '(+353)' },
  { label: 'Isle of Man', phone: '(+44)' },
  { code: 'IL',
   label: 'Israel', phone: '(+972)' },
  { ode: 'KR', label: 'Korea, Republic of',
   phone: '(+82)' },
  { code: 'KW', label: 'Kuwait', phone: '(+965)' },
  {
    code: 'LA',
    label: "Lao People's Democratic Republic",
    phone: '(+856)'
  },
  { label: 'Madagascar',
   phone: '(+261)' },
  { code: 'NG', label: 'Nigeria',
   phone: '(+234)' },
  { code: 'NU', label: 'Niue', phone: '(+683)' },
  { code: 'Rs', 
  label: 'Russian Federation', phone: '(+7)' },
  { cde: 'RW', 
  label: 'Rwanda', phone: '(+250)' },
  { label: 'Saint Barthelemy',
   phone: '(+590)' },
  { code: 'MF', label: 'Saint Martin (French part)',
   phone: '(+590)' },
  { label: 'Serbia',
   phone: '(+381)', code: 'SB' },
  { label: 'Seychelles', phone: '(+248)' },
  { code: 'SB', 
  label: 'Solomon Islands', phone: '(+677)' },
  { code: 'AE', label: 'United Arab Emirates',
   phone: '(+971)' },
  { code: 'US',
   label: 'United States', phone: '(+1)' },
  { odc: 'YE', 
  label: 'Yemen', phone: '(+967)' },
  { cd: 'ZM', label: 'Zambia', phone: '(+260)' },
  { ce: 'ZM', label: 'Zambia', 
  phone: '(+260)' },
  { label: 'Zimbabwe',
   phone: '(+263)' }
]


exports.MesErrProfile = {
  imageUplode:"Please upload valid image.",
  imageUrl: "Please upload your image.",
  gender: "Please specify your gender.",
  fullName: "Please enter your full name.",
  email: "Please enter your email.",
  country: "Please enter your country.",
  phoneNumber: "Please enter a valid phone number.",
  streetAddress: "Please enter the address line 1.",
  flatNo: "Please enter the address line 2.",
  city: "Please enter your town/city.",
  postCode: "Please enter your post code.",
  phoneCountry: "Please enter your phone number's country code.",
  firstNameBo:"Please enter your first name",
  lastNameBo:"Please enter your last name",
  phoneNumberBo:"Please enter your phone number",
  emailBo:"Please enter your email"
};
exports.emailRegex=/^\s*[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\s*$/;
exports.nameRegex=/^\s*[a-zA-Z]+(?:\s[a-zA-Z]+)*\s*$/;
exports.numerRegex= /^\s*\d+\s*$/;
exports.message='Old password is incorrect'
// Customizable Area End
import React from "react";
// Customizable Area Start
import { Theme, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography, createStyles, withStyles, DialogActions, Button } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import ReviewsPoBoController, { Props } from "./ReviewsPoBoController.web";
import { Rating } from "@material-ui/lab";
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { ContactUsTextField } from "../../contactus/src/ContactUsHelp.web";
import CustomizedSnackbars from "../../../components/src/CustomSnackbar.web";
// Customizable Area End

// Customizable Area Start
const StyledRating = withStyles((theme) => ({
    iconFilled: {
        fontSize: '6rem',
        color: theme.palette.warning.main,
        [theme.breakpoints.down('sm')]: {
            fontSize: '3rem'
        }
    },
    iconHover: {
        color: 'none'

    },
}))(Rating);

// Customizable Area End
export class Addreview extends ReviewsPoBoController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End

    render() {
        // Customizable Area StartAddreview
        const { classes } = this.props;
        return (
            <>

                <Dialog open={this.state.addreviewPopupStatus} maxWidth={'md'}
                    onClose={this.closeAddReviewModal} aria-labelledby="form-dialog-title"
                    PaperProps={
                        {
                            style: {
                                maxWidth: "765px",
                                width: '100%',
                                borderRadius: "8px 8px 32px 8px"
                            }
                        }
                    }
                >
                    <CustomizedSnackbars
                        message={'Thanks for providing the Feedback'}
                        toasterType="success"
                        openToaster={this.state.reviewcreateStatus} duration={20000}/>
                    <DialogTitle>
                        <Grid item xs={12}>
                            <Grid container justifyContent="space-between" alignItems="center" style={{}}>
                                <Grid item>
                                    <Typography variant='h4' className={classes.nameheading}> Review</Typography>
                                </Grid>
                                <Grid item>
                                    <IconButton aria-label="close" data-test-id="close_dailog" onClick={this.closeAddReviewModal} >
                                        <CloseIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>

                        </Grid>

                    </DialogTitle>
                    <DialogContent>
                        <Grid item xs={12}>
                            <Grid container direction="row" justifyContent="center" style={webStyle.reviewContent} spacing={4}>
                                <Grid item xs={12} >
                                    <Typography variant="subtitle2" className={classes.nameheading}>How would you rate your experience?</Typography>
                                </Grid>
                                <Grid item xs={12} className={classes.align}>
                                    <StyledRating
                                        value={this.state.ratingValue} onChange={(event, newValue) => {
                                            this.setState({ ratingValue: newValue });
                                        }}

                                        emptyIcon={<StarBorderIcon className={classes.ratingEmptyIcon} />}
                                    />
                                </Grid>
                                <Grid item xs={12} >
                                    <Typography variant="subtitle2" className={classes.nameheading}>Do you have any additional comments?</Typography>
                                </Grid>
                                <Grid item xs={12} >
                                    <ContactUsTextField
                                    data-test-id="descriptionInput"
                                    value={this.state.ratingDescription}
                                    onChange={this.handleRatingsDescription}
                                    multiline
                                    minRows={5}
                                    maxRows={6}
                                    className={classes.messageInput}
                                    name='message'
                                    fullWidth
                                    variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} style={webStyle.submitButton}>
                                <Button variant="contained"
                            onClick={this.createReview}
                            data-test-id="submit_rating"
                            className={classes.submitReviewButton} color="secondary">Submit Review</Button>

                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                   
                </Dialog>
            </>
        )
        // Customizable Area End
    }
}

// Customizable Area Start
export const AddReviewsStyles = (theme: Theme) =>
    createStyles({
        nameheading: {
            color: theme.palette.common.black,
            fontWeight: theme.typography.fontWeightBold,
            textAlign: 'center'

        },
        closeIconLayout: {
            padding: 10,
            display: 'flex',
            justifyContent: "flex-end",
            alignItems: 'center'
        },
        actionLayout: {
            justifyContent: 'center'
        },
        submitReviewButton: {
            width: 308,
            height: 56,
            borderRadius: 8,
            fontSize: 16,
            textTransform:'capitalize',
            fontWeight: theme.typography.fontWeightBold,
            '&:hover': {
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.common.white
            },
            [theme.breakpoints.down('xs')]: {
                width: "100%"
            }
        },
        ratingEmptyIcon: {
            color: theme.palette.warning.main,
            fontSize: '6rem !important',
            [theme.breakpoints.down('sm')]: {
                fontSize: '3rem !important'
            }
        },
        align: {
            textAlign: 'center'
        },
        messageInput: {
            "& .MuiInputBase-root": {
                 height: 'auto !important'
            }
        }
    })
export default withStyles(AddReviewsStyles)(Addreview);


const webStyle={
    reviewContent:{
        widt:'100%',
        padding:'30px',
        margin:'auto !important'
    },
    submitButton:{
        marginTop:'20px',
        textAlign:'center' as const
    },
}
// Customizable Area End
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";

// Customizable Area Start
interface LogInResponse {
  data: {
    token: string;
  };
  error?: { password: string[], };
  errors?:
    | {
        password?: string;token?:string
      }[]
    | { password_confirmation?: string[]; password?: string}
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  id: string;
  navigation:any;
}

export interface S {
  // Customizable Area Start
  newPassword: string;
  showPassword: boolean;
  passwords: string;
  apiErrorLogIn: string;
  showYourPassword: boolean;
  forgotPopUpOwner: boolean;
  email:any
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class ForgotPasswordPetOwnerController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  forgotPasswordPetId: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start

      newPassword: "",
      showPassword: false,
      passwords: "",
      apiErrorLogIn: "",
      showYourPassword: false,
      forgotPopUpOwner: false,
      email:window.location.href.split("=")[1]
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.forgotPasswordPetId) {
      this.handleResForSignUp(responseJson);
    }

    // Customizable Area End
  }
  // Customizable Area Start




  handleResForSignUp = (responseJson: LogInResponse) => {
    if (responseJson) {
      this.setState({
        apiErrorLogIn: "",
        passwords: "",
        newPassword: "",
        forgotPopUpOwner: true,
      });
      window.location.href = "/LogInPetOwner";
      localStorage.clear();
    }
   
    
  };

  handleClosePopupOwner = () => {
    this.setState({ forgotPopUpOwner: false });
  };
  handlePasswordNewChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const passwords = event.target.value;
    this.setState({ passwords });
  };

  handlePasswordToggle = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ newPassword: event.target.value });
  };

  handleYourPasswordToggle = () => {
    this.setState((prevState: S) => ({
      showYourPassword: !prevState.showYourPassword,
    }));
  };

  callApiForPoLogIn = () => {
    const loginRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    loginRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_forgot_password/forgot_reset_password`
    );

    this.forgotPasswordPetId = loginRequestMessage.messageId;
    loginRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({})
    );

    let data = new FormData();
    data.append("email",this.state.email)
    data.append("data[new_password]", this.state.passwords);
    data.append("data[confirm_password]", this.state.newPassword);

    loginRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      data
    );

    loginRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(loginRequestMessage.id, loginRequestMessage);
  };

  validatePassword = () => {
    const passwords = this.state.passwords;

    const isCapitalLetter = /[A-Z]/.test(passwords);
    const isLowerCaseLetter = /[a-z]/.test(passwords);
    const isNumber = /\d/.test(passwords);
    const isMinLength = passwords.length >= 8;
    const isSpecialChracter= /\W+/.test(passwords)

    const isValid =
      isCapitalLetter && isLowerCaseLetter && isNumber && isMinLength;

    return {
      isValid,
      isCapitalLetter,
      isLowerCaseLetter,
      isNumber,
      isMinLength,
      isSpecialChracter
    };
  };
  closeStatusForgotPassword=(status:boolean)=>{
    this.setState({forgotPopUpOwner:status})

  }
  // Customizable Area End
}

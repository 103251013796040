import React, { FunctionComponent } from "react";
import { LatLngLiteral } from "leaflet";
import { useMap } from "react-leaflet";
import { Marker } from "react-leaflet";
import { Icon } from 'leaflet';

interface Props {
  coords: [number, number],
  markerIconPng: string
}

const Maps: FunctionComponent<Props> = ({
  coords,
  markerIconPng
}) => {

  const setViewOnClick = (coords: [number, number]) => {
    const [latitude, longitude] = coords;
    const latLng: LatLngLiteral = { lat: latitude, lng: longitude };
    const mapValue = useMap();
    mapValue.setView(latLng, mapValue.getZoom());
    return null;
  };

  return (
    <>
      {setViewOnClick(coords)}
      <Marker position={coords} icon={new Icon({ iconUrl: markerIconPng, iconSize: [40, 40], iconAnchor: [12, 41] })} />
    </>
  );
};

export default Maps;

export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const DogImg = require("../assets/group_dog.png");
export const CatImg = require("../assets/group_cat.png");
export const DogCatImg = require("../assets/Frameimg.png");
export const Daycare = require("../assets/daycareImg.png");
export const HotelServ = require("../assets/hotelserviceimg.png");
export const Grooming = require("../assets/groomingImg.png");
export const Imgicon = require("../assets/group_Frame 319.png");
export const imagelogo = require("../assets/ImageiconDark.png");
export const map = require("../assets/locationMap.png")
export const backgroundUploadLogo=require('../assets/backgroundUploadFile.svg')
export const uploadImage=require('../assets/uploadImage.png')
export const uploadIcon=require('../assets/uploadFile.png');
export const arrowLeft=require('../assets/arrowLeft.png');
export const arrowRight=require('../assets/arrowRight.png');
export const notFoundIcon=require('../assets/notFound.svg');
export const filterIcon=require('../assets/filter.svg');

export const calenderIcon=require('../assets/calender.png')
export const profileIcon=require('../assets/group_avatar.png')
export const threeDotsIcon =require('../assets/threeDots.svg')
export const deleteIcon=require('../assets/delete.svg');
export const tickIcon=require('../assets/tick.svg');
export const dog_cat_Image=require('../assets/dog_catImage.png')
export const location = require('../assets/location.png');




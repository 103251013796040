import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
interface RootObject {
  about_us: Aboutus;
}
interface Aboutus {
  data: Datum[];
}
interface Datum {
  id: string;
  type: string;
  attributes: Attributes;
}
interface Attributes {
  description: string;
  heading:string;
  about_us_images: Aboutusimage[];
  why_register_images:Aboutusimage[]
}
interface Aboutusimage {
  id: number;
  url: string;
  name: string;
  content_type: string;
  size: number;
}
interface LinkObject { 
  url: string; 
  title: string; 
}
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start

// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  pageLoader: boolean
  contentDetails:Datum[],
  details:{
    heading:string,
    description:string,
    images:Aboutusimage[]

  },
  socialLinksData: {icons: string, navigationUrl: string}[];
  poBOLinksData: LinkObject[]


  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class AboutUsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  landingPageCardId: string = "";
  apiCallIdGetAboutUsWhyRegisterDetails: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      pageLoader: false,
      contentDetails:[],
      details:{
        heading: "",
        description: "",
        images: []
      },
      socialLinksData: [],
      poBOLinksData: []

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.apiCallIdGetAboutUsWhyRegisterDetails) {
      if (responseJson &&!responseJson.errors) {
        window.scrollTo(0,0)
        let details={
          heading: responseJson.about_us?.data[0]?.attributes?.heading||responseJson.why_register?.data[0]?.attributes?.heading,
          description: responseJson.about_us?.data[0]?.attributes?.description||responseJson.why_register?.data[0]?.attributes?.description,
          images: responseJson.about_us?.data[0]?.attributes?.about_us_images||responseJson.why_register?.data[0]?.attributes?.why_register_images
        }
        this.setState({
          contentDetails :responseJson?.about_us?.data,
          details:details,
          pageLoader: false
        });
      }
      
    }
    

    // Customizable Area End
  }
  // Customizable Area Start
 async componentDidMount(): Promise<void> {
  this.getDetails()
  const mySocialLinksData = await getStorageData("footerNavigationUrl", true);
  this.setState({socialLinksData: mySocialLinksData }); 
  const allLinksData = await getStorageData("BOandPOLinks",true)
  this.setState({poBOLinksData: allLinksData}) 
  }
  getDetails = () => {
    const loginHeader = {
      "Content-Type": configJSON.validationApiContentType
    };

    const pathName=window.location.pathname

    this.setState({pageLoader:true})
    const loginRequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.apiCallIdGetAboutUsWhyRegisterDetails = loginRequestMessage.messageId;
    loginRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
    pathName==='/AboutUs'?configJSON.aboutUsEndPoint:configJSON.whyRegisterEndPoint);

    loginRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(loginHeader)
    );

    loginRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod
    );
    runEngine.sendMessage(loginRequestMessage.id, loginRequestMessage);
  };

  // Customizable Area End
}

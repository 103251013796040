import React from "react";
// Customizable Area Start
import {
    Grid, Typography, Box, MenuItem, Button, ClickAwayListener, Snackbar, Hidden
} from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { CustomizedTextFieldAutoComplete, StyledMenuPet, StyledMenuPetInOut } from "../../../components/src/CustomizedComponents.web";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { Calendar } from "react-multi-date-picker";
import { IconSearch } from "./assets";
import { Cat, Dog } from "../../landingpage/src/assets";
import { boxShadows } from "../../../components/src/theme";
import LandingPageController, { Props } from "./LandingPagePOController.web";
import { Alert } from "@material-ui/lab";
import CustomizedSnackbars from "../../../components/src/CustomSnackbar.web";

// Customizable Area End

// Customizable Area Start

// Customizable Area End
export class LandingPageSearch extends LandingPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    locationList = () => {
        const { classes } = this.props
        return (
            <>
                <ClickAwayListener onClickAway={this.handleLoactionListclose}>
                    <Typography component={'div'}>
                        <Box className={classes.toggleRequestModalContainer} >
                        </Box>
                    </Typography>
                </ClickAwayListener>
            </>
        );
    }
    renderAddPets = () => {
        const { classes } = this.props;
        const dogTextColor = this.state.addRoomDog === 0 ? "#E2E8F0" : "#1E293B";
        const catTextColor = this.state.addRoomCat === 0 ? "#E2E8F0" : "#1E293B";
        const catIconColor = this.state.addRoomCat === 0 ? "#B2E4F8" : "#65C9F1";
        return (
            <ClickAwayListener onClickAway={this.addPetsPopupClose}>
                <Box className={classes.toggleRequestModalContainer} >
                    <MenuItem className={classes.addpetDogText}>
                        <Grid container alignItems="center" >

                            <img
                                src={Dog}
                                alt="Dog"
                                style={{ marginRight: "8px" }}
                            />
                            <p style={{ fontWeight: 700 }}>Dog</p>
                        </Grid>
                        <Grid container justifyContent="center" alignItems="center" >
                            <RemoveCircleOutlineIcon
                                className={classes.circleIcon}
                                onClick={this.decrementDogs}
                                data-test-id="minus_dog"
                            />
                            <p style={{ color: dogTextColor }}>
                                &nbsp;&nbsp;{this.state.addRoomDog} &nbsp;&nbsp;
                            </p>
                            <AddCircleOutlineIcon
                                className={classes.circleIcon}
                                onClick={this.incrementDogs}
                                data-test-id="add_dog"
                            />
                        </Grid>
                    </MenuItem>

                    <MenuItem className={classes.addpetDogText}>
                        <Grid container alignItems="center" >
                            <img
                                src={Cat}
                                alt="Cat"
                                style={{ marginRight: "8px" }}
                            />
                            <p style={{ fontWeight: 700 }}>Cat</p>
                        </Grid>
                        <Grid container justifyContent="center" alignItems="center">
                            <RemoveCircleOutlineIcon
                                style={{ cursor: "pointer", color: catIconColor }}
                                onClick={this.catsDecrement}
                                data-test-id="cat_decrement"
                            />
                            <p style={{ color: catTextColor }}>
                                &nbsp;&nbsp;
                                {this.state.addRoomCat} &nbsp;&nbsp;
                            </p>
                            <AddCircleOutlineIcon
                                className={classes.circleIcon}
                                onClick={this.catsIncrement}
                                data-test-id="cat_increment"
                            />
                        </Grid>
                    </MenuItem>

                    <Typography
                        className={classes.continueText}
                        onClick={this.addPetsPopupClose}
                        data-test-id="close_pet_popup"
                    >
                        Continue
                    </Typography>
                </Box>
            </ClickAwayListener>
        )
    }

    calender = () => {
        const { classes } = this.props
        return (
            <div style={{ overflow: "hidden" }}>
                <Calendar
                    onChange={this.handleDateChange}
                    data-test-id="calender"
                    range
                    numberOfMonths={2}
                    weekStartDayIndex={1}
                    format="DD MMM"
                    minDate={new Date()}
                    value={this.state.selectedRange}
                    className={classes.fontCalender}
                >
                    <Typography
                        data-test-id="checkinout_close"
                        onClick={this.datePopupClose}
                        className={classes.dateContinueBtn}>
                        Continue
                    </Typography>
                </Calendar>
            </div>
        )
    }
    renderCalender = () => {
        const { classes } = this.props
        return (
            <>
                <ClickAwayListener onClickAway={this.datePopupClose}>
                    <Typography component={'div'}>
                        <Box className={classes.toggleRequestModalContainer} >
                            {this.calender()}
                        </Box>
                    </Typography>
                </ClickAwayListener>
            </>
        );
    }
    // Customizable Area End

    render() {
        // Customizable Area Start

        const { classes } = this.props;

        return (
            <>
               <CustomizedSnackbars openToaster={this.state.openSnckBar} message="You must select both check-in and check-out dates"
                    toasterType={'error'}
                    duration={20000} />
                <Grid item xs={12} className={classes.serchBar}>
                    <Grid container direction="row" alignItems="center" spacing={1} className={classes.padding} >
                        <Grid item xs={12} sm={5} md={4} >
                            <Grid container direction="row" className={classes.locationLayout}>
                                <Grid item xs={12}>
                                    <Typography variant="body1" className={classes.locationText}>Location</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <CustomizedTextFieldAutoComplete
                                        placeholder="Your pet’s home away from home"
                                        variant="outlined"
                                        className={classes.inputLocation}
                                        fullWidth
                                        value={this.state.locationName}
                                        data-test-id="search_location"
                                        onChange={this.handleLocationChange}
                                        onClick={this.handleLocationListPopup}
                                        InputProps={{ classes: { input: classes['input'] } }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={3} md={4} style={{ position: 'relative' }}>
                            <Button
                                onClick={this.datePopupOpen}
                                data-test-id="check_in_out"
                                fullWidth
                                className={classes.buttonLayout}
                            >
                                <div className={classes.inputHeadings}>
                                    <Typography variant="body1" className={classes.priceText}>Check in - Check out</Typography>
                                    <Typography variant="body1" color='textSecondary' className={classes.inputSelectedText}>
                                        {this.state.selectedDateRange.length === 0
                                            ? "Add dates"
                                            : this.state.selectedDateRange}
                                    </Typography>
                                </div>
                            </Button>

                            {this.state.checkInOut === true && (
                                <>
                                    <Hidden only={['sm', 'md', 'lg', 'xl']}>
                                        {this.renderCalender()}

                                    </Hidden>
                                    <Hidden only={'xs'}>
                                        <StyledMenuPetInOut
                                            anchorEl={this.state.inOutAnchorElPet}
                                            keepMounted
                                            open={Boolean(this.state.inOutAnchorElPet)}
                                            data-test-id="dog-cat-pet"
                                            onClose={this.datePopupClose}
                                            style={{
                                                border: "none",
                                            }}
                                        >
                                            {this.calender()}

                                        </StyledMenuPetInOut>

                                    </Hidden>

                                </>
                            )}

                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                            <Grid container direction="row" justifyContent="flex-start" spacing={1}>
                                <Grid item xs={10} sm={8} md={8} lg={10} style={{ position: 'relative' }}>
                                    <Button
                                        onClick={this.addPetsPopupOpen}
                                        data-test-id="handle_pet_open"
                                        fullWidth
                                        className={classes.buttonLayout}
                                    >
                                        <div className={classes.inputHeadings}>
                                            <Typography variant="body1" className={classes.priceText}>Add Pet</Typography>
                                            <Typography variant="body1" color='textSecondary' className={classes.inputSelectedText}>
                                            {this.state.addRoomDog === 0 && this.state.addRoomCat === 0 ? (
                                                    "Add Pet"
                                                ) : (
                                                    <>
                                                        {this.state.addRoomDog !== 0 && (
                                                            <>
                                                                {this.state.addRoomDog} {this.state.addRoomDog === 1 ? "Dog" : "Dogs"}
                                                            </>
                                                        )}
                                                        {" "}{this.state.addRoomDog > 0 && this.state.addRoomCat > 0 && "&"}{" "}
                                                        {this.state.addRoomCat !== 0 && (
                                                            <>
                                                                {this.state.addRoomCat} {this.state.addRoomCat === 1 ? "Cat" : "Cats"}
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </Typography>
                                        </div>
                                    </Button>
                                    {this.state.petMenuOpen && this.renderAddPets()}
                                </Grid>
                                <Grid item xs={2} sm={4} md={4} lg={2}  >
                                    <div className={classes.searchLayout}
                                        data-test-id='handleSearch'
                                        onClick={this.handleSearch}
                                    >
                                        <img src={IconSearch} className={classes.iconImageSearch} />
                                    </div>
                                </Grid>
                            </Grid>

                        </Grid>

                    </Grid>
                </Grid>
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export const LandingPageSearchStyles = (theme: Theme) =>
    createStyles({
        padding: {
            padding: "3px 8px"
        },
        serchBar: {
            minHeight: "80px",
            backgroundColor: theme.palette.info.main,
            boxShadow: boxShadows.blueShadow,
            borderRadius: 8,
            margin: 'auto',
            position: 'relative',
            top: "-50%",
            [theme.breakpoints.down('xs')]: {
                top: 0,

            }
        },
        petlistLayout: {
            boxShadow: boxShadows.whiteShadow,
            marginBottom: 30,
            backgroundColor: theme.palette.common.white,
            borderRadius: 8

        },
        locationText: {
            paddingLeft: 15,
            color: theme.palette.common.black,
            fontWeight: theme.typography.fontWeightBold,
        },
        priceText: {
            textTransform: 'capitalize',
            color: theme.palette.common.black,
            fontWeight: theme.typography.fontWeightBold,
        },
        fontCalender: {
            fontFamily: "Inter",
            boxShadow: 'none',
            [theme.breakpoints.down('xs')]: {
                width: "-webkit-fill-available !important",
                padding:"0.4rem",
                },
            "& .rmdp-header": {
                fontSize: "18px",
                [theme.breakpoints.down('xs')]: {
                    fontSize: "14px",
                }
            },
            "& .rmdp-calendar": {
                width: "-webkit-fill-available",
                padding:"0.2rem"
            },
            "& .rmdp-day-picker": {
                [theme.breakpoints.down('xs')]: {

                    display: "block !important"
                }
            },
            "& .rmdp-week-day": {
                fontSize: "15px",
                fontWeight: 400
            }
        },
        toggleRequestModalContainer: {
            left: 0,
            position: "absolute",
            zIndex: 9,
            width: "100%",
            minHeight: "194px",
            borderRadius: "5px",
            backgroundColor: theme.palette.common.white,
            boxShadow: boxShadows.blueShadow,
        },
        position: {
            position: "relative",
            boxShadow: boxShadows.blueShadow
        },
        sortText: {
            fontWeight: theme.typography.fontWeightRegular
        },
        locationLayout: {
            padding: '10px',
            backgroundColor: theme.palette.common.white,
            borderRadius: "8px",
            minHeight: 64,
            textTransform: "none",
        },
        inputHeadings: {
            width: '100%',
            backgroundColor: theme.palette.common.white,
            borderRadius: "6px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            height: "64px",
            paddingLeft: 20,
            [theme.breakpoints.down('xs')]: {
                height: '52px'
            }
        },
        inputSelectedText: {
            fontWeight: theme.typography.fontWeightRegular,
            textTransform: 'none'

        },
        addpetDogText: {
            color: theme.palette.text.primary,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
        continueText: {
            color: "#1E293B",
            padding: "20px 20px 5px 20px",
            textAlign: "end",
            fontWeight: 700,
            fontSize: "16px",
            cursor: "pointer",
        },
        circleIcon: {
            cursor: "pointer",
            color: theme.palette.info.main,
        },
        hotelLayout: {
            marginTop: 90,
            position: 'relative',
            [theme.breakpoints.down('sm')]: {
                marginTop: 40
            }
        },
        searchBarLayout: {
            margin: 'auto',
            width: '100%',
            position: 'relative',
            top: "-50%",
            height: 'fit-content',
            [theme.breakpoints.down('xs')]: {
                top: 0,
            }
        },
        searchBoxLayout: {
            margin: 'auto',
            backgroundColor: theme.palette.common.white,
            width: '100%',
            height: 64,
            borderRadius: 8
        },
        buttonLayout: {
            padding: 0
        },
        'input': {
            '&::placeholder': {
                textOverflow: 'ellipsis !important',
                color: theme.palette.text.secondary,
                opacity: 10,
                fontSize: 14
            }
        },
        inputLocation: {
            "& .MuiInputBase-root": {
                fontFamily: "Inter",
                color: "#000000",
                backgroundColor: "#ffffff",
                height: "25px"
            },
        },
        searchLayout: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: theme.palette.common.white,
            borderRadius: 4,
            width: "100%",
            height: 64,
            cursor:'pointer',
            [theme.breakpoints.down('xs')]: {
                width: "100%",
                height: 52
            }
        },
        iconImageSearch: {
            width: 35,
            height: 35,
            [theme.breakpoints.down('xs')]: {
                width: 24,
                height: 24
            }
        },
        dateContinueBtn: {
            padding: "25px",
            fontWeight: 700,
            cursor: "pointer",
            textAlign: 'end'
        }
    })
export default withStyles(LandingPageSearchStyles)(LandingPageSearch);
// Customizable Area End
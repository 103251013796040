import React, { Component } from 'react';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

interface CustomMenuProps {
    anchorEl: null | HTMLElement;
    onClose: () => void;
    onEdit: () => void;
    onDelete: () => void;
    customProp: string;
  }

class CustomMenu extends Component <CustomMenuProps> {
  state = {
    mouseHover: 0,
  };

  handleMouseEnter = (index: number) => {
    this.setState({ mouseHover: index });
  };

  handleMouseLeave = () => {
    this.setState({ mouseHover: 0 });
  };

  render() {
    const { anchorEl, onClose, onEdit, onDelete } = this.props;
    const { mouseHover } = this.state;

    return (
      <Menu
        anchorEl={anchorEl}
        id="long-menu"
        open={Boolean(anchorEl)}
        keepMounted
        onClose={onClose}
        PaperProps={{
          style: {
            width: '20ch',
            maxHeight: 200,
            borderRadius: '8px',
            marginTop: '5%',
          },
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
      >
        <div>
          <Button
            onClick={onEdit}
            data-test-id="editData"
            style={{
              backgroundColor: mouseHover === 1 ? '#B2E4F8' : 'white',
              justifyContent: 'flex-start',
              borderRadius: '8px',
              width: '95%',
              margin: '5px',
            }}
            onMouseLeave={this.handleMouseLeave}
            onMouseEnter={() => this.handleMouseEnter(1)}
          >
            <EditIcon style={{ marginRight: '7px' }} />
            <span>Edit</span>
          </Button>

          <Button
            onClick={onDelete}
            data-test-id="handleClickModelOpen"
            style={{
              borderRadius: '8px',
              backgroundColor: mouseHover === 2 ? '#B2E4F8' : 'white',
              width: '95%',
              margin: '5px',
              justifyContent: 'flex-start',
            }}
            onMouseLeave={this.handleMouseLeave}
            onMouseEnter={() => this.handleMouseEnter(2)}
          >
            <DeleteOutlineIcon style={{ marginRight: '7px' }} />
            <span>Delete</span>
          </Button>
        </div>
      </Menu>
    );
  }
}

export default CustomMenu;

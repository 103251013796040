import React from "react";
// Customizable Area Start
import { Button, Grid, Theme, Typography, createStyles, withStyles } from "@material-ui/core";
import PropertyDetailsController, { Props } from "./PropertyDetailsController.web";
import Footer from "../../../components/src/Footer.web";
import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";
import { errorImage } from "../../../components/src/CommonFunctions";
import { IconSearch, crossIcon } from "../../landingpage/src/assets";
import { CustomizedTextFieldAutoComplete } from "../../../components/src/CustomizedComponents.web";
import { filterIcon, notFoundIcon } from "../../user-profile-basic/src/assets";
import { HotelListData } from "./types";
import Loader from "../../../components/src/Loader.web";
import PropertyFilters from "./PropertyFilters.web";
import BussinessOwnerSideBar from "../../../components/src/SideBar";
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import ListSharpIcon from '@material-ui/icons/ListSharp';
import { theme } from "../../../components/src/theme";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CustomizedSnackbars from "../../../components/src/CustomSnackbar.web";
// Customizable Area End

// Customizable Area Start

// Customizable Area End

export class PropertyDetails extends PropertyDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderStatusText = (status: string) => {
        const { classes } = this.props;
        let StatusValue;

        if (status === 'Draft') {
            StatusValue = classes.draftText;
        } else if (status === 'Under Review') {
            StatusValue = classes.warningText;
        } else if (status === 'Reject') {
            StatusValue = classes.errorText
        } else if (status === 'disable') {
            StatusValue = classes.errorText
        } else {
            StatusValue = classes.ActiveText;
        }

        return (
            <>
                <Typography variant="body1" className={StatusValue}>
                    {status}
                </Typography>
            </>
        )

    }


    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props
        const {hotelToasterStatus,hotelMessage,hotelToasterType}=this.state

        return (
            <>
                <BussinessOwnerSideBar active={1} navigation={this.props.navigation} />
                <CustomizedSnackbars openToaster={hotelToasterStatus} message={hotelMessage}
                duration={2000}
                toasterType={hotelToasterType}
                closeStatus={this.closeToaster}
                
                />

                <div className={classes.content}>
                    <Grid container direction="row" >
                        <Grid item xs={12}>
                            <Grid container direction="row" spacing={2}>
                                <Grid item xs={12} className={classes.searchBar}>
                                    <Grid container direction="row" alignItems="center" spacing={2} >
                                        <Grid item xs={12} sm={6} md={9}>
                                            <Grid container direction="row" alignItems="center" className={classes.searchHotelLayout}>
                                                <Grid item xs={11}>
                                                    <Typography variant="body1" className={classes.locationText}>Search</Typography>
                                                    <CustomizedTextFieldAutoComplete
                                                        placeholder="Search the property name"
                                                        variant="outlined"
                                                        className={classes.inputSearch}
                                                        fullWidth
                                                        value={this.state.searchHotel}
                                                        onChange={this.handleHotelSearch}
                                                        data-test-id="search_hotel"
                                                        InputProps={{ classes: { input: classes['input'] } }}
                                                    />
                                                </Grid>

                                                {this.state.searchHotel && <Grid item xs={1} container justifyContent="flex-end"
                                                    style={{ cursor: 'pointer' }}
                                                    data-test-id='clear_search'
                                                    onClick={this.clearSearch}>
                                                    <img src={crossIcon} width={32} height={32} />
                                                </Grid>}


                                            </Grid>
                                        </Grid>
                                        <Grid item xs={2} sm={2} md={1}>
                                            <div className={classes.searchIconLayout}
                                                data-test-id='searchIcon'
                                                onClick={this.handeSearch}
                                            >
                                                <img src={IconSearch} className={classes.iconImageSearch} />
                                            </div>
                                        </Grid>
                                        <Grid item xs={10} sm={4} md={2}>
                                            <div className={classes.filterLayout} data-test-id="filter_open" onClick={()=>this.openFiltersPopup('filters',"")}>
                                                <div>
                                                    <ListSharpIcon  style={this.state.filtersSeleted?webStyle.selectMenuIcon:webStyle.nonSelectMenuIcon}/>
                                                
                                                </div>
                                                <div>
                                                    <Typography variant="body1" style={this.state.filtersSeleted?webStyle.selectFilterText:webStyle.nonSelectFilterText}>Filters</Typography>
                                                </div>

                                            </div>

                                        </Grid>
                                    </Grid>

                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container direction="row" alignItems="center" className={classes.headingLayout}>
                                        <Grid item xs={4}>
                                            <Typography variant="body1" className={classes.headingLabel}>Property & Address</Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography variant="body1" className={classes.headingLabel}>Type of rooms</Typography>

                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography variant="body1" className={classes.headingLabel}>Pet type</Typography>

                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography variant="body1" className={classes.headingLabel}>Status</Typography>

                                        </Grid>
                                        <Grid item xs={1}>


                                        </Grid>

                                    </Grid>

                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                {this.state.hotelsList?.map((hotel: HotelListData) => {
                                    let coverPhoto = hotel.attributes.images.find((image: { is_cover_image: boolean }) => image.is_cover_image);
                                    const displayedRooms = hotel?.attributes["Types of rooms"].slice(0, 2).slice(0, 2); // Get the first two rooms
                                    const remainingCount = hotel?.attributes["Types of rooms"].length - displayedRooms.length;
                                    // If there is no cover photo, use the first photo as cover
                                    if (!coverPhoto && hotel.attributes.images.length > 0) {
                                        coverPhoto = hotel.attributes.images[0];

                                    }
                                    return (<Grid item xs={12} className={classes.hotelsListLayout} key={hotel.id}
                                    >
                                        <Grid container direction="row" alignItems="center" className={classes.hotelListLayout}
                                        >
                                            <Grid item xs={4}
                                              onClick={() => this.handleDraftNavigation(hotel.attributes)}
                                              data-test-id='see_hotel_details'
                                            >
                                                <Grid container justifyContent="flex-start" spacing={3}>
                                                    <Grid item xs={3}>
                                                        <img src={coverPhoto?.url} onError={errorImage} className={classes.hotelImage} />

                                                    </Grid>
                                                    <Grid item xs={9}>
                                                        <Grid container >
                                                            <Grid item xs={12}>
                                                                <Grid container alignItems="center" justifyContent="flex-start" spacing={1} >
                                                                    <Grid item>

                                                                        <Typography align="left" variant="body1" className={classes.propertyName}>{hotel?.attributes?.name}</Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Grid container alignItems="center" justifyContent="flex-start" spacing={1} >
                                                                    <Grid item xs={1}>
                                                                        <LocationOnOutlinedIcon />
                                                                    </Grid>
                                                                    <Grid item xs={11}>
                                                                        <Typography variant="body1" className={classes.textLocation}>{hotel?.attributes?.hotel_city},{hotel?.attributes?.hotel_country_region}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>

                                                            </Grid>
                                                        </Grid>

                                                    </Grid>

                                                </Grid>
                                            </Grid>
                                            <Grid item xs={3}
                                              onClick={() => this.handleDraftNavigation(hotel.attributes)}
                                              data-test-id='see_hotel_details'
                                            >
                                                {displayedRooms?.map((rommType: any) => {
                                                    return (
                                                        <Typography variant="body1" className={classes.headingLabel}>
                                                            <Typography display="inline" variant="body1" className={classes.textLabel}>{rommType?.room_type}({rommType?.count})</Typography>
                                                        </Typography>
                                                )
                                                })}

                                                {remainingCount > 0 && <Typography variant="body1" className={classes.textLabel}>+&nbsp;{remainingCount}&nbsp;more</Typography>}


                                            </Grid>
                                            <Grid item xs={2}
                                              onClick={() => this.handleDraftNavigation(hotel.attributes)}
                                              data-test-id='see_hotel_details'
                                            >
                                                {hotel.attributes["Pet type"].dogs > 0 && <Typography variant="body1" className={classes.textLabel}>Dog({hotel.attributes["Pet type"].dogs})</Typography>}
                                                {hotel.attributes["Pet type"].cats > 0 && <Typography variant="body1" className={classes.textLabel}>Cat({hotel?.attributes["Pet type"].cats})</Typography>}

                                            </Grid>
                                            <Grid item xs={2}
                                            
                                            onClick={() => this.handleDraftNavigation(hotel.attributes)}
                                            data-test-id='see_hotel_details'
                                            >
                                                {this.renderStatusText(hotel?.attributes.status)}


                                            </Grid>
                                            <Grid item xs={1} container justifyContent="center">
                                                <DeleteOutlineIcon color="error" fontSize="medium" style={webStyle.deleteIcon}
                                                data-test-id='delete_hotel'
                                                onClick={()=>this.openFiltersPopup("confirmation",hotel?.id)}
                                                
                                                />
                                                </Grid>

                                        </Grid>

                                    </Grid>)
                                })}
                                {this.state.hotelsList?.length === 0 &&
                                    <>
                                        <Grid item xs={12} className={classes.notFoundLayout}>
                                            <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
                                                <Grid item >
                                                    <img src={notFoundIcon} />
                                                </Grid>
                                                <Grid item >
                                                    <Typography variant="body1" className={classes.textLabel}>
                                                        {this.state.searchHotel ? "No Search Found" : " No Hotels Found"}

                                                    </Typography>
                                                </Grid>

                                            </Grid>

                                        </Grid>
                                    </>

                                }

                            </Grid>
                        </Grid>
                        <Grid item xs={12} container justifyContent="flex-end" className={classes.addBtnLayout}>
                            <Button variant="outlined" color="secondary"
                                onClick={this.addproperty}
                                data-test-id='addButton'
                                className={classes.addBtn}>
                                <AddCircleOutlineRoundedIcon fontSize="small" color="secondary" />
                                Add Property</Button>
                        </Grid>

                        <Grid item xs={12}>
                            <Footer footerNavigationData={this.state.linksData} boAndPOLinks={this.state.boLinksData}/>
                        </Grid>
                        <PropertyFilters
                            StatusType={this.state.StatusType}
                            petType={this.state.petType}
                            statusName={this.state.statusName}
                            statusValue={this.state.statusValue}
                            petName={this.state.petName}
                            petValue={this.state.petValue}
                            selectStatusFilter={this.selectStatusFilter}
                            selectpetsFilter={this.selectpetsFilter}
                            clearStatus={this.clearStatus}
                            searchFilter={this.searchFilter}
                            closeFilterDropDowns={this.closeFilterDropDowns}
                            openFilterDropDowns={this.openFilterDropDowns}
                            statusdropDownStatus={this.state.statusdropDownStatus}
                            dropDownStatus={this.state.dropDownStatus}
                            closeDailog={this.closeFiltersPopup}
                            dailogOpen={this.state.filterPopUpStatus} 
                            clearFilters={this.clearFilters}
                            popUpType={this.state.popupType}
                            deleteHotel={this.deleteHotel}
                        />

                    </Grid>
                </div>
                <Loader loading={this.state.pagLoader} />

            </>

        );
    }
    // Customizable Area End
}

// Customizable Area Start
export const PropertyStyles = (theme: Theme) =>
    createStyles({
        bgLayout: {
            backgroundColor: "#E0F4FC",
            minHeight: "100vh",
            width: "100%",
        },
        propertyLayout: {
            padding: "0 40px"
        },
        addBtn: {
            textTransform: 'none',
            fontSize: 16,
            fontWeight: theme.typography.fontWeightBold,
            color: theme.palette.secondary.main,
            borderRadius: 8,
            "&:hover": {
                color: theme.palette.secondary.main,

            }

        },
        headingLayout: {
            marginTop: 20,
            background: " #F0FAFE",
            height: "54px",
            borderRadius: 8
        },
        hotelListLayout: {
            marginTop: 10,
            background: theme.palette.common.white,
            height: 86,
            borderRadius: 8,
            cursor: 'pointer'
        },
        headingLabel: {
            color: theme.palette.common.black,
            fontWeight: theme.typography.fontWeightRegular,
            textAlign: 'center'
        },
        textLabel: {
            color: theme.palette.common.black,
            fontWeight: theme.typography.fontWeightBold,
            textAlign: 'center'
        },
        propertyName: {
            color: theme.palette.common.black,
            fontWeight: theme.typography.fontWeightBold,

        },
        hotelsListLayout: {
            // height: '500px',

        },
        ActiveText: {
            color: "#059669",
            fontWeight: theme.typography.fontWeightBold,
            textAlign: 'center'

        },
        warningText: {
            color: theme.palette.warning.main,
            fontWeight: theme.typography.fontWeightBold,
            textAlign: 'center'

        },
        draftText: {
            color: theme.palette.text.disabled,
            fontWeight: theme.typography.fontWeightBold,
            textAlign: 'center'

        },
        hotelImage: {
            borderRadius: 8,
            height: 52,
            width: '100%',
            marginLeft: 10

        },
        notFoundLayout: {
            margin: '20px 0'
        },
        searchIconLayout: {
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: theme.palette.common.white,
            borderRadius: 4,
            width: "100%",
            height: 64,
            [theme.breakpoints.down('xs')]: {
                width: "100%",
                height: 52
            }
        },
        filterLayout: {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            background: theme.palette.common.white,
            borderRadius: 4,
            width: "100%",
            height: 64,
            [theme.breakpoints.down('xs')]: {
                width: "100%",
                height: 52
            },
        },
        filterIcon: {
            margin: '0 20px'
        },
        iconImageSearch: {
            width: 35,
            height: 35,
            [theme.breakpoints.down('xs')]: {
                width: 24,
                height: 24
            }
        },
        inputSearch: {
            "& .MuiInputBase-root": {
                fontFamily: "Inter",
                color: "#000000",
                backgroundColor: "#ffffff",
                height: "25px"
            },
        },
        searchHotelLayout: {
            padding: '10px',
            backgroundColor: theme.palette.common.white,
            borderRadius: 4,
            minHeight: 64,
            textTransform: "none",
        },
        locationText: {
            paddingLeft: 15,
            color: theme.palette.common.black,
            fontWeight: theme.typography.fontWeightBold,
        },
        searchBar: {
            marginTop: 50,
            minHeight: "80px",
            backgroundColor: theme.palette.info.main,
            borderRadius: 8,
            margin: 'auto',
        },
        addBtnLayout: {
            margin: "20px 0"
        },
        content: {
            marginLeft: "140px", // Adjust based on the width of your sidebar
            flexGrow: 1,
            backgroundColor: theme.palette.primary.main,
            padding: theme.spacing(3),
            overflowY: "auto", // Allow main content area to scroll
        },
        root: {
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh', // Ensure that the container takes at least the full viewport height
        },
        textLocation: {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            color: theme.palette.common.black,
            fontWeight: theme.typography.fontWeightRegular,
        }, errorText: {
            color: theme.palette.error.main,
            fontWeight: theme.typography.fontWeightBold,
            textAlign: 'center',
            textTransform:'capitalize'

        }


    })
export default withStyles(PropertyStyles)(PropertyDetails);

const webStyle={
    selectMenuIcon:{
        margin:'0 20px',
        transform:'rotate(180deg)',
        color:theme.palette.secondary.main,
        width:"32px",
        height:"32px"
    },
    nonSelectMenuIcon:{
        margin:'0 20px',
        transform:'rotate(180deg)',
        color:'#4386A1',
        width:"32px",
        height:"32px"

    },
    selectFilterText:{
        color:theme.palette.secondary.main,
        fontWeight:theme.typography.fontWeightBold

    },
    nonSelectFilterText:{
        color:theme.palette.common.black,
        fontWeight:theme.typography.fontWeightBold

    },
    deleteIcon:{
        cursor:'pointer'
    }
}
// Customizable Area End

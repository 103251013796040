import React from "react";

// Customizable Area Start
import { Box, Divider, Grid, Typography, Container } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import { landingPage, landingPageMobile, location } from "./assets";
import LandingPagePOController, { Props } from "./LandingPagePOController.web";
import LandingPageSearch from "./LandingPageSearch.web";
import Loader from "../../../components/src/Loader.web";
import Footer from '../../../components/src/Footer.web';
import CustomRating from "../../../components/src/CustomizedComponents.web";
import Navbar from "../../../components/src/Navbar";
import { dummyImage } from "../../catalogue/src/assets";
import { errorImage } from "../../../components/src/CommonFunctions";
import { boxShadows, theme } from "../../../components/src/theme";
import { HotelList } from "./types";
import { configJSON } from "./LandingPageController";
import CustomizedSnackbars from "../../../components/src/CustomSnackbar.web";

// Customizable Area End

export class LandingPagePO extends LandingPagePOController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  cardList = (hotelList: HotelList[]) => {
    const { classes } = this.props
    const newHotelsList= hotelList.slice(0, 4)
   
    return (
      <>
        <Grid item xs={12} className={classes.hotelCards}>
          <Grid container direction="row" spacing={4}>
            {newHotelsList.map((hotel: HotelList, index: number) => {
              let coverPhoto = hotel.attributes.images.find((image: { is_cover_image: boolean; }) => image.is_cover_image);
              if (!coverPhoto && hotel.attributes.images.length > 0) {
                coverPhoto = hotel.attributes.images[0];
              }
              return(
                <>
                 <Grid item xs={12} sm={6} md={3} key={index}>
                <Card className={classes.relativeHotel}
                onClick={() => this.checkHotelDetails(hotel.id)}
                data-test-id='handle_redirection'
                >
                  <div style={webStyle.imgBackground}>
                    <img
                      src={coverPhoto?.url || dummyImage}
                      onError={errorImage}
                      data-test-id='image_break'
                      className={classes.petHotelImage}
                      alt="pet"
                      height={200}
                    />

                  </div>
                  <CardContent style={{padding:'16px'}}>
                    <Grid item xs={12}>
                      <Grid container direction="row" alignItems="center" spacing={2} >
                        <Grid item xs={12} className={classes.hotelNameAlign}>
                          <Typography variant="h6" className={classes.hotelNameText}>{hotel.attributes.name}</Typography>
                        </Grid>
                        <Grid item xs={12} container alignItems="center" spacing={2}>
                          <img src={location} width={24} height={24} alt="location" />
                          <Typography display="inline" variant="body1" color="textSecondary" className={classes.locationName}>
                            {hotel.attributes.hotel_city},&nbsp;{hotel.attributes.hotel_country_region}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container alignItems="center" spacing={1}>
                            <Grid item >
                              <CustomRating rating={hotel.attributes?.rating} />
                            </Grid>
                            <Grid item >
                            <Typography display="inline" variant="body1" color="textSecondary" className={classes.locationName}>
                                {hotel.attributes.rating||0} &nbsp;</Typography>
                            </Grid>
                            <Grid item>
                                <Typography display="inline" variant="body1" color="textSecondary" className={classes.locationName}>
                                  {hotel.attributes.rating||0} reviews
                                </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography color="textSecondary" variant="body1" className={classes.descStyles}>{hotel?.attributes.description}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Divider className={classes.cardDivider} />
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item>
                              <Typography className={classes.hotelNameText} variant="h6">£{hotel.attributes.budget_per_day}</Typography>
                              <Typography color="textSecondary" variant="body2">Starting from Per Day</Typography>
                            </Grid>
                            <Grid item>
                              <Box className={classes.cardAvailabilityBtn} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                <Typography variant="body1" data-test-id="see_availibality_btn" color="secondary" className={classes.cardAvailabilityBtnText} onClick={() => this.checkHotelDetails(hotel.id)}>See Availability</Typography>
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>

                </Card>
              </Grid>
                </>
              )
            })
            }
          </Grid>

        </Grid>
        {newHotelsList.length === 0 &&
          <Grid item xs={12}>
            <Grid container direction="row" justifyContent="center" alignItems="center"
              className={classes.notFoundText}
            >
              <Grid item xs={12}>
                <Typography align="center" variant="h6" className={classes.hotelNameText}>{configJSON.notFoundData}</Typography>
              </Grid>
            </Grid>

          </Grid>
        }
      </>
    )
  }

  renderCardHeading = (title: string, ViewAllKey: string) => {
    const { classes } = this.props;
    return (
      <>
        <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <Grid item>
            <Typography className={classes.hotelTypes} variant="h3">{title}</Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.viewAllBtn} variant="subtitle1"
              data-test-id="selected_view"
              onClick={() => this.handleViewAll(ViewAllKey)}
            >View All</Typography>
          </Grid>
        </Grid>

      </>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props
    return (
      <>
        <Navbar navType={'fullNavbar'} activeLink={1} profilePhoto={this.state.imageUrl}/>
        {this.state.toasterStatus&&
        <CustomizedSnackbars openToaster={this.state.toasterStatus} message={this.state.toasterText}
        toasterType={'error'}
        duration={20000}/>}
        <Container maxWidth={false} className={classes.subDiv}>
        
          <Grid item xs={12}>
            <Grid container direction="row" justifyContent="flex-start" spacing={2} >
              <Grid item xs={12}>
                <Container maxWidth={false} className={classes.landingpageSearchLayout}>
                  <LandingPageSearch
                    navigation={this.props.navigation}
                    id={""}
                  />
                </Container>
              </Grid>
            </Grid>
          </Grid>
        </Container>
        
        <Container maxWidth={false} className={classes.cardsContainer}>
           <Grid item xs={12}>
          <Grid container direction="row" justifyContent="center" spacing={3}>
            <Grid item xs={12}>
              {this.renderCardHeading("Hotels for Dogs", "dog_hotel")}

              {this.cardList(this.state.dogHotelList)}
            </Grid>

            <Grid item xs={12}>
              {this.renderCardHeading("Hotels for Cats", "cat_hotel")}

              {this.cardList(this.state.catHotelList)}
            </Grid>

            <Grid item xs={12}>
              {this.renderCardHeading("Hotels for Dogs and Cats", "dog_cat_hotel")}

              {this.cardList(this.state.cardData)}
            </Grid>

            <Grid item xs={12}>
               <Footer footerNavigationData={this.state.linksData} boAndPOLinks={this.state.poLinksData} />
            </Grid>

          </Grid>

        </Grid> 
        </Container>
    
        <Loader loading={this.state.landingPageLoader} />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

export const LandingPageStyles = (theme: Theme) =>
  createStyles({
    landingpageSearchLayout: {
      position: 'relative',
      top: "-50%",
      [theme.breakpoints.down('xs')]: {
        top: 0,

      }
    },
    serchBar: {
      minHeight: "80px",
      backgroundColor: theme.palette.info.main,
      boxShadow: boxShadows.blueShadow,
      borderRadius: 8,
      margin: 'auto',
      [theme.breakpoints.down('xs')]: {
        top: 0,
      },
    },
    hotelNameText: {
      color: theme.palette.common.black,
      fontWeight: theme.typography.fontWeightBold
    },
    locationName: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontWeight: theme.typography.fontWeightRegular,
      marginLeft: 10,
      width: "83%"
    },
    descStyles: {
      overflowWrap: "anywhere",
      fontWeight: theme.typography.fontWeightRegular,
      display: '-webkit-box',
      WebkitLineClamp: 2,
      overflow: 'hidden',
      WebkitBoxOrient: 'vertical',
      minHeight: 44
    },
    cardDivider: {
      backgroundColor: theme.palette.primary.main
    },
    cardAvailabilityBtn: {
      width: "125px",
      height: "50px",
      borderRadius: 8,
      backgroundColor: "#E0F4FC",
      gap: '8px'

    },
    cardAvailabilityBtnText: {
      textDecoration: 'underline',
      fontWeight: theme.typography.fontWeightBold,
      ' &:hover': {
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.common.black,
      }
    },
    petHotelImage: {
      maxWidth: "100%",
      maxHeight: '250px',
      borderRadius: 8,

    },
    hotelTypes: {
      color: theme.palette.common.black,
      fontWeight: theme.typography.fontWeightRegular
    },
    sliderLayout: {
      // padding: "30px"
    },
    cardLayout: {
      marginTop: 30
    },
    relativeHotel: {
      height: '100%',
      borderRadius: "15px",
      maxHeight: '100%',
      width: '100%',
      cursor:'pointer'

    },
    ratingEmptyIcon: {
      color: theme.palette.warning.main
    },
    hotelNameAlign: {
      paddingLeft: 0
    },
    subDiv: {
      minHeight: "642px",
      height: '100%',
      backgroundImage: `url(${landingPage})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "100% 100%",
      backgroundPosition: "center",
      display: 'flex',
      justifyContent: 'center',
      marginTop: 170,
      position: 'relative',
      [theme.breakpoints.down('xs')]: {
        minHeight: '742px !important',
        backgroundImage: `url(${landingPageMobile}) !important`,
        display: "block",
        marginTop: 120,
        padding: 20,
        backgroundSize: "95% 100%",

      },

    },
    notFoundText: {
      height: '150px'
    },
    viewAllBtn: {
      cursor: 'pointer',
      color: theme.palette.secondary.main,
      fontWeight: theme.typography.fontWeightBold,
      textDecoration:'underline',
      "&:hover": {
        color: theme.palette.common.black,
      }
    },
    cardsContainer:{
      padding:"30px"
    },hotelCards:{
      margin:"30px 0"
    }
  })
export default withStyles(LandingPageStyles)(LandingPagePO);

const webStyle={

  imgBackground:{
    backgroundColor:theme.palette.primary.main,
    borderRadius:8,
    height:250,
    display:'flex',
    justifyContent:'center',
    alignItems:'center'

  }

}
// Customizable Area End

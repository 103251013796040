import React from "react";
// Customizable Area Start
import { Box, ClickAwayListener, Divider, Grid, InputAdornment, Typography, WithStyles } from "@material-ui/core";
import { CustomizedTextField, StyledMenuItem } from "../../../components/src/CustomizedComponents.web";
import { dropDownIcon } from "../../catalogue/src/assets";
// Customizable Area End

// Customizable Area Start
interface Props extends WithStyles {
    handlesortFilterClose: any;
    sortByFilters: any;
    handleSelectSortFilter: any
    selectedSortType: string;
    filterModalOpen: any;
    sortFilterStatus: any
}
// Customizable Area End
export default class SortingPO extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    sortBYFiltersList = () => {
        const { classes } = this.props
        return (
            <>
                <ClickAwayListener onClickAway={this.props.handlesortFilterClose}>
                    <Typography component={'div'}>
                        <Box className={classes.sortPopUpLayout} >
                            <Box >
                                {this.props.sortByFilters.map((item: any) => (
                                    <>
                                        <StyledMenuItem
                                            data-test-id="selectStatus"
                                            key={item.id}
                                            value={item.name}
                                            onClick={() => this.props.handleSelectSortFilter(item)}
                                        >
                                            {item.name}
                                        </StyledMenuItem>
                                        {item.name && <Divider style={{ marginTop: "5px" }} />}
                                    </>
                                ))}
                            </Box>
                        </Box>
                    </Typography>
                </ClickAwayListener>
            </>
        );
    }

    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props
        return (
            <>
                <Grid item xs={12} className={classes.sortingPaddingLayout}>
                    <Box  className={classes.sortPosition} >
                        <Grid container direction="row" alignItems="center" spacing={2} >
                            <Grid item xs={4}>
                                <Typography align="center" variant="body1" color="textSecondary" className={classes.sortHeading}>Sort By:</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                <CustomizedTextField
                                    fullWidth
                                    value={this.props.selectedSortType}
                                    className={classes.sortHeading}
                                    variant="outlined"
                                    InputProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <img src={dropDownIcon} width={24} height={24} alt="" />
                                            </InputAdornment>
                                        ),
                                    }}
                                    data-test-id="sortby_filters"
                                    onClick={this.props.filterModalOpen}
                                />
                                {this.props.sortFilterStatus &&
                                    this.sortBYFiltersList()
                                }
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </>

        );
    }
    // Customizable Area End
}

// Customizable Area Start

// Customizable Area End

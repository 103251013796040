import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps, Color } from '@material-ui/lab/Alert';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { theme } from './theme';

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

interface SnackBarProps {
    openToaster: boolean,
    message: string
    toasterType: Color,
    duration?: number
    closeStatus?: Function
}

export default function CustomizedSnackbars(props: SnackBarProps) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(props.openToaster);
    const [severity, setseverity] = React.useState<Color | undefined>(undefined);

    React.useEffect(() => {
        if (props.openToaster) {
            setOpen(true)
            setseverity(props.toasterType)
        }

    }, [props.openToaster])

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
        if (props.closeStatus) {
            props.closeStatus(false);
        }
    };
    return (
        <div className={classes.root}>
            <Snackbar anchorOrigin={{
                vertical: "top",
                horizontal: "center"
            }} open={open} autoHideDuration={props.duration ? props.duration : 3000}
data-test-id="closeToaster"
                onClose={handleClose}

            >
                <Alert variant='standard'
                style={styles.toastLayout}
                
                onClose={handleClose} severity={severity}
                >
                    <span style={styles.successMessage as React.CSSProperties}>
                        {props.message}
                    </span>
                </Alert>
            </Snackbar>
        </div>
    )
}

const styles = {
    toastLayout: {
        display: 'flex',
        alignItems: 'center'
    },
    successMessage: {
        color: theme.palette.common.black,
        fontSize: "18px",
        fontWeight: theme.typography.fontWeightRegular,
      

    }
}
import React from "react";
// Customizable Area Start
import {
    Button,
    Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, Typography
} from "@material-ui/core";
import { Theme, createStyles, withStyles } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import { theme } from "../../../components/src/theme";

// Customizable Area End
// Customizable Area Start
interface Props {
    open: boolean
    handleClose: any
    typeOfModel: string
    bookContinue:()=>void
}
// Customizable Area End

export class DeleteBookReservation extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes }: any = this.props
        return (
            <>
                <Dialog open={this.props?.open} maxWidth={'md'}
                    onClose={this.props.handleClose} aria-labelledby="form-dialog-title"
                    PaperProps={{
                        style: {
                            maxWidth: "634px",
                            width: '100%',
                            height: "312px",
                            borderRadius: '8px',
                        },
                    }}
                >
                    <DialogTitle className={classes.closeIconLayout}>{(this.props.typeOfModel === "dateService" || this.props.typeOfModel === "addPet") ? <></> : <IconButton aria-label="close" data-test-id="close_dailog" onClick={this.props.handleClose} >
                        <CloseIcon />
                    </IconButton>}
                    </DialogTitle>
                    <DialogContent className={classes.dailogContent}>
                    {this.props.typeOfModel === 'delete' ?<>
                        <Typography variant="h4" className={classes.dailogHeading} gutterBottom>Delete Pet’s Details</Typography>
                        <Typography variant="h6" className={classes.dailogSubHeading} gutterBottom>Are you sure you want to delete this pet's details?</Typography>
                    </>
                    :
                    <>
                    <Typography variant="h4" className={classes.dailogHeading} gutterBottom>Great News!</Typography>
                        <Typography variant="h6" className={classes.dailogSubHeading} gutterBottom>Your pet hotel reservation has been received.</Typography>
                        <Typography component="div" style={webStyles.confirmText} gutterBottom>
                            Please check the status under my reservations to confirm booking.
                            </Typography>
                
                </>
                }
                    </DialogContent>
                    <Grid item xs={12} className={classes.dividerLayout}>
                        <Divider />
                    </Grid>
                    <DialogActions className={classes.padding15}>
                        {this.props.typeOfModel === 'delete' ?
                            <> <Button variant="outlined" color={'secondary'} onClick={this.props.handleClose}
                                className={classes.yesButton}
                            >
                                Yes,Sure
                            </Button>
                                <Button variant="contained" color={'secondary'} onClick={this.props.handleClose}
                                    className={classes.continueButton}
                                >
                                    No, I don’t
                                </Button>
                            </>

                            :
                            <Button variant="contained" color={'secondary'}
                             onClick={this.props?.bookContinue}
                                className={classes.continueButton}
                                data-test-id="continueButton"
                            >
                                Continue
                            </Button>


                        }
                    </DialogActions>
                </Dialog>

            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyles={
    confirmText:{
        color:theme.palette.common.black,
        fontWeight:400,
        fontSize:"18px",


    }
}
const DeleteBookReservationStyles = (theme: Theme) =>
    createStyles({
        closeIconLayout: {
            padding: 10,
            display: 'flex',
            justifyContent: "flex-end",
            alignItems: 'center'
        },
        continueButton: {
            width: '100%',
            textTransform: 'capitalize',
            height: 56,
            borderRadius: 8,
            fontSize: 16,
            color: theme.palette.common.white,
            fontWeight: theme.typography.fontWeightBold,
            '&:hover': {
                backgroundColor: theme.palette.secondary.main
            },
        },
        padding15: {
            padding: 15
        },
        dailogHeading: {
            color: theme.palette.common.black,
            fontWeight: theme.typography.fontWeightBold
        },
        dailogSubHeading: {
            color: theme.palette.common.black,
            fontWeight: theme.typography.fontWeightRegular
        },
        dailogContent: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'

        },
        dividerLayout: {
            flexBasis: "auto !important",
            marginBottom: 15
        },
        yesButton: {
            border: `2px solid ${theme.palette.secondary.main}`,
            textTransform: 'capitalize',
            height: 56,
            borderRadius: 8,
            fontSize: 16,
            fontWeight: theme.typography.fontWeightBold,
            '&:hover': {
                border: `2px solid ${theme.palette.secondary.main}`,

            },
            width: '100%'

        },
    })
export default withStyles(DeleteBookReservationStyles)(DeleteBookReservation)
// Customizable Area End

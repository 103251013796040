import React from "react";
import { CircularProgress } from "@material-ui/core";

interface myProps {
  loading: boolean;
}
export default function DropdownLoader(props: myProps) {
  return props.loading ? (
    <div style={{ flex: 1, textAlign: "center", justifyContent: "center", marginTop: "10px" }}>
     <CircularProgress style={{color:"#98C8DB"}} />
    </div>
  ) : (
    <div />
  );
}

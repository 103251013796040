import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";

// Customizable Area Start
interface LogInResponse {
  meta?: {
    token: string;
    success: string;
  };
  data?: {
    attributes: {
      email: string;
    };
  };
  errors?:
    | {
        account?: string;
      }[]
    | { password_confirmation?: string[]; account?: string };
  error?: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  id: string;
}

export interface S {
  // Customizable Area Start
  apiErrorLogIn: string;
  email: string;
  mobileView: boolean;
  anchorEl: HTMLButtonElement | null;
  menuOpen: boolean;
  resetPasswordSucessPet: string;
  resetpoPopUp: boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class ResetPasswordPoController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  resetPassswordPoId: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      email: "",
      anchorEl: null,
      mobileView: false,
      menuOpen: false,
      apiErrorLogIn: "",
      resetPasswordSucessPet: "",
      resetpoPopUp: false,
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.resetPassswordPoId) {
      this.handleResForSignUp(responseJson);
    }

    // Customizable Area End
  }
  // Customizable Area Start
  handleResForSignUp = (responseJson:any) => {
    if (responseJson?.message) {
     
      this.setState({ email: "", resetpoPopUp: true });
    }
    else if (Array.isArray(responseJson.errors)) {
      if (responseJson.errors[0].account) {
        this.setState({ apiErrorLogIn: responseJson.errors[0].account });
      }
    } else if (responseJson.error) {
      this.setState({ apiErrorLogIn: responseJson.error });
    }
  };

  handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const email = event.target.value;
    this.setState({ email });
  };
  handleRestPo = () => {
    this.setState({ resetpoPopUp: false });
  };
  callApiForPoLogIn = () => {
    const loginRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    loginRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_forgot_password/forgot_password`
    );

    loginRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({})
    );
    let data = new FormData();
    data.append("email", this.state.email);
    data.append(
      "reset_link_url",
      window.location.origin + "/ForgotPasswordPetOwner"
    );

    loginRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      data
    );

    loginRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    this.resetPassswordPoId = loginRequestMessage.messageId;
    runEngine.sendMessage(loginRequestMessage.id, loginRequestMessage);
  };
  closeStatusForgotPassword=(status:boolean)=>{
    this.setState({resetpoPopUp:status})

  }
  // Customizable Area End
}

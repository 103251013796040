import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { WithStyles } from "@material-ui/core";
import { BusinessType, HotelStripeConnectedAccounts, MonthData, PaymentForm, PaymentInformatation, PaymentInformatationNotFound } from "./types";
import { checkApiErrorResponce, validateUploadedPdf } from "../../../components/src/CommonFunctions";
export const configJSON = require("./config");
import { Color } from "@material-ui/lab";
import { Identity } from "../../user-profile-basic/src/StripeAccountDetailsController.web";

// Customizable Area End


export interface Props extends WithStyles {
    navigation: any;
    // Customizable Area Start
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    editPaymentInformatation: boolean
    paymentInformationForm: PaymentForm;
    paymentFormErrors: any;
    PdfFile: File | null;
    firstNameTypeDropDownStatus: boolean
    phoneCountrycodeDropDownStatus: boolean
    merchantNameTypeDropDownStatus: boolean
    bussinessTypeDropDownStatus: boolean;
    bussinessDropDowns: BusinessType[];
    bussinessType: string;
    monthData: MonthData[];
    yearsList: any;
    daysList: any;
    monthDropDownStatus: boolean,
    yearDropDownStatus: boolean
    dayDropDownStatus: boolean,
    monthIndex: number;
    documentStatus: HotelStripeConnectedAccounts;
    documentUploadStatus: boolean;
    fileuploadedSize: string;
    stripePageLoading: boolean;
    paymentDetailsStatus: boolean;
    toasterMessage: string;
    toasterType: Color,
    toasterStatus: boolean;
    pdfError: string;
  identityDocuments: Identity[],
  documentType:boolean




    // Customizable Area End
}
interface SS { }

export default class paymentInformationcontroller extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    apiCallIdGetPaymentDetails: string = "";
    apiCallIdupdatePaymentInformation: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            editPaymentInformatation: false,
            paymentInformationForm: {
                bussinessType: "",
                firstName: "",
                surname: "",
                email: "",
                phonenumber: "",
                month: "",
                monthValue: 0,
                date: 0,
                year: 0,
                line1: "",
                state: "",
                city: "",
                postcode: "",
                countryCode: "",
                countryShortCode: "",
                merchantCode: "",
                merchantName: "",
                merchantState: "",
                merchantUrl: "",
                merchantCountry: "",
                merchantShortCode: "",
                accountType: "",
                accountTypeValue: "",
                swiftCode: "",
                accountNumber: "",
                currency: "",
                accountVerificationStatus: "",
                accountName: "",
                firstNameSalutation: "",
                phoneNumberCountryCode: "",
                mechantNameSalutation: "",
                accountNameSalutation: "",
                bussinessTypeValue: "",
                pdfPath: "",
                companyName: "",
                companyAddress: "",
                companyState: "",
                companyCity: "",
                companyPostalCode: "",
                companyTax: "",
                companyPhone: "",
                companyEmail: "",
                businessName: "",
                companyPhoneNumberCountryCode: "",
                companyPhoneCode: "",
        documentType: "",

            },
            paymentFormErrors: {},
            PdfFile: null,
            firstNameTypeDropDownStatus: false,
            merchantNameTypeDropDownStatus: false,

            phoneCountrycodeDropDownStatus: false,
            bussinessTypeDropDownStatus: false,
            bussinessDropDowns: [
                { id: 1, name: 'Individual', value: "individual" },
                { id: 2, name: 'Company', value: "company" },
            ],
            bussinessType: "",
            monthData: [
                { id: 1, monthName: 'January' },
                { id: 2, monthName: 'Feburary' },
                { id: 3, monthName: 'March' },
                { id: 4, monthName: 'April' },
                { id: 5, monthName: 'May' },
                { id: 6, monthName: 'June', },
                { id: 7, monthName: 'July' },
                { id: 8, monthName: 'August' },
                { id: 9, monthName: 'September' },
                { id: 10, monthName: 'October' },
                { id: 11, monthName: 'November', },
                { id: 12, monthName: 'December' },
            ],
            daysList: Array.from({ length: 31 }, (_, i) => i + 1),
            yearsList: [],
            monthDropDownStatus: false,
            yearDropDownStatus: false,
            dayDropDownStatus: false,
            monthIndex: 0,
            documentStatus: {
                identity_document_status: "",
                external_account_status: "",
                proof_of_identity_document:""
            },
            documentUploadStatus: false,
            fileuploadedSize: "",
            stripePageLoading: false,
            paymentDetailsStatus: false,
            toasterMessage: "",
            toasterType: 'success',
            toasterStatus: false,
            pdfError: "",
            identityDocuments: [
                {
                  identity_id: 1,
                  identity_name: 'Passport',
                  selected: false
                },
                {
                  identity_id: 2,
                  identity_name: 'Driver License',
                  selected: false
        
                }, {
                  identity_id: 3,
                  identity_name: 'Resident Permit',
                  selected: false
        
                },
                {
                  identity_id: 4,
                  identity_name: 'Citizen Card',
                  selected: false
        
                }, {
                  identity_id: 5,
                  identity_name: 'Electoral ID',
                  selected: false
        
                },
                {
                  identity_id: 6,
                  identity_name: 'Other',
                  selected: false
        
                },
        
              ],
              documentType:false






        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        this.getPaymentInformation()
        this.getYearsList()
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const webApiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

            let webResponseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

            if (webApiRequestCallId === this.apiCallIdGetPaymentDetails) {
                this.getpaymentDetailsApiResponce(webResponseJson)
                this.hotelIdNotFound(webResponseJson)
            }
            if (webApiRequestCallId === this.apiCallIdupdatePaymentInformation) {
                this.getpaymentDetailsApiResponce(webResponseJson)
                // this.hotelIdNotFound(webResponseJson)
            }

        }
        // Customizable Area End
    }
    // Customizable Area Start
    getYearsList = () => {
        let years = []
        const currentYear = (new Date()).getFullYear();
        years = Array.from({ length: (currentYear - 1990) / 1 + 1 }, (_, i) => 1990 + (i * 1));
        this.setState({ yearsList: years })
    }

    selectDay = (day: number) => {
        this.setState({ paymentInformationForm: { ...this.state.paymentInformationForm, date: day } }, () => {
            this.closeDobDropDowns('day')
        })


    }
    selectYear = (year: any) => {
        this.validateMonthsLeapYear(year, this.state.monthIndex)

        this.setState({ paymentInformationForm: { ...this.state.paymentInformationForm, year: year } }, () => {
            this.closeDobDropDowns('year')
        })

    }
    selectMonth = (monthName: string, monthValue: number) => {
        let MonthIndex = this.state.monthData.findIndex(month => month.id === monthValue);
        this.setState({ monthIndex: MonthIndex })
        this.validateMonthsLeapYear(this.state.paymentInformationForm.year, MonthIndex)
        this.setState({ paymentInformationForm: { ...this.state.paymentInformationForm, month: monthName, monthValue: monthValue } }, () => {
            this.closeDobDropDowns('month')
        })

    }
    getpaymentDetailsApiResponce = (webResponseJson: PaymentInformatation) => {
        if (webResponseJson && !webResponseJson.errors && webResponseJson?.data) {

            let indiduvalForm = {
                bussinessTypeValue: webResponseJson.data.business_type,
                firstName: webResponseJson.data?.individual?.first_name,
                surname: webResponseJson.data?.individual?.last_name,
                email: webResponseJson.data.email,
                phonenumber: webResponseJson.data?.individual?.phone.replace(/\+/g, ""),
                month: webResponseJson.data.individual?.dob.month,
                monthValue: webResponseJson.data.individual?.dob.month,
                date: webResponseJson.data.individual?.dob.day,
                year: webResponseJson.data.individual?.dob.year,
                line1: webResponseJson.data.individual?.address.line1,
                state: webResponseJson.data.individual?.address.state,
                city: webResponseJson.data.individual?.address.city,
                postcode: webResponseJson.data.individual?.address.postal_code,
                countryCode: "United Kingdom",
                countryShortCode: webResponseJson.data.individual?.address.country,
                merchantCode: webResponseJson.data.business_profile.mcc,
                merchantName: webResponseJson.data.business_profile.name,
                merchantState: webResponseJson.data.business_profile.mcc,
                merchantUrl: webResponseJson.data.business_profile.url,
                merchantCountry: "United Kingdom",
                merchantShortCode: webResponseJson.data.country,
                accountType: "Bank account",
                accountTypeValue: webResponseJson.data.external_accounts[0].object,
                swiftCode: webResponseJson.data.external_accounts[0].routing_number.replace(/-/g, ''),
                accountNumber: webResponseJson.data.external_accounts[0].last4,
                accountName: webResponseJson.data.external_accounts[0].account_holder_name,
                currency: "United kingdom",
                accountVerificationStatus: webResponseJson.data.external_accounts[0].status,
                firstNameSalutation: webResponseJson.data.individual?.first_name_salutation || "",
                phoneNumberCountryCode: webResponseJson.data.individual?.phone_country_code,
                mechantNameSalutation: webResponseJson.data.business_profile.business_profile_salutation,
                accountNameSalutation: webResponseJson.data.external_salutation,
                documentType:webResponseJson.data.hotel_stripe_connected_accounts?.proof_of_identity_document,
                companyName: "",
                companyAddress: "",
                companyState: "",
                companyCity: "",
                companyPostalCode: "",
                companyTax: "",
                companyPhone: "",
                companyEmail: "",
                businessName: "",
                companyPhoneNumberCountryCode: "",
                companyPhoneCode: ""
            }
            let companyForm = {
                bussinessTypeValue: webResponseJson.data.business_type,
                firstName: webResponseJson.data.representaive_details?.first_name,
                surname: webResponseJson.data.representaive_details?.last_name,
                email: webResponseJson.data.representaive_details?.email,
                phonenumber: webResponseJson.data.representaive_details?.phone.replace(/\+/g, ""),
                month: webResponseJson.data.representaive_details?.dob.month || "",
                monthValue: webResponseJson.data.representaive_details?.dob.month,
                date: webResponseJson.data.representaive_details?.dob.day,
                year: webResponseJson.data.representaive_details?.dob.year,
                line1: webResponseJson.data.representaive_details?.address.line1,
                state: webResponseJson.data.representaive_details?.address.state,
                city: webResponseJson.data.representaive_details?.address.city,
                postcode: webResponseJson.data.representaive_details?.address.postal_code,
                countryCode: "United Kingdom",
                countryShortCode: webResponseJson.data.representaive_details?.address.country,
                merchantCode: "",
                merchantName: "",
                merchantState: "",
                merchantUrl: webResponseJson.data.business_profile.url,
                merchantCountry: "",
                merchantShortCode: "",
                accountType: "Bank account",
                accountTypeValue: webResponseJson.data.external_accounts[0].object,
                swiftCode: webResponseJson.data.external_accounts[0].routing_number.replace(/-/g, ''),
                accountNumber: webResponseJson.data.external_accounts[0].last4,
                accountName: webResponseJson.data.external_accounts[0].account_holder_name,
                currency: "United kingdom",
                accountVerificationStatus: webResponseJson.data.external_accounts[0].status,
                firstNameSalutation: webResponseJson.data.representaive_details?.first_name_salutation,
                phoneNumberCountryCode: webResponseJson.data.representaive_details?.phone_country_code,
                mechantNameSalutation: "",
                accountNameSalutation: webResponseJson.data.external_salutation,
                companyName: webResponseJson.data.company?.name,
                companyAddress: webResponseJson.data.company?.address.line1,
                companyState: webResponseJson.data.company?.address.state,
                companyCity: webResponseJson.data.company?.address.city,
                companyPostalCode: webResponseJson.data.company?.address.postal_code,
                companyTax: "",
                companyPhone: webResponseJson.data.company?.phone,
                companyEmail: webResponseJson.data.email,
                businessName: webResponseJson.data.buiness_name,
                companyPhoneNumberCountryCode: "",
                companyPhoneCode: "",
                documentType:webResponseJson.data.hotel_stripe_connected_accounts?.proof_of_identity_document
                

            }


            this.setState({
                paymentInformationForm: webResponseJson.data?.business_type === 'company' ? companyForm : indiduvalForm,
                documentStatus: webResponseJson.data.hotel_stripe_connected_accounts,
                stripePageLoading: false,
                editPaymentInformatation: false,
                paymentDetailsStatus: true
            }, () => {
                this.manageBussinessTypeValue()
            })

        } else if (webResponseJson && webResponseJson.status == 500) {
            this.setState({ stripePageLoading: false, paymentDetailsStatus: false })
        }
        else if (webResponseJson.errors) {
            {
                webResponseJson.errors.map((error: any, index: number) => {
                    const errorKey = Object.keys(error)[0]; // Get the first key of the error object
                    const errorMessage = error[errorKey]; // Get the corresponding error message
                    this.setState({
                        stripePageLoading: false,
                        toasterMessage: errorMessage,
                        toasterStatus: true,
                        toasterType: 'error',
                        paymentDetailsStatus: true

                    }, () => {
                        this.getPaymentInformation()
                    })
                })
            }
            checkApiErrorResponce(webResponseJson, this.props.navigation)
        }
    }

    hotelIdNotFound = (webResponseJson: PaymentInformatationNotFound) => {
        if (webResponseJson ? webResponseJson.data === "Stripe account id not found" : "") {
            this.setState({
                stripePageLoading: false,
                editPaymentInformatation: false,
                paymentDetailsStatus: true
            }, () => {
                this.manageBussinessTypeValue()
            })

        }
    }


    manageBussinessTypeValue = () => {
        const value = this.state.paymentInformationForm.bussinessTypeValue
        {
            this.state.bussinessDropDowns.map((item: BusinessType) => {
                if (item.value === value) {
                    this.setState({
                        paymentInformationForm: {
                            ...this.state.paymentInformationForm,
                            bussinessType: item.name,
                            bussinessTypeValue: item.value
                        }
                    })
                }

            })
        }

    }
    editPaymentInformatation = () => {
        this.setState({ editPaymentInformatation: true })
    }


    handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let errors = { ...this.state.paymentFormErrors }
        const { name, value } = event.target
        { name === 'firstName' && this.nameValidation(value, "firstName", 'First name', errors) }
        { name === 'surname' && this.nameValidation(value, "surname", 'Surname', errors) }
        { name === 'email' && this.emailValidation(value, "email", errors) }
        { name === 'phonenumber' && this.numberValidation(value, "phonenumber", 'Phone number', errors) }
        { name === 'line1' && this.numeracalCharacterValidation(value, "line1", 'Address line1', errors) }
        { name === 'city' && this.nameValidation(value, "city", 'City', errors) }
        { name === 'state' && this.nameValidation(value, "state", 'State', errors) }
        { name === 'postcode' && this.numeracalCharacterValidation(value, "postcode", 'Postcode', errors) }
        { name === 'merchantCode' && this.numeracalCharacterValidation(value, "merchantCode", 'Merchant Category Code-MCC*', errors) }
        { name === 'merchantName' && this.nameValidation(value, "merchantName", 'Name', errors) }
        { name === 'accountNumber' && this.numberValidation(value, "accountNumber", 'Account number', errors) }
        { name === 'swiftCode' && this.numberValidation(value, "swiftCode", 'Swift code', errors) }
        { name === 'accountName' && this.nameValidation(value, "accountName", 'Name', errors) }
        this.companyForm(name, value, errors)
        this.inputValues(name, value, errors)
        this.setState({
            paymentFormErrors: errors
        })


    }
    inputValues = (name: string, value: string, errors: PaymentForm) => {
        if (name === 'url') {
            this.validateUrl(value, name, errors, "Business URL");
            // Ensure the URL always starts with "https://"
            if (value.startsWith('https://')) {
                this.setState({
                    paymentInformationForm: { ...this.state.paymentInformationForm, merchantUrl: value },
                });
            } else {
                this.setState({
                    paymentInformationForm: { ...this.state.paymentInformationForm, merchantUrl: 'https://' },
                });
            }

        } else {
            // Handle other input fields
            this.setState({
                paymentInformationForm: { ...this.state.paymentInformationForm, [name]: value },
            });
        }

    }
    companyForm = (name: string, value: string, errors: PaymentForm) => {
        { name == 'companyName' && this.nameValidation(value, name, "Company Name", errors) }
        { name == 'companyAddress' && this.numeracalCharacterValidation(value, name, "Company Address", errors) }
        { name == 'companyState' && this.nameValidation(value, name, "Company State", errors) }
        { name == 'companyCity' && this.nameValidation(value, name, "Company City", errors) }
        { name == 'companyPostalCode' && this.numeracalCharacterValidation(value, name, "Company Postcode", errors) }
        { name == 'companyTax' && this.numeracalCharacterValidation(value, name, "Company Tax number", errors) }
        { name == "companyEmail" && this.emailValidation(value, name, errors) }
        { name == "businessName" && this.nameValidation(value, name, "Business Name", errors) }
        { name == 'companyPhone' && this.numberValidation(value, name, "Company Phone Number", errors) }
    }
    nameValidation = (value: string, name: string, key: string, errors: any) => {
        const firstNameRegex = configJSON.nameRegex

        if (!value) {
            errors[name] = `${key} is required`
        } else if (!firstNameRegex.test(value)) {
            errors[name] = "Please enter alphabetical characters";
        }
    }
    validateUrl = (value: string, name: string, errors: any, type: string) => {

        if (!value) {
            errors[name] = `${type} is required`
        } else if (!configJSON.urlRegex.test(value)) {

            errors[name] = `Please enter valid ${type}`;
        } else {
            delete errors[name];
        }
    }

    emailValidation = (value: string, name: string, errors: any) => {
        const regexEmail = configJSON.emailRegex
        if (!value) {
            errors[name] = "Email Address is required";
        } else if (!regexEmail.test(value)) {
            errors[name] = "Please enter the valid email address";
        }
    }
    numeracalCharacterValidation = (value: string, name: string, key: string, errors: any) => {
        const postcodeRegex = /^[a-zA-Z0-9\s]*$/

        if (!value) {
            errors[name] = `${key} is required`
        } else if (!postcodeRegex.test(value)) {
            errors[name] = `Please enter valid ${key}`;
        }
    }
    numberValidation = (value: string, name: string, type: string, errors: any) => {
        const regexNumbers = configJSON.numerRegex
        if (!value) {
            errors[name] = `${type} is required`;
        } else if (!regexNumbers.test(value)) {
            errors[name] = ` ${type} in numerical characters`;
        }
    }

    handleOpendropDowns = (dropDownType: string) => {

        if (dropDownType === 'firstName') {
            this.setState({ firstNameTypeDropDownStatus: true })
        } else if (dropDownType === 'merchantName') {
            this.setState({ merchantNameTypeDropDownStatus: true })
        }
       else if(dropDownType==='identity'){
            this.setState({ documentType: true })

        }

    }
    handleClosedropDowns = (dropDownType: string) => {

        if (dropDownType === 'firstName') {
            this.setState({ firstNameTypeDropDownStatus: false })
        } else if (dropDownType === 'merchantName') {
            this.setState({ merchantNameTypeDropDownStatus: false })
        }else if(dropDownType==='identity'){
            this.setState({ documentType: false })
        }


    }

    handleSelectNameType = (value: string, selectType: string) => {
        if (selectType === 'firstName') {
            this.setState({ paymentInformationForm: { ...this.state.paymentInformationForm, firstNameSalutation: value } }, () => {
                this.handleClosedropDowns(selectType)
            })

        } else if (selectType === 'merchantName') {
            this.setState({ paymentInformationForm: { ...this.state.paymentInformationForm, mechantNameSalutation: value } }, () => {
                this.handleClosedropDowns(selectType)
            })

        }

    }

    handleSelectBussiness = (selected: BusinessType) => {
        this.setState({
            paymentInformationForm: {
                ...this.state.paymentInformationForm,
                bussinessType: selected.name,
                bussinessTypeValue: selected.value
            }
        }, () => {
            this.handleClosedropDowns('bussiness')
        })

    }


    selectPhoneNumberCountry = (countryName: string, CountryCode: string) => {
        this.setState({ paymentInformationForm: { ...this.state.paymentInformationForm, phoneNumberCountryCode: `${countryName}${CountryCode}` } },
            () => {
                this.handlecloseCountryCodes("phonecountry")
            })
    }

    handleOpenCountryCodes = (dropDownType: string) => {
        if (dropDownType === 'phonecountry') {
            this.setState({ phoneCountrycodeDropDownStatus: true })

        }

    }
    handlecloseCountryCodes = (dropDownType: string) => {
        if (dropDownType === 'phonecountry') {
            this.setState({ phoneCountrycodeDropDownStatus: false })

        }
    }


    openDobDropDowns = (openType: string) => {
        if (openType === 'day') {
            this.setState({ dayDropDownStatus: true })
        } else if (openType === 'month') {
            this.setState({ monthDropDownStatus: true })
        } else if (openType === 'year') {
            this.setState({ yearDropDownStatus: true })
        }

    }
    closeDobDropDowns = (openType: string) => {
        if (openType === 'day') {
            this.setState({ dayDropDownStatus: false })
        } else if (openType === 'month') {
            this.setState({ monthDropDownStatus: false })
        } else if (openType === 'year') {
            this.setState({ yearDropDownStatus: false })
        }

    }


    getPaymentInformation = async () => {
        let tokenValue = await getStorageData("authToken");
        const hotelID = await getStorageData('BoHotelId')
        this.setState({ stripePageLoading: true })
        const headers = {
            token: tokenValue,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getPaymentInformationEndPoint}=${hotelID}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.dashboarApiMethodType
        );
        this.apiCallIdGetPaymentDetails = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }





    updatePayment = () => {
        let errors: any = {}
        const { bussinessType, firstName, surname, email, phonenumber, line1, city, state, postcode,
            merchantName, accountName, accountNumber, swiftCode, } = this.state.paymentInformationForm
        this.nameValidation(firstName, "firstName", 'First name', errors)
        this.nameValidation(surname, "surname", 'Surname', errors)
        this.emailValidation(email, "email", errors)
        this.numberValidation(phonenumber, "phoneNumber", 'Phone number', errors)
        this.numeracalCharacterValidation(line1, "line1", 'Address line1', errors)
        this.nameValidation(city, "city", 'City', errors)
        this.nameValidation(state, "state", 'State', errors)
        this.numeracalCharacterValidation(postcode, "postcode", 'Postcode', errors)
        this.numberValidation(accountNumber, "accountNumber", 'Account number', errors)
        this.numberValidation(swiftCode, "swiftCode", 'Swift code', errors)
        this.nameValidation(accountName, "accountName", 'Name', errors)
        if (bussinessType != "Company") {
            this.nameValidation(merchantName, "merchantName", 'Name', errors)

        }
        this.validateFile()
        this.setState({ paymentFormErrors: errors },
            () => {
                if (Object.keys(this.state.paymentFormErrors).length === 0) {
                    this.upadtePaymentInformation()
                }
            }
        );

    }

    validateFile = () => {
        if (this.state.documentStatus.identity_document_status == 'unverified') {
            if (this.state.PdfFile) {
                this.validatePdf(this.state.PdfFile)
            }
        }

    }


    upadtePaymentInformation = async () => {

        let tokenValue = await getStorageData("authToken");
        const hotelID = await getStorageData('BoHotelId')

        const headers = {
            token: tokenValue,
        };
        let formdata = new FormData();
        const { bussinessType } = this.state.paymentInformationForm
        this.setState({ stripePageLoading: true })
        formdata.append("connected_account[business_type]", this.state.paymentInformationForm.bussinessTypeValue);
        formdata.append("connected_account[first_name]", this.state.paymentInformationForm.firstName);
        formdata.append("connected_account[surname]", this.state.paymentInformationForm.surname);
        formdata.append("connected_account[email]", this.state.paymentInformationForm.email);
        formdata.append("connected_account[full_phone_number]", this.state.paymentInformationForm.phonenumber);
        formdata.append("connected_account[dob_month]", this.state.paymentInformationForm.date.toString());
        formdata.append("connected_account[dob_year]", this.state.paymentInformationForm.year.toString());
        formdata.append("connected_account[dob_day]", this.state.paymentInformationForm.date.toString());
        formdata.append("connected_account[address_line]", this.state.paymentInformationForm.line1);
        formdata.append("connected_account[city]", this.state.paymentInformationForm.city);
        formdata.append("connected_account[state]", this.state.paymentInformationForm.state);
        formdata.append("connected_account[postcode]", this.state.paymentInformationForm.postcode);
        formdata.append("connected_account[business_url]", this.state.paymentInformationForm.merchantUrl);
        formdata.append("connected_account[external_account][account_type]", "bank_account");
        formdata.append("connected_account[external_account][account_country]", 'GB');
        formdata.append("connected_account[external_account][currency]", 'GBP');
        formdata.append("connected_account[external_account][account_holder_name]", this.state.paymentInformationForm.accountName);
        formdata.append("connected_account[external_account][routing_number]", this.state.paymentInformationForm.swiftCode);
        formdata.append("connected_account[external_account][account_number]", this.state.paymentInformationForm.accountNumber);
        if(this.state.PdfFile){
            formdata.append("connected_account[identity_document]", this.state.PdfFile);
        }
        formdata.append("connected_account[first_name_salutation]", this.state.paymentInformationForm.firstNameSalutation);
        formdata.append("connected_account[phone_country_code]", this.state.paymentInformationForm.phoneNumberCountryCode);
        formdata.append("connected_account[business_profile_salutation]", this.state.paymentInformationForm.mechantNameSalutation || "");
        formdata.append("connected_account[external_salutation]", this.state.paymentInformationForm.accountNameSalutation);
        formdata.append("connected_account[address_country]", 'GB');
        formdata.append("connected_account[hotel_id]", hotelID)

        if (bussinessType != "Company") {
            formdata.append("connected_account[business_name]", this.state.paymentInformationForm.merchantName || "");

        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.updatePaymentEndPoint}/${hotelID}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.updateApiMethodType
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage), formdata
        );
        this.apiCallIdupdatePaymentInformation = requestMessage.messageId;
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    validateMonthsLeapYear = (year: number, monthIndex: number) => {

        if ([0, 2, 4, 6, 7, 9, 11].includes(monthIndex)) {
            this.setState({ daysList: Array.from({ length: 31 }, (_, i) => i + 1) })

        } else if ([3, 5, 8, 10].includes(monthIndex)) {
            this.setState({ daysList: Array.from({ length: 30 }, (_, i) => i + 1) })

        } else if (((0 == year % 4) && (0 != year % 100) || (0 == year % 400)) && (1 === monthIndex)) {
            this.setState({ daysList: Array.from({ length: 29 }, (_, i) => i + 1) })

        } else if (1 === monthIndex && !((0 == year % 4) && (0 != year % 100) || (0 == year % 400))) {
            this.setState({ daysList: Array.from({ length: 28 }, (_, i) => i + 1) })
        }
    }


    validateUploadedFileSize = (file: File | null) => {
        let size = 1024
        let calculateSize = file && file.size / size || 0;
        this.setState({ fileuploadedSize: calculateSize.toFixed(2) + " KB" })

    }

    closeToasterStatus = () => {
        this.setState({ toasterStatus: false })
    }


    validatePdf = (pdfName: File | null): string => {

        const imageExtensions = /\.(jpg|jpeg|png)$/i;
        const pdfExtension = /\.pdf$/i;
        let error = '';
    
        // Check if pdfFile is empty or null
        if (!pdfName) {
          error = 'Please upload an identity document';
        } else if (!imageExtensions.test(pdfName.name) && !pdfExtension.test(pdfName.name)) {
          error = 'Please select only JPEG/PNG or PDF document.';
        }
    
        return error;
      };

    handlePdfUploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        let pdfError = this.validatePdf(file);
        this.validateUploadedFileSize(file);
        this.setState({
            PdfFile: file,
            pdfError: pdfError,
            documentUploadStatus: !pdfError,
        });
    }

    selectVerificatioDocument = (slectedId: number, selectecType: string) => {

        const newSelectedDocument = this.state.identityDocuments.map((item: Identity) => {
          return { ...item, selected: item.identity_id === slectedId };
        });
        const selectedDocument = newSelectedDocument.find(item => item.identity_id === slectedId && item.selected);
    
        this.setState({
          identityDocuments: newSelectedDocument,
          paymentInformationForm: { ...this.state.paymentInformationForm, documentType: selectedDocument ? selectedDocument.identity_name : "" },
          paymentFormErrors: { ...this.state.paymentFormErrors, documentType: "" }
    
        }, () => {
          this.handleClosedropDowns('identity')
        })
      }
    // Customizable Area End
}

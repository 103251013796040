export const Location = require("../assets/location_on.png");
export const Img13 = require("../assets/img13.png");
export const Img15 = require("../assets/img15.png");
export const Img14 = require("../assets/img14.png");
export const Spa1 = require("../assets/Spa1.png");
export const Water = require("../assets/Water.png");
export const eye = require("../assets/eye.png");
export const Pickup = require("../assets/Pickup.png");
export const Train = require("../assets/Train.png");
export const Start = require("../assets/Start.png");
export const update = require("../assets/update.png");
export const Birthday = require("../assets/Birthday.png");
export const Boy1 = require("../assets/Boy1.jpg");
export const Girl1 = require("../assets/Girl1.png");
export const FarmeDog = require("../assets/FarmeDog.png");
export const FarmeCat = require("../assets/FarmeCat.png");
export const Profile1 = require("../assets/Profile1.png");
export const ShowAllPhoto = require("../assets/showAll.png");
export const FarmeDate = require("../assets/FarmeDate.png");
export const Payment = require("../assets/payment.png");

export const images = require("../assets/image.png");
export const image_Sampe_1 = require("../assets/image_Sampe_1.png");
export const image_Sampe_2 = require("../assets/image_Sampe_2.png");
export const image_Sampe_3 = require("../assets/image_Sampe_3.png");
export const image_Sampe_4 = require("../assets/image_Sampe_4.png");
export const image_Sampe_5 = require("../assets/image_Sampe_5.png");
export const image_Sampe_6 = require("../assets/image_Sampe_6.png");
export const image_Sampe_7 = require("../assets/image_Sampe_7.png");
export const image_Sampe_8 = require("../assets/image_Sampe_8.png");
export const image_Sampe_9 = require("../assets/image_Sampe_9.png");
export const PhotoLibrery = require("../assets/library.svg");
export const Dog = require("../assets/Dog.svg");
export const Cat = require("../assets/Cat.png");
 export const dummyImage=require('../assets/dummy.svg');
 export const locationIcon=require('../assets/location.png');
 export const searchIcon =require('../assets/searchIcon.svg');
 export const dropDownIcon =require('../assets/dropdownIcon.png');
 export const search=require('../assets/search.png');
 export const locationMap=require('../assets/locationMap.png');
 export const backIcon=require('../assets/iconBack.svg');
 export const markerIconPng=require("../assets/location.png");
 export const locationSymbol=require("../assets/location_on.png");

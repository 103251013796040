Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.apiContentType = 'application/json'
exports.getApiMethod = 'GET'
exports.postApiMethod = 'POST'
exports.deleteApiMethod = 'DELETE'

exports.getOrdersApiEndPoint = 'bx_block_shopping_cart/shopping_carts/'
exports.createOrderItemApiEndPoint = 'shopping_cart/order_items'
exports.deleteOrderItemApiEndPoint = 'shopping_cart/orders'

exports.errorTitle = 'Error'
exports.errorAllFieldsAreMandatory = 'All fields are mandatory.'

exports.userPetsApiEndPoint='bx_block_catalogue/pets/pet_details'
exports.hotesListApiEndPoint="bx_block_custom_form/hotels";
exports.cancellationPolicyEndPoint="bx_block_settings/cancellation_policies?hotel_id"
exports.standardPolicyEndPoint="bx_block_settings/standard_cancellations"
exports.daily = "Daily";
exports.dayCareService = "Day Care Services";
exports.oneTime = "Set the price for daycare based on the number of days:";
exports.priceFor = "Price for";
exports.day = "day";
exports.days = "days";
exports.continue = "Continue";
exports.weeklyPrice = "Set the weekly price for daycare based on the number of days:";
exports.dayCareEndpoint = "bx_block_custom_form/hotel_day_care_services?hotel_id={hotelId}";
exports.dayCareText = "Doggy Daycare";
exports.selectCatRoom = "Select cat per room";
exports.selectDogRoom = "Select dog per room";
exports.addAdditionalServices = "Add additional services";
exports.additionalServices = "Additional services";
exports.dogTxt = "Dog";
exports.catTxt = "Cat";
exports.orderDetailsApi = "bx_block_shopping_cart/order_creations/";
exports.transactionFeeText = "Transaction Fee";
exports.getCancelationPolicyEndPoint="bx_block_settings/cancellation_policies?hotel_id={hotelId}";
exports.question1="1. Additional question";
exports.question2="2. Additional question";
exports.question3="3. Additional question";
exports.dropDown="Additional Conditions and Questions of the hotel";
exports.conditions=" Additional conditions of the hotel";
// Customizable Area End

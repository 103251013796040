import React from "react";

// Customizable Area Start
import Grid from "@material-ui/core/Grid";
import { Button, Snackbar, TextField, Typography, withStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import Alert from "@material-ui/lab/Alert/Alert";
// Customizable Area End

import ResetPasswordBoController, {
  Props
} from "./ResetPasswordBoController.web";
import Navbar from "../../../components/src/Navbar";
import CustomizedSnackbars from "../../../components/src/CustomSnackbar.web";

export default class ResetPasswordBo extends ResetPasswordBoController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start

    return (
      <>
        <Navbar navType="auth" authContent="Bo" />

        

<CustomizedSnackbars
            message={'Link has been sent to the registered mail'}
            toasterType="success"
            duration={20000}
            openToaster={this.state.resetPopUp}
             closeStatus={this.closeresetPopUpBo}
             />
        <div style={formstyle.mainContainer}>
          <Grid container style={formstyle.mainGrid}>
            <Grid style={formstyle.innerGrid}>
              <div style={formstyle.mainHeading}>Password Recovery</div>
              <div style={{ marginTop: "40px" }}>
                <span style={formstyle.secComptHead as React.CSSProperties}>Forgotten Your Password?</span>
                <br />
                <div style={formstyle.secComptText as React.CSSProperties}>
                  No problem, we've got you covered! Simply click the link below to reset your password:
                </div>
              </div>
              <div style={{ marginTop: "40px" }}>
                <span style={formstyle.secComptHead as React.CSSProperties}>Email</span>
                <CssTextField
                  style={formstyle.inputStyle}
                  type="text"
                  variant="outlined"
                  value={this.state.email}
                  onChange={this.getEmail}
                  placeholder="   Your email"
                  data-test-id="handle_email_change"
                />
              </div>
              {this.state.apiErrorLogInForBO && (
                <Typography color="error" style={{ padding: "10px" }}>
                  {this.state.apiErrorLogInForBO}
                </Typography>
              )}
              {this.state.resetPasswordSucess && (
                <Typography
                  style={{ padding: "10px", color: "rgb(46 125 50)" }}
                >
                  {this.state.resetPasswordSucess}
                </Typography>
              )}
              <div>
                <Button
                  data-test-id="handle_continue_click"
                  style={formstyle.button as React.CSSProperties}
                  onClick={this.callApiForBoResetPassword}
                >
                  Send a recovery link
                </Button>
              </div>
              <div style={formstyle.alreadyAccount as React.CSSProperties}>
                <label style={formstyle.one15 as React.CSSProperties}>
                  Already have an account?{" "}
                  <Link to="/LogInBusinessOwner" style={{textDecorationLine:"none"}}>
                    <span style={formstyle.signIn as React.CSSProperties}>Sign In</span>{" "}
                  </Link>{" "}
                </label>
              </div>
            </Grid>
          </Grid>
        </div>
      </>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CssTextField = withStyles({
  root: {
    
    '& .MuiInput-underline:after': {
      borderBottomColor: '#54A7C9',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#CBD5E1',
        borderRadius:"8px"
      },
      
      '&.Mui-focused fieldset': {
        borderColor: '#54A7C9',
        borderWidth:"2px",
        borderRadius:"8px"
      },
    },
  },
})(TextField);
const formstyle = {
  mainContainer: {
    backgroundColor: "#B2E4F8",
    marginTop: "110px",
    marginLeft: "30px",
    marginRight: "30px",
    marginBottom: "30px",
    borderBottomRightRadius: "25px",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  mainGrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    height: "auto"
  },
  innerGrid: {
    backgroundColor: "#fff",
    padding: "40px",
    borderRadius: "3px",
    borderBottomRightRadius: "30px",
    height: "500px",
    width: "425px",
  },
  mainHeading: {
    fontFamily: "inter",
    fontSize: "30px",
    fontWeight: 700,
    color: "#1E293B",
    margin: "10px 0px 30px 0px",
    lineHeight: "40px",
    font: "inter",
  },
  secComptHead: {
    fontSize: "14px",
    fontWeight: 700,
    color: "rgba(51, 65, 85, 1)",
    font: "inter",
    textTransform: "none",
    fontFamily: "inter"
  },
  secComptText: {
    fontSize: "16px",
    fontWeight: 400,
    color: "rgba(100, 116, 139, 1)",
    margin: "18px 0px 20px 0px",
    font: "inter",
    textTransform: "none",
    fontFamily: "inter"
  },
  inputStyle: {
    marginTop: "10px",
    width: "98%",
    height: "52px",

    borderRadius: "8px",
    fontSize: "18px",
  },
  button: {
    width: "100%",
    padding: "14px",
    backgroundColor: "#EA0C78",
    borderRadius: "8px",
    marginTop: "60px",
    color: "#fff",
    fontWeight: 700,
    font: "inter",
    textTransform: "none",
    fontFamily: "inter"
  },
  alreadyAccount: {
    marginTop: "60px",
    textAlign: "center",
  },
  one15: {
    font: "inter",
    fontWeight: 400,
    fontSize: "16px",
    color: "#1E293B",
    textTransform: "none",
    fontFamily: "inter"
  },
  signIn: {
    color: "#1E293B",
    font: "inter",
    fontWeight: 700,
    fontSize: "18px",
    cursor: "pointer",
    textDecorationLine:"underline",
  },
};
// Customizable Area End

import React from "react";
import { withStyles, makeStyles, Theme, createStyles, WithStyles } from "@material-ui/core/styles";
import { Slider, Checkbox, TextField, MenuItem, Radio, RadioProps, NativeSelect, Button, styled, Box } from "@material-ui/core";
import Switch, { SwitchClassKey, SwitchProps } from "@material-ui/core/Switch";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Menu, { MenuProps } from "@material-ui/core/Menu";
import { Rating } from "@material-ui/lab";
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { theme } from "./theme";
import clsx from 'clsx';
import Popper , { PopperProps }from '@material-ui/core/Popper';


const pxToRem = (px: number, oneRemPx = 16) => `${px / oneRemPx}rem`;
const spacing = 8;
const size = pxToRem(28);
const width = pxToRem(48 + 2 * spacing);
const borderWidth = 2;
const height = `calc(${size} + ${borderWidth * 2}px + ${pxToRem(2 * spacing)})`;

interface RatingProps {
  rating?: number
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 300 + theme.spacing(3) * 2
  },
  margin: {
    height: theme.spacing(3)
  },
  ratingEmptyIcon: {
    color: theme.palette.warning.main
  },
  checkedBoxIcon: {
    border: '1px solid #475569',
    borderRadius: 6,
    width: 20,
    height: 20,
    backgroundColor: theme.palette.common.white,
    '$root.Mui-focusVisible &': {
      outline: `1px auto ${theme.palette.secondary.main}`,
      outlineOffset: 2,
    },
  },
  tickIcon: {
    backgroundColor: theme.palette.secondary.main,
    border: 'none',
    width: 20,
    height: 20,
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 20,
      height: 20,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  greyCheckedBoxIcon: {
    border: '1px solid #475569',
    borderRadius: 6,
    width: 20,
    height: 20,
    backgroundColor: theme.palette.common.white,
    '$root.Mui-focusVisible &': {
      outline: `1px auto #334155`,
      outlineOffset: 2,
    },
  },
  greyTickIcon: {
    backgroundColor: '#334155',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 20,
      height: 20,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#334155',
    },
  },

}));

export const CustomizedSlider = withStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.info.main,
      height: 20,
      padding: "13px 0"
    },
    thumb: {
      height: 27,
      width: 27,
      backgroundColor: "#ffffff",
      border: `6px solid ${theme.palette.primary.light}`,
      marginTop: -10,
      marginLeft: -13,
      "& .bar": {
        height: 9,
        width: 2,
        backgroundColor: "#ffffff",
        marginLeft: 1,
        marginRight: 1
      }
    },
    active: {},
    track: {
      height: 12
    },
    rail: {
      color: theme.palette.primary.main,
      opacity: 1,
      height: 12,
      borderRadius: 32
    },
    markLabel: {
      fontSize: 12,
      color: '#64748B',
      top: -20,
      fontFamily: "Inter",
      fontWeight: 400
    },
    markLabelActive: {
      fontSize: 12,
      color: "#1E293B",
      fontFamily: "Inter",
      fontWeight: 700
    }
  }))(Slider);

export const StyledCheckbox: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "20px",
      width: "20px",
      color: theme.palette.info.dark,
      borderRadius: 5,
      "& .MuiCheckbox-colorSecondary.Mui-checked": {
        color: theme.palette.info.dark
      },
      "& .MuiSvgIcon-root": {
        fontSize: "2rem"
      }
    }
  }))(Checkbox);

export const StyledSecondaryCheckbox: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "20px",
      width: "20px",
      // color: theme.palette.info.dark,
      borderRadius: 8,
      "& .MuiCheckbox-colorSecondary.Mui-checked": {
        borderRadius: 8,
        color: theme.palette.info.dark
      },
      "& .MuiSvgIcon-root": {
        // fontSize: "2rem"
      }
    }
  }))(Checkbox);


export const CustomizedTextField = withStyles({
  root: {
    "& .MuiInputBase-root": {
      fontFamily: "Inter",
      color: "#000000",
      fontWeight: 700,
      fontSize: 14,
      ' &:hover': {
        backgroundColor: "#E0F4FC",
        borderRadius: 8
      }
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none"
    },
    "& .MuiOutlinedInput-root:hover": {
      // borderColor: "rgba(0, 0, 0, 0.23)",
      border: "none"
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "#E0F4FC"
    },
    "& .MuiSvgIcon-root": {
      fontSize: "25px"
    }
  }
})(TextField);

export const CustomizedTextFieldAutoComplete = withStyles({
  root: {
    "& .MuiInputBase-root": {
      fontFamily: "Inter",
      color: "#000000",
      backgroundColor: "#ffffff",
      height: "30px"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none"
    },
    "& .MuiOutlinedInput-root:hover": {
      // borderColor: "rgba(0, 0, 0, 0.23)",
      border: "none"
    },
    "& .MuiSvgIcon-root": {
      // fontSize: "2rem"
      display: 'none'
    }
  }
})(TextField);

export const StyledMenuItem = withStyles((theme) => ({
  root: {
    marginTop: "5px",
    padding: "0.6rem",
    '&:hover': {
      backgroundColor: "#E0F4FC",
      borderRadius: 8
    },
    '&:focus': {
      backgroundColor: "#E0F4FC",
      borderRadius: 8
    },
  },
}))(MenuItem);


interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

interface Props extends SwitchProps {
  classes: Styles;
}

export const CustomizedSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width,
      height,
      padding: pxToRem(spacing),
      margin: 0,
    },
    switchBase: {
      padding: borderWidth,
      top: pxToRem(spacing),
      left: pxToRem(spacing),
      "&$checked": {
        transform: `translateX(calc(${width} - ${size} - ${borderWidth * 2
          }px - ${pxToRem(2 * spacing)}))`,
        color: theme.palette.common.white,
        "& + $track": {
          backgroundColor: "#326478",
          opacity: 1,
          border: "none",
        },
      },
      "&$focusVisible $thumb": {
        color: "#52d869",
      },
    },
    thumb: {
      width: 28,
      height: 28,
    },
    track: {
      borderRadius: 40,
      borderWidth,
      backgroundColor: "#CCEDFA",
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
  })
)(Switch);


export const StyledRadio = withStyles({
  root: {
    color: "#224350",
    '&$checked': {
      color: "#224350",
    },
  },
  checked: {},
})((props: RadioProps) =>


  <Radio color="default" {...props} />
);

export const CustomSelect = withStyles({
  root: {
    height: "20px",
    background: "#E0F4FC",
    color: "#64748B",
    fontSize: "16px",
    padding: "9px",
    fontWeight: 500,
    width: "100%",

  },

})((props: any) => <NativeSelect {...props} IconComponent={ExpandMoreIcon} />);



export const StyledMenu = withStyles(() => ({}))((props: MenuProps & WithStyles) => (
  <Menu
    {...props}
    getContentAnchorEl={null}
    anchorOrigin={{
      horizontal: "center",
      vertical: "bottom",
    }}
    transformOrigin={{
      horizontal: "center",
      vertical: "top",
    }}
    PaperProps={{
      style: {
        borderRadius: "8px",
        width: "389px",
        maxHeight: "400px",
        marginRight: "200px",
        marginTop: "10px",
      },
    }}
    disableScrollLock={false}
  />
));


export const StyledMenuPet = withStyles(() => ({}))(
  (props: MenuProps & WithStyles) => (
    <Menu
      {...props}
      getContentAnchorEl={null}
      transformOrigin={{
        horizontal: "center",
        vertical: "top",
      }}
      anchorOrigin={{
        horizontal: "center",
        vertical: "bottom",
      }}
      PaperProps={{
        style: {
          marginLeft: "42px",
          maxHeight: "400px",
          marginTop: "10px",
          width: "267px",
          borderRadius: "8px",
          marginRight: "200px",
        },
      }}
      disableScrollLock={false}
    />
  )
);

export const StyledMenuPetInOut = withStyles((theme) => ({
  paper: {
    marginRight: '200px',
    width: '505px',
    marginTop: '10px',
    borderRadius: '8px',
    position: 'fixed',
  },
  list: {
    padding: '0px',
  },
}))((props: MenuProps & WithStyles) => (
  <Menu
    {...props}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    disableScrollLock={false}
  />
));


export default function CustomRating(props: RatingProps) {
  const classes = useStyles();
  return (
    <Rating precision={0.5} readOnly value={props.rating}
      emptyIcon={<StarBorderIcon fontSize="inherit" className={classes.ratingEmptyIcon} />}
    />
  )
}

export const SettingsTextField = withStyles({
  root: {
    "& .MuiInputBase-root": {
      fontFamily: "Inter",
      color: theme.palette.common.black,
      backgroundColor: "#ffffff",
      fontSize: 16,
      fontWeight: theme.typography.fontWeightRegular,
      height: 44
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #CBD5E1",
      borderRadius: 8
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #CBD5E1",
      borderRadius: 8
    },
    "& .MuiOutlinedInput-root:hover": {
      borderRadius: 8
    },
    "& .MuiSvgIcon-root": {
      display: 'none'
    },
    "& .MuiOutlinedInput-adornedStart": {
      paddingLeft: 0
    },
    "& .MuiFormHelperText-root": {
      marginTop: 5,
      fontSize: 14
    }
  }
})(TextField);
export const DropDownInputField = withStyles({
  root: {
    "& .MuiInputBase-root": {
      background: `linear-gradient(0deg, #E0F4FC, #E0F4FC),linear-gradient(0deg, #CCEDFA, #CCEDFA)`,
      color: theme.palette.common.black,
      fontSize: 16,
      fontWeight: theme.typography.fontWeightRegular,
      textAlign: 'center',
      height: 44,
      cursor: 'pointer'
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #CCEDFA",
      borderRadius: "8px 0px 0px 8px"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #CCEDFA",
      borderRadius: "8px 0px 0px 8px"
    },
  }
})(TextField);


export const SelectButton = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiButton-label": {
        marginLeft: 15
      },
    }
  }))(Button)
  interface Styles{
    margin?:string
  }

interface CustomCheckboxProps {
  name: string
  onChange: any
  "data-test-id"?: string
  checked: boolean;
  style?:Styles;
  testID?:any
}

export const CustomCheckboxSecondary = withStyles({
  root: {
    "&$checked": {
      color: "#EA0C78",
    },
    padding: "0px",
  },
  checked: {},
})((props: CustomCheckboxProps) => {
  const classes = useStyles(); // Initialize useStyles
  return (
    <Checkbox
      checkedIcon={<span className={clsx(classes.checkedBoxIcon, classes.tickIcon)} />}
      icon={<span className={classes.checkedBoxIcon} />}
      color="default"
      {...props}
    />
  );
});

export const CustomCheckboxGrey = withStyles({
  root: {
    "&$checked": {
      color: "#334155",
    },
    padding: "0px",
  },
  checked: {},
})((props: CustomCheckboxProps) => {
  const classes = useStyles(); // Initialize useStyles
  return (
    <Checkbox
      checkedIcon={<span className={clsx(classes.greyCheckedBoxIcon, classes.greyTickIcon)} />}
      icon={<span className={classes.greyCheckedBoxIcon} />}
      color="default"
      {...props}
    />
  );
});
export const MyMapBox = styled(Box)({
  height: "100%",
  "& .mapContainer": { 
      width: "100%", 
      height: "80vh",
      "@media(max-height:490px)": {
          height: "260vh"
      },
      "@media(min-height:491px) and (max-height:550px)": {
          height: "230vh"
      },
      "@media(min-height:551px) and (max-height:650px)": {
          height: "195vh"
      },
      "@media(min-height:651px) and (max-height:700px)": {
          height: "181vh"
      },
      "@media(min-height:701px) and (max-height:750px)": {
          height: "169vh"
      },
      "@media(min-height:751px) and (max-height:800px)": {
          height: "158vh"
      },
      "@media(min-height:801px) and (max-height:850px)": {
          height: "148vh"
      },
      "@media(min-height:851px) and (max-height:900px)": {
          height: "141vh"
      },
      "@media(min-height:901px) and (max-height:950px)": {
          height: "134vh"
      },
      "@media(min-height:951px) and (max-height:1000px)": {
          height: "127vh"
      },
      "@media(min-height:1001px) and (max-height:1050px)": {
          height: "120vh"
      },
      "@media(min-height:1051px) and (max-height:1100px)": {
          height: "116vh"
      },
      "@media(min-height:1101px) and (max-height:1150px)": {
          height: "110vh"
      },
      "@media(min-height:1151px) and (max-height:1200px)": {
          height: "106vh"
      },
      "@media(min-height:1201px) and (max-height:1250px)": {
          height: "101vh"
      },
      "@media(min-height:1251px) and (max-height:1300px)": {
          height: "97vh"
      },
      "@media(min-height:1301px) and (max-height:1350px)": {
          height: "94vh"
      },
      "@media(min-height:1351px) and (max-height:1400px)": {
          height: "90vh"
      },
      "@media(min-height:1401px) and (max-height:1450px)": {
          height: "88vh"
      },
      "@media(min-height:1451px) and (max-height:1500px)": {
          height: "85vh"
      },
      "@media(min-height:1501px) and (max-height:1550px)": {
          height: "82vh"
      }
      
  },
  "& .gm-style": {
      display: "none"
  },
  "& .place-card-large": {
      display: "none"
  },
});


export const CustomPopper = (props:PopperProps) => {
  return <Popper {...props} placement="bottom-start" />;
};

import {
    createTheme,
    responsiveFontSizes
} from '@material-ui/core/styles';

export const colors = {
    darkBlack: "#334155",
    lightBlack: "",
    thinBlack: "",
    primaryBlue:"#B2E4F8",
    primaryLightBlue:"#224350",
    greyBlack:"#1E293B"
    
}

export const boxShadows = {
    blueShadow: "0px 8px 32px 0px #4386A10F,0px 4px 8px 0px #4386A108",
    whiteShadow: "0px 8px 32px 0px #0000000F,0px 4px 8px 0px #00000008"
}

export const fontWeight = {
    fontWeight300: 300,
    fontWeight400: 400,
    fontWeight500: 500,
    fontWeight600: 600,
    fontWeight700: 700,
    fontWeight800: 800,
}
const responsiveTheme = createTheme({
    palette: {
        common: {
            black: "#0F172A",
            white: "#ffffff",
        },
        primary: {
            main: "#E0F4FC",
            light: "#4386A1",
            dark: "#54A7C9",
            contrastText:"#CCEDFA",
            
        },
        secondary: {
            main: "#EA0C78",
        },
        info: {
            main: "#65C9F1",
            dark:"#326478",
        },
        warning: {
            main: "#F59E0B",
            dark: "#D97706",
            light: "#F59E0B"
        },
        error: {
            main: "#DC2626"
        },
        text: {
            primary: "#1E293B",
            secondary: "#475569",
            disabled:"#64748B"
        },
        grey: {
            100: "#475569",
        },
    },
    typography: {
        fontFamily: "Inter",
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700,
        h1: {
            fontSize: 48,
        },
        h2: {
            fontSize: 42,
        },
        h3: {
            fontSize: 30,
        },
        h4: {
            fontSize: 24,
        },
        h5: {
            fontSize: 22,
        },
        h6: {
            fontSize: 20,
        },
        subtitle1: {
            fontSize: 18,
        },
        subtitle2: {
            fontSize: 16,
        },
        body1: {
            fontSize: 14,
        },
        body2: {
            fontSize: 12
        },
        caption: {
            fontSize: 10
        }
    },
});

export const theme = responsiveFontSizes(responsiveTheme);
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  MenuItem,
  Menu,
  Button,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { Link } from "react-router-dom";


class TopNav extends Component {
  state = {
    anchorEl: null,
    mobileView: false,
    menuOpen: false,
  };

  componentDidMount() {
    if (window.innerWidth < 600) {
      this.setState({ mobileView: true });
    }
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    if (window.innerWidth < 600) {
      this.setState({ mobileView: true });
    } else {
      this.setState({ mobileView: false });
    }
  };

  handleMenuOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget, menuOpen: true });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null, menuOpen: false });
  };

  render() {
    const { anchorEl, mobileView, menuOpen } = this.state;
    const isMenuOpen = Boolean(anchorEl);

    return (
      <AppBar
        position="static"
        style={{
          color: "#334155",
          backgroundColor: "white",
          boxShadow: "none",
        }}
      >
        <Toolbar
          style={{
            display: "flex",
            justifyContent: mobileView ? "space-between" : "space-between",
          }}
        >
          {mobileView ? (
            <>
              <IconButton
                edge="start"
                color="inherit"
                onClick={this.handleMenuOpen}
              >
                <MenuIcon />
              </IconButton>
              <img
                src={require("../../components/src/LOGO_HERE.png")}
                alt="PET LOGO"
                style={{ marginRight: "30px" }}
              />

              <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                keepMounted
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                open={isMenuOpen}
                onClose={this.handleMenuClose}
              >
                <MenuItem onClick={this.handleMenuClose}>Home</MenuItem>

                <MenuItem onClick={this.handleMenuClose}>About us</MenuItem>

                <MenuItem onClick={this.handleMenuClose}>Why register</MenuItem>
                <MenuItem onClick={this.handleMenuClose}>Help</MenuItem>
                <MenuItem onClick={this.handleMenuClose}> Sign in</MenuItem>
              </Menu>
            </>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                                  }}
              >
                <img
                  src={require("../../components/src/LOGO_HERE.png")}
                  alt="PET LOGO"
                  style={{ marginRight: "30px" }}
                />

                <Button color="inherit">Home</Button>

                <Button color="inherit">About us</Button>

                <Button color="inherit">Why register</Button>
                <Button color="inherit">Help</Button>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  style={{
                    textAlign: "center",
                    fontSize: "16px",
                    marginRight: "10px",
                  }}
                >
                  Already have an account?
                </Typography>
                <Link to="/LogInBusinessOwner">
                <Button
                  style={{
                    border: "1px solid #64748B",
                    backgroundColor: "#FFFFFF",
                    color: "#64748B",
                  }}
                >
                  Sign in
                </Button>
                </Link>
              </div>
            </>
          )}
        </Toolbar>
      </AppBar>
    );
  }
}

export default withRouter(TopNav);

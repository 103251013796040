//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { baseURL } from "../../bulkuploading/src/BulkUploadingController";
interface LinkObject {
  url: string;
  title: string;
}
interface ProfileForm {
  salutation: string;
  firstName: string;
  surname: string;
  email: string;
  phoneNumberCountryCode: string;
  phoneNumber: string;
  addressLine1: string;
  addressline2: string;
  town: string;
  county: string;
  postcode: string;
  countryRegion?: string


}
import React from "react";
import { setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");


export interface Props {
  // Customizable Area Start
  id: string;
  navigation: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedFile: File | null;
  imageUrl: string | null;
  gender: string;
  fullName: string;
  email: string;
  phNoCountry: string;
  phoneNumber: string;
  country: string;
  streetAddress: string;
  flatNo: string;
  city: string;
  postCode: string;
  error: {
    [key: string]: string;
  };
  MesErrProfile: typeof configJSON.MesErrProfile;
  savedPet: any[];
  popupedit: boolean;
  hovermouse: number;
  county: string;
  surname: string;
  profileForm: ProfileForm;
  isProfileErrors: any
  navbarProfilePhoto:any,
  anchorElgendertitle:any,
  anchorPocountrycode:any,
  anchorElcounrty:any,
  socialLinksData: {icons: string, navigationUrl: string}[];
  poBOLinksData: LinkObject[];
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ContentManagementController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  profileId: string = "";
  addPetCallPoId: string = "";
  fileInputRef = React.createRef() as any;
  deletePhotoApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      selectedFile: null,
      imageUrl: null,
      gender: "mr",
      fullName: "",
      email: "",
      phNoCountry: "+44 United Kingdom",
      phoneNumber: "",
      country: "United Kingdom",
      streetAddress: "",
      flatNo: "",
      city: "",
      postCode: "",
      error: {},
      MesErrProfile: configJSON.MesErrProfile,
      savedPet: [],
      popupedit: false,
      hovermouse: 0,
      county: "",
      surname: "",
      profileForm: {
        salutation: "Mr.",
        firstName: "",
        surname: "",
        email: "",
        phoneNumberCountryCode: "United Kingdom (+44)",
        phoneNumber: "",
        addressLine1: "",
        addressline2: "",
        town: "",
        county: "",
        postcode: "",
        countryRegion: ""
      },
      isProfileErrors: {},
      navbarProfilePhoto:"",
      anchorElgendertitle:null,
      anchorPocountrycode:null,
      anchorElcounrty:null,
      socialLinksData: [],
      poBOLinksData: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.profileId) {

      this.profileDetailsApiresponce(responseJson)
    } else if (apiRequestCallId === this.addPetCallPoId) {
      if (responseJson && !responseJson.errors) {
        setStorageData('accountDetails', JSON.stringify(responseJson?.data?.attributes))
        this.props.navigation.navigate("AddPetDetails");
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  profileDetailsApiresponce = (responseJson: any) => {

    if (responseJson && !responseJson.errors) {
      const profileForm = this.profileForm(responseJson)

      this.setState({
        profileForm: profileForm,
        navbarProfilePhoto:responseJson.data.attributes.photo,
        imageUrl:responseJson.data.attributes.photo,
        country: responseJson.data.attributes.country || "United Kingdom"
      });

    } else {
      if (responseJson.errors[0]?.token === 'Invalid token') {
        this.props.navigation.navigate('LandingPage')
      }
    }
  }

  profileForm=(responseJson)=>{
     return {
      firstName: responseJson.data.attributes.full_name || "",
      surname: responseJson.data.attributes.surname || "",
      email: responseJson.data.attributes.email || "",
      phoneNumber: responseJson.data.attributes.full_phone_number || "",
      addressLine1: responseJson.data.attributes.address || "",
      addressline2: responseJson.data.attributes.flat_number || "",
      town: responseJson.data.attributes.city || "",
      county: responseJson.data.attributes.county || "",
      postcode: responseJson.data.attributes.postcode || "",
      salutation: responseJson.data.attributes.name_title || "Mr.",
      phoneNumberCountryCode:responseJson.data.attributes.phone_number_country_code||"United Kingdom (+44)" ,
    }
  }


  handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fieldName = event.target.name;
    let errorMsg = "";
    const selected = event.target.files ? event.target.files[0] : null;

    if (selected) {
      if (!selected.type.startsWith("image")) { errorMsg = `${fieldName} should be an image`; }

      this.setState((prevState) => ({
        error: {
          [`${fieldName}Error`]: errorMsg,
          ...prevState.error,
        },
        selectedFile: selected,
        imageUrl: URL.createObjectURL(selected),
      }));
    } else {
      this.setState({
        selectedFile: null,
        imageUrl: "",
      });
    }
    this.setState({ popupedit: false })
  };

  callApiForBoLogIn = () => {
    let tokenValues = localStorage.getItem("authToken");
    const headers = {
      token: tokenValues,
    };

    const loginRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.profileId = loginRequestMessage.messageId;
    loginRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/account_details`
    );

    loginRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers)
    );

    loginRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `GET`
    );
    runEngine.sendMessage(loginRequestMessage.id, loginRequestMessage);
  };

  async componentDidMount() {
    
    this.callApiForBoLogIn();
    const mySocialLinksData = await getStorageData("footerNavigationUrl", true);
    this.setState({socialLinksData: mySocialLinksData });
    const allLinksData = await getStorageData("BOandPOLinks",true)
    this.setState({poBOLinksData: allLinksData}) 
  }

  photodelete = () => {
    const deletephotoHeader = {
      "Content-Type": "application/json",
      token: localStorage.getItem("authToken")
    };

    const deleteRequestMessagePO = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    deleteRequestMessagePO.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/remove_profile_photo`
    );

    deleteRequestMessagePO.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(deletephotoHeader)
    );
    deleteRequestMessagePO.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `DELETE`
    );
    this.deletePhotoApiCallId = deleteRequestMessagePO.messageId;
    runEngine.sendMessage(deleteRequestMessagePO.id, deleteRequestMessagePO);
    this.setState({
      imageUrl: '',
      selectedFile: null,
      popupedit: false,
    })
  }

  handlepopupclose = (event: { target: any; }) => {
    if (this.fileInputRef.current && !this.fileInputRef.current.contains(event.target)) {

      this.setState({ popupedit: false })
    }
  };

 

  handleProfileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    
  
    const errors = { ...this.state.isProfileErrors };

    if (name === 'firstName') {
        this.validateNames(name, value, errors, "Full name");
    } else if (name === 'surname') {
        this.validateNames(name, value, errors, "Surname");
    } else if (name === "email") {
        this.validateEmail(name, value, errors);
    } else if (name === 'phoneNumber') {
        this.numberCheck(name, value, errors, 'Phone number');
    } else if (name === 'addressLine1') {
        this.numCharCheck(name, value, errors, 'Address line 1');
    } else if (name === 'addressline2') {
        this.validateCounty(name, value, errors, 'Address Line 2');
    } else if (name === 'town') {
        this.validateNames(name, value, errors, 'Town');
    } else if (name === 'county') {
        this.validateCounty(name, value, errors, 'County');
    } else if (name === 'postcode') {
        this.numCharCheck(name, value, errors, 'Postcode');
    }

    
    this.setState({
        profileForm: { ...this.state.profileForm, [name]: value },
        
        isProfileErrors: errors
    });
}


validateNames = (name, value, errors, fieldName) => {
    if (!value) {
        errors[name] = `${fieldName} is required`;
    } else if (!/^[A-Za-z]+$/.test(value)) {
        errors[name] = `${fieldName} must contain only letters`;
    } else {
        delete errors[name];
    }
}

validateEmail = (name, value, errors) => {
  const regexEmail = configJSON.emailRegex
    if (!value) {
        errors[name] = "Email is required.";
    } else if (!regexEmail.test(value)) {
        errors[name] = "Email is not valid.";
    } else {
        delete errors[name];
    }
}

numberCheck = (name, value, errors, fieldName) => {
  const regexNumbers = configJSON.numerRegex
    if (!value) {
        errors[name] = `${fieldName} is required`;
    } else if (!regexNumbers.test(value)) {
        errors[name] = `${fieldName} must contain only numbers.`;
    } else {
        delete errors[name];
    }
}

numCharCheck = (name, value, errors, fieldName) => {
  
    if (!value) {
        errors[name] = `${fieldName} is required`;
    } else if (value.length < 5) {
        errors[name] = `${fieldName} must be at least 5 characters long.`;
    } else {
        delete errors[name];
    }
}

validateCounty = (name, value, errors, fieldName) => {
    if (!value) {
        errors[name] = `${fieldName} is required.`;
    } else {
        delete errors[name];
    }
}




  


  submitProfileForm = () => {
    let errors: any = {}
    this.validateNames('firstName', this.state.profileForm.firstName, errors, "Firstname")
    this.validateNames("surname", this.state.profileForm.surname, errors, "Surname")
    this.numberCheck("phoneNumber", this.state.profileForm.phoneNumber, errors, 'Phone number')
    this.numCharCheck("addressLine1", this.state.profileForm.addressLine1, errors, 'Address line 1')
    this.validateNames("town", this.state.profileForm.town, errors, 'Town')
    this.numCharCheck("postcode", this.state.profileForm.postcode, errors, 'Postcode')
    this.setState({ isProfileErrors: errors }, () => {
      if (Object.keys(this.state.isProfileErrors).length === 0) {
        this.submitProfileApi()
      }
    })
  }
  submitProfileApi = () => {

    let selectedProfilePoId = sessionStorage.getItem("profileIdPo");
    let tokenValue = localStorage.getItem("authToken");
    const headers = {
      token: tokenValue,
    };
    let formdata = new FormData();
    formdata.append("data[name_title]", this.state.profileForm.salutation);
    formdata.append("data[full_name]", this.state.profileForm.firstName);
    formdata.append('data[surname]', this.state.profileForm.surname)
    formdata.append("data[email]", this.state.profileForm.email);
    formdata.append("data[phone_number_country_code]", this.state.profileForm.phoneNumberCountryCode);
    formdata.append("data[full_phone_number]", this.state.profileForm.phoneNumber);
    // Address Section
    formdata.append("data[address]", this.state.profileForm.addressLine1);
    formdata.append("data[flat_number]", this.state.profileForm.addressline2);
    formdata.append("data[city]", this.state.profileForm.town);
    formdata.append('data[county]', this.state.profileForm.county)
    formdata.append("data[postcode]", this.state.profileForm.postcode);
    formdata.append("data[country]", this.state.country);
    this.state.selectedFile &&
      formdata.append("data[photo]", this.state.selectedFile);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/${selectedProfilePoId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    this.addPetCallPoId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
    sessionStorage.setItem("userName", this.state.fullName.charAt(0));
  };


  handlesalutationpoopen= (event: React.MouseEvent<HTMLDivElement>)=>{
    this.setState({anchorElgendertitle:event.currentTarget})
 }

 salutationpoClose=(value: any) => {
  this.setState({
    anchorElgendertitle: null,profileForm:{...this.state.profileForm,salutation:value}
  })
}


BoCountryOpen = (event: React.MouseEvent<HTMLDivElement>) => {
  this.setState({ anchorPocountrycode: event.currentTarget});
}

handelBoCloses = (value1: any, code1: any) => {
  const countryCode1 = code1 ===""? "(+44)":code1;
  const selectedValue1 = value1===""? "United Kingdom":value1;
  const phNoCountry1 = selectedValue1 + countryCode1;

  this.setState({
    profileForm:{...this.state.profileForm,phoneNumberCountryCode:phNoCountry1} ,
    anchorPocountrycode: null
  });
};

handleCountryOpen = (event: React.MouseEvent<HTMLDivElement>) => {
  this.setState({ anchorElcounrty: event.currentTarget});
}

countryCloses = (value1: any) => {
  const countryCode1 =  value1 === '' ? "United Kingdom":value1;

  this.setState({
    country: countryCode1,
    anchorElcounrty: null
  });
};

  // Customizable Area End
}

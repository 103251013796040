import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";

// Customizable Area Start
interface LogInResponseForBO {
  meta?: {
    token: string;
    success: string;
  };
  data?: {
    attributes: {
      email: string;
    };
  };
  error?: string;

  errors?:
    | {
        account?: string;
      }[]
    | { password_confirmation?: string[]; account?: string };
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  id: string;
}

export interface S {
  // Customizable Area Start
  email: string;
  apiErrorLogInForBO: string;
  resetPasswordSucess: string;
  resetPopUp: boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class ResetPasswordBoController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  resetCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AccoutLoginSuccess)
      // Customizable Area End
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start

      email: "",
      apiErrorLogInForBO: "",
      resetPasswordSucess: "",
      resetPopUp: false
      // Customizable Area End
    };

    // Customizable Area Start

    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.resetCallId) {
      this.handleResForSignUp(responseJson);
    }

    // Customizable Area End
  }
  // Customizable Area Start
  handleResForSignUp = (responseJson: any) => {
    if (responseJson) {
      this.setState({
        resetPopUp: true,
        apiErrorLogInForBO: "",
        email: ""
      });
    } else if (Array.isArray(responseJson.errors)) {
      if (responseJson.errors[0].account) {
        this.setState({
          apiErrorLogInForBO: responseJson.errors[0].account
        });
      }
    } else if (responseJson.error) {
      this.setState({ apiErrorLogInForBO: responseJson.error });
    }
  };
  handleResetClose = () => {
    this.setState({
      resetPopUp: false
    });
  };
  getEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      email: event.target.value
    });
  };

  callApiForBoResetPassword = () => {
    const loginRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    loginRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_forgot_password/forgot_password`
    );
    this.resetCallId = loginRequestMessage.messageId;

    loginRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({})
    );
    let dataBusinessOwner = new FormData();
    dataBusinessOwner.append("email", this.state.email);
    dataBusinessOwner.append(
      "reset_link_url",
      window.location.origin + "/ForgotPasswordBusinessOwner"
    );
    loginRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      dataBusinessOwner
    );

    loginRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(loginRequestMessage.id, loginRequestMessage);
  };
  closeresetPopUpBo=(status:boolean)=>{
this.setState({resetPopUp:status})
  }
  // Customizable Area End
}

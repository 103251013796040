import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import { catsDogsImage, catsImage, dogImage, ratingStarIcon, whiteStarIcon } from "./assets";
import React from "react";

export interface CategoriesSubCategories {
    id: number,
    name: string
    image: string,
    activeStatus: boolean
}

export interface AdditionalService {
    id: number | string;
    type: string;
    attributes: {
        id: string;
        service_name: string;
    },
    hidden?: boolean

}

export interface RatingValues {
    id: number,
    value: number,
    icon: string,
    selectedIcon: string,
    activeStatus: boolean
}

export interface SortFilters {
    id: number,
    name: string,
    value: string,
    type: string
}
interface Filters {
    id: number,
    title: string,
    value: string,
    hidden: boolean
}
export interface List {
    number: string;
    heading: string;
    description: string

}

interface FilterOption {
    keys: string;
    value: string;
}

interface FiltersList {
    popular: FilterOption;
    sortBy: FilterOption;
    otherOptions: FilterOption;
    rating: FilterOption;
    sorting: FilterOption;
    budject: FilterOption
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
    navigation: any;
    id: string;
    // Customizable Area Start
    getApiFiltersEndPoint: Function
    // showGoogleMap
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    popularFiltersData: Filters[];
    otherOtionFiltersData: AdditionalService[];
    budjectSliderRange: number[];
    ratingData: RatingValues[];
    selectedRating: number;
    mapCheckStatus: boolean;
    hotelsLoading: boolean;
    newPopularFilter: string;
    filters: FiltersList;
    filterType: string;
    sortByFilters: SortFilters[];
    selectedSortType: string
    sortFilterStatus: boolean,
    selectedSortedTypeFilter: string;
    categories: CategoriesSubCategories[]
    services: CategoriesSubCategories[]
    step: number,
    originalOthetOptionFiltersData: AdditionalService[],
    selectedFilter: string
    showGoogleMap: boolean
    // Customizable Area End
}



interface SS {
    id: any;
}

export default class CategoriesPOBOController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    apiCallIdGetAdditionalServicesList: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.SelectMap),


            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            showGoogleMap: false,
            popularFiltersData: [
                { id: 1, title: "Dog Hotel", value: "dog_hotel", hidden: false },
                { id: 2, title: "Cat Hotel", value: "cat_hotel", hidden: false },
                { id: 3, title: "Dog & Cat Hotel", value: "dog_cat_hotel", hidden: false },
                { id: 4, title: "Doggy Daycare", value: "daycare_services", hidden: false }
            ],
            otherOtionFiltersData: [],
            budjectSliderRange: [0, 200],
            ratingData: [
                {
                    id: 1,
                    value: 1,
                    icon: ratingStarIcon,
                    selectedIcon: whiteStarIcon,
                    activeStatus: false
                },
                {
                    id: 2,
                    value: 2,
                    icon: ratingStarIcon,
                    selectedIcon: whiteStarIcon,
                    activeStatus: false,

                },
                {
                    id: 3,
                    value: 3,
                    icon: ratingStarIcon,
                    selectedIcon: whiteStarIcon,
                    activeStatus: false
                },
                {
                    id: 4,
                    value: 4,
                    icon: ratingStarIcon,
                    selectedIcon: whiteStarIcon,
                    activeStatus: false

                },
                {
                    id: 5,
                    value: 5,
                    icon: ratingStarIcon,
                    selectedIcon: whiteStarIcon,
                    activeStatus: false
                }
            ],
            selectedRating: 0,
            mapCheckStatus: false,
            hotelsLoading: true,
            newPopularFilter: "",
            filters: {
                popular: {
                    keys: "popular_filters",
                    value: ""
                },
                sortBy: {
                    keys: "filter",
                    value: ""
                },
                otherOptions: {
                    keys: "filter_additional_services",
                    value: ""
                },
                rating: {
                    keys: "rating",
                    value: ""
                },
                budject: {
                    keys: "budget_per_day",
                    value: ""
                },
                sorting: {
                    keys: "",
                    value: ""
                }
            },
            filterType: "",
            sortByFilters: [
                {
                    id: 1,
                    name: "Price (Low to High)",
                    value: "budget_per_day",
                    type: 'asc'
                },
                {
                    id: 2,
                    name: "Price (High to Low)",
                    value: "budget_per_day",
                    type: "desc"
                },
                {
                    id: 3,
                    name: "Property rating (Low to High)",
                    value: "rating",
                    type: 'asc'
                },
                {
                    id: 4,
                    name: "Property rating (High to Low)",
                    value: "rating",
                    type: 'desc'
                },
            ],
            selectedSortType: "Nearby properties",
            sortFilterStatus: false,
            selectedSortedTypeFilter: "",
            categories: [
                {
                    id: 1,
                    name: 'Dogs',
                    image: dogImage,
                    activeStatus: false,

                },
                {
                    id: 2,
                    name: "Cats",
                    image: catsImage,
                    activeStatus: false,

                },
                {

                    id: 3,
                    name: "Dogs & Cats",
                    image: catsDogsImage,
                    activeStatus: false,

                }
            ],
            services: [],
            step: 0,
            originalOthetOptionFiltersData: [],
            selectedFilter: ""


            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    async componentDidMount() {
        const url = new URL(window.location.href);
        const searchParams = url.searchParams;
        const tapPaymentCargeId = searchParams.get("dayCareService");
        this.handleSelectSortFilter(this.state.sortByFilters[3]);
        if (tapPaymentCargeId) {
            this.handlePopularFilters(4);
        }
        this.getAdditionalServices()

    }
    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
            this.handleNavigationPayload(message);
        }
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        if (apiRequestCallId === this.apiCallIdGetAdditionalServicesList) {
            if (responseJson && !responseJson.errors) {
                let filtersData = responseJson?.data.map((list: AdditionalService) => {
                    return {
                        id: list.id,
                        type: list.type,
                        attributes: {
                            id: list.attributes.id,
                            service_name: list.attributes.service_name
                        },
                        hidden: false
                    }
                }
                )

                this.setState({
                    originalOthetOptionFiltersData: responseJson?.data,
                    otherOtionFiltersData: filtersData
                });
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start

    handlePopularFilters = (id: number) => {
        const newQuoteToggleData = this.state.popularFiltersData.map((item: Filters) => {
            if (item.id === id) {
                return { ...item, hidden: !item.hidden };
            }
            return item
        })

        let newArray = newQuoteToggleData.filter((item: Filters) => item.hidden).map((item: Filters) => `popular_filters[]=${item.value}`);
        const array = newArray.length >= 1 ? newArray.join('&') : ""
        const someThing = this.state.filters
        someThing["popular"]['value'] = array
        this.setState({
            popularFiltersData: newQuoteToggleData,
            newPopularFilter: array,
            filterType: "popular",
            filters: someThing
        }, () => {
            this.setParamsApi()
        });
    }
    setParamsApi = () => {
        let paramsString = "";
        (Object.keys(this.state.filters) as Array<keyof FiltersList>).forEach((element) => {
            if (this.state.filters[element].value) {
                paramsString += paramsString ? `&${this.state.filters[element].value}` : this.state.filters[element].value
            }
        })
        this.props.getApiFiltersEndPoint(paramsString)

    }

    handleOtherOptionFilters = (id: number) => {
        const filtersNewData = this.state.otherOtionFiltersData.map((item: AdditionalService) => {

            if (item.id === id) {
                return { ...item, hidden: !item.hidden };
            }
            return item

        })
        let newArray = filtersNewData.filter((item: AdditionalService) => item.hidden).map((item: AdditionalService) => `other_options[]=${item.attributes.id}`);
        const array = newArray.length >= 1 ? newArray.join('&') : ""
        const someThing = this.state.filters
        someThing["otherOptions"]['value'] = array
        this.setState({
            otherOtionFiltersData: filtersNewData,
            newPopularFilter: array,
            filterType: "otherOptions",
            filters: someThing
        }, () => {
            this.setParamsApi()
        });
    }
    handlebudjectSlider = (event: any, newRange: number | number[]) => {
        this.setState({ budjectSliderRange: newRange as number[] })
        const budjetSlider = this.state.filters
        budjetSlider["budject"]['value'] = `min_budget_per_day=${this.state.budjectSliderRange[0]}&max_budget_per_day=${this.state.budjectSliderRange[1]}`
        this.setParamsApi()
    }


    handleRating = (rating: RatingValues) => {
        const newratingData = this.state.ratingData.map((item: RatingValues) => {

            if (rating.id === item.id) {
                return { ...item, activeStatus: !item.activeStatus };
            }
            return item
        })

        let newRatingArray = newratingData.filter((item: RatingValues) => item.activeStatus).map((item: RatingValues) => `rating[]=${item.value}`)
        const array = newRatingArray.length >= 1 ? newRatingArray.join('&') : ""
        const someThing = this.state.filters
        someThing["rating"]['value'] = array
        this.setState({ ratingData: newratingData, selectedRating: rating.id }, () => {
            this.setParamsApi()
        })
    }

    handleMapChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ mapCheckStatus: event.target.checked });

        const msg: Message = new Message(getName(MessageEnum.SelectMap));
        msg.addData(getName(MessageEnum.SelectedMapData), event.target.checked);
        this.send(msg);

    };

    clearFilters = () => {
        const clearFilter = { ...this.state.filters };

        // Use forEach to iterate over the keys and reset the value
        (Object.keys(this.state.filters) as Array<keyof FiltersList>).forEach((element) => {
            clearFilter[element].value = '';
        });
        const newratingData = this.state.ratingData.map((item: RatingValues) => {

            item.activeStatus = false
            return item
        })
        const newPopularFilterData = this.state.popularFiltersData.map((item: Filters) => {

            item.hidden = false
            return item
        })
        const newOtherOtionFiltersData = this.state.otherOtionFiltersData.map((item: AdditionalService) => {

            item.hidden = false
            return item
        })
        this.setState({
            budjectSliderRange: [0, 200],
            mapCheckStatus: false,
            selectedSortedTypeFilter: "",
            filters: clearFilter,
            ratingData: newratingData,
            popularFiltersData: newPopularFilterData,
            otherOtionFiltersData: newOtherOtionFiltersData,

        }, () => {
            this.setParamsApi()
        })
    }

    // Sorting filter functions

    filterModalOpen = () => {
        this.setState({
            sortFilterStatus: true
        })
    }

    handlesortFilterClose = () => {
        this.setState({
            sortFilterStatus: false
        })
    }

    handleSelectSortFilter = (item: SortFilters) => {
        const sortingValue = this.state.filters
        sortingValue["sorting"]['value'] = `sort_type=${item.value}&sort_order=${item.type}`
        this.setState({
            selectedSortType: item.name,
            selectedSortedTypeFilter: item?.value,
            sortFilterStatus: false
        }, () => {
            this.setParamsApi()
        })
    }

    handleGetStarted = () => {
        this.setState(prevState => ({
            step: prevState.step + 1
        }))
    }
    handleBack = () => {
        this.setState(prevState => ({
            step: prevState.step - 1
        }))
    }

    getAdditionalServices = () => {
        const loginHeader = {
            "Content-Type": "application/json"
        };

        const message = new Message(getName(MessageEnum.RestAPIRequestMessage));

        this.apiCallIdGetAdditionalServicesList = message.messageId;
        message.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.additionalServicesEndPoint);

        message.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(loginHeader)
        );

        message.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetType
        );
        runEngine.sendMessage(message.id, message);
    };

    handleNavigationPayload = (message: Message) => {
        const selectedDetails = message.getData(getName(MessageEnum.HotelDetails));

        this.setState({ selectedFilter: selectedDetails.filterType }, () => {
            if (this.state.selectedFilter === 'dog_hotel') {
                this.handlePopularFilters(1)
            }
            else if (this.state.selectedFilter === 'cat_hotel') {
                this.handlePopularFilters(2)
            }
            else if (this.state.selectedFilter === 'dog_cat_hotel') {
                this.handlePopularFilters(3)
            }
        })

    }
    // Customizable Area End
}

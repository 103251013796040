// Customizable Area Start
export const emailIcon=require('../assets/email.png')
export const setingsIcon=require('../assets/settings.png')
export const searchIcon=require('../assets/search.svg')
export const cancelIcon=require('../assets/cancel.png')
export const addIcon=require('../assets/addPlus.svg');
export const minusIcon=require('../assets/minusPlus.svg')
export const expandMore=require('../assets/expandMore.png')
export const expandLess=require('../assets/expandLess.png')
export const greenArrow=require('../assets/greenDown.png')
export const redArrow=require('../assets/redDown.png')
// Customizable Area End
export const Dog1 = require("../assets/Dog1.png");
export const Dog = require("../assets/Dog.svg");
export const Cat = require("../assets/Cat.png");
export const IconSearch = require("../assets/search.svg");
export const Cat1 = require("../assets/CAT1.png");
export const Dog11 = require("../assets/DOG3.png");
export const Dog2 = require("../assets/DOG2.png");
export const landingPage=require('../assets/landingPage.png')
export const search=require('../assets/search.png');
export const location=require('../assets/location.svg');
export const menuIcon=require('../assets/menuIcon.png');
export const crossIcon=require('../assets/crossIcon.png');
export const profileIcon=require('../assets/profileIcon.png');
export const landingPageMobile=require('../assets/landingPageMobile.png')
export const dotBlue=require('../assets/dotBlue.svg')
export const dotBlack=require('../assets/dotBlack.svg')
export const fb=require('../assets/fb.png')







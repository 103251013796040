import React from "react";
import { withStyles, makeStyles, Theme, createStyles, WithStyles } from "@material-ui/core/styles";
import { Slider, Checkbox, TextField, MenuItem, Radio, RadioProps, NativeSelect } from "@material-ui/core";
import Switch, { SwitchClassKey, SwitchProps } from "@material-ui/core/Switch";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Menu, { MenuProps } from "@material-ui/core/Menu";
import { Rating } from "@material-ui/lab";
import StarBorderIcon from '@material-ui/icons/StarBorder';
const pxToRem = (px: number, oneRemPx = 16) => `${px / oneRemPx}rem`;
const spacing = 8;
const size = pxToRem(28);
const width = pxToRem(48 + 2 * spacing);
const borderWidth = 2;
const height = `calc(${size} + ${borderWidth * 2}px + ${pxToRem(2 * spacing)})`;

interface RatingProps {
  rating?: number
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 300 + theme.spacing(3) * 2
  },
  margin: {
    height: theme.spacing(3)
  },
  ratingEmptyIcon: {
    color: theme.palette.warning.main
  }

}));

export const CustomizedSlider = withStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.info.main,
      height: 20,
      padding: "13px 0"
    },
    thumb: {
      height: 27,
      width: 27,
      backgroundColor: "#ffffff",
      border: `6px solid ${theme.palette.primary.light}`,
      marginTop: -10,
      marginLeft: -13,
      "& .bar": {
        height: 9,
        width: 2,
        backgroundColor: "#ffffff",
        marginLeft: 1,
        marginRight: 1
      }
    },
    active: {},
    track: {
      height: 12
    },
    rail: {
      color: theme.palette.primary.main,
      opacity: 1,
      height: 12,
      borderRadius: 32
    },
    markLabel: {
      fontSize: 12,
      color: '#64748B',
      top: -20,
      fontFamily: "Inter",
      fontWeight: 400
    },
    markLabelActive: {
      fontSize: 12,
      color: "#1E293B",
      fontFamily: "Inter",
      fontWeight: 700
    }
  }))(Slider);

export const StyledCheckbox: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "20px",
      width: "20px",
      color: theme.palette.info.dark,
      borderRadius: 5,
      "& .MuiCheckbox-colorSecondary.Mui-checked": {
        color: theme.palette.info.dark
      },
      "& .MuiSvgIcon-root": {
        fontSize: "2rem"
      }
    }
  }))(Checkbox);


export const CustomizedTextField = withStyles({
  root: {
    "& .MuiInputBase-root": {
      fontFamily: "Inter",
      color: "#000000",
      fontWeight:700,
      fontSize:14,
      ' &:hover': {
        backgroundColor: "#E0F4FC",
        borderRadius: 8
      }
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none"
    },
    "& .MuiOutlinedInput-root:hover": {
      // borderColor: "rgba(0, 0, 0, 0.23)",
      border: "none"
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "#E0F4FC"
    },
    "& .MuiSvgIcon-root": {
      fontSize: "25px"
    }
  }
})(TextField);

export const CustomizedTextFieldAutoComplete = withStyles({
  root: {
    "& .MuiInputBase-root": {
      fontFamily: "Inter",
      color: "#000000",
      backgroundColor: "#ffffff",
      height: "30px"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none"
    },
    "& .MuiOutlinedInput-root:hover": {
      // borderColor: "rgba(0, 0, 0, 0.23)",
      border: "none"
    },
    "& .MuiSvgIcon-root": {
      // fontSize: "2rem"
      display: 'none'
    }
  }
})(TextField);

export const StyledMenuItem = withStyles((theme) => ({
  root: {
    marginTop: "5px",
    padding: "0.6rem",
    '&:hover': {
      backgroundColor: "#E0F4FC",
      borderRadius: 8
    },
    '&:focus': {
      backgroundColor: "#E0F4FC",
      borderRadius: 8
    },
  },
}))(MenuItem);


interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

interface Props extends SwitchProps {
  classes: Styles;
}

export const CustomizedSwitch = withStyles((theme: Theme) =>
createStyles({
  root: {
    width,
    height,
    padding: pxToRem(spacing),
    margin: 0,
  },
  switchBase: {
    padding: borderWidth,
    top: pxToRem(spacing),
    left: pxToRem(spacing),
    "&$checked": {
      transform: `translateX(calc(${width} - ${size} - ${
        borderWidth * 2
      }px - ${pxToRem(2 * spacing)}))`,
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#326478",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
    },
  },
  thumb: {
    width: 28,
    height: 28,
  },
  track: {
    borderRadius: 40,
    borderWidth,
    backgroundColor: "#CCEDFA",
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
})
)(Switch);


export const StyledRadio = withStyles({
  root: {
    color: "#224350",
    '&$checked': {
      color: "#224350",
    },
  },
  checked: {},
})((props: RadioProps) =>


  <Radio color="default" {...props} />
);

export const CustomSelect = withStyles({
  root: {
    height: "20px",
    background: "#E0F4FC",
    color: "#64748B",
    fontSize: "16px",
    padding: "9px",
    fontWeight: 500,
    width: "100%",

  },
  
})((props:any) => <NativeSelect {...props} IconComponent={ExpandMoreIcon}/>);



export const StyledMenu = withStyles(() => ({}))((props: MenuProps & WithStyles) => (
  <Menu
    {...props}
    getContentAnchorEl={null}
    anchorOrigin={{
      horizontal: "center",
      vertical: "bottom",
    }}
    transformOrigin={{
      horizontal: "center",
      vertical: "top",
    }}
    PaperProps={{
      style: {
        borderRadius: "8px",
        width: "389px",
        maxHeight: "400px",
        marginRight: "200px",
        marginTop: "10px",
      },
    }}
    disableScrollLock={false}
  />
));


export const StyledMenuPet = withStyles(() => ({}))(
  (props: MenuProps & WithStyles) => (
    <Menu
      {...props}
      getContentAnchorEl={null}
      transformOrigin={{
        horizontal: "center",
        vertical: "top",
      }}
      anchorOrigin={{
        horizontal: "center",
        vertical: "bottom",
      }}
      PaperProps={{
        style: {
          maxHeight: "400px",
          marginTop: "10px",
          width: "300px",
          borderRadius: "8px",
          padding:"1%"
        },
      }}
      disableScrollLock={false}
    />
  )
);

export const StyledMenuPetInOut = withStyles((theme) => ({
  paper: {
    marginRight: '200px',
    width: '505px',
    marginTop: '10px',
    borderRadius: '8px',
    position: 'fixed',
  },
  list: {
    padding: '0px',
  },
}))((props: MenuProps & WithStyles) => (
  <Menu
    {...props}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    disableScrollLock={false}
  />
));


export default function CustomRating(props: RatingProps) {
  const classes = useStyles();
  return (
    <Rating precision={0.5} readOnly value={props.rating}
      emptyIcon={<StarBorderIcon fontSize="inherit" className={classes.ratingEmptyIcon} />}
    />
  )
}
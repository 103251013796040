import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { baseURL } from "../../bulkuploading/src/BulkUploadingController";
import { Message } from "../../../framework/src/Message";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";

interface LinkObject {
  url: string;
  title: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  id: string;
  navigation: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  activeStep: number;
  imageUrl: string;
  fullName: string;
  stepdata:any;
  firstLoader:boolean
  socialLinksData: {icons: string, navigationUrl: string}[];
  poBOLinksData: LinkObject[];
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class BOwnerDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  profileIds: string = "";
  profilestep:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      activeStep: 0,
      imageUrl:"",
      fullName:"",
      stepdata:[],
      firstLoader:true,
      socialLinksData: [],
      poBOLinksData: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (apiRequestCallId === this.profileIds) {
      this.setState({
        imageUrl: responseJson?.data?.attributes?.photo  ,
        fullName: responseJson.data?.attributes?.full_name,
      })
     
    } 

    if (apiRequestCallId === this.profilestep) 
    {

      this.setState({stepdata:responseJson.data,firstLoader:false})
      this.callApiForProgile()
      
    } 
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    this.callApiForStep()
    const mySocialLinksData = await getStorageData("footerNavigationUrl", true);
    this.setState({socialLinksData: mySocialLinksData });
    const allLinksData = await getStorageData("BOandPOLinks",true)
    this.setState({poBOLinksData: allLinksData})
  }

  handleNext = () => {
    this.setState(prevState => ({ activeStep: prevState.activeStep + 3 }));
    this.props.navigation.navigate("BussinessOwnerDetailsFirst");
  };

  callApiForProgile = () => {
    let token = localStorage.getItem("authToken");
    const headers = {
      token: token,
    };

    const loginRequestMessages = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.profileIds = loginRequestMessages.messageId;
    loginRequestMessages.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/account_details`
    );

    loginRequestMessages.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    loginRequestMessages.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(loginRequestMessages.id, loginRequestMessages);
  };


  callApiForStep = () => {
    
    const loginRequestMessages = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.profilestep = loginRequestMessages.messageId;
    loginRequestMessages.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/pet_hotel_steps`
    );

    loginRequestMessages.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(loginRequestMessages.id, loginRequestMessages);
  };

  handleBack = () => {
    this.setState(prevState => ({ activeStep: prevState.activeStep - 3 }));
  };
  // Customizable Area End
}

import React from "react";
// Customizable Area Start
import { Divider, Container, Button, Typography, Grid, Box, Radio, FormControlLabel, MenuItem, Checkbox, Snackbar, IconButton } from "@material-ui/core";
import { Link } from "react-router-dom";
import {
  markerIconPng,
  Location,
  Spa1,
  FarmeDog,
  FarmeCat,
  Profile1,
  ShowAllPhoto,
  Payment,
  FarmeDate, Dog, Cat, locationMap, backIcon, dummyImage, eye, Pickup, update, Birthday, Train, Water
} from "./assets";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { Alert } from "@material-ui/lab";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Theme, createStyles, withStyles, makeStyles, styled } from "@material-ui/core/styles"
import PetDetailsPageController, {
  Props,
  SelectedHotel
} from "./PetDetailsPageController.web";
import Loader from "../../../components/src/Loader.web";
import CustomizedDailog from "../../../components/src/CustomizedDailog.web";
import { boxShadows, theme } from "../../../components/src/theme";
import { Calendar } from "react-multi-date-picker";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Footer from '../../../components/src/Footer.web'
import CustomRating from "../../../components/src/CustomizedComponents.web";
import Navbar from "../../../components/src/Navbar";
import CustomDate from "../../../components/src/CustomizeCalenderPo";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { StyledMenuPet } from "../../../components/src/CustmizeAddPetModel";
import ReviewsPo from "../../reviews/src/ReviewsPo.web";
import Addreview from "../../reviews/src/AddReview.web";
import { errorImage } from "../../../components/src/CommonFunctions";
import CloseIcon from '@material-ui/icons/Close';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import CustomizedSnackbars from "../../../components/src/CustomSnackbar.web";
import Maps from "../../../components/src/Maps";
import DayCareServiceModal from "../../../components/src/DayCareService";
import { MapContainer, TileLayer } from "react-leaflet";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
export const configJSON = require("./config");
import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const useStyles = makeStyles({
  root: {
    color: 'default',
    '&$checked': {
      color: 'red',
    },
  },
  checked: {},
});

export interface CustomRadioProp {
  checked: boolean;
  onChange: () => void;
  // Add any other props you may want to accept
}

export const CustomRadio: React.FC<CustomRadioProp> = ({ checked, onChange, ...props }) => {
  const classes = useStyles();

  return (
    <Radio
      classes={{
        root: classes.root,
        checked: classes.checked,
      }}
      color="default"
      disableRipple
      checkedIcon={
        <span
          style={{
            borderRadius: '50%',
            height: 7,
            backgroundColor: 'white',
            border: '6px solid #224350',
            display: 'inline-block',
            width: 7,
          }}
        />
      }
      icon={
        <span
          style={{
            borderRadius: '50%',
            border: '1px solid #224350',
            display: 'inline-block',
            height: 16,
            width: 16,
          }}
        />
      }
      checked={checked}
      onChange={onChange}
      {...props}
    />
  );
};
// Customizable Area End

export class PetDetailsPage extends PetDetailsPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  additionalServices = () => {
    const { additionalServe } = this.state;

    return (
      <>
        <div style={compt8.modalStyle as React.CSSProperties}>

          <div>
            <div style={{ marginBottom: "20px" }}>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: 400,
                  fontFamily: "inter",
                }}
                data-test-id="popupHeadingTestId"
              >
                {this.state.additionalServicesPet.length > 0 ? this.state.additionalServe : "Services Does Not Exist."}
              </Typography>
            </div>
            {this.state.additionalServicesPet.map((service, index) => (
              <div key={index}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "3%",
                    backgroundColor: this.state.checkedServices.includes(service.name) ? "#E0F4FC" : "#fff",
                    borderBottom: "1px solid #326478",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Checkbox
                      onChange={(event) => this.handleCheckboxChange(event, additionalServe, service.price, service.additional_service_id, service.id, service.service_provided_type === configJSON.daily, service.charge_type==="All")}
                      value={`${service.name}`}
                      data-test-id={`handleCheckboxChange_${index}`}
                      inputProps={{
                        "aria-label": "checkbox with default color",
                      }}
                      checked={this.state.checkedServices.includes(service.name)}
                      color="default"
                      style={{ padding: "0px" }}
                    />
                    <Typography
                      style={{
                        marginLeft: "7px",
                        fontSize: "14px",
                        fontWeight: 400,
                        fontFamily: "inter",
                        color: "#475569"
                      }}
                    >
                      {`${service.name} (${service.service_provided_type === "OneTime" ? "Onetime": "Daily"})`}
                    </Typography>
                  </div>
                  <Typography
                    style={{
                      fontSize: "14px",
                      fontWeight: 700,
                      fontFamily: "inter",
                    }}
                  >
                    {`£ ${service.price}`}
                  </Typography>
                </div>
              </div>
            ))}
            {this.state.additionalServicesPet.length > 0 && <div
              style={{
                textAlign: "right",
                margin: "5% 0px 0px",
                cursor: "pointer",
                textDecoration: "underline",
                fontSize: "16px",
                fontWeight: 700,
                fontFamily: "inter",
                color: "#EA0C78"
              }}
              onClick={this.addAdditionalService}
            >
              Continue
            </div>}
          </div>
        </div>
      </>
    )
  }

  getPetTypeDescription(petType: string) {
    if (petType === "Double_Suite") {
      return "Perfect for Pairs";
    } else if (petType === "Single_Suite") {
      return "Tailored Comfort for One:";
    } else {
      return "Extravagance for Extra Large Groups:";
    }
  }

  getIcon(name: string = "") {
    let nam = name.toLowerCase()
    if (nam.includes("spa")) {
      return Water
    } else if (nam.includes("dogs")) {
      return Spa1
    } else if (nam.includes("pick")) {
      return Pickup
    } else if (nam.includes("webcam")) {
      return eye
    } else if (nam.includes("pet")) {
      return update
    } else if (nam.includes("birthday")) {
      return Birthday
    } else if (nam.includes("training")) {
      return Train
    }
  }

  duplicateAdditionalService = (catService: any, dogService: any) => {
    let allServices = [...catService, ...dogService];
    let duplicates = [];
    let val: any = [];
    for (let i = 0; i < allServices.length; i++) {
      if (val.includes(allServices[i].hotel_additional_service_id)) {
        duplicates.push(allServices[i]);
      } else {
        val.push(allServices[i].hotel_additional_service_id)
      }
    }
    return duplicates
  }
  duplicateAdditionalServiceIds = (catService: any, dogService: any) => {
    let allServices = [...catService, ...dogService];
    let duplicates = [];
    let val: any = [];
    for (let i = 0; i < allServices.length; i++) {
      if (val.includes(allServices[i].hotel_additional_service_id)) {
        duplicates.push(allServices[i].hotel_additional_service_id);
      } else {
        val.push(allServices[i].hotel_additional_service_id)
      }
    }
    return duplicates
  }
  calculateSliceRange(isSingleSuite: boolean, isDoubleSuite: boolean) {
    let sliceStart: number | undefined;
    let sliceEnd: number | undefined;

    if (isSingleSuite) {
      sliceStart = 0;
      sliceEnd = 2;
    } else if (isDoubleSuite) {
      sliceStart = 2;
      sliceEnd = 5;
    } else {
      sliceStart = 4;
      sliceEnd = undefined;
    }

    return { sliceStart, sliceEnd };
  }

  indexToWord = (index: number) => {
    const numberWords = [
      'one', 'two', 'three', 'four', 'five',
      'six', 'seven', 'eight', 'nine', 'ten'
    ];

    if (index >= 0 && index < numberWords.length) {
      return numberWords[index];
    } else {
      return `${index + 1}th`;
    }
  };

  indexToWord1 = (index: number) => {
    const numberWords = [
      'One', 'Two', 'Three', 'Four', 'Five',
      'Six', 'Seven', 'Eight', 'Nine', 'Ten'
    ];

    if (index >= 0 && index < numberWords.length) {
      return numberWords[index];
    } else {
      return `${index + 1}th`;
    }
  };

  bookingRooms = () => {
    const { selectedFor, roomsDog, roomsCat, selectRoomPet } = this.state;
    const roomData = selectedFor === "dogs" ? roomsDog : roomsCat;
    const newRoomData = [...roomData]
    const selectedRooms = newRoomData.find((room) => room.name === selectRoomPet) || newRoomData[0];



    const renderRoomTypes = () => (
      newRoomData.map((type, roomIndex) => (



        <div key={roomIndex}>
          <FormControlLabel
            data-test-id="petChangeForm"
            style={selectRoomPet === type.name ?compt8.selectedRoomTypeEle : compt8.roomTypeEle}
            value={type}
            control={
              <CustomRadio
                checked={selectRoomPet === type.name}
                onChange={() => this.handlePetTypeChange(type.name, selectedFor, type.data.price, type.data.id)}
                data-test-id={`handlePetTypeChangee${roomIndex}`}
              />
            }
            label={
              <span
                style={selectRoomPet === type.name ? compt8.selectedRoomLabel : compt8.roomLabel}
              >
                {type.name}
              </span>
            }
          />
        </div>
      ))
    );


    const renderRoomDetails = () => {


      if (!selectedRooms) {
        return null;
      }

      return (
        <div>
          {selectedRooms.data?.attributes.pet_room_prices?.map((value: string, roomIndex: number) => (


            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "11px 13px",
                margin: "10px 0px",
                backgroundColor: "#fff",
                borderRadius: "10px",
              }}
              key={roomIndex}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <CustomRadio
                  checked={
                    this.isChecked(selectedFor, value)
                  }
                  onChange={() => this.checkroomprice(value, selectedFor, roomIndex, selectedRooms.data.attributes.room_type, selectedRooms.data.id)}
                  data-test-id={`handlePetTypeChangee${roomIndex}`}
                />

                <Typography
                  style={{
                    fontSize: "14px",
                    fontWeight: 400,
                    fontFamily: "inter",
                  }}
                >
                  {`${configJSON.roomPriceText} ${this.indexToWord(roomIndex)} ${this.trimExtra(selectedFor)}${roomIndex>0?"s":""} ${configJSON.perDay}`}
                </Typography>
              </div>

              <Typography
                style={{
                  fontSize: "14px",
                  fontWeight: 700,
                  fontFamily: "inter",
                }}
              >
                {`£${value}`}

              </Typography>
            </div>
          ))}
        </div>
      );
    };

    const getPetTypeExplanation = () => {

      return (
        <Typography
          style={{
            fontSize: "14px",
            fontWeight: 400,
            fontFamily: "inter",
            overflowWrap: "anywhere"
          }}
        >
          {selectedRooms?.data?.attributes?.description}
        </Typography>
      )
    }


    const getPetTypeDescription = () => {

      return (
        <Typography
          style={{
            fontSize: "14px",
            fontWeight: 400,
            fontFamily: "inter",
          }}
        >
          {/* {selectedRoom?.data.description} */}
        </Typography>
      );
    };

    const getExplain = () => {


      const roomPrice = selectedRooms?.data?.attributes.pet_room_prices.length - 1


      return (
        <Typography
          style={{
            fontSize: "14px",
            fontWeight: 700,
            fontFamily: "inter",
          }}
        >
          {roomPrice > 0 ? `${this.indexToWord1(roomPrice)} ${selectedFor} fit into the room` : `${this.indexToWord1(roomPrice)} ${selectedFor.slice(0, -1)} fit into the room`}
        </Typography>
      )
    }

    return (
      <>
        <div
          style={compt8.modalDescription}
        >
          {newRoomData.length>0?
          <div>
            <div style={compt8.modalTitleBox}>
              <Typography
                style={compt8.modalTitle}
                data-test-id="selectRoomHeadingId"
              >
                {`Select the room type for ${selectedFor}`}
              </Typography>
            </div>
            <div style={compt8.roomSelection}>
            <div
              style={compt8.roomType}
            >
              {renderRoomTypes()}
            </div>
            <div
              style={compt8.roomDetailBox}
            >
              <div style={{ margin: "10px 0px" }}>
                {getPetTypeDescription()}
              </div>
              <div style={{ marginBottom: "5px" }}>

                {getPetTypeExplanation()}
              </div>
              <div>
                <div style={{ margin: "10px 0px" }}>
                  {getExplain()}
                </div>
                <div>
                  {renderRoomDetails()}
                </div>
              </div>
            </div>
            </div>
            <div
              style={compt8.buttonDiv}
              onClick={this.selectRoomForPet}
              data-test-id="selectRoomForPet"
            >
              <Typography style={compt8.btnText}>
                {configJSON.continueBtnText}
              </Typography>
              
            </div>
          </div>:
          <>
          <Typography variant="h3" style={compt8.noAvailable} >{configJSON.noRoomsAvailable}</Typography>
          </>
  }
        </div>
      </>
    );
  };

  hotelDescription = () => {

    return (
      <>
        {this.state.hotelDetails.data?.attributes.description && this.state.hotelDetails.data?.attributes.description.split(/\r?\n/).map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ))};
      </>
    )
  }

  morePropertyDetails = () => {
    const { classes } = this.props;
    return (
      <>
        <Box paddingLeft={'30px'}>
          <Typography variant="h4" className={classes.selectType}>About this property</Typography>
        </Box>
        <Box paddingLeft={"30px"} paddingRight={"30px"} marginTop={"20px"}>
          <Typography variant="body1" color="textPrimary" className={classes.detailsProperty}>
            {this.hotelDescription()}
          </Typography>
        </Box>
      </>
    )
  }

  dateRangeSelect = () => {
    return (
      <><div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <>
          <div style={{ overflow: "hidden" }}>
            <Calendar
              onChange={this.handleChangeDate}
              data-test-id="handle_change_calender"
              range
              numberOfMonths={2}
              format="DD MMM"
            >
              <Typography
                onClick={this.handleCloseDailog}
                style={{
                  marginLeft: "400px",
                  paddingBottom: "25px",
                  textDecoration: "underline",
                  fontWeight: 700,
                  cursor: "pointer",
                }}
              >
                Continue
              </Typography>
            </Calendar></div></>

      </div></>
    )
  }
  addPetModel = () => {
    const dogTextColor = this.state.addRoomDog === 0 ? "#E2E8F0" : "#1E293B";
    const dogIconColor = this.state.addRoomDog === 0 ? "#B2E4F8" : "#65C9F1";

    const catTextColor = this.state.addRoomCat === 0 ? "#E2E8F0" : "#1E293B";
    const catIconColor = this.state.addRoomCat === 0 ? "#B2E4F8" : "#65C9F1";
    return (
      <>


        <StyledMenuPet
          anchorEl={this.state.anchorElPet}
          keepMounted
          open={Boolean(this.state.anchorElPet)}
          data-test-id="close_addPet_popup"
          onClose={this.addPetsPopupClose}

        >{this.state.hotelType && (this.state.hotelType.includes("dog_hotel") || this.state.hotelType.includes("dog_cat_hotel") || this.state.hotelType.includes("daycare_services")) ? <>
          <MenuItem style={{ borderBottom: "1px solid #E2E8F0", height: "100px", marginBottom: "20px" }}>
            <Grid container alignItems="center" >
              <img
                src={Dog}
                alt="Dog"
                style={{ marginRight: "8px" }}
              />
              <p style={{ fontWeight: 700 }}>Dog</p>
            </Grid>
            <Grid container justifyContent="flex-end" alignItems="center" >
              <RemoveCircleOutlineIcon
                style={{ color: dogIconColor }}
                onClick={this.minusdogsRoomOnClick}
                data-test-id="minus_dog"
              />
              <p style={{ color: dogTextColor }} data-test-id="dogNumberId">
                &nbsp;&nbsp;{this.state.addRoomDog} &nbsp;&nbsp;
              </p>
              <AddCircleOutlineIcon

                onClick={this.adddogsRoomOnClick}
                data-test-id="add_dog"
              />
            </Grid>
          </MenuItem></> : <></>}

          {this.state.hotelType && (this.state.hotelType.includes("cat_hotel") || this.state.hotelType.includes("dog_cat_hotel") || this.state.hotelType.includes("daycare_services")) ?
            <>
            {this.state.hotelType !=="daycare_services" &&
            <><MenuItem style={{ height: "100px" }}>
              <Grid container alignItems="center" >
                <img
                  src={Cat}
                  alt="Cat"
                  style={{ marginRight: "8px" }}
                />
                <p style={{ fontWeight: 700 }}>Cat</p>
              </Grid>
              <Grid container justifyContent="flex-end" alignItems="center">
                <RemoveCircleOutlineIcon
                  style={{ cursor: "pointer", color: catIconColor }}
                  onClick={this.handleDecrement}
                  data-test-id="cat_decrement"
                />
                <p style={{ color: catTextColor }} data-test-id="catCount">
                  &nbsp;&nbsp;
                  {this.state.addRoomCat} &nbsp;&nbsp;
                </p>
                <AddCircleOutlineIcon

                  onClick={this.handleIncrement}
                  data-test-id="cat_increment"
                />
              </Grid>
            </MenuItem></>
            }</> :
            <>

            </>
          }


          <Typography
            style={{
              display: "flex", justifyContent: "flex-end", marginRight: "18px", fontSize: "16px",
              fontWeight: 700, color: "#EA0C78", textDecoration: "underline", cursor: "pointer"
            }}
            onClick={this.addPetsPopupCloseSave}
            data-test-id="close_pet_popup"
          >
            Continue
          </Typography>
        </StyledMenuPet>
      </>
    )
  }
  addDialog = () => {
    const { selectedType, propertyDetailsModal, dailogOpen } = this.state;



    if (propertyDetailsModal || dailogOpen) {
      if (selectedType.includes('dogAdditional') || selectedType.includes('catAdditional')) {
        this.additionalServices();
      } else if (selectedType.includes('dateService')) {
        this.dateRangeSelect();
      } else {
        this.bookingRooms();
      }
    }

    return (
      <>
        {propertyDetailsModal && (
          <CustomizedDailog
            open={propertyDetailsModal}
            handleClose={this.handlePropertyModalClose}
            data-test-id="handle_PropertyModal_Close"
            dailogTitle={this.morePropertyDetails}
            typeOfModel={selectedType}
          />
        )}

        {dailogOpen && (
          <CustomizedDailog
            open={dailogOpen}
            handleClose={this.handleCloseDailog}
            typeOfModel={selectedType}
            dailogTitle={this.getDailogTitle()}
            data-test-id="handleDialogId"
          />
        )}
      </>
    );
  };


  getDailogTitle = () => {
    const { selectedType } = this.state;

    if (selectedType.includes('dogAdditional') || selectedType.includes('catAdditional')) {
      return this.additionalServices;
    } else if (selectedType.includes('dateService')) {
      return this.dateRangeSelect;
    } else {
      return this.bookingRooms;
    }
  };

  additionalServicesForDog = () => {
    return (
      <>
        <div style={compt8.sideRatingComptFifth1}>
          <p>
            {this.state.addAdditionalServiceForPetClose ? this.state.checkedServicesDog.join(', ') : ""}
          </p>
        </div></>
    )
  }

  additionalServicesForCat = () => {
    return (
      <>
        <div style={compt8.sideRatingComptFifth1}>
          <p>
            {this.state.addAdditionalServiceForPetClose ? this.state.checkedServicesCat.join(', ') : ""}
          </p>
        </div></>
    )
  }
  selectroomForDog = (roomIndex: number) => {
    return (
      <>

        <span style={compt8.radioStyle}>{this.state.dogSelectedHotel[roomIndex].name &&
          this.correctDogRoom(roomIndex)
        }

        </span>
      </>

    )
  }
  selectroomForCat = (roomIndex: number) => {
    return (
      <>

        <span style={compt8.radioStyle}>{this.state.catSelectedHotel[roomIndex].name &&
        this.correctCatRoom(roomIndex)
        }

        </span>
      </>

    )
  }
  roomPriceForDogs = (totalDayStay: number, classes: ClassNameMap<string>) => {
    return (
      this.state.dogSelectedHotel.map(hotel => {
        return <div style={compt8.sideRatingComptSec}>
        <Typography
          variant="body2"
          color="textSecondary"
          className={classes.priceSectionText}
        >
          {`£${hotel.price} ${hotel.name} for dog X ${totalDayStay} Day`}{totalDayStay > 1 ? "s ":" "}
        </Typography><Typography
          variant="body2"
          color="textSecondary"
          className={classes.priceSectionText}
        >
            {`£${this.getRoomPrice(+hotel.price, totalDayStay)}`}
          </Typography>
          </div>
      })
    )
  }

  roomPriceForCats = (totalDayStay: number, classes: ClassNameMap<string>) => {
    return (
      this.state.catSelectedHotel.map(hotel => {
        return <div style={compt8.sideRatingComptSec}>
        <Typography
          variant="body2"
          color="textSecondary"
          className={classes.priceSectionText}
        >
          {`£${hotel.price} ${hotel.name} for cat X ${totalDayStay} Day`}{totalDayStay > 1 ? "s ":" "}
        </Typography><Typography
          variant="body2"
          color="textSecondary"
          className={classes.priceSectionText}
        >
            {`£${this.getRoomPrice(+hotel.price, totalDayStay)}`}
          </Typography>
          </div>
      })
    )
  }

  summaryDetails = () => {
    const { classes } = this.props;
    const {
      checkedServicesDogPrice,
      checkedServicesCatPrice,
      selectRoomForDog,
      selectRoomForCat,
      selectRoomForDogPrice,
      selectRoomForCatPrice,
      totalDay,
      totalPrice,
      addRoomDog,
      addRoomCat,
    } = this.state;

    const totalDayStay = Math.floor(Number(totalDay)) || 0;




    return (
      <div>
        <Typography
          variant="body1"
          color="textSecondary"
          className={classes.sideSecText1}
          style={{ padding: "10px 0px" }}
        >
          Summary:
        </Typography>
        <div style={compt8.sideRatingComptSecDiv}>
          {(this.getDogRoomDetails()) && (
            <div>
              {this.roomPriceForDogs(totalDayStay, classes)}
            </div>
          )}

          {this.getCatRoomPrice() && (
            <div style={compt8.sideRatingComptSec}>
              <Typography
                variant="body2"
                color="textSecondary"
                className={classes.priceSectionText}
              >
                {`£${selectRoomForCatPrice} ${selectRoomForCat} for cat X ${totalDayStay == undefined ? 0 : totalDayStay} Day`}{totalDayStay > 1 ? "s ":" "}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                className={classes.priceSectionText}
              >
                {`£${this.getRoomPrice(selectRoomForCatPrice, totalDayStay)}`}
              </Typography>
              </div>
          )}
          {this.getRoomPriceForCats() && (
            <div>
              {this.roomPriceForCats(totalDayStay, classes)}
            </div>
          )}
          {this.state.hotelType==="daycare_services" && this.state.displayPrice!== "0" && <div style={compt8.sideRatingComptSec}>
              <Typography
                variant="body2"
                color="textSecondary"
                className={classes.priceSectionText}
              >
                {`£${this.state.displayPrice} X ${this.state.petId.length} Pets ${this.getNumberOfAllWeeks()}`}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                className={classes.priceSectionText}
              >
                {`£${this.calculateDayCarePrice()}`}
              </Typography>
            </div>
          }

          {checkedServicesDogPrice.map((value, index) => {
            if (this.duplicateAdditionalServiceIds(checkedServicesDogPrice, checkedServicesCatPrice).includes(value.hotel_additional_service_id)) {
              return null
            }
            return (

              <div style={compt8.sideRatingComptSec} key={index}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  className={classes.priceSectionText}
                >
                  {`${value.name} for Dog`}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  className={classes.priceSectionText}
                >
                  {`£${value.price * addRoomDog}`}
                </Typography>
              </div>
            )
          })}

          {checkedServicesCatPrice.map((value, index) => {
            if (this.duplicateAdditionalServiceIds(checkedServicesDogPrice, checkedServicesCatPrice).includes(value.hotel_additional_service_id)) {
              return null
            }
            return (
              <div style={compt8.sideRatingComptSec} key={index}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  className={classes.priceSectionText}
                >
                  {`${value.name} X ${this.totalPets(value)} Pets`}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  className={classes.priceSectionText}
                >
                  {`£${value.price * (this.totalPets(value))}`}
                </Typography>
              </div>
            )
          })}
          {this.duplicateAdditionalService(checkedServicesCatPrice, checkedServicesDogPrice).map((value, index) => {

            return (
              <div style={compt8.sideRatingComptSec} key={index}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  className={classes.priceSectionText}
                >
                  {`${value.name.split(' ')[0]} Dog £${value.price} and Cat £${value.price} (£${value.price}+£${value.price})`}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  className={classes.priceSectionText}
                >
                  {`£${(value.price * addRoomCat + value.price * addRoomDog)}`}
                </Typography>
              </div>
            )
          })}
        </div>

        <div style={compt8.totalGrid}>
          <Typography
            variant="body1"
            className={classes.sideFirstText}
          >
            Total
          </Typography>
          <Typography
            variant="body1"
            className={classes.sideFirstText}
          >
            {`£${totalPrice}`}
          </Typography>
        </div>

        <Button
          type="button"
          style={compt8.reservButton as React.CSSProperties}
          onClick={this.checkCondition}
          data-test-id="reserveRoom"
        >
          Reserve
        </Button>
      </div>
    );
  };

  petinformation = () => {
    return (
      <>
        {this.state.addRoomDog > 0 && (
          <span>
            {this.state.addRoomDog} Dog{this.state.addRoomDog > 1 ? "s" : ""}
            {this.state.addRoomCat > 0 ? " and " : ""}
          </span>
        )}
        {this.state.addRoomCat > 0 && (
          <span>
            {this.state.addRoomCat} Cat{this.state.addRoomCat > 1 ? "s" : ""}
          </span>
        )}
      </>
    )
  }

  renderCatRoomSelection = () => {
    return (
      this.state.catSelectedHotel.map((catRoom: SelectedHotel, roomIndex: number) => (
        <div style={compt8.roomBox}>
        <div
            style={this.state.roomErrorCatMessage ?compt8.sideRatingComptForthDisable: compt8.sideRatingComptForth }
            onClick={() => this.handleAvailabilityRoomsPopup("cats", roomIndex)}
            data-test-is={`cats_rooms_${roomIndex}`}
          >
            <div> {this.selectroomForCat(roomIndex)}</div>
            <ChevronRightIcon />
          </div>
          <button 
            style={compt8.removeButton}
            onClick={()=>{this.handleRemoveCatRoom(roomIndex)}}
            data-test-id={`removeCatRoomId${roomIndex}`}  
          >
          <HighlightOffIcon style={this.state.roomErrorCatMessage ? compt8.removeIconDisable: compt8.removeIcon}/>
          </button>
        </div>
      ))
    );
  }

  sideBarData=()=>{
    const { classes } = this.props
    const allDetails = this.state.hotelDetails;
  
    return(
      <>
        <Grid item xs={12} sm={4} md={3}>
                <Grid style={{ padding: "20px" }} className={classes.hotelDetailsLayout}>
                  <div style={compt8.preNewTextDiv}>
                    <div style={compt8.sideRatingCompt}>
                      <span style={compt8.hedingSideBar}>
                        £{this.getPrice() ?? 0}
                      </span>{" "}
                      <CustomRating
                        rating={
                          this.state.hotelDetails.data?.attributes.rating ?? 0
                        }
                      />
                    </div>
                    <div style={compt8.sideRatingCompt}>
                      <Typography variant="body1" color="textPrimary">
                        Starting from Per Day
                      </Typography>
                      <div style={compt8.sideRatingComptNew}>
                        
                        <div>
                          <span style={{ ...compt8.roomsThirdSec, marginRight: "31px",marginLeft:"5px" }}>{`${this.state.hotelDetails.data?.attributes.rating == null ? "0" : this.state.hotelDetails.data?.attributes.rating}    `}</span>

                          <span style={compt8.roomsThirdSec}>{`${this.state.hotelDetails.data?.attributes.reviews_count ?? 0} reviews`}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div style={{ position: "relative", marginTop: "15px", marginRight: "30px" }}>
                      <Typography
                        variant="body1"
                        className={classes.sideFirstText}
                        data-test-id="checkinCallId"
                      >
                        Check in
                      </Typography>
                      <CustomCalendar style={{ marginTop: "0px", padding: '1px', width: "110%" }}>
                        <CustomDate
                          data-test-id="dogbirth"
                          value={this.state.checkInDate}
                          onChange={this.handleCheckInDateChange}
                        />
                        <CalendarTodayIcon
                          style={{
                            position: "absolute",
                            top: "50%",
                            right: "-5%",
                            color: "#334155",
                            zIndex: 2
                          }}
                        />
                      </CustomCalendar>


                    </div>

                    <div style={{ position: "relative", marginTop: "0px", marginRight: "30px" }}>
                      <Typography
                        variant="body1"
                        className={classes.sideFirstText}
                      >
                        Check out
                      </Typography>
                      <CustomCalendar style={{ marginTop: "0px", padding: '1px', width: "110%" }}>
                        <CustomDate
                          data-test-id="dogbirthd"
                          value={this.state.checkOutDate}
                          onChange={this.handleCheckOutDateChange}
                        />
                        <CalendarTodayIcon
                          style={{
                            position: "absolute",
                            top: "50%",
                            right: "-5%",
                            color: "#334155",
                            zIndex: 2
                          }}
                        />
                      </CustomCalendar>
                    </div>
                    <span style={compt8.checkinDateEroorText as React.CSSProperties}>
                      {this.state.totalDay !== undefined && this.state.totalDay < 1
                        ? "Please choose a valid date: the checkout date must be after the check-in date"
                        : ""}
                    </span>

                    <div
                      style={{ position: "relative", marginTop: "0px" }}
                      onClick={this.openAddPetModel}
                      data-test-id="openAddPetModel"
                    >
                      <Typography
                        variant="body1"
                        className={classes.sideFirstText}
                      >
                        Add Pets
                      </Typography>
                      <div style={compt8.sideRatingComptThird}>
                        <Typography
                          variant="body1"
                          className={classes.sideSecText}
                          data-test-id="addPetsTestId"
                        >

                          {this.petinformation()}

                        </Typography>
                        <KeyboardArrowDownIcon
                          style={{
                            position: "absolute",
                            top: "54%",
                            right: "4%",
                            color: "#334155",
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  {this.isDayCareShow() && <>
                  <div
                      style={compt8.dayCareDiv}
                    >
                      <div style={compt8.textDiv}>
                        <Typography
                          variant="body1"
                          style={compt8.dayCareText}
                          data-test-id="dayCareTextId"
                        >
                          {configJSON.dayCareText}
                        </Typography>
                      </div>
                      <div
                        style={compt8.border}
                      ></div>
                    </div>
                    <div style={compt8.radioButtonGrid}>
                      <div
                        style={compt8.sideRatingComptForth}
                        onClick={this.handleDilogOpen}
                        data-test-id="dayCareButtonId"
                      >
                        <div><Typography style={compt8.dayCareDivText}>{configJSON.dayCareText}</Typography></div>
                        <ChevronRightIcon />
                      </div>

                    </div>
                  </>}
                  {this.state.hotelType !== "daycare_services" && <>
                  {this.state.addRoomDog > 0 && <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        margin: "20px 0px",
                        justifyContent: "space-between"
                      }}
                    >
                      <div style={{ width: "35%", height: "20px" }}>
                        <Typography
                          variant="body1"
                          style={{
                            fontSize: "14px",
                            fontWeight: 400,
                            color: "#0F172A",
                            justifyContent: "space-between"
                          }}
                        >
                          Select for Dogs
                        </Typography>
                      </div>
                      <div
                        style={{ borderBottom: "1px solid #65C9F1", width: "60%" }}
                      ></div>
                    </div>
                    <div style={compt8.radioButtonGrid}>
                      <Typography variant="body1" className={classes.sideFirstText} data-test-id="selectTextTestId">
                        Select the room type
                      </Typography>
                    {(
                      this.state.dogSelectedHotel.map((dogRoom: SelectedHotel, roomIndex: number) => (
                        <div style={compt8.roomBox}>
                          <div
                            style={this.state.roomErrorDogMessage ?compt8.sideRatingComptForthDisable: compt8.sideRatingComptForth }
                            onClick={() => this.handleAvailabilityRoomsPopup("dogs", roomIndex)}
                            data-test-id={`dogs_rooms_${roomIndex}`}
                          >
                            <div>{this.selectroomForDog(roomIndex)}</div>
                            <ChevronRightIcon />
                          </div>
                          <button
                            style={compt8.removeButton}
                            onClick={() => { this.handleRemoveDogRoom(roomIndex) }}
                            data-test-id={`removeDogRoomId${roomIndex}`}
                          >
                            <HighlightOffIcon style={this.state.roomErrorDogMessage ? compt8.removeIconDisable: compt8.removeIcon}/>
                          </button>
                        </div>)
                      )
                    )}

                    {this.state.isDogRoomSelected && (
                      <Grid item xs={12} container className={classes.addBtnLayout}>
                      <Button color="secondary"
                          onClick={() => this.handleAvailabilityRoomsPopup("dogs", -1)} disabled={this.state.dogSelectedHotel[0].name === ""} data-test-id='addButton'
                          className={classes.addBtn}>
                          <AddCircleOutlineRoundedIcon fontSize="small" color={this.getAddDogsIconColor()} /> &nbsp;
                          <u>{configJSON.addRoom}</u>
                          </Button>
                      </Grid>
                    )}
                    </div>
                    {this.state.roomErrorDogMessage && <Typography style={compt8.checkinDateEroorText}>{this.state.roomErrorDogMessage}</Typography>}
                  </>}

                  {this.state.addRoomCat > 0 && <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        margin: "20px 0px",
                        justifyContent: "space-between"
                      }}
                    >
                      <div style={{ width: "35%", height: "20px" }}>
                        <Typography
                          variant="body1"
                          style={{
                            fontSize: "14px",
                            fontWeight: 400,
                            color: "#0F172A",
                          }}
                        >
                          Select for Cats
                        </Typography>
                      </div>
                      <div
                        style={{ borderBottom: "1px solid #65C9F1", width: "60%" }}
                      ></div>
                    </div>
                    <div style={compt8.radioButtonGrid}>
                      <Typography variant="body1" className={classes.sideFirstText}>
                        Select the room type
                      </Typography>
                      {this.renderCatRoomSelection()}
                      {this.state.isCatRoomSelected && (
                    <Grid item xs={12} container className={classes.addBtnLayout}>
                    <Button color="secondary"
                        data-test-id="addCatRoom"
                        onClick={() => this.handleAvailabilityRoomsPopup("cats", -1)} disabled={this.state.catSelectedHotel[0].name === ""}
                        className={classes.addBtn}>
                          <AddCircleOutlineRoundedIcon fontSize="small" color={this.getAddCatsIconColor()} /> &nbsp;
                        <u>{configJSON.addRoom}</u>
                        </Button>
                    </Grid>
                    )}
                    </div>
                    {this.state.roomErrorCatMessage && <Typography style={compt8.checkinDateEroorText}>{this.state.roomErrorCatMessage}</Typography>}
                  </>}

                {(this.showAdditionalService()) &&
                  <>
                    <div
                      style={styles.additonalServiceDiv}
                    >
                      <div style={styles.additionalServiceTextDiv}>
                        <Typography
                          variant="body1"
                          style={styles.additionalServiceText}
                        >
                          {configJSON.additionalService}
                        </Typography>
                      </div>
                      <div
                        style={styles.serviceBox}
                      ></div>
                    </div>
                    <div style={compt8.radioButtonGrid}>
                      <Typography variant="body1" className={classes.sideFirstText}>
                      {configJSON.addAdditionalService}
                      </Typography>
                      <div style={compt8.sideRatingComptFifthMainContainer} onClick={() => {
                        this.handleAdditinalAddPet("catAdditional");
                      }}
                        data-test-id="catAdditional">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}


                        >
                          <Typography variant="body1">
                            Add additional services
                          </Typography>
                          <div
                            style={compt8.sideRatingComptFifth}

                          >
                            <ChevronRightIcon

                            />
                          </div>
                        </div>

                        {this.additionalServicesForCat()}
                      </div>
                    </div>
                  </>
                }

                  </>}

                  {this.summaryDetails()}

                </Grid>
              </Grid>
      </>
    )
  }




  // Customizable Area End
  render() {
    // Customizable Area Start

    const { classes } = this.props
    const allDetails = this.state.hotelDetails;
    const { openSnck } = this.state

    
    const coverPhotoMain = this.state.hotelDetails?.data?.attributes?.images.find(image => image.is_cover_image);
  const remainingPhotos = this.state.hotelDetails?.data?.attributes?.images.filter(image => image !== coverPhotoMain).slice(0,2);
    return (
      <>
        {this.state.isOpenDayCareDialog && 
        <DayCareServiceModal 
          openModal={this.state.isOpenDayCareDialog}
          closeModal={this.handleDialogClose}
          userPet={this.state.userPetList}
          addDogRoom={this.state.addRoomDog}
          addCatRoom={this.state.addRoomCat}
          selectedPriceList={this.state.priceList}
          data-test-id="dayCareModalId"
          handleAdditionalDetails={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => this.handleAdditionalDetailsChange(event)}
          handleModal={this.openPopUp}
          closePetModal={this.closePopup}
          handleType={(event: React.ChangeEvent<HTMLInputElement>) => this.handleSelectedType(event)}
          addRoom={(selceteddPetId: string, selectedPetName: string, selectedPetType: string) => this.addRoomOnClick(selceteddPetId, selectedPetName, selectedPetType)}
          checkPrice={this.checkDisplayPrice}
          addSelectedDays={(event: React.MouseEvent<HTMLElement, MouseEvent>, dayIndex: number) => this.addSelectedDay(dayIndex)}
          getSelectFeild={this.getSelectFeildText} 
          selectedDaysList={this.state.selectedDays} 
          selectedPetId={this.state.petId} 
          selectedPricing={this.state.selectedPricingType} 
          openPetModal={this.state.openPetPopup} 
          additionalServiceDetails={this.state.additionalDetails}
          displayPriceValue={this.getDayCarePrice()}
          modalErrorText={this.state.modalError}
          handleSubmit={this.handleContinue}
          isDisabled={false}
          checkIn={this.state.checkInDate}
          checkOut={this.state.checkOutDate}
          handleCheckOutDate={this.handleCheckOutDateChange}
          handleCheckInDate={this.handleCheckInDateChange}
        />
        }
        <Navbar navType="fullNavbar" />
        {this.addPetModel()}
        <CustomizedSnackbars duration={3000} openToaster={this.state.checkAvailability.isAvailable} message={this.state.checkAvailability.availabilityMessage}
        
        toasterType={'error'} />
        <CustomizedSnackbars duration={3000} openToaster={this.state.checkDate.isAvailable} message={this.state.checkDate.availabilityMessage} toasterType={'error'} />
        <CustomizedSnackbars duration={20000} openToaster={this.state.oneMonthStayToast} message={this.state.oneMonthStayMessage} toasterType={'error'} />
        <CustomizedSnackbars duration={20000} openToaster={this.state.flashLogin} message={"Please login or register to continue your booking"} toasterType={"error"} />
        <CustomizedSnackbars duration={20000} openToaster={this.handleError()} message={"Please select the room type"} toasterType={"error"} />
        <CustomizedSnackbars duration={20000} openToaster={this.handleToastError()} message={"Please add day care details"} toasterType={"error"} />
        <CustomizedSnackbars duration={20000} openToaster={this.state.conditionSlack} message={this.state.slackData} toasterType={"error"} />
        <CustomizedSnackbars duration={20000} openToaster={this.state.availabilityError} message={this.state.multipleRoomError} toasterType={"error"} />
        <CustomizedSnackbars duration={20000} openToaster={openSnck} message={this.state.checkInDateError && this.state.checkOutDateError
              ? "You must select a checkout date later than the check-in date"
              : "You must select both check-in and check-out dates"} toasterType={"error"} data-test-id="clockinErrorId"/>



        <Container maxWidth={false} className={classes.layoutHotel}>
          <Grid item xs={12}  >
            <Grid container direction="row" spacing={3}>
              <Grid item xs={12} sm={8} md={9} >
                <Grid container direction="row" style={{ paddingLeft: "13px" }} spacing={3} className={classes.hotelDetailsLayout}>
                  <Grid item xs={12}>
                    <Grid container direction="row" alignItems="center" spacing={1} style={{ marginTop: 30 }}>
                      <Grid item >
                        <img src={backIcon}

                          data-test-id="back_icon_button"
                          className={classes.cursor}
                          onClick={this.goBackHotelsListPage} />
                      </Grid>
                      <Grid item>
                        <Typography style={{ marginLeft: "20px" }} variant="h3" className={classes.headingText} data-test-id="hotelNameId">
                          {allDetails.data?.attributes.name}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Grid container alignItems="center">
                          <img
                            src={Location}
                            alt="location"

                            style={compt8.locationimg as React.CSSProperties}
                          />
                          <Typography

                            variant="body1"
                            className={classes.textLocation}
                          >
                            {allDetails.data?.attributes?.hotel_address_line_1}
                            {allDetails.data?.attributes.hotel_city ? ", " + allDetails.data?.attributes.hotel_city : null}
                            {allDetails.data?.attributes.hotel_country_region ? ", " + allDetails.data?.attributes.hotel_country_region : null}
                          </Typography>
                        </Grid>

                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} style={{ padding: "20px" }}>
                    <Grid container direction="row" spacing={2} justifyContent="space-between">
                      <Grid item md={8} xs={12} className={classes.bigImageLayout}>
                        <img
                          src={coverPhotoMain?.url || allDetails?.data?.attributes?.images[0]?.url}
                          data-test-id='errorImage'
                          onError={errorImage}
                          alt="Hotel image 1" className={classes.bidImg} />

                      </Grid>
                      <Grid item md={4} xs={12} style={{ maxWidth: '33%' }}>
                        <Grid container spacing={1} style={{ gap: 10 }}>
                          <Grid item xs={6} sm={12} className={classes.smallImageLayout}>
                            <img
                              src={remainingPhotos&&remainingPhotos[0]?.url || dummyImage}
                              data-test-id='errorImage'
                              onError={errorImage}
                              alt="Hotel image 2"
                              className={classes.smallImg}
                            />

                          </Grid>
                          <Grid item xs={6} sm={12} className={classes.smallImageLayout} >
                            <img
                              src={remainingPhotos&&remainingPhotos[1]?.url || dummyImage}
                              data-test-id='errorImage'
                              onError={errorImage}
                              alt="Hotel Image 3"
                              className={classes.smallImg}
                            />
                            {allDetails.data?.attributes.images &&
                              allDetails.data?.attributes.images.length > 3 && (
                                <img
                                  src={ShowAllPhoto}
                                  alt="ShowAllPhoto"
                                  style={{
                                    position: "absolute",
                                    right: "4px",
                                    bottom: "4px",
                                  }}
                                  data-test-id='see_all_photos'
                                  onClick={this.seeAllPhotos}
                                />
                              )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>

                    <img
                      src={allDetails.data?.attributes?.owner_details?.data[0].attributes.photo}
                      alt="Profile1"
                      style={compt8.rectImg}
                    />&nbsp;&nbsp;
                    <Typography display="inline" variant="h4" style={{ position: "absolute", marginTop: "20px" }} className={classes.selectedType}>
                      Entire Hotel hosted by {allDetails.data?.attributes.representative_details[0]?.first_name + " " + allDetails.data?.attributes.representative_details[0]?.surname}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider className={classes.dividerColor} />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item lg={3} xs={12}>
                        <Grid style={{ display: "grid" }}>
                          <img src={FarmeDog} alt="FarmeDog" />
                          <br />
                          <span style={{ ...compt8.slefCheckHeader, marginBottom: "-5px" }}>
                            Dogs grooming service{" "}
                          </span>
                          <br />
                          <span style={compt8.slefCheckText}>
                            Some dog grooming related <br /> text will be here.
                          </span>
                        </Grid>
                      </Grid>
                      <Grid item lg={3} xs={12}>
                        <Grid style={{ display: "grid" }}>
                          <img src={FarmeCat} alt="FarmeCat" />
                          <br />
                          <span style={{ ...compt8.slefCheckHeader, marginBottom: "-5px" }}>
                            Cats grooming service
                          </span>
                          <br />
                          <span style={compt8.slefCheckText}>
                            Some cat grooming related <br /> text will be here.
                          </span>
                        </Grid>
                      </Grid>
                      <Grid item lg={3} xs={12}>
                        <Grid style={{ display: "grid" }}>
                          <img src={FarmeDate} alt="FarmeDate" />
                          <br />
                          <span style={{ ...compt8.slefCheckHeader, marginBottom: "-5px" }}>
                            Free cancellation{" "}
                          </span>
                          <br />
                          <span style={compt8.slefCheckText}>
                            Free cancellation before <br /> 48 hours.
                          </span>
                        </Grid>
                      </Grid>
                      <Grid item lg={3} xs={12}>
                        <Grid style={{ display: "grid" }}>
                          <img style={{ height: "50px" }} src={Payment} alt="FarmeDate" />
                          <br />
                          <span style={{ ...compt8.slefCheckHeader, marginBottom: "-5px" }}>
                            Payment{" "}
                          </span>
                          <br />
                          <span style={compt8.slefCheckText}>
                            We only accept Full <br /> Payment upfront.
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>

                  </Grid>
                  <Grid item xs={12}>
                    <Divider className={classes.dividerColor} />
                  </Grid>
                  <Grid item xs={12} style={compt8.preTextGrid}>
                    <Typography variant="body1" color="textSecondary" className={classes.descriptionLayoutSize}>
                      {this.hotelDescription()}</Typography>
                    {allDetails.data?.attributes.description &&
                      allDetails.data?.attributes.description?.length > 300 && (
                        <Button
                          style={compt8.buttonGrid}
                          data-test-id="more_property"
                          onClick={this.handlePropertyModalOpen}
                        >
                          <div style={{ ...compt8.buttonText, textTransform: "none" }}>
                            <span>Read more</span>
                            <KeyboardArrowRightIcon />
                          </div>
                        </Button>
                      )}
                  </Grid>
                  <Grid item xs={12}>
                    <Divider className={classes.dividerColor} />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography data-test-id="serviceHeading" variant="h4" className={classes.selectType}>
                          What this place provides services
                        </Typography>
                      </Grid>
                      <Grid item xs={12} className={classes.hotelServicesLayout}>

                        {this.state.servicesDog.map((service: any) => {

                          return (
                            <Grid container spacing={2} alignItems="center" key={service?.id}>
                              <Grid item>
                                {/* <img src={this.getIcon(service?.name || "")}  /> */}
                                <FiberManualRecordIcon />
                              </Grid>
                              <Typography variant="body1" className={classes.selectType}>{service?.name}</Typography>
                            </Grid>
                          )
                        })}
                      </Grid>
                    </Grid>


                  </Grid>
                  <Grid item xs={12}>
                    <Divider className={classes.dividerColor} />
                  </Grid>
                  <ReviewsPo navigation={this.props.navigation} id={""}
                    selectedRoomId={this.state.hotelDetails?.data?.id}
                    open={false} />
                  <Grid item xs={12}>
                    <Divider className={classes.dividerColor} />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" className={classes.heading}>Location</Typography>
                    {/* <img src={locationMap} style={compt8.googleMapReact} /> */}
                    <MapBox data-test-id="mapBtnTestID">
                      <MapContainer
                        zoomControl={false}
                        center={!!this.getCenter() ? this.state.coords : [20.5937, 78.9629]}
                        zoom={25}
                        className="mapContainer"
                        data-test-id="mapBtnsTestID"
                      >
                        <TileLayer
                          url={configJSON.url}
                        />
                        { !!this.getCoords() && <Maps markerIconPng={markerIconPng} coords={this.state.coords}/>}
                      </MapContainer>
                    </MapBox>
                  </Grid>
                </Grid>

              </Grid>

              {this.sideBarData()}
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.layoutfooter}>
            <Footer footerNavigationData={this.state.socialLinksData} boAndPOLinks={this.state.poBOLinksData} />
          </Grid>
        </Container>
        {this.state.reviewStatus &&
          <Addreview navigation={this.props.navigation} id={""}
            open={false} classes={this.props.classes}
            accountId={this.state.accountId}
            hotelId={this.state.hotelId}
          />
        }
        <Loader loading={this.state.petDetailsPageLoader} />
        <>{this.addDialog()}</>
      </>
    );
    // Customizable Area Start

  }
}

// Customizable Area Start
const styles = {
  additonalServiceDiv: {
    display: "flex",
    alignItems: "center",
    margin: "20px 0px",
    justifyContent: "space-between"
  }, 
  additionalServiceTextDiv: { 
    width: "35%", 
    height: "20px" 
  },
  additionalServiceText: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#0F172A",
    justifyContent: "space-between"
  },
  serviceBox: { 
    borderBottom: "1px solid #65C9F1", 
    width: "60%" 
  }
}
export const CustomCalendar = styled(Box)({

  "& .rmdp-container": {
    width: "100%",
    fontFamily: "inter",
    padding: "5px",
    paddingLeft: '0px'
  },
  "& .rmdp-input": {
    cursor: "pointer",
    position: "relative",
    zIndex: 22,
    backgroundColor: "#61b0cf26",
    fontFamily: "inter",
    height: "43px",
    width: "100%",
    borderRadius: "8px",
    border: "1px solid #65C9F1",

  },
  "& .rmdp-header": {
    backgroundColor: "#CCEDFB",
    borderRadius: 8,
    fontSize: "16px",
    display: "flex",
    fontFamily: "inter",
    justifyContent: "center",
    alignItems: "center",
    fontWeight: 400
  },
  "& .rmdp-week-day": {
    color: "#000"
  },
  "& .rmdp-day.rmdp-selected span:not(.highlight) ": {
    backgroundColor: "#EA0C78",
    color: "#fff",
    fontFamily: "inter"
  },
  "& .rmdp-day.rmdp-today span": {
    backgroundColor: "#fff",
    color: "#000",
    fontFamily: "inter"
  },
  "& .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover": {
    backgroundCcolor: "#EA0C78",
    color: "#fff",
    fontFamily: "inter"
  },
  
  "& .rmdp-right i": {
    marginRight: "3px",
    fontFamily: "inter",
    // WebkitTransform: "rotate(45deg)",
  },
  "& .rmdp-arrow": {
    borderColor: "#EA0C78"
  },

  "& .rmdp-arrow-container.disabled .rmdp-arrow, .rmdp-arrow-container.disabled:hover .rmdp-arrow": {
    borderColor: "#EA0C78"
  }

})

const MapBox = styled(Box)({
  "& .gm-style": {
    display: "none"
  },
  "& .place-card-large": {
    display: "none"
  },
  "& .mapContainer": {
    width: "100%", 
    height: "70vh"
  }
});

const DailogStyles = (theme: Theme) =>
  createStyles({
    layoutHotel: {
      backgroundColor: theme.palette.primary.main,
      marginTop: '113px',
      padding: '0 50px',
      height: 'max-content',
      [theme.breakpoints.down('sm')]: {
        marginTop: 90
      }
    },
    contentLayout: {
      maxWidth: "574px",
      width: "100%",
      backgroundColor: theme.palette.primary.main,
      height: "100%",
      maxHeight: "292px",
      borderRadius: 15,
      margin: 'auto',
      overflowY: 'scroll'
    },
    hotelDescription: {
      color: theme.palette.common.black,
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: 14
    },
    heading: {
      color: theme.palette.common.black,
      fontWeight: theme.typography.fontWeightBold,
    },
    costBox: {
      backgroundColor: theme.palette.common.white,
      maxWidth: "534px",
      width: "100%",
      minHeight: "42px",
      display: "flex",
      alignItems: 'center',
      borderRadius: 8,
      justifyContent: 'space-between',
      margin: 'auto'
    },
    selectedType: {
      marginTop: 0,
    },
    servicesBox: {
      backgroundColor: "#E0F4FC",
      maxWidth: "534px",
      width: "100%",
      height: "42px",
      display: "flex",
      alignItems: 'center',
      borderRadius: 8,
      justifyContent: 'space-between',
      margin: 'auto',
    },
    selectType: {
      color: theme.palette.common.black,
      fontWeight: theme.typography.fontWeightRegular,
    },
    closeIconLayout: {
      padding: 10,
      display: 'flex',
      justifyContent: "flex-end",
      alignItems: 'center'
    },
    selectedRadioLayout: {
      backgroundColor: theme.palette.primary.main,
      maxWidth: '168px',
      width: "100%",
      minHeight: '64px',
      height: "100%",
      borderRadius: "6px 6px 0px 0px",
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    radioLayout: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    radiogroup: {
      flexWrap: 'nowrap'
    },
    headingText: {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.common.black,
    },
    textLocation: {
      // fontSize: "14px",
      fontWeight: theme.typography.fontWeightRegular,
      // color: "#475569",
    },
    hotelDetailsLayout: {
      boxShadow: boxShadows.whiteShadow,
      borderRadius: '0 0 15px 15px',
      height: "fit-content",
      background: theme.palette.common.white
    },
    priceSectionText: {
      fontWeight: theme.typography.fontWeightRegular,
    },

    sideFirstText: {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.common.black
    },
    addBtnLayout: {
      margin: "20px 0",
      display: "flex",
      justifyContent: "end"
    },
    addBtn: {
      textTransform: 'none',
      fontSize: 16,
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.secondary.main,
      "&:hover": {
          color: theme.palette.secondary.main
      }
    },
    removeIcon: {
      color: theme.palette.secondary.main,
    },
    addRoomText: {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.secondary.main,
      fontSize: "16px"
    },
    sideSecText: {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.common.black
    },
    sideSecText1: {
      marginTop: "20px",
      fontWeight: theme.typography.fontWeightRegular,
    },
    descriptionLayout: {
      [theme.breakpoints.down('xs')]: {
        padding: "10px !important"

      }
    },
    dividerColor: {
      background: "rgba(101, 201, 241, 0.6)",
      height: '2px'
    },
    layoutfooter: {
      marginTop: 60
    },
    hotelServicesLayout: {
      height: "fit-content"
    },
    cursor: {
      cursor: 'pointer'
    },
    bigImageLayout: {
      background: theme.palette.primary.main,
      borderRadius: 8,
      height: "510px",
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      maxWidth: '66%'

    },
    smallImageLayout: {
      background: theme.palette.primary.main,
      borderRadius: 8,
      height: "250px",
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',



    },
    bidImg: {
      maxHeight: "500px",
      maxWidth: '100%'
    },
    smallImg: {
      maxHeight: "245px",
      maxWidth: '100%'
    },
    descriptionLayoutSize: {
      display: '-webkit-box',
      WebkitLineClamp: 5,
      overflow: 'hidden',
      WebkitBoxOrient: 'vertical',
    },
    detailsProperty: {
      overflowWrap: 'anywhere'
    }
  })


const compt8 = {
  modalDescription: {
    backgroundColor: "#fff"
  },
  modalTitleBox: {
    height: "52px",
    padding: "0px 48px 20px 48px",
    opacity: "0px"
  },
  modalTitle: {
    fontSize: "24px",
    fontWeight: 400,
    fontFamily: "Inter",
    color: "rgba(15, 23, 42, 1)",
    lineHeight: "32px"
  },
  roomSelection:{
    padding: "0px 48px"
  },
  roomType: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  roomTypeEle: {
    backgroundColor: "#fff",
    padding: "10px 30px 10px 17px",
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
    margin: "0px"
  }, 
  roomLabel: {
    fontFamily: "Inter",
    fontSize: "16px",
    color: "#224350",
    fontWeight: 400,
  },
  selectedRoomLabel: {
    color: "#224350",
    fontFamily: "Inter",
    fontWeight:  700 ,
    fontSize: "16px",
  },
  selectedRoomTypeEle: {
    padding: "10px 30px 10px 17px",
    borderTopLeftRadius: "13px",
    backgroundColor: "#B2E4F8",
    borderTopRightRadius: "13px",
    margin:"0px 0px -15px 0px"
  },
  buttonDiv: {
    display: "flex",
    justifyContent: "right" as "right",
    padding: "20px 48px 32px 48px"
  },
  btnText: {
    cursor: "pointer" as "pointer",
    textDecoration: "underline" as "underline",
    fontSize: "16px",
    fontWeight: 700,
    fontFamily: "Inter",
    color: "#EA0C78"
  },
  roomBox: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%"
  },
  roomDetailBox: {
    backgroundColor: "#B2E4F8",
    padding: "30px 40px",
    borderRadius: "13px",
  },
  removeButton:{
    border: "none",
    textDecoration: "none",
    backgroundColor: "white",
    marginTop: "10px",
    "&:hover": {
      border: "none",
      textDecoration: "none",
      backgroundColor: "white"
    }
  },
  removeIcon: {
    color: "rgb(234, 12, 120)"
  }, 
  removeIconDisable:{
    color: "rgba(0, 0, 0, 0.26)"
  },
  dayCareDivText: {
    fontFamily: "Inter",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#0F172A"
  },

  dayCareDiv: {
    display: "flex",
    alignItems: "center",
    margin: "30px 0px",
    justifyContent: "space-between",
    padding: "10px" 
  },

  textDiv: {
    width: "45%", 
    height: "20px" 
  },

  dayCareText: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#0F172A",
    justifyContent: "space-between"
  },

  border: {
    borderBottom: "1px solid #65C9F1", 
    width: "40%" 
  },
  
  modalStyle: {
    position: "absolute",
    width: "500px",
    backgroundColor: "#fff",
    boxShadow: "5px",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "15px"
  },
  handlePetCss: {
    color: "#1E293B",
    padding: "20px 20px 5px 20px",
    textAlign: "end",
    fontWeight: 700,
    fontSize: "16px",
    cursor: "pointer",
  },
  handleIncrimentDiv: {
    color: "#65C9F1",
    cursor: "pointer",
  },
  handleRemoveIconDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  handleonClickAddCat: {
    color: "#1E293B",
    display: "flex",
    justifyContent: "space-between",
  },
  handleonClickAddCatSubDiv: {
    display: "flex",
    alignItems: "center",
  },
  handleRemoveDiv: {
    display: "flex",
    alignItems: "center",
  },
  addPetSubDivDog: {
    color: "#1E293B",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  handleonClickAddDiv: {
    cursor: "pointer",
    color: "#65C9F1",
  },
  handleonClickRemoveDiv: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  totalGrid: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "20px",
    marginTop: "0px",
    fontFamily: "inter",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "22px",
    color: "#0F172A"
  },
  particia1: {
    fontFamily: "inter",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#0F172A"
  },
  sideRatingComptSecDiv: {
    fontFamily: "inter",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "18px",
    paddingBottom: "8px",
    marginTop: "5px",
    borderTop: "1px solid rgba(101, 201, 241, 0.6)"
  },
  reservButton: {
    backgroundColor: "#EA0C78",
    fontSize: "16px",
    fontWeight: 700,
    color: "#fff",
    width: "100%",
    padding: "8px",
    borderRadius: "8px",
    marginTop: "5px",
    marginBottom: "15px",
    fontFamily: "inter",
    lineHeight: "24px",
    textTransform: "none"
  },
  sideRatingComptSec: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "10px",
  },
  radioButtonGrid: {
    marginTop: "10px"
  },
  sideRatingComptForth: {
    cursor: "pointer",
    height: "34px",
    display: "flex",
    justifyContent: "space-between",
    padding: "5px 10px 5px 10px",
    backgroundColor: "#E0F4FC",
    marginTop: "10px",
    borderRadius: "8px",
    border: "1px solid #65C9F1",
    alignItems: "center",
    width: "90%"
  },

  sideRatingComptForthDisable: {
    display: "flex",
    backgroundColor: "rgba(190, 190, 190, 0.26)",
    cursor: "pointer",
    height: "34px",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "5px 10px 5px 10px",
    borderRadius: "8px",
    border: "1px solid rgba(0, 0, 0, 0.26)",
    marginTop: "10px",
    width: "90%"
  },

  sideRatingComptFifthMainContainer: {

    cursor: "pointer",
    padding: "5px 10px 5px 10px",
    backgroundColor: "#E0F4FC",
    marginTop: "10px",
    borderRadius: "8px",
    border: "1px solid #65C9F1",
  },
  sideRatingComptFifth: {

  },
  sideRatingComptFifth1: {
    fontFamily: "inter",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "22px",
    color: "#0F172A"
  },
  typRat: {
    fontFamily: "inter",
    fontWeight: 400,
    fontSize: "24px",
    lineHeight: "32px",
    color: "#0F172A"
  },
  radioStyle: {
    fontFamily: "inter",
    fontWeight: 400,
    lineHeight: "16px",
    color: "#0F172A",
    fontSize: "14px"
  },
  sideRatingComptThird: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px",
    backgroundColor: "#E0F4FC",
    borderRadius: "8px",
    marginTop: "10px",
    border: "1px solid #65C9F1",
    height: "20px"
  },
  preNewTextDiv: {
    paddingBottom: "25px",
    borderBottom: "1px solid rgba(101, 201, 241, 1)",
    marginTop: '30px'
  },
  sideRatingComptNew: {
    width: "118px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  martin: {
    fontFamily: "inter",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#0F172A"
  },
  roomsNewSec: {
    fontFamily: "inter",
    lineHeight: "22px",
    fontSize: "14px",
    fontWeight: 400,
    color: "#475569"
  },
  roomsThirdSec: {
    fontFamily: "inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#475569"
  },
  preNewText: {
    fontFamily: "inter",
    lineHeight: "28px",
    fontSize: "20px",
    fontWeight: 700,
    color: "#0F172A"
  },
  sideRatingCompt: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  hedingSideBar: {
    fontFamily: "inter",
    lineHeight: "28px",
    fontSize: "20px",
    fontWeight: 700,
    color: "#0F172A"
  },
  locationDiv: {
    display: "flex",
    alignItems: "center",
    marginTop: "20px"
  },
  textLocation: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#475569",
    fontFamily: "inter"
  },
  locationimg: {
    width: "14px",
    marginRight: "10px",
    marginLeft: "20px"
  },

  rectImgDiv: {
    display: "flex",
    alignItems: "center",
    marginTop: "30px",
    paddingBottom: "30px",
    borderBottom: "1px solid #E2E8F0"
  },
  rectImg: {
    width: "64px",
    height: "64px",
    marginRight: "10px"
  },
  cercleImg: {
    marginRight: "10px",
    borderRadius: "150px"
  },
  rectImgHeader: {
    fontSize: "18px",
    fontWeight: 700,
    color: "#334155"
  },
  rectImgText: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#64748B",
    display: "flex",
    alignItems: "center"
  },

  slefCheckHeader: {
    fontSize: "14px",
    fontWeight: 700,
    color: "#334155",
    // marginTop: "15px",
    fontFamily: "Inter",

  },
  slefCheckText: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#64748B",
    marginTop: "5px",
    fontFamily: "Inter"
  },
  selfCheckDiv: {
    marginTop: "30px",
    paddingBottom: "30px",
  },
  preText: {
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    color: "#475569"
  },
  buttonText: {
    fontSize: "16px",
    fontWeight: 600,
    color: "#64748B",
    display: "flex",
    alignItems: "center",
  },
  buttonGrid: {
    marginTop: "10px",
    padding: "6px 0px"
  },
  preTextGrid: {
    marginTop: "0px 0px",
    paddingBottom: "10px",
  },
  dogGroomerImg: {
    width: "100%",
    heigth: "136px",
    borderRadius: "8px"
  },
  dogGromerText: {
    marginTop: "7px",
    fontSize: "12px",
    fontWeight: 700,
    color: "#334155"
  },
  rooms: {
    fontFamily: "inter",
    marginLeft: "10px",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#0F172A"
  },

  roomsSec: {
    textDecoration: "underline",
    fontFamily: "inter",
    lineHeight: "22px",
    fontSize: "14px",
    fontWeight: 700,
    color: "#0F172A"
  },
  buttonNewText: {
    marginTop: "20px",
    fontSize: "16px",
    fontWeight: 700,
    color: "#334155",
    display: "flex",
    alignItems: "center",
    marginBottom: "20px"
  },
  OctoberText: {
    fontFamily: "inter",
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: 400,
    color: "#475569"
  },

  ratingNewText: {
    fontFamily: "inter",
    lineHeight: "22px",
    fontSize: "14px",
    fontWeight: 700,
    color: "#0F172A",
    marginLeft: "6px",
    textAlign: "center"
  },
  ratingSmallText: {
    fontFamily: "inter",
    lineHeight: "22px",
    fontSize: "14px",
    fontWeight: 400,
    color: "#475569"
  },

  googleMapReact: {
    width: "100%",
    height: "514px",
    marginTop: "12px",
    marginBottom: "40px"
  },
  locationText: {
    fontSize: "18px",
    fontWeight: 700,
    color: "#334155",
    marginTop: "30px"
  },
  thirdCompt: {
    marginTop: "20px",
    marginBottom: "20px",
    boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008",
    borderRadius: 15,
    height: "fit-content",
    padding: "10px 30px"
  },
  checkinDateEroorText: {
    fontSize: "14px",
    fontFamily: "inter",
    color: "red"
  },
  noAvailable:{
    color:theme.palette.common.black,
    fontWeight:theme.typography.fontWeightBold,
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    height:'250px'
    }

};
export default withStyles(DailogStyles)(PetDetailsPage)
// Customizable Area End

Object.defineProperty(exports, '__esModule', {
  value: true
});

// Customizable Area Start
exports.apiContentType = 'application/json';
exports.apiFormContentType = 'multipart/form-data';
exports.getApiMethod = 'GET';
exports.postApiMethod = 'POST';
exports.putApiMethod = 'PUT';
exports.deleteApiMethod='DELETE'

exports.getChatListApiEndPoint = 'chat/chats';
exports.showChatApiEndPoint = 'chat/chats';
exports.getChatHistoryApiEndPoint = 'chat/chats/history';
exports.searchMessageApiEndPoint = 'bx_block_chat/search_chats?search_chat[name]';
exports.searchChatGroupApiEndPoint = 'chat/chats/search';
exports.createChatRoomApiEndPoint = 'chat/chats';
exports.addUserToChatApiEndPoint = 'chat/chats/add_user';
exports.leaveChatApiEndPoint = 'chat/chats/leave';
exports.sendMessageApiEndPoint = 'chat/chats';
exports.updateReadMessageApiEndPoint = 'chat/chats/read_messages';
exports.updateChatApiEndPoint = 'chat/chats';

exports.getMyChatsApiEndpoint = 'bx_block_chat/mychats';
exports.seeChatsEndPoint="bx_block_chat/chats";
exports.sendMessageEndPoint='bx_block_chat/messages'

exports.createButtonText = 'Create';
exports.createRoomButtonText = 'Create Room'
exports.addButtonText = 'Add';
exports.closeButtonText = 'Close';
exports.addAccountText = 'Add Account';
exports.leaveChatRoomText = 'Leave Chat';
exports.sendText = 'Send';
exports.cancelText = 'Cancel';
exports.errorTitle = 'Error';
exports.errorAllFieldsAreMandatory = 'All fields are mandatory.';
exports.unreadMessageText = "Unread Messages";
exports.lastMessageText = "Last Message:";
exports.writeMessagePlaceholder = "Write message here";
exports.usersText = "Users:";
exports.accountIdPlaceholder = "Account ID";
exports.namePlaceholder = "Name";


exports.mockChatList = [
  {
    "id": "1",
    "attributes": {
      "name": "Chat 1",
      "accounts_chats": [
        {
          "attributes": {
            "account_id": "123",
            "muted": true,
            "unread_count": 5
          }
        },
        {
          "attributes": {
            "account_id": "456",
            "muted": false,
            "unread_count": 2
          }
        }
      ],
      "messages": {
        "attributes": {
          "message": "Hello from Chat 1"
        }
      }
    }
  },
  {
    "id": "2",
    "attributes": {
      "name": "Chat 2",
      "accounts_chats": [
        {
          "attributes": {
            "account_id": "123",
            "muted": false,
            "unread_count": 3
          }
        },
        {
          "attributes": {
            "account_id": "789",
            "muted": true,
            "unread_count": 0
          }
        }
      ],
      "messages": {
        "attributes": {
          "message": "Hello from Chat 2"
        }
      }
    }
  },
  {
    "id": "3",
    "attributes": {
      "name": "Chat 3",
      "accounts_chats": [
        {
          "attributes": {
            "account_id": "456",
            "muted": true,
            "unread_count": 1
          }
        },
        {
          "attributes": {
            "account_id": "123",
            "muted": false,
            "unread_count": 4
          }
        }
      ],
      "messages": {
        "attributes": {
          "message": "Hello from Chat 3"
        }
      }
    }
  }
]

  // Customizable Area End
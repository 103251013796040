export const Dog1 = require("../assets/image_pet.png")
export const locationicon = require("../assets/locationicon.png")
export const arrowback = require("../assets/Arrowback.svg")
export const cancel = require("../assets/cancel.svg")
export const arrowDown=require('../assets/arrowDown.png')
export const plusIcon=require('../assets/plus.png');
export const timeIcon=require('../assets/time.png');
export const payments=require('../assets/payment.svg');
export const threeDots=require('../assets/threeDots.png');
export const deleteIcon=require('../assets/deleteIcon.png')

//@ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

import { baseURL } from "../../bulkuploading/src/BulkUploadingController";
import { setStorageData } from "../../../framework/src/Utilities";
import { handleNavigation } from "../../../components/src/CommonFunctions";

interface LinkObject {
  url: string;
  title: string;
}

// Customizable Area End

export const configJSON = require("./config");


export interface Props {
  // Customizable Area Start
  id: string;
  navigation: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedFile: File | null;
  imageUrl: string | null;
  gender: string;
  fullName: string;
  email: string;
  phNoCountry: string;
  phoneNumber: string;
  country: string;
  streetAddress: string;
  flatNo: string;
  city: string;
  postCode: string;
  error: {
    [key: string]: string;
  };
  MesErrProfile: typeof configJSON.MesErrProfile;
  lastName: string,
  anchorElMenu: any;
  anchorElgender: any;
  selectedNum: string;
  county: string;
  profilePicture: string,
  editPencilIconStatus:boolean;
  hovermouse:number;
  photoUploaded: boolean;
  socialLinksData: {icons: string, navigationUrl: string}[];
  poBOLinksData: LinkObject[];
  // Customizable Area End
}

interface SS {
  // Customizable Area Start

  // Customizable Area End
}

export default class ContentManagementControllerBo extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  profileId: string = "";
  addPetCallPoId: string = "";
  deletePhotoApiCallId:string ="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      imageUrl: null,
      selectedFile: null,
      MesErrProfile: configJSON.MesErrProfile,
      email: "",
      gender: "Mr.",
      phNoCountry: "United Kingdom (+44)",
      fullName: "",
      country: "United Kingdom",
      phoneNumber: "",
      streetAddress: "",
      error: {},
      flatNo: "",
      postCode: "",
      city: "",
      lastName: "",
      anchorElMenu: null,
      anchorElgender: null,
      selectedNum: "01",
      county: "",
      profilePicture: "",
      editPencilIconStatus:false,
      hovermouse:0,
      photoUploaded: false,
      socialLinksData: [],
      poBOLinksData: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);


  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (apiRequestCallId === this.profileId) {
      const isResponseJson = responseJson && !responseJson.errors
      this.profileInformatationApiResponce(responseJson, isResponseJson)
    } else if (apiRequestCallId === this.addPetCallPoId) {
      localStorage.setItem("profileIdBos", responseJson.data.id)
      if (responseJson && !responseJson.errors) {
        this.setState({
          profilePicture: responseJson?.data?.attributes?.photo ? responseJson.data.attributes.photo : null,

        }, () => {
          setStorageData('accountDetails', JSON.stringify(responseJson?.data?.attributes))

          handleNavigation('BownerDetaild', {}, this.send, this.props);

        })

      }
    }
   if(apiRequestCallId === this.deletePhotoApiCallId){
    this.setState({profilePicture:null})
   }
    // Customizable Area End
  }

  // Customizable Area Start


  setProfileStates = (attributes) => {
    const {
      city,
      postcode,
      photo,
      email,
      first_name,
      last_name,
      country,
      full_phone_number,
      street_line_2,
      address_line_1,
      phone_number_country_code,
      number_of_business,
      county,
      name_title
    } = attributes;
  
    this.setState({
      city: city || "",
      postCode: postcode || "",
      imageUrl: photo ? photo : null,
      photoUploaded: photo ? true : false,
      email: email,
      fullName: first_name || "",
      lastName: last_name || "",
      country: country || "United Kingdom",
      phoneNumber: full_phone_number || "",
      flatNo: street_line_2 || "",
      streetAddress: address_line_1 || "",
      phNoCountry: phone_number_country_code || "United Kingdom (+44)",
      // selectedNum: number_of_business || "01",
      county: county || "",
      gender: name_title || "Mr.",
      profilePicture: photo ? photo : null,
    });
  }
  
  profileInformatationApiResponce = (responseJson: any, isResponseJson: boolean) => {
    if (isResponseJson) {
      this.setProfileStates(responseJson.data.attributes);
    } else if (responseJson.errors[0]?.token === 'Invalid token') {
      this.props.navigation.navigate('LandingPage');
    }
  }
  



  handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    const imgUrl = URL.createObjectURL(file)
    if (file) {
      this.setState({
        imageUrl: URL.createObjectURL(file),
        selectedFile: file,
        editPencilIconStatus:false,
        photoUploaded: true,
      });

    } else {
      this.setState({
        imageUrl: null,
        selectedFile: null,
        photoUploaded: false,
      });
    }
  };

  handleInputChange = (
    event: React.ChangeEvent<
      HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = event.target;
    let newValueBo = value;
    this.setState((prevState) => ({

      ...prevState,
      [name]: newValueBo,
      error: {
        ...prevState.error,
        [`${name}Error`]: newValueBo ? "" : `${name} is required`,
      },
    }));
  };

  checkAllFieldValidate = (fieldBo: keyof S): boolean => {
    const MesErrProfile = configJSON.MesErrProfile;
    if (!this.state[fieldBo]) {
      this.setState((prevState) => ({
        ...prevState,
        error: {
          ...prevState.error, [`${fieldBo}Error`]: MesErrProfile[fieldBo] ? MesErrProfile[fieldBo] : `${fieldBo} is required.`,
        },
      }));
      return false;
    } else {
      this.setState((prevState) => ({
        ...prevState,
        error: {
          ...prevState.error,
          [`${fieldBo}Error`]: '',
        },
      }));
      return true;
    }
  };

  handelCountryOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleGenderopen = (event: React.MouseEvent<HTMLDivElement>) => {
    this.setState({ anchorElgender: event.currentTarget })
  }

  handleGenderClose = (value: string) => {
    this.setState({
      gender: value ? value : "Mr.",
      anchorElgender: null
    })
  }

  handelNumOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    this.setState({ anchorElMenu: event.currentTarget });
  }

  handelNumCloses = (value: string) => {
    this.setState({
      anchorElMenu: null
    })
  }

  handelCountryCloses = (value: string) => {
    this.setState({
      country: value ? value : "United Kingdom",
      anchorEl: null
    })
  }
  handleCountry = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> | any) => {
    const value = event.target.value ? event.target.value as string : "United Kingdom";
    this.setState({
      country: value,
    });
  };

  handelPhCountryCloses = (value: string, code: string) => {
    const countryCode = code ? code : "(+44)";
    const selectedValue = value ? value : "United Kingdom";
    const phNoCountry =  selectedValue + " " + countryCode;

    this.setState({
      phNoCountry: phNoCountry,
      anchorEl: null
    });
  };
  backHandle = () => {
    this.props.navigation.navigate("BownerDetaild")
  }

  handleApi = (event: React.MouseEvent<HTMLButtonElement>) => {

    // setStorageData('BussinessCount', this.state.selectedNum)

    event.preventDefault()
    const fields: (keyof S)[] = [
      "fullName", "lastName", "email",
      "phoneNumber",
      "streetAddress",
      "city",
      "postCode",
    ];
    let hasError = false;
    fields.forEach((field) => {
      if (!this.checkAllFieldValidate(field)) {
        hasError = true;
      }
    });

    if (!hasError) {



      let tokenValue = localStorage.getItem("authToken");
      let selectedProfilePoId = localStorage.getItem("profileIdBo");
      const headers = {
        token: tokenValue,
      };
      let formdata = new FormData();
      // formdata.append("data[number_of_business]", this.state.selectedNum)
      formdata.append("data[phone_number_country_code]", this.state.phNoCountry)
      formdata.append("data[first_name]", this.state.fullName);
      formdata.append("data[last_name]", this.state.lastName);
      formdata.append("data[postcode]", this.state.postCode);
      formdata.append("data[email]", this.state.email);
      formdata.append("data[city]", this.state.city);
      formdata.append("data[full_phone_number]", this.state.phoneNumber);
      formdata.append("data[address_line_2]", this.state.flatNo);
      formdata.append("data[country]", this.state.country);
      formdata.append("data[address_line_1]", this.state.streetAddress);
      formdata.append("data[county]", this.state.county)
      formdata.append("data[name_title]", this.state.gender)

      this.state.selectedFile &&
        formdata.append("data[photo]", this.state.selectedFile);

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `account_block/accounts/${selectedProfilePoId}`

      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formdata
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "PUT"
      );

      this.addPetCallPoId = requestMessage.messageId;

      runEngine.sendMessage(requestMessage.id, requestMessage);

    if (this.state.isImageDeleted) {
      this.callDeleteImageApi();
    }
    }
  }

  callApiForBoLogIn = () => {
    let tokenValue = localStorage.getItem("authToken");
    const headers = {
      token: tokenValue,
    };

    const loginRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.profileId = loginRequestMessage.messageId;
    loginRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/account_details`
    );

    loginRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    loginRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(loginRequestMessage.id, loginRequestMessage);
  };

  async componentDidMount() {

    this.callApiForBoLogIn();
    const mySocialLinksData = await getStorageData("footerNavigationUrl", true);
    this.setState({socialLinksData: mySocialLinksData });
    const allLinksData = await getStorageData("BOandPOLinks",true)
    this.setState({poBOLinksData: allLinksData}) 
  }

  handleMenuItemClick = async (selectedIndex: number) => {
    const formattedValue = String(selectedIndex).padStart(2, '0');

    this.setState({ selectedNum: formattedValue }, () => {


      this.handelNumCloses();
    });
  };
  handleEditPencilIcon=()=>{
    this.setState({editPencilIconStatus:!this.state.editPencilIconStatus})
  }
  handleDeleteImage = () => {
    this.setState({ imageUrl: "", editPencilIconStatus: false, photoUploaded: false, isImageDeleted: true });
  }
  
  callDeleteImageApi = () => {
    const deletephotoHeader = {
      "Content-Type": "application/json",
      token: localStorage.getItem("authToken")
    };

    const deleteRequestMessageBO = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    deleteRequestMessageBO.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/remove_profile_photo`
    );

    deleteRequestMessageBO.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(deletephotoHeader)
    );
    deleteRequestMessageBO.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      `DELETE`
    );
    this.deletePhotoApiCallId = deleteRequestMessageBO.messageId;
    runEngine.sendMessage(deleteRequestMessageBO.id, deleteRequestMessageBO);

    this.setState({ isImageDeleted: false });
  }

  // Customizable Area End
}

import React from 'react';
import {
  Menu,
  MenuItem,
  Divider
} from "@material-ui/core";

interface CountryMenuProps {
  anchorEl: Element | null;
  onClose: any;
  configJSON: any;
  listType: string;
  user:string;
  width:string
}

const CountryMenu: React.FC<CountryMenuProps> = ({ anchorEl, onClose, configJSON, listType ,user,width}) => {


  return (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={() => onClose('', '')}
      data-test-id="handleSelectClose"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      getContentAnchorEl={null}
      PaperProps={{
        style: {
          width:width,
          padding: '0px 10px',
          cursor: 'pointer',
          maxHeight: '300px',
          overflowY: 'auto',
          scrollbarColor: '#94A3B8 transparent',
          scrollbarWidth: 'thin',
          marginTop: '5px',
          borderRadius: '8px',
        },
      }}
    >

  {listType === "countries" && configJSON.countries.map((value: { label: string; phone: string; }) => (
        <React.Fragment key={value.label}>
          <MenuItem
            data-test-id="countrymap"
            value={value.label}
            onClick={() => onClose(value.label, value.phone)}
            style={{ borderRadius: '8px', width: '90%', height: '44px' }}
          >
            {`${value.label} ${value.phone}`}
          </MenuItem>
          <Divider style={{ margin: '2px', backgroundColor:user==="po"?"#F1F5F9": '#B2E4F8' }} />
        </React.Fragment>
      ))}

   {listType === "dogbreed" && configJSON.map((value: any) => (

     
     <React.Fragment key={value.label}>
          
          <MenuItem
            data-test-id="countrymap"
            value={value.label}
            onClick={() => onClose(value)}
            style={{ borderRadius: '8px', width: '90%', height: '44px' }}
          >
            {`${value}`}
          </MenuItem>
          <Divider style={{ margin: '2px', backgroundColor: '#F1F5F9' }} />
        </React.Fragment>
      ))}

   {listType === "catbreed" && configJSON.map((value: any) => (
        <React.Fragment key={value.label}>
          <MenuItem
            data-test-id="countrymap"
            value={value.label}
            onClick={() => onClose(value)}
            style={{ borderRadius: '8px', width: '90%', height: '44px' }}
          >
            {`${value}`}
          </MenuItem>
          <Divider style={{ margin: '2px', backgroundColor: '#F1F5F9' }} />
        </React.Fragment>
      ))}

    {listType === "country" && configJSON.countries.map((value: { label: string}) => (
        <React.Fragment key={value.label}>
          <MenuItem
            data-test-id="countrymap"
            value={value.label}
            onClick={() => onClose(value.label)}
            style={{ borderRadius: '8px', width: '90%', height: '44px' }}
          >
            {`${value.label}`}
          </MenuItem>
          <Divider style={{ margin: '2px', backgroundColor:user==="po"?"#F1F5F9": '#B2E4F8' }} />
        </React.Fragment>
      ))}

    </Menu>
  );
};

export default CountryMenu;

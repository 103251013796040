Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";

exports.btnExampleTitle = "CLICK ME";

exports.getAccountInformationEmdPoint='account_block/account_details'

exports.notFoundData="No Hotels Found"
exports.socialLinksEndPoint = "bx_block_content_management/social_links";

exports.poLinksEndPoint="bx_block_content_management/po_links/"
exports.featuredHotelListEndPoint="bx_block_custom_form/hotels?popular_filters[]={petType}&is_featured=true"

// Customizable Area End
Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/bx_block_dashboard/candidates";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.totalCandidateslabelTitleText = "Total Candidates";
exports.viewDetailsBtn = "View Details";
exports.candidateslabelTitleText = "Candidates";
exports.baseUrl = ""
exports.labelBodyText = "dashboard Body";
exports.updateApiMethodType="PUT"
exports.createApiMethodType="POST"
exports.deleteMethiodType='DELETE'


exports.HotelListEndPoint="bx_block_custom_form/all_properties"
exports.getReviewsEndPoint="bx_block_reviews/list_hotel_reviews?hotel_id="
exports.getCancelationPolicyEndPoint="bx_block_settings/cancellation_policies?hotel_id="
exports.standardPolicyEndPoint="bx_block_settings/standard_cancellations"
exports.searchPropertyEndPoint='bx_block_hotel/search_properties?query'
exports.updateCancellationEndPoint="bx_block_settings/cancellation_policies"
exports.getPaymentInformationEndPoint="bx_block_stripe_integration/show_connected_account?connected_account[hotel_id]"
exports.updateHotelEndPoint="bx_block_custom_form/hotels"
exports.coverPhotoEndPoint="bx_block_custom_form/hotels/cover_image"
exports.destroyPhotoEndPoint="bx_block_custom_form/hotels/destroy_images"
exports.updatePaymentEndPoint="/bx_block_stripe_integration/stripe_connected_accounts"
exports.additionalServicesEndPoint='bx_block_custom_form/hotels/additional_services'
exports.getRoomsEndPoint='bx_block_custom_form/rooms?hotel_id',
exports.getRepresentativeDetails="/bx_block_custom_form/seller_accounts/representative_details_list"
exports.updateDeleteRepresentativeDetails="/bx_block_custom_form/seller_accounts/representative_details"
exports.blockOffDaysEndPoint="bx_block_custom_form/blocked_off_days";
exports.additionalServicesEndPoint="bx_block_custom_form/hotels/additional_services"
exports.url = "https://api.maptiler.com/maps/streets-v2/256/{z}/{x}/{y}.png?key=SX5K85uAbJBHcmznSrkR";
exports.googleUrl = "https://nominatim.openstreetmap.org/search?postalcode=";
exports.params = "&format=json";



exports.emailRegex=/^\s*[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\s*$/;
exports.nameRegex=/^\s*[a-zA-Z]+(?:\s[a-zA-Z]+)*\s*$/;
exports.numerRegex= /^\s*\d+\s*$/;
exports.pdfRegex = /\.pdf$/i;

exports.propertyText={
  property:"Property Name*",
  edit:"Edit",
  save:"Save",
  licience:"License Number*",
  contact:'Contact Details*',
  description:'Description*',
  days:'Blocked Off Days',
  address:"Address",
  represenatative:"Representative Contact Details",
  represenatativeNumber:"Representive Phone Number*",
  select:'Select dates',
  clear:'Clear dates',
  reviews:"Pet owner reviews",
  cancellation:"Choose Cancellation Policy for this Business*",
  standard:"Standard Cancellation Policy",
  anyTime:"You can change it anytime.",
  dropDown:"Additional Conditions and Questions of the hotel",
  conditions:" Additional conditions of the hotel",
  placeHolderConditions:"Additional conditions",
  question1:" Additional question",
  placeHolderQuestion:"Enter additional questions",
  photos:"Photos (Minimum 5 photos required)",

  question2:" Additional question",
  question3:" Additional question",
  editAddress1:"House number and street name*",
  editAddress2:" Additional Address Information",
  optional:"(Optional)",
  editAddress3:"Town/City*",
  editAddress4:"County",
  editAddress5:"Postcode*",
  editAddress6:"Country/Region",
  postcode:"Post Code*",
  emptyDays:"No Blocked Off Days Found"
}

exports.NoReviews="No Reviews Ratings Found"



exports.tailored={
  heading:"Tailored Cancellation Policy",
  point1:"1.Until when can the pet owner cancel their reservation free of charge",
  point2:"2.Until when can the pet owner amend (extend the amount of days or reduce the amount of days) their reservation free of charge.",
  point3:"3.There is an option to charge a deposit or a pre-payment. Which would you prefer?",
  deposit:"Deposit",
  prePayment:"Pre-payment",
  client:"If the client cancels within the allowed free of charge cancellation period deposit will be returned to the customer",
  placeHolder1:"Please confirm the amount (no more than £100)",
  placeHolder2:"If the client does not turn up, you will hold on to the following %",
  placeHolder3:"Please select the % of pre-payment"
}


exports.paymentInformation={
  heading:"Information for Stripe Account",
  bussniessLabel:"Business Type*",
  firstName:"First Name*",
  surname:"Surname*",
  email:"Email*",
  Phonenumber:"Phone number*",
  dob:"DOB",
  address:"Address",
  addressSubText:"(Address of person opening the account)",
  line1:"Address line 1*",
  city:"City*",
  state:"State*",
  postcode:"Postcode*",
  country:"Country/Region",
  optional:"(Optional)",
  pofile:"Bussiness profile",
  merchantcode:"Merchant Category Code-MCC*",
  name:"Name*",
  url:"URL",
  external:"External Account",
  type:"Account Type",
  currency:"Currency*",
  accountName:"Account Holder Name",
  swift:"Routing Number / Swift Code*",
  number:"Account Number*",
  document:"Proof of Identity Document*",
  companyName:" Company Name*",
  businessName:"Business Name*",
  companyEmail:" Company Email*",
  companyPhone:" Company Phone Number*",
  companyState:" Company State*",
  companyTax:" Company Tax*",
  companyCity:" Company City*",
  companyAddress:"Company Address*",
  companyPostcode:"Company Postcode*",




}

exports.verifiactionKeys={
  pending:"Pending",
  verified:"Verfied",
  failed:"Verification Failed"
}


exports.rooms={
  heading1:"Number of rooms at the property",
  heading2:{
    type:"Type of room",
    additionalServices:"Additional services offered",
    dogs:"Number of dogs in one room",
    catrooms:"Number of dogs in one room"
  },
  heading5:"",
}


exports.propertyFilter={
  petlabel:"Pet type",
  status:"Status"
}

exports.typeofRooms={
  setupMore:"Setup Your Room Prices*",
  addMore:"Add Room Type"
}

exports.emailError="Please enter valid email address"
exports.roomErrorMessages={
  roomTypeEmpty:"Please enter the room type name",
  roomTextNotValid:"Room name can only contain letters, numbers, and spaces",
  descriptionEmpty:"Please enter the room description",
  descriptionTextNotValid:"Room description can only contain letters, numbers, spaces, and common punctuation",
  roomPricesEmpty:'Please enter the room price'
}
exports.socialLinksEndPoint = "bx_block_content_management/social_links";
exports.boLinksEndPoint="bx_block_content_management/bo_links/"

exports.dayCareEndpoint = "bx_block_custom_form/hotel_day_care_services?hotel_id={hotelId}"
exports.oneDay = "Day";
exports.days = "Days";
exports.priceFor = "Price for";
exports.priceSettings = "Set the price for daycare based on the number of days:";
exports.price = "Prices and Discounts";
exports.changeAnyTime = "You can change it anytime.";
exports.weeklyPrice = "Set the Weekly price for daycare based on the number of days:";
exports.saveAndContinue = "Save Changes";
exports.morethan30DaysText = "Allow stays over 30 nights?";
exports.editDayCareEndpoint = "bx_block_custom_form/day_care_services/{hotelId}";
exports.disableText = "Is Active?";
exports.disableEndpoints = "bx_block_custom_form/hotels/destroy_room?hotel_id={hotelId}&room_id={roomId}";
exports.enableEndpoints = "bx_block_custom_form/rooms/12?room[room_id]={roomId}&room[hotel_id]={hotelId}&room[status]=active";
exports.deleteRoomEndpoint="/bx_block_custom_form/rooms/[room_id]"
exports.regex = /^[0-9]*(\.[0-9]{0,2})?$/;
exports.numberRegex=/^\d*$/;
exports.spacesText="The number of spaces";
// Customizable Area End
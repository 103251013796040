// CustomDate component
import { Box, ClickAwayListener, InputAdornment, TextField, Theme, WithStyles, styled, withStyles } from "@material-ui/core";
import React from "react";
import DatePicker, { DateObject } from "react-multi-date-picker";
export const CalendarIcon = require('./calenderIcon.png')
import { Calendar } from "react-multi-date-picker";
import { boxShadows } from "./theme";
import moment from "moment";

export const CustomCalendar = styled(Box)(({ theme }: any) => ({
  "& .rmdp-wrapper": {
    width: '100%',
  },
  "& .rmdp-calendar": {
    width: '100%',
  },
  "& .rmdp-input": {
    backgroundColor: "#E0F4FC",
    height: "34px",
    width: "265%",
    borderRadius: "8px"
  },
  "& .rmdp-header": {
    backgroundColor: "#CCEDFB",
    borderRadius: 8,
    fontSize: "14px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  "& .rmdp-week-day": {
    color: "#000"
  },
  "& .rmdp-day.rmdp-selected span:not(.highlight) ": {
    backgroundColor: "#d9000a",

  },
  "& .rmdp-day.rmdp-today span": {
    backgroundColor: "#fff",
    color: "#fff"
  },
  '& .rmdp-day-picker': {
    '& div': {
      width: '100%'
    }
  },
  "& .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:focus": {
    backgroundColor: theme.palette.secondary.main,
    color: "#fff",
    fontFamily: theme.typography.fontFamily
  },
  "& .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover": {
    backgroundColor: theme.palette.secondary.main,
    color: "#fff",
    fontFamily: theme.typography.fontFamily
  },
  "& .rmdp-left": {
    display: "none"
  },
  "& .rmdp-right i": {
    marginRight: "3px",
    WebkitTransform: "rotate(45deg)",
  },
  "& .rmdp-arrow": {
    borderColor: "red"
  }
}));



export const CustomizedCalenderTextField = withStyles({
  root: {
    "& .MuiInputBase-root": {
      width: "100%",
      fontFamily: "Inter",
      height: 44,
      color: "#000000",
      fontWeight: 700,
      fontSize: 14,
      backgroundColor: "#E0F4FC",
      borderColor: "#65C9F1",
      borderRadius: 8,
      border: '1px solid #65C9F1'
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: '1px solid #65C9F1'
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: '1px solid #65C9F1'
    },
    "& .MuiOutlinedInput-root:hover": {
      borderColor: "65C9F1",
      border: '1px solid #65C9F1'
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "#E0F4FC",
      border: '1px solid #65C9F1'
    },
    "& .MuiSvgIcon-root": {
      fontSize: "25px"
    }
  }
})(TextField);

interface CustomDateProps {
  value?: DateObject | null;
  onChange?: (date: DateObject) => void;
  readOnly: boolean;
}

interface CustomiZedCalenderProps extends WithStyles {
  onChange?: (date: DateObject) => void;
  dateValue?: string

}

class CustomDate extends React.Component<CustomDateProps> {
  render() {
    const { value, onChange, readOnly } = this.props;

    return (
      <DatePicker
        format="DD MMMM YYYY"
        weekStartDayIndex={1}
        value={value}
        onChange={onChange}
        placeholder="Choose date"
        maxDate={new Date()} 
        style={{fontFamily:"inter"}}
        readOnly={readOnly}
      />
    );
  }
}

export default CustomDate;


export class CustomizedCalender extends React.Component<CustomiZedCalenderProps>{
  state = {
    calenderStatus: false
  }
  openCalender = () => {
    this.setState({ calenderStatus: true })
  }
  closeCalender = () => {
    this.setState({ calenderStatus: false })

  }
  renderCalender = () => {
    const { classes } = this.props;

    return (
      <>
        <ClickAwayListener onClickAway={this.closeCalender}>
          <Box style={webStyle.modalPopup}>
            <Box>
              <CustomCalendar >
                <Calendar
                 weekStartDayIndex={1}
                  format="DD MMMM YYYY"
                  onChange={this.props.onChange}
                />
              </CustomCalendar>
            </Box>
          </Box>

        </ClickAwayListener>
      </>
    )
  }
  render() {
    const { classes } = this.props;
    return (

      <>
        <CustomizedCalenderTextField
          variant='outlined'
          fullWidth         
          value= {this.props.dateValue}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <img src={CalendarIcon} width={24} height={24} />
              </InputAdornment>
            ),
          }}
          onClick={this.openCalender}
        />
        {this.state.calenderStatus &&
          this.renderCalender()
        }
      </>

    )
  }

}


const webStyle = {
  modalPopup: {
    position: "absolute" as const,
    zIndex: 9,
    width: '100%',
    borderRadius: "8px",
  },
}

import React from "react";

// Customizable Area Start
import { Grid, Typography, Container, CssBaseline } from "@material-ui/core";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import Navbar from "../../../components/src/Navbar";
import { Link } from "react-router-dom";
// Customizable Area End

import TermsConditionsController, {
  Props,
  configJSON,
  ITermsConds,
} from "./TermsConditionsController";

export default class TermsConditions extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start


    return (
      <>
      {this.state.userType!="BO"&&<Navbar navType="fullNavbar"/>}
        <React.Fragment>
          <CssBaseline />
          <div style={this.state.userType!="BO"?termscon.poLayout:termscon.boLayout}>
            <Grid container style={{ marginTop: "10px" }}>
              <Grid item lg={12} xs={12} style={termscon.gridOne}>
                <Grid style={{ alignItems: "start" }}>
                  <Grid item xs={12} sm={2} style={termscon.linkCss}></Grid>
                  <Grid item>
                    <Grid style={{ display: "flex", alignItems: "center", borderBottom:"1px solid #1E293B", paddingBottom:"10px" }}>
                      <div
                      onClick={this.handleBack}
                       
                        style={termscon.gridMaincss}
                      >
                        <KeyboardArrowLeftIcon
                          style={{ fontSize: "55px", color: "#0F172A", paddingRight:"7%" }}
                        />{" "}
                      
                      <Typography
                        variant="h6"
                        style={
                          termscon.typographyGridCss as React.CSSProperties
                        }
                      >
                        {this.getTitle()}
                      </Typography>
                      </div>
                    </Grid>

                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.state.termsAndConditon,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </React.Fragment>
      </>
    );

    // Customizable Area End
  }
}

// Customizable Area Start
const termscon = {
  gridOne: {
    fontSize: "18px",
    color: "rgba(100, 116, 139, 1)",
  },
  linkCss: {
    marginTop: "10px",
    color: "rgba(100, 116, 139, 1)",
    fontSize: "18px",
    fontWeight: 700,
  },
  gridMaincss: {
    display: "flex",
    color: "#64748B",
    textDecoration: "none",
    cursor:"pointer"
  },
  typographyGridCss: {
    textAlign: "left",
    fontSize: "30px",
    fontWeight: 700,
    color: "#334155",
    marginTop: "0px",
    fontFamily:"inter"
  },
  boLayout:{
    height: "100vh",marginTop:'10px' , padding:"0% 7%"
  },
  poLayout:{
    height: "100vh",marginTop:'110px' , padding:"0% 7%"
  }
};
// Customizable Area End

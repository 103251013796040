import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";


interface myProps {
  loading: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  loaderStyle: {
    zIndex: 1000 + 1,
    color: theme.palette.info.main,
    justifyContent: 'center',
    alignItems: 'center',
  }
}));

export default function Loader(props: myProps) {
  const classes = useStyles();
  return props.loading ? (
    <Backdrop className={classes.loaderStyle} open={props?.loading} >
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : (
    <div />
  );
}

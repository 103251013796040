export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/down.png");
export const checked = require("../assets/check_box.png");
export const unchecked = require("../assets/blank_check_box.png");
export const Delete = require("../assets/delete.png");
export const mapImage =require('../assets/map.png')
export const ratingStarIcon =require('../assets/ratingStar.png')
export const catsImage=require('../assets/cats.svg')
export const dogImage=require('../assets/dogs.svg')
export const catsDogsImage=require('../assets/dog_cat.svg')
export const hotelServiceImage=require('../assets/hotelServices.svg')
export const dayCareServiceImage=require('../assets/daycare.svg')
export const grommingServiceImage=require('../assets/grommingServices.svg')
export const whiteArrowLeft=require('../assets/whiteArrowLeft.svg');
export const arrowRight=require('../assets/arrowRight.svg')
export const whiteStarIcon=require('../assets/whiteStarIcon.png')


import React from "react";
// Customizable Area Start
import { Typography, Grid, Box, ClickAwayListener, MenuItem, Divider, RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import SinglePropertyController, {
    Props, configJSON,
} from "./SinglePropertDetailsController.web";
import { CustomizedTextField } from "./SinglePropertiesDetails.web";
import { arrowDown } from "../../shoppingcart/src/assets";
import { Location, locationSymbol, markerIconPng } from "../../catalogue/src/assets";
import Loader from "../../../components/src/Loader.web";
import CustomizedDailog from "../../../components/src/CustomizedDailog.web";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
const countryCodes = require('../../settings2/src/config');
import { Calendar, DateObject } from "react-multi-date-picker";
import { BlockOffDays, RepresentativeDetail } from "./types";
import CustomizedSnackbars from "../../../components/src/CustomSnackbar.web";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { MapContainer as Container, TileLayer } from "react-leaflet";
import Maps from "../../../components/src/Maps";
import { location } from "../../user-profile-basic/src/assets";
import { styled, withStyles } from "@material-ui/core/styles";
import MapMarker, { HotelDetailsData } from "../../../components/src/MapMarker";
import { theme } from "../../../components/src/theme";

// Customizable Area End


export default class AboutListing extends SinglePropertyController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    morePropertyDetailsDexcription = () => {
        const { classes } = this.props;
        return (
            <>
                <Box paddingLeft={'30px'}>
                    <Typography variant="h4" className={classes.tabText}>About this property</Typography>
                </Box>
                <Box paddingLeft={"30px"} paddingRight={"30px"} marginTop={"20px"}>
                    <Typography variant="body1" color="textPrimary" className={classes.detailsProperty}>
                        {this.state.hotelDetails?.attributes?.description && this.state.hotelDetails?.attributes.description.split(/\r?\n/).map((line: string, index: number) => (
                            <React.Fragment key={index}>
                                {line}
                                <br />
                            </React.Fragment>
                        ))}
                    </Typography>
                </Box>
            </>
        )
    }
    rendernameTypes = (type: string, repIndex: number) => {
        const { classes } = this.props;
        return (
            <ClickAwayListener onClickAway={() => this.closeNameTypeDropDowns(type)}>
                <Box className={classes.typeInner}>
                    <Box className={classes.outer}>
                        {
                            ["Mr.", "Miss", "Mrs.", "Mrx."].map((value: string, index: number) => {
                                return (
                                    <>
                                        <MenuItem className={classes.dropDownText}
                                            data-test-id="select_nameType"
                                            key={index}
                                            value={value}
                                            onClick={() => { this.handleSelectNameType(value, repIndex) }}
                                        >
                                            {value}
                                        </MenuItem>
                                        <Divider />
                                    </>
                                )
                            })
                        }


                    </Box>
                </Box>
            </ClickAwayListener>)

    }
    renderMoreThan30NightStay = () => {
        const { classes } = this.props
        const { stayMoreThan30NightStatus, stayMoreThan30Days, hotelForm } = this.state
        const { stayMoreThan30Night } = hotelForm
        return (
            <>
                <Grid item>
                    <Typography
                        data-test-id="edit_nightStay"
                        onClick={stayMoreThan30NightStatus ? this.manageUpdateHotelDetails : () => this.listingFormEdit('stayMoreThan30Night')}
                        variant="body1"
                        className={classes.editText}
                    >
                        {stayMoreThan30NightStatus ? 'Save' : 'Edit'}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    {stayMoreThan30NightStatus ? (
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <div style={{ alignItems: "center", display: "flex", marginRight: "2%" }}>
                                <RadioGroup
                                    value={stayMoreThan30Days || stayMoreThan30Night}
                                    onChange={this.handleCheckboxChange}
                                    style={{ display: "flex !important", flexDirection: "row" }}
                                    data-test-id="radiotype_own"
                                >
                                    <FormControlLabel
                                        value="true"
                                        label="Yes"
                                        control={<Radio />}
                                        style={{
                                            borderRadius: "8px",
                                            height: "33px",
                                            width: "100%",
                                            whiteSpace: "nowrap"
                                        }}
                                        data-test-id="mrvalue"
                                    />
                                    <FormControlLabel
                                        control={<Radio />}
                                        value="false"
                                        label="No"
                                        style={{
                                            width: "90%",
                                            height: "33px",
                                            borderRadius: "8px",
                                        }}
                                        data-test-id="missvalue"
                                    />
                                </RadioGroup>
                            </div>
                        </div>
                    ) : (
                        <Typography variant="body1" className={classes.tabText}>
                            {stayMoreThan30Night === 'true' ? 'Yes' : "No"}
                        </Typography>
                    )}
                </Grid>
            </>
        )
    }
    renderCountryCodes = (type: string, repId: number) => {
        const { classes } = this.props;
        return (
            <>
                <ClickAwayListener onClickAway={() => this.closeNameTypeDropDowns(type)}>
                    <Box className={classes.typeInnerCountry}>
                        <Box className={classes.outer}>
                            {
                                countryCodes.countries.map((country: any, index: any) => (
                                    <>
                                        <MenuItem className={classes.menuItemText}
                                            data-test-id="select_country_code"
                                            key={index}
                                            value={`${country.label} ${country.phone}`}
                                            onClick={() => this.handleSelectCountryName(`${country.label} ${country.phone}`, type, repId)}
                                        >
                                            {`${country.label} ${country.phone}`}
                                        </MenuItem>
                                        <Divider />
                                    </>
                                ))}


                        </Box>
                    </Box>
                </ClickAwayListener>
            </>
        )
    }
    renderMapSection(hotelData: HotelDetailsData): JSX.Element {
        const { classes } = this.props;

        return (
            <Grid style={step3Styles.mainContainerDiv}>
                <div style={step3Styles.mapGrid}>
                    <MapBox data-test-id="mapBtnTestID">
                        <Container
                            zoomControl={false}
                            center={this.state.coords}
                            zoom={25}
                            className="mapCOntainer"
                            data-test-id="mapBtnsTestID"
                        >
                            <TileLayer
                                url={configJSON.url}
                            />
                            {
                                this.state.isMapSet ? (
                                    <Maps coords={this.state.coords} markerIconPng={location} />
                                ) : (
                                    this.getPosition(hotelData) && (
                                        <MapMarker
                                            markerIconPng={markerIconPng}
                                            coords={[hotelData?.attributes?.latitude, hotelData?.attributes?.longitude]}
                                            locationIcon={locationSymbol}
                                            classes={classes}
                                            hotelData={hotelData}
                                        />
                                    )
                                )
                            }
                        </Container>
                    </MapBox>
                </div>
            </Grid>
        );
    }
    renderAddressCountry = (type: string,) => {
        const { classes } = this.props;
        return (
            <>
                <ClickAwayListener onClickAway={() => this.closeNameTypeDropDowns(type)}>
                    <Box className={classes.typeInnerCountry}>
                        <Box className={classes.outer}>
                            {
                                countryCodes.countries.map((country: any, index: any) => (
                                    <>
                                        <MenuItem className={classes.menuItemText}
                                            data-test-id="select_country_code"
                                            key={index}
                                            value={country.label}
                                            onClick={() => this.handleSelectAdressCountry(country.label, type,)}

                                        >
                                            {country.label}
                                        </MenuItem>
                                        <Divider />
                                    </>
                                ))}


                        </Box>
                    </Box>
                </ClickAwayListener>
            </>
        )
    }

    calendarRender = (days: BlockOffDays, index: number) => {
        const { classes } = this.props
        return (
            <>
                <ClickAwayListener onClickAway={() => this.closeBlockedCalander()}>
                    <div className={classes.calenderSection}>
                        <Calendar
                            onChange={(values: DateObject[]) => this.handleDateChangeBlockOffDays(index, values)}
                            data-test-id="select_calender"
                            weekStartDayIndex={1}
                            range
                            numberOfMonths={2}
                            minDate={days.type === 'new' ? new Date() : days.fromDate}
                            value={days.selectedBlockOffDateRange}
                            className={classes.blockoffDaysCalender}
                        >
                        </Calendar>
                    </div>
                </ClickAwayListener>
            </>
        )
    }

    renderEmptyBlockOffDays = () => {
        return (
            <>
                {this.state.blockOffDays.length === 0 && <Grid item xs={12}>
                    <Grid container direction="row" justifyContent="center" alignItems="center" style={step3Styles.noemptyText}>
                        <Grid item xs={12}>
                            <Typography align="center" variant="subtitle2" style={step3Styles.boldText}>{configJSON.propertyText.emptyDays}</Typography>
                        </Grid>

                    </Grid>

                </Grid>
                }

            </>
        )
    }

    renderBlockOffDays = () => {
        const { classes } = this.props;
        const { blockOffDaysEditStatus } = this.state;
        return (
            <>
                <Grid item xs={12}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Typography variant="body1" className={classes.labelText}>{configJSON.propertyText.days}</Typography>

                        </Grid>
                        <Grid item>
                            <Typography data-test-id='edit_block_off'
                                onClick={blockOffDaysEditStatus ? this.addBlockOffDays : () => { this.listingFormEdit('days') }}
                                variant="body1" className={classes.editText}>
                                {blockOffDaysEditStatus ? "Add more" : configJSON.propertyText.edit}
                            </Typography>

                        </Grid>

                        <Grid item xs={12} style={{ position: 'relative' }}>
                            {blockOffDaysEditStatus ? <>
                                {this.state.blockOffDays.map((days: BlockOffDays, index: number) => {
                                    return (
                                        <>
                                            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} marginTop={'15px'}>
                                                <Box marginRight={'10px'}>
                                                    <Typography variant="body1" className={classes.labelText}>Blocked off days ({index + 1})</Typography>
                                                </Box>
                                                <Box display={'flex'} alignItems={'center'}>
                                                    <Box marginRight={'15px'}>
                                                        <Typography variant="body1" className={classes.editText}
                                                            data-test-id='save_block'
                                                            onClick={() => this.updateBlockOffDays(days)}
                                                        >
                                                            {days.type === 'new' ? "Save" : "Update"}
                                                        </Typography>
                                                    </Box>
                                                    <Box className={classes.cursor} onClick={() => this.delteBlockOffDays(days)} data-test-id='delete_days' >
                                                        <Typography variant="body1" className={classes.editText}>Delete</Typography>
                                                    </Box>

                                                </Box>

                                            </Box>
                                            <Box className={classes.blockOffDaysBox}
                                                data-test-id='open_cal'
                                                key={index}
                                                onClick={() => this.openBlockedCalander(days.id)}>
                                                <Box>
                                                    <CalendarTodayIcon
                                                        style={{

                                                            color: "#326478"
                                                        }}
                                                    />

                                                </Box>
                                                <Box marginLeft={'10px'}>
                                                    <Typography variant="body1" style={{ fontWeight: 400 }} color="error">{days.fromDate}&nbsp;-&nbsp;{days.toDate}</Typography>

                                                </Box>


                                            </Box>
                                            {this.state.blockedCalenderStatus === days.id && this.calendarRender(days, index)}

                                        </>
                                    )
                                })}
                                {this.renderEmptyBlockOffDays()}
                            </>
                                :

                                <Box marginTop={'15px'}>

                                    {this.state.blockOffDays.map((day: BlockOffDays) => {

                                        return (
                                            <>
                                                <Box className={classes.blockOffDaysBox}
                                                    data-test-id='open_cal'
                                                    key={day.id}>
                                                    <Box>
                                                        <CalendarTodayIcon style={{ color: "#326478" }} />

                                                    </Box>
                                                    <Box marginLeft={'10px'}>
                                                        <Typography variant="body1" style={{ fontWeight: 400 }} color="error">{day.fromDate}&nbsp;{day.toDate && "-"}&nbsp;{day.toDate}</Typography>
                                                    </Box>
                                                </Box>
                                                <br />
                                            </>
                                        )
                                    })

                                    }
                                    {this.renderEmptyBlockOffDays()}

                                </Box>



                            }
                        </Grid>

                    </Grid>
                </Grid>

            </>
        )
    }

    renderRepresentativeDetails = () => {
        const { classes } = this.props;
        return (
            <Grid item xs={12}>
                <Grid container justifyContent="space-between" alignItems="center" spacing={3}>
                    <Grid item>
                        <Typography variant="subtitle2" className={classes.labelText}>{configJSON.propertyText.represenatative}</Typography>

                    </Grid>
                    <Grid item>
                        <Typography data-test-id='edit_representative'
                            onClick={this.state.representataiveEditStatus ? this.addNewRepresentative : () => { this.listingFormEdit('representative') }}
                            variant="body1" className={classes.editText}>
                            {!this.state.representataiveEditStatus ? configJSON.propertyText.edit : 'Add More'

                            }
                        </Typography>
                    </Grid>

                    {this.state.representativeDetails.map((details: RepresentativeDetail, index: number) => {
                        return (
                            <>
                                <Grid item xs={12}>
                                    <Grid container direction="row" spacing={this.state.representataiveEditStatus ? 3 : 1} alignItems="center">
                                        {this.state.representataiveEditStatus ?
                                            <>
                                                <Grid item xs={12}>
                                                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                                        <Box>
                                                            <Typography variant="body1" gutterBottom className={classes.labelText}>{`Representative details (${(index + 1)})`}</Typography>
                                                        </Box >
                                                        <Box display={'flex'}>
                                                            <Box>
                                                                <Typography variant="body1" color="secondary" style={{ marginRight: '10px' }}
                                                                    data-test-id='update_rep'
                                                                    className={classes.editText}
                                                                    onClick={() => this.handleUpdateRepresentativeDetails(details)}
                                                                >

                                                                    {details.type === 'new' ? "Save" : "Update"}

                                                                </Typography>
                                                            </Box>
                                                            <Box>
                                                                {this.state.representativeDetails.length > 1 &&
                                                                    <Typography variant="body1" color="secondary" gutterBottom
                                                                        className={classes.editText}
                                                                        onClick={() => this.handleDeleteRepresentative(details)}
                                                                        data-test-id='delete_rep'
                                                                    >Delete</Typography>
                                                                }
                                                            </Box>

                                                        </Box>


                                                    </Grid>

                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid container>
                                                        <Typography variant="body1" gutterBottom className={classes.labelText}>{configJSON.paymentInformation.firstNam}</Typography>

                                                        <Grid item xs={2} style={{ position: 'relative', cursor: 'pointer' }}>
                                                            <CustomizedTextField
                                                                fullWidth
                                                                style={{ cursor: 'pointer' }}
                                                                className={classes.countryDropDow}
                                                                value={details.representative_contact_title}
                                                                variant="outlined"
                                                                InputProps={{
                                                                    readOnly: true,
                                                                    endAdornment: (
                                                                        <img src={arrowDown} style={{ cursor: 'pointer' }} />
                                                                    )
                                                                }}
                                                                data-test-id='representative_contact_title'
                                                                onClick={() => this.openNameTypeDropDowns('salutation', details.id)}
                                                            />
                                                            {this.state.repId == details.id && this.rendernameTypes('salutation', index)}
                                                        </Grid>
                                                        <Grid item xs={10}>
                                                            <CustomizedTextField
                                                                fullWidth
                                                                placeholder="First Name"
                                                                className={classes.dropDown}
                                                                onChange={(event) => this.handleInputChange(index, event, 'first_name')}
                                                                name="first_name"
                                                                value={details.first_name}
                                                                variant="outlined"
                                                                data-test-id="first_name"
                                                            />

                                                        </Grid>
                                                        <Typography variant="body1" color="error">{this.state.repErrors[index]?.first_name}</Typography>


                                                    </Grid>

                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="body1" gutterBottom className={classes.labelText}>{configJSON.paymentInformation.surname}</Typography>

                                                    <CustomizedTextField
                                                        fullWidth
                                                        placeholder="Last Name"
                                                        name="surname"
                                                        value={details.surname}
                                                        variant="outlined"
                                                        data-test-id='surname'
                                                        onChange={(event) => this.handleInputChange(index, event, 'surname')}

                                                    />

                                                    <Typography variant="body1" color="error">{this.state.repErrors[index]?.surname}</Typography>



                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="body1" gutterBottom className={classes.labelText}>{configJSON.paymentInformation.email}</Typography>

                                                    <CustomizedTextField
                                                        fullWidth
                                                        placeholder="kevinbarrett@iamacat.com"
                                                        name="email"
                                                        value={details.email}
                                                        variant="outlined"
                                                        data-test-id='representative_email'
                                                        onChange={(event) => this.handleInputChange(index, event, 'email')}

                                                    />

                                                    <Typography variant="body1" color="error">{this.state.repErrors[index]?.email}</Typography>



                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="body1" gutterBottom className={classes.labelText}>{configJSON.paymentInformation.Phonenumber}</Typography>
                                                    <Grid container>

                                                        <Grid item xs={4} style={{ position: 'relative', cursor: 'pointer' }}>
                                                            <CustomizedTextField
                                                                fullWidth
                                                                className={classes.countryDropDow}
                                                                value={details.representive_country_code}
                                                                variant="outlined"
                                                                InputProps={{
                                                                    readOnly: true,
                                                                    endAdornment: (
                                                                        <img src={arrowDown} style={{ cursor: 'pointer' }} />
                                                                    )
                                                                }}
                                                                data-test-id='representativeCountryCode'
                                                                onClick={() => this.openNameTypeDropDowns('repCountryCode', details.id)}

                                                            />
                                                            {this.state.repCountryCode === details.id && this.renderCountryCodes('repCountryCode', index)}
                                                        </Grid>
                                                        <Grid item xs={8}>
                                                            <CustomizedTextField
                                                                fullWidth
                                                                className={classes.dropDown}
                                                                onChange={(event) => this.handleInputChange(index, event, 'reprsentive_phone_number')}
                                                                name="reprsentive_phone_number"
                                                                value={details.reprsentive_phone_number}
                                                                variant="outlined"
                                                                data-test-id="reprsentive_phone_number"
                                                                placeholder="Phone Number"
                                                            />

                                                        </Grid>
                                                        <Typography variant="body1" color="error">{this.state.repErrors[index]?.reprsentive_phone_number}</Typography>


                                                    </Grid>

                                                </Grid>

                                            </> :
                                            <>

                                                {this.state.representativeDetails.length > 1 &&
                                                    <Grid item xs={12}>

                                                        <Typography variant="body1" gutterBottom className={classes.labelText}>{`Representative details (${(index + 1)})`}</Typography>
                                                    </Grid>
                                                }
                                                <Grid item xs={12}>
                                                    <Grid container spacing={1} direction="row">
                                                        <Grid item xs={12}>
                                                            <Typography variant="body1" className={classes.licienceText}>{details.representative_contact_title}&nbsp;
                                                                <Typography display="inline" variant="body1" className={classes.licienceText}>{details.first_name}

                                                                </Typography>
                                                            </Typography>

                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography display="inline" variant="body1" className={classes.licienceText}>{details.surname}

                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography display="inline" variant="body1" className={classes.licienceText}>{details.email}

                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography variant="body1" className={classes.licienceText}>{details.representive_country_code}&nbsp;
                                                                <Typography display="inline" variant="body1" className={classes.licienceText}>{details.reprsentive_phone_number}

                                                                </Typography>
                                                            </Typography>
                                                        </Grid>

                                                    </Grid>

                                                </Grid>

                                            </>}
                                    </Grid>

                                </Grid>
                            </>

                        )

                    })}



                </Grid>


            </Grid>
        )
    }


    renderAddressDetails = () => {
        const { classes } = this.props;
        const { hotelForm, addressEditStatus } = this.state;
        const { postCode, additionalAddress, houseNumber, town, county, country_region } = hotelForm
        return (
            <>
                <Grid item xs={12}>
                    <Grid container justifyContent="space-between" alignItems="center" spacing={3}>
                        <Grid item>
                            <Typography variant="subtitle2" className={classes.labelText}>{configJSON.propertyText.address}</Typography>

                        </Grid>
                        <Grid item>
                            <Typography data-test-id='edit_address'
                                onClick={addressEditStatus ? this.manageUpdateHotelDetails : () => { this.listingFormEdit('address') }}
                                variant="body1" className={classes.editText}>
                                {addressEditStatus ? configJSON.propertyText.save : configJSON.propertyText.edit}
                            </Typography>

                        </Grid>
                        <Grid item xs={12}>
                            <Grid container direction="row" spacing={addressEditStatus ? 3 : 1} alignItems="center">
                                {addressEditStatus ?
                                    <>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" gutterBottom className={classes.labelText}>{configJSON.propertyText.editAddress1}</Typography>

                                            <CustomizedTextField
                                                fullWidth
                                                placeholder="E.g. 20 Salisbury Avenue"
                                                name="houseNumber"
                                                value={houseNumber}
                                                variant="outlined"
                                                data-test-id='houseNumber'
                                                onChange={this.handleListingChange}

                                            />
                                            <Typography variant="body1" color="error">{this.state.hotelFormErrors?.houseNumber}</Typography>

                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" gutterBottom className={classes.labelText}>{configJSON.propertyText.editAddress2}</Typography>

                                            <CustomizedTextField
                                                fullWidth
                                                placeholder="E.g. flat, suite, building"
                                                name="additionalAddress"
                                                value={additionalAddress}
                                                variant="outlined"
                                                data-test-id='additionalAddress'

                                                onChange={this.handleListingChange}

                                            />

                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" gutterBottom className={classes.labelText}>{configJSON.propertyText.editAddress3}</Typography>

                                            <CustomizedTextField
                                                fullWidth
                                                placeholder="E.g. London"
                                                name="town"
                                                value={town}
                                                variant="outlined"
                                                data-test-id='town'
                                                onChange={this.handleListingChange}

                                            />
                                            <Typography variant="body1" color="error">{this.state.hotelFormErrors?.town}</Typography>

                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" gutterBottom className={classes.labelText}>{configJSON.propertyText.editAddress4}</Typography>

                                            <CustomizedTextField
                                                fullWidth
                                                placeholder="E.g. Middlesex"
                                                name="county"
                                                value={county}
                                                variant="outlined"
                                                data-test-id='county'
                                                onChange={this.handleListingChange}

                                            />
                                            <Typography variant="body1" color="error">{this.state.hotelFormErrors?.county}</Typography>

                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body1" gutterBottom className={classes.labelText}>{configJSON.propertyText.editAddress5}</Typography>

                                            <CustomizedTextField
                                                fullWidth
                                                placeholder="E.g. SW1A 1AA"
                                                name="postCode"
                                                value={postCode}
                                                variant="outlined"
                                                data-test-id="postCode"
                                                onChange={this.handleListingChange}

                                            />
                                            <Typography variant="body1" color="error">{this.state.hotelFormErrors.postCode}</Typography>

                                        </Grid>
                                        <Grid item xs={12} style={{ position: 'relative' }}>
                                            <Typography variant="body1" gutterBottom className={classes.labelText}>{configJSON.propertyText.editAddress6}</Typography>

                                            <CustomizedTextField
                                                fullWidth
                                                className={classes.singleInputField}
                                                name="country_region"
                                                value={country_region}
                                                variant="outlined"
                                                InputProps={{
                                                    readOnly: true,
                                                    endAdornment: (
                                                        <img src={arrowDown} style={{ cursor: 'pointer' }} />
                                                    )
                                                }}
                                                onClick={() => this.openNameTypeDropDowns('addressCountry', 0)}
                                            />
                                            {this.state.addressCountryStatus && this.renderAddressCountry('addressCountry')}

                                        </Grid>

                                    </> :
                                    <><Grid item>

                                        <img src={Location} />
                                    </Grid>
                                        <Grid item>
                                            <Typography variant="body1" color="textSecondary">{this.state.hotelDetails?.attributes?.hotel_city}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body1" color="textSecondary">{this.state.hotelDetails?.attributes?.hotel_country_region}</Typography>

                                        </Grid>
                                    </>}
                            </Grid>

                        </Grid>


                    </Grid>


                </Grid>
            </>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { hotelForm, propertyNameEditStatus, descriptionEditStatus, hotelData, contactDetailsEditStatus, licienceNumberEditStatus } = this.state;
        const { propertyName, emailAddress, phoneNumber, phonecountryCode, description, licienceNumber } = hotelForm
        return (<>
            <CustomizedSnackbars openToaster={this.state.toasterStatus} message={this.state.toasterText}
                toasterType={"error"}
                closeStatus={this.closeToaster}
            />
            <Grid item xs={12}>
                <Grid container direction="row" spacing={4}>
                    <Grid item xs={12}>
                        <Grid container justifyContent="space-between" alignItems="center" spacing={3}>
                            <Grid item>
                                <Typography variant="body1" className={classes.labelText}>{configJSON.propertyText.property}</Typography>

                            </Grid>
                            <Grid item>
                                <Typography data-test-id='edit_name'
                                    onClick={propertyNameEditStatus ? this.manageUpdateHotelDetails : () => { this.listingFormEdit('propertyName') }}
                                    variant="body1" className={classes.editText}>
                                    {propertyNameEditStatus ? configJSON.propertyText.save : configJSON.propertyText.edit}
                                </Typography>

                            </Grid>
                            <Grid item xs={12}>
                                {propertyNameEditStatus ?
                                    <>
                                        <CustomizedTextField
                                            fullWidth
                                            name="propertyName"
                                            onChange={this.handleListingChange}
                                            value={propertyName}
                                            variant="outlined"
                                            data-test-id="propertyName"

                                        />
                                        <Typography variant="body1" color="error">{this.state.hotelFormErrors?.propertyName}</Typography>
                                    </>
                                    :
                                    <Typography variant="body1" className={classes.tabText}>{propertyName}</Typography>

                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justifyContent="space-between" alignItems="center" spacing={3}>
                            <Grid item>
                                <Typography variant="body1" className={classes.labelText}>
                                    {configJSON.morethan30DaysText}
                                </Typography>
                            </Grid>
                            {this.renderMoreThan30NightStay()}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justifyContent="space-between" alignItems="center" spacing={3}>
                            <Grid item>
                                <Typography variant="body1" className={classes.labelText}>{configJSON.propertyText.licience}</Typography>

                            </Grid>
                            <Grid item>
                                <Typography data-test-id='edit_licience'
                                    onClick={licienceNumberEditStatus ? this.manageUpdateHotelDetails : () => { this.listingFormEdit('licience') }}
                                    variant="body1" className={classes.editText}>
                                    {licienceNumberEditStatus ? configJSON.propertyText.save : configJSON.propertyText.edit}
                                </Typography>

                            </Grid>

                            <Grid item xs={12}>
                                {licienceNumberEditStatus ?
                                    <>
                                        <CustomizedTextField
                                            fullWidth
                                            name="licienceNumber"
                                            onChange={this.handleListingChange}
                                            value={licienceNumber}
                                            variant="outlined"
                                            data-test-id="licienceNumber"

                                        />
                                        <Typography variant="body1" color="error">{this.state.hotelFormErrors?.licienceNumber}</Typography>
                                    </>
                                    :
                                    <Typography variant="body1" className={classes.tabText}>{licienceNumber}</Typography>

                                }

                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justifyContent="space-between" alignItems="center" spacing={3}>
                            <Grid item>
                                <Typography variant="body1" className={classes.labelText}>{configJSON.propertyText.contact}</Typography>

                            </Grid>
                            <Grid item>
                                <Typography data-test-id='edit_contact'
                                    onClick={contactDetailsEditStatus ? this.manageUpdateHotelDetails : () => { this.listingFormEdit('contact') }}
                                    variant="body1" className={classes.editText}>
                                    {contactDetailsEditStatus ? configJSON.propertyText.save : configJSON.propertyText.edit}
                                </Typography>


                            </Grid>
                            <Grid item xs={12}>
                                {contactDetailsEditStatus ?
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Grid container>
                                                <Grid item xs={4} style={{ position: 'relative' }}>

                                                    <CustomizedTextField
                                                        fullWidth
                                                        className={classes.countryDropDow}
                                                        value={phonecountryCode}
                                                        variant="outlined"
                                                        InputProps={{
                                                            readOnly: true,
                                                            endAdornment: (
                                                                <img src={arrowDown} style={{ cursor: 'pointer' }} />
                                                            )
                                                        }}
                                                        data-test-id='contactCountry'
                                                        onClick={() => this.openNameTypeDropDowns('contactCountry', -1)}
                                                    />
                                                    {this.state.countryCodeStatus && this.renderCountryCodes('contactCountry', 0)}
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <CustomizedTextField
                                                        fullWidth
                                                        className={classes.dropDown}
                                                        onChange={this.handleListingChange}
                                                        name="phoneNumber"
                                                        value={phoneNumber}
                                                        variant="outlined"
                                                        data-test-id="phoneNumber"
                                                    />

                                                </Grid>
                                                <Typography variant="body1" color="error">{this.state.hotelFormErrors?.phoneNumber}</Typography>

                                            </Grid>

                                        </Grid>
                                        <Grid item xs={12}>
                                            <CustomizedTextField
                                                fullWidth
                                                name="emailAddress"
                                                value={emailAddress}
                                                onChange={this.handleListingChange}
                                                variant="outlined"
                                                data-test-id="emailAddress"
                                            />
                                            <Typography variant="body1" color="error">{this.state.hotelFormErrors?.emailAddress}</Typography>

                                        </Grid>

                                    </Grid>

                                    :
                                    <>
                                        <Typography variant="body1" className={classes.licienceText}>{this.state.hotelDetails?.attributes?.phone_number_country_code}&nbsp;
                                            <Typography variant="body1" display="inline" className={classes.licienceText}>{this.state.hotelDetails?.attributes?.phone_number}
                                            </Typography>
                                        </Typography>
                                        <Typography variant="body1" className={classes.licienceText}>{this.state.hotelDetails?.attributes?.email}</Typography>
                                    </>

                                }



                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justifyContent="space-between" alignItems="center" spacing={3}>
                            <Grid item>
                                <Typography variant="body1" className={classes.labelText}>{configJSON.propertyText.description}</Typography>

                            </Grid>
                            <Grid item>
                                <Typography data-test-id='edit_description'
                                    onClick={descriptionEditStatus ? this.manageUpdateHotelDetails : () => { this.listingFormEdit('description') }}
                                    variant="body1" className={classes.editText} >
                                    {descriptionEditStatus ? configJSON.propertyText.save : configJSON.propertyText.edit}
                                </Typography>

                            </Grid>
                            <Grid item xs={12}>
                                {descriptionEditStatus ?
                                    <>
                                        <CustomizedTextField
                                            className={classes.descriptionInput}
                                            fullWidth
                                            multiline
                                            data-test-id='description'
                                            name="description"
                                            value={description}
                                            onChange={this.handleListingChange}
                                            variant="outlined"
                                            minRows={5}
                                        />
                                        <Typography variant="body1" color="error">{this.state.hotelFormErrors?.description}</Typography>

                                    </>
                                    :


                                    <Typography variant="body1" color="textSecondary"
                                        className={classes.descriptionTextLayout}
                                    >{this.state.hotelDetails?.attributes?.description}</Typography>

                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    {!descriptionEditStatus &&
                        <>
                            {this.state.hotelDetails?.attributes?.description && this.state.hotelDetails?.attributes?.description.length > 100 &&
                                <Grid item xs={12} container alignItems="center" style={{ cursor: 'pointer' }}>
                                    <Typography variant="body1"
                                        data-test-id='more_description'
                                        className={classes.labelText}
                                        onClick={this.openMoreDescription}
                                    >Read more
                                    </Typography>
                                    <KeyboardArrowRightIcon />
                                </Grid>
                            }
                        </>

                    }

                    {this.renderBlockOffDays()}
                    {this.renderAddressDetails()}
                    {this.renderRepresentativeDetails()}


                </Grid>
                {this.renderMapSection(hotelData)}

            </Grid>
            {this.state.moreDescription &&
                <CustomizedDailog
                    open={this.state.moreDescription}
                    handleClose={this.closeMoreDescription}
                    data-test-id="handle_PropertyModal_Close"
                    dailogTitle={this.morePropertyDetailsDexcription}
                    typeOfModel={'description'}
                />
            }
            <Loader loading={this.state.pageLoading} />
        </>
        )
        // Customizable Area End
    }
}

// Customizable Area Start
const MapBox = styled(Box)({
    "& .gm-style": {
        display: "none"
    },
    "& .place-card-large": {
        display: "none"
    },
    "& .mapContainer": {
        width: "100%",
        height: "100%"
    }
});

const step3Styles = {
    fontHading: {
        fontSize: "30px",
        fontWeight: "400",
        color: "#334155",
        marginBottom: "0px",
        fontFamily: "inter",
    },
    nextButton: {
        height: "44px",
        width: "210px",

        margin: "10px 0px 0px 0px",
        borderRadius: "8px",
        padding: "10px 16px 10px 16px",
        backgroundColor: "#64748B",
        color: "#CBD5E1",
        fontWeight: "700",
    },
    onClickNextButton: {
        fontWeight: "700",
        width: "210px",
        borderRadius: "8px",
        height: "44px",
        padding: "10px 16px 10px 16px",
        color: "#fff",
        backgroundColor: "#64748B",
        margin: "10px 0px 0px 0px",
    },
    backButton: {
        color: "#64748B",
        width: "76px",
        height: "26px",
        fontWeight: "700",
        backgroundColor: "#fff",
        padding: "10px 16px 10px 16px",
    },
    buttonGrid: {
        flexWrap: "wrap",
        margin: "40px 0px 30px 0px",
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
    },
    inputStyle: {
        width: "100%",
        height: "45px",
        marginTop: "10px",
        paddingLeft: "10px",
        border: "1px solid #B2E4F8",
        borderRadius: "8px",
        fontSize: "14px",
        fontFamily: "inter",
    },
    countrynameselect: {
        paddingLeft: "10px",
        width: "99%",
        height: "45px",
        marginTop: "10px",
        border: "1px solid #B2E4F8",
        borderRadius: "8px",
        backgroundColor: "#E0F4FC",
        position: "relative",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    countryPhnameselect: {
        paddingLeft: "10px",
        width: "40%",
        height: "43px",
        border: "1px solid #B2E4F8",
        borderRadius: "8px 0px 0px 8px",
        backgroundColor: "#E0F4FC",
        position: "relative",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    lableText: {
        fontSize: "14px",
        fontWeight: "700",
        color: "#334155",
        fontFamily: "inter",
    },
    optionalText: {
        fontSize: "14px",
        fontWeight: "400",
        color: "#334155",
        display: 'inline'
    },
    lableTextSec: {
        fontSize: "14px",
        fontWeight: "700",
        color: "#0F172A",
        fontFamily: "inter",
    },
    mainContainer: {
        width: "100%",
        margin: "10px 0px 0px",
    },
    mainContainerSec: {
        width: "100%",
        margin: "0px",
    },
    mainContainerDiv: {
        marginTop: "30px",
    },
    mainContainerSecDiv: {
        marginTop: "20px",
    },
    mapGrid: { width: "100%", height: "406px" },
    mapStyle: { width: "100%", height: "100%" },
    whoElse: {
        fontSize: "24px",
        color: "#334155",
        marginTop: "5%",
        fontFamily: "inter",
        fontWeight: 400,
    },
    expendIconStyle: {
        position: "absolute",
        top: "25%",
        right: "2%",
    },
    countrySpan: {
        color: "#0F172A",
        fontSize: "16px",
        fontWeight: 400,
        fontFamily: "inter",
    },
    noemptyText: {
        height: '100px',

    },
    boldText: {
        fontWeight: theme.typography.fontWeightBold,

    }

};
// Customizable Area End

// index.js - WEB
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import registerServiceWorker from '../../components/src/registerServiceWorker';
import { ThemeProvider } from '@material-ui/core';
import { theme } from '../../components/src/theme';
import "leaflet/dist/leaflet.css";
ReactDOM.render(
  <ThemeProvider theme={theme}>
  <Router>
    <App />
  </Router>
  </ThemeProvider>,
  document.getElementById('root')
);
registerServiceWorker();

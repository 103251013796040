import React from "react";
// Customizable Area Start
import {
    Box,
    Button,
    ClickAwayListener,
    Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, MenuItem, Typography
} from "@material-ui/core";
import { Theme, WithStyles, createStyles, withStyles } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import { boxShadows, colors, theme } from "../../../components/src/theme";
import { arrowDown } from "../../settings2/src/assets";
import { PetFilter, StatusFilter } from "./types";
import { crossIcon } from "../../landingpage/src/assets";
import { configJSON } from "./PropertyDetailsController.web";

// Customizable Area End

// Customizable Area Start

interface Props extends WithStyles{
    StatusType: StatusFilter[];
    petType: PetFilter[];
    statusName: string;
    statusValue: string;
    petName: string,
    petValue: string,
    selectStatusFilter:any;
    selectpetsFilter:any;
    clearStatus:any;
    searchFilter:any;
    closeFilterDropDowns:any
    openFilterDropDowns:any;
    dailogOpen:boolean;
    closeDailog:any;
    statusdropDownStatus:boolean;
    dropDownStatus:boolean;
    clearFilters:()=>void;
    popUpType?:string;
    deleteHotel?:()=>void;
}
// Customizable Area End

export class PropertyFilters extends React.Component<Props>  {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderFilters = (type: string) => {

        const { classes ,closeFilterDropDowns} = this.props;
    
        return (
            <>
                <ClickAwayListener onClickAway={() => closeFilterDropDowns(type)}>
                    <Box className={classes.outerFilter}>
                        <Box className={classes.innerFilter}>
                            {type ==='pet_type'&&this.renderPetTypes()}
                            {type ==='status'&&this.renderStatus()}


                        </Box>

                    </Box>

                </ClickAwayListener>
            </>
        )
    }

    renderStatus = () => {
        const { classes,StatusType,selectStatusFilter } = this.props;
        return (
            <>
                {StatusType.map((status: StatusFilter) => {
                    return (
                        <>
                            <MenuItem
                            data-test-id="select_status"
                                onClick={() => {
                                    selectStatusFilter(status)
                                }}
                                className={classes.dropDownText}
                                key={status.id}
                                value={status.StatusValue}>
                                {status.StatusType}
                            </MenuItem>
                        </>
                    )

                })}
            </>
        )
    }
    renderPetTypes = () => {
        const { classes,petType ,selectpetsFilter} = this.props;
        return (
            <>
                {petType.map((type: PetFilter) => {
                    return (
                        <>
                            <MenuItem
                            data-test-id='select_pet_filter'
                                onClick={() => {
                                    selectpetsFilter(type)
                                }}
                                className={classes.dropDownText}
                                key={type.id}
                                value={type.petValue}>
                                {type.petType}
                            </MenuItem>
                        </>
                    )

                })}
            </>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes,openFilterDropDowns,statusName,searchFilter,statusdropDownStatus,dropDownStatus,petName,clearFilters,deleteHotel} = this.props;
        
        return (
            <>
                <Dialog open={this.props.dailogOpen} maxWidth={'md'}
                    onClose={this.props.closeDailog} aria-labelledby="form-dialog-title"
                    PaperProps={{
                        style: {
                            maxWidth: "634px",
                            width: '100%',
                            height:this.props.popUpType !="confirmation"?'400px':"300px",
                            borderRadius: '8px',
                        },
                    }}
                >
                    <DialogTitle disableTypography style={this.props.popUpType !="confirmation"?webStyle.closeIconLayout:webStyle.onlyIcon}>
                    {this.props.popUpType !="confirmation" &&<Typography style={webStyle.dailogHeading} variant="h4">Filters</Typography>}

                        <IconButton aria-label="close"
                            data-test-id="close_dailog"
                            onClick={this.props.closeDailog} >
                            <CloseIcon />

                        </IconButton>

                    </DialogTitle>
                    <DialogContent className={classes.dailogContent}>
                    {this.props.popUpType !="confirmation" ?<Grid item xs={12} style={{ width: '100%' }}>
                            <Grid container direction="row" spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="body1" className={classes.inputLabelText} gutterBottom>{configJSON.propertyFilter.petlabel}</Typography>

                                    <Box style={{ position: 'relative' }}>
                                        <Box className={classes.boxField} 
                                        data-test-id="pet_type_popup_open"
                                        onClick={() => openFilterDropDowns('pet_type')}>
                                            <Box className={classes.boxContent}>

                                                <Typography variant="body1">{petName}</Typography>
                                                <img src={arrowDown} />
                                            </Box>

                                        </Box>
                                        {dropDownStatus && this.renderFilters('pet_type')}
                                    </Box>

                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body1" className={classes.inputLabelText} gutterBottom>{configJSON.propertyFilter.status}</Typography>

                                    <Box style={{ position: 'relative' }}>
                                        <Box className={classes.boxField} 
                                        data-test-id="status_open_popup"
                                        onClick={() => openFilterDropDowns('status')}>
                                            <Box className={classes.boxContent}>
                                                <Typography variant="body1">{statusName}</Typography>
                                                <img src={arrowDown} />
                                            </Box>

                                        </Box>
                                        {statusdropDownStatus && this.renderFilters('status')}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>:

                        <>
                         <Typography variant="h4" style={webStyle.dailogHeading} gutterBottom>
                                    Delete Hotel
                                </Typography>
                                <Typography variant="subtitle2" style={webStyle.dailogSubHeading} gutterBottom>
                                    Are you sure you want to delete the hotel?
                                </Typography>
                        </>}
                    </DialogContent>
                    <DialogActions className={classes.padding15}>
                   {this.props.popUpType !="confirmation" ?
                   <>
                    <Button variant="outlined" color={'secondary'}
                            style={webStyle.filterButton as React.CSSProperties}
                            data-test-id="clearFilters"
                            onClick={clearFilters}
                        >
                            Clear Filters
                        </Button>
                        <Button variant="contained" color={'secondary'}
                            className={classes.filterSearchButton}
                            data-test-id="filterSearchButton"
                            onClick={searchFilter}

                        >
                            Search
                        </Button>
                        </>:
                        <>
                         <Button variant="outlined" color={'secondary'}
                            style={webStyle.filterButton as React.CSSProperties}
                            data-test-id="clearFilters"
                            onClick={clearFilters}
                        >
                            No
                        </Button>
                        <Button variant="contained" color={'secondary'}
                            className={classes.filterSearchButton}
                            data-test-id="delete_hotel"
                            onClick={deleteHotel}

                        >
                            Yes
                        </Button>
                        </>
                        }
                    </DialogActions>
                </Dialog>

            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const PropertyFiltersStyles = (theme: Theme) =>
    createStyles({
        closeIconLayout: {
            padding: 10,
            display: 'flex',
            justifyContent: "space-between",
            alignItems: 'center',
            fontSize: 24,

        },
        filterText: {
            color: theme.palette.common.black,
            fontWeight: theme.typography.fontWeightBold
        },
        filterSearchButton: {

            width: '50%',
            textTransform: 'capitalize',
            height: 56,
            borderRadius: 8,
            fontSize: 16,
            color: theme.palette.common.white,
            fontWeight: theme.typography.fontWeightBold,
            '&:hover': {
                backgroundColor: theme.palette.secondary.main
            },
            [theme.breakpoints.down('xs')]: {
                width: '100%'
            }
        },
        padding15: {
            padding: 15
        },
        dailogHeading: {
            color: theme.palette.common.black,
            fontWeight: theme.typography.fontWeightBold
        },
        dailogSubHeading: {
            color: theme.palette.common.black,
            fontWeight: theme.typography.fontWeightRegular
        },
        dailogContent: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'

        },
        dividerLayout: {
            flexBasis: "auto !important",
            marginBottom: 15
        },
        yesButton: {
            border: `2px solid ${theme.palette.secondary.main}`,
            textTransform: 'capitalize',
            height: 56,
            borderRadius: 8,
            fontSize: 16,
            fontWeight: theme.typography.fontWeightBold,
            '&:hover': {
                border: `2px solid ${theme.palette.secondary.main}`,

            },
            width: '100%'

        },
        inputLabelText: {
            opacity: '0.5',
            color: '#000'
        },
        boxField: {
            width: '100%',
            height: 44,
            border: `1px solid ${colors.primaryBlue}`,
            borderRadius: 8,
            backgroundColor: theme.palette.primary.main,
            cursor: "pointer",
        },
        outerFilter: {
            position: 'absolute',
            boxShadow: boxShadows.whiteShadow,
            borderRadius: 8,
            zIndex: 99,
            width: "100%",
            height: "auto",
            backgroundColor: theme.palette.common.white,
            padding: '0.5rem'

        },
        innerFilter: {
            padding: '0.5rem'
        },
        dropDownText: {
            color: theme.palette.common.black,
            fontSize: 14,
            padding: '0.5rem',
            fontWeight: theme.typography.fontWeightRegular,
            '&:hover': {
                background: colors.primaryBlue,
                borderRadius: 8
            }
        },
        boxContent: {
            display: "flex",
            justifyContent: " space-between",
            alignItems: "center",
            padding: "0 15px",
            height: "100%",
        },
        selectStatus: {
            border: `1px solid ${theme.palette.secondary.main}`,
            borderRadius: 8,
            backgroundColor: theme.palette.common.white,
            width: '100%',
            height: 44
        }

    })
export default withStyles(PropertyFiltersStyles)(PropertyFilters)

const webStyle={
    filterButton:{
        width: '50%',
        textTransform: 'capitalize',
        height: 56,
        borderRadius: 8,
        fontSize: 16,
        color: theme.palette.secondary.main,
        fontWeight: theme.typography.fontWeightBold,
        '&:hover': {
            backgroundColor: theme.palette.common.white
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    dailogHeading: {
        color: theme.palette.common.black,
        fontWeight: theme.typography.fontWeightBold
    },
    dailogSubHeading: {
        color: theme.palette.common.black,
        fontWeight: theme.typography.fontWeightRegular
    },
    closeIconLayout: {
        padding: 10,
        display: 'flex',
        justifyContent: "space-between",
        alignItems: 'center',
        fontSize: 24,

    }, onlyIcon: {
        padding: 10,
        display: 'flex',
        justifyContent: "flex-end",
        alignItems: 'center',
        fontSize: 24,

    },
}
// Customizable Area End
